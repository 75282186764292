import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, message, Modal, Select, Space, Switch} from "antd";
import {Loading} from "../../libs/loading";
import {loadDomainGroup, updateDomainGroup} from "../../actions/domains";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadHostingAccounts} from "../../actions/hosting";
import {loadIPAddressGroups} from "../../actions/ip_addresses";

class GroupSettingsModal extends React.Component {
    state = {
        loading: false,
        dataLoaded: false,
        loadingHostingAccounts: true,
        name: '',
        defaultHostingAccount: '',
        availableHostingAccounts: [],
        listSubdomains: true,
        loadingIPGroups: false,
        selectedIPGroups: [],
        availableIPGroups: []
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.dataLoaded && this.props.showModal && this.state.availableHostingAccounts.length === 0) {
            this.props.loadDomainGroup(this.props.group, (res) => {
                this.setState({
                    dataLoaded: true,
                    name: res.data.name,
                    defaultHostingAccount: res.data.default_hosting_account.guid,
                    listSubdomains: res.data.include_subdomains_in_list,
                    selectedIPGroups: res.data.allowed_ip_address_groups.map((group) => group.guid)
                })

                this.props.loadHostingAccounts(1, 9999, (res) => {
                    this.setState({ loadingHostingAccounts: false, availableHostingAccounts: res.data.data });
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                        this.setState({ loadingHostingAccounts: false });
                    }
                });

                this.props.loadIPAddressGroups(1, 9999, null, (res) => {
                    this.setState({ availableIPGroups: res.data.data });
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                        this.setState({ loadingIPGroups: false });
                    }
                });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                    this.setState({ dataLoaded: true })
                }
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal() {
        this.props.close();
        this.setState({ dataLoaded: false, loadingHostingAccounts: true, availableHostingAccounts: []});
    }

    saveSettings() {
        this.setState({ loading: true });

        if(this.state.name.trim() === '') {
            return message.error('Please enter your group\'s name!');
        }

        if(this.state.defaultHostingAccount.trim() === '') {
            return message.error('Please enter your group\'s default hosting account!');
        }

        let data = {
            name: this.state.name,
            default_hosting_account: this.state.defaultHostingAccount,
            include_subdomains_in_list: this.state.listSubdomains,
            allowed_ip_address_groups: this.state.selectedIPGroups
        };

        this.props.updateDomainGroup(this.props.group, data, () => {
            message.success('Group successfully updated!');
            this.setState({ loading: false });
            this.closeModal();
            this.props.reloadGroup();

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    render() {
        let content;

        if(!this.state.dataLoaded) {
            content = <div className='text-center'><Loading /></div>;
        } else {
            const { Option } = Select;

            content = <Form layout='vertical'>
                <Form.Item label='Group name'>
                    <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                </Form.Item>
                <Form.Item label='Hosting account' extra={<small><em>The default hosting account used for domains.</em></small>}>
                    <Select value={this.state.defaultHostingAccount}
                            disabled={this.state.loadingHostingAccounts}
                            loading={this.state.loadingHostingAccounts}
                            onChange={(value) => this.setState({ defaultHostingAccount: value })}>
                        {this.state.availableHostingAccounts.map((account) => {
                            return <Option disabled={account.status !== 'ACTIVE'} key={account.guid} value={account.guid}>{account.name} <small>({normalizeEnum(account.account_type) + ' - ' + normalizeEnum(account.status)})</small></Option>;
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label='IP address groups' extra={<small><em>The default IP address groups. Set empty if you allow all IP groups.</em></small>}>
                    <Select value={this.state.selectedIPGroups}
                            disabled={this.state.loadingIPGroups}
                            loading={this.state.loadingIPGroups}
                            mode='multiple'
                            onChange={(value) => this.setState({ selectedIPGroups: value })}>
                        {this.state.availableIPGroups.map((account) => {
                            return <Option key={account.guid} value={account.guid}>{account.name}</Option>;
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label='List subdomains' extra={<small><em>Display subdomains in domain list.</em></small>}>
                    <Switch
                        unCheckedChildren='No'
                        checkedChildren='Yes'
                        defaultChecked={this.state.listSubdomains}
                        onChange={(e) => this.setState({ listSubdomains: e})} />
                </Form.Item>
            </Form>;
        }

        return(
            <Modal
                centered
                title={'Settings'}
                open={this.props.showModal}
                destroyOnClose={true}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button type='primary' loading={this.state.loading} onClick={() => this.saveSettings()}>Save</Button>
                </Space>}
                confirmLoading={this.state.loading}
                onCancel={() => this.closeModal()}>
                {content}
            </Modal>
        );
    }
}

export default connect(null, { loadDomainGroup, loadHostingAccounts, updateDomainGroup, loadIPAddressGroups })(GroupSettingsModal);