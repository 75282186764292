import React from "react";
import {connect} from "react-redux";
import {Button, Card, Checkbox, Col, Descriptions, Row, Select, Space} from "antd";
import {normalizeEnum} from "../../libs/utils";

class CompanyUserPermissionsTable extends React.Component {
    state = {

    };

    render() {
        let labelWidth = '190px';        
        const { Option } = Select;


        return(
            <Card bordered={false} title='User Permissions' size='small' extra={
                <Space>
                    <Select placeholder='Select preset' style={{width: '150px'}} onChange={(value) => this.props.selectPreset(value)}>
                        {Object.keys(this.props.presets).map(preset => <Option value={preset} key={preset}>{normalizeEnum(preset)}</Option>)}
                    </Select>
                    <Button onClick={() => this.props.selectAll()}>Select All</Button>
                    <Button onClick={() => this.props.clearAll()}>Clear All</Button>
                </Space>
            }>
                <Descriptions bordered size='small' layout='horizontal' column={1}>
                    <Descriptions.Item label='Domains' labelStyle={{width: labelWidth}}>
                        <Descriptions bordered size='small' layout='horizontal' column={1}>
                            <Descriptions.Item label='General' labelStyle={{width: labelWidth}}>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_domains} onChange={(e) => this.props.updateField('can_view_domains', e.target.checked)}>View domains</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_domains} onChange={(e) => this.props.updateField('can_add_domains', e.target.checked)}>Add domains</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_domains} onChange={(e) => this.props.updateField('can_edit_domains', e.target.checked)}>Edit domains</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_domains} onChange={(e) => this.props.updateField('can_delete_domains', e.target.checked)}>Delete domains</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Groups'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_domain_groups} onChange={(e) => this.props.updateField('can_view_domain_groups', e.target.checked)}>View domain groups</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_domain_groups} onChange={(e) => this.props.updateField('can_add_domain_groups', e.target.checked)}>Add domain groups</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_domain_groups} onChange={(e) => this.props.updateField('can_edit_domain_groups', e.target.checked)}>Edit domain groups</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_domain_groups} onChange={(e) => this.props.updateField('can_delete_domain_groups', e.target.checked)}>Delete domain groups</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='SSL certificates'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_install_ssl_certificates} onChange={(e) => this.props.updateField('can_install_ssl_certificates', e.target.checked)}>Install SSL certificates</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_uninstall_ssl_certificates} onChange={(e) => this.props.updateField('can_uninstall_ssl_certificates', e.target.checked)}>Uninstall SSL certificates</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='DNS zone'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_domain_dns_zone_records} onChange={(e) => this.props.updateField('can_add_domain_dns_zone_records', e.target.checked)}>Add DNS records</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_domain_dns_zone_records} onChange={(e) => this.props.updateField('can_edit_domain_dns_zone_records', e.target.checked)}>Edit DNS records</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_domain_dns_zone_records} onChange={(e) => this.props.updateField('can_delete_domain_dns_zone_records', e.target.checked)}>Delete DNS records</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_reset_domain_dns_zone} onChange={(e) => this.props.updateField('can_reset_domain_dns_zone', e.target.checked)}>Reset DNS zone</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Email addresses'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_email_addresses} onChange={(e) => this.props.updateField('can_add_email_addresses', e.target.checked)}>Add email addresses</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_email_addresses} onChange={(e) => this.props.updateField('can_edit_email_addresses', e.target.checked)}>Edit email addresses</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_email_addresses} onChange={(e) => this.props.updateField('can_delete_email_addresses', e.target.checked)}>Delete email addresses</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='URL redirects'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_domain_url_redirects} onChange={(e) => this.props.updateField('can_add_domain_url_redirects', e.target.checked)}>Add URL redirects</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_domain_url_redirects} onChange={(e) => this.props.updateField('can_edit_domain_url_redirects', e.target.checked)}>Edit URL redirects</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_domain_url_redirects} onChange={(e) => this.props.updateField('can_delete_domain_url_redirects', e.target.checked)}>Delete URL redirects</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Software'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_install_software} onChange={(e) => this.props.updateField('can_install_software', e.target.checked)}>Install software</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_uninstall_software} onChange={(e) => this.props.updateField('can_uninstall_software', e.target.checked)}>Uninstall software</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_login_to_software} onChange={(e) => this.props.updateField('can_login_to_software', e.target.checked)}>Login to software</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_software_installation} onChange={(e) => this.props.updateField('can_edit_software_installation', e.target.checked)}>Edit software installations</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Software presets'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_software_presets} onChange={(e) => this.props.updateField('can_view_software_presets', e.target.checked)}>View software presets</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_software_presets} onChange={(e) => this.props.updateField('can_add_software_presets', e.target.checked)}>Add software presets</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_software_presets} onChange={(e) => this.props.updateField('can_edit_software_presets', e.target.checked)}>Edit software presets</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_software_presets} onChange={(e) => this.props.updateField('can_delete_software_presets', e.target.checked)}>Delete software presets</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Misc'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_domain_caching} onChange={(e) => this.props.updateField('can_edit_domain_caching', e.target.checked)}>Edit caching</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_domain_redirects} onChange={(e) => this.props.updateField('can_edit_domain_redirects', e.target.checked)}>Edit domain redirects</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Descriptions.Item>

                    <Descriptions.Item label='IP addresses' labelStyle={{width: labelWidth}}>
                        <Descriptions bordered size='small' layout='horizontal' column={1}>
                            <Descriptions.Item label='General' labelStyle={{width: labelWidth}}>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_ip_addresses} onChange={(e) => this.props.updateField('can_view_ip_addresses', e.target.checked)}>View IP addresses</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_ip_addresses} onChange={(e) => this.props.updateField('can_add_ip_addresses', e.target.checked)}>Add IP addresses</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_ip_addresses} onChange={(e) => this.props.updateField('can_edit_ip_addresses', e.target.checked)}>Edit IP addresses</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Groups'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_ip_groups} onChange={(e) => this.props.updateField('can_view_ip_groups', e.target.checked)}>View IP groups</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_ip_groups} onChange={(e) => this.props.updateField('can_add_ip_groups', e.target.checked)}>Add IP groups</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_ip_groups} onChange={(e) => this.props.updateField('can_edit_ip_groups', e.target.checked)}>Edit IP groups</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_ip_groups} onChange={(e) => this.props.updateField('can_delete_ip_groups', e.target.checked)}>Delete IP groups</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Descriptions.Item>

                    <Descriptions.Item label='Hosting' labelStyle={{width: labelWidth}}>
                        <Descriptions bordered size='small' layout='horizontal' column={1}>
                            <Descriptions.Item label='General' labelStyle={{width: labelWidth}}>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_hosting} onChange={(e) => this.props.updateField('can_view_hosting', e.target.checked)}>View hosting</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Accounts'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_hosting_accounts} onChange={(e) => this.props.updateField('can_view_hosting_accounts', e.target.checked)}>View hosting accounts</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_hosting_accounts} onChange={(e) => this.props.updateField('can_add_hosting_accounts', e.target.checked)}>Add hosting accounts</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_hosting_accounts} onChange={(e) => this.props.updateField('can_edit_hosting_accounts', e.target.checked)}>Edit hosting accounts</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_hosting_accounts} onChange={(e) => this.props.updateField('can_delete_hosting_accounts', e.target.checked)}>Delete hosting accounts</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_login_to_hosting_accounts} onChange={(e) => this.props.updateField('can_login_to_hosting_accounts', e.target.checked)}>Login to hosting accounts</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Descriptions.Item>

                    <Descriptions.Item label='Firewall' labelStyle={{width: labelWidth}}>
                        <Descriptions bordered size='small' layout='horizontal' column={1}>
                            <Descriptions.Item label='General' labelStyle={{width: labelWidth}}>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_firewall} onChange={(e) => this.props.updateField('can_view_firewall', e.target.checked)}>View firewall</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Rules'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_firewall_rules} onChange={(e) => this.props.updateField('can_view_firewall_rules', e.target.checked)}>View firewall rules</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_firewall_rules} onChange={(e) => this.props.updateField('can_add_firewall_rules', e.target.checked)}>Add firewall rules</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_firewall_rules} onChange={(e) => this.props.updateField('can_edit_firewall_rules', e.target.checked)}>Edit firewall rules</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_firewall_rules} onChange={(e) => this.props.updateField('can_delete_firewall_rules', e.target.checked)}>Delete firewall rules</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Policies'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_firewall_policies} onChange={(e) => this.props.updateField('can_view_firewall_policies', e.target.checked)}>View firewall policies</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_firewall_policies} onChange={(e) => this.props.updateField('can_add_firewall_policies', e.target.checked)}>Add firewall policies</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_firewall_policies} onChange={(e) => this.props.updateField('can_edit_firewall_policies', e.target.checked)}>Edit firewall policies</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_firewall_policies} onChange={(e) => this.props.updateField('can_delete_firewall_policies', e.target.checked)}>Delete firewall policies</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Rulesets'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_firewall_rulesets} onChange={(e) => this.props.updateField('can_view_firewall_rulesets', e.target.checked)}>View firewall rulesets</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_firewall_rulesets} onChange={(e) => this.props.updateField('can_add_firewall_rulesets', e.target.checked)}>Add firewall rulesets</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_firewall_rulesets} onChange={(e) => this.props.updateField('can_edit_firewall_rulesets', e.target.checked)}>Edit firewall rulesets</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_firewall_rulesets} onChange={(e) => this.props.updateField('can_delete_firewall_rulesets', e.target.checked)}>Delete firewall rulesets</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Descriptions.Item>

                    <Descriptions.Item label='Billing' labelStyle={{width: labelWidth}}>
                        <Descriptions bordered size='small' layout='horizontal' column={1}>
                            <Descriptions.Item label='General' labelStyle={{width: labelWidth}}>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_billing} onChange={(e) => this.props.updateField('can_view_billing', e.target.checked)}>View billing</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Orders'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_orders} onChange={(e) => this.props.updateField('can_view_orders', e.target.checked)}>View orders</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_orders} onChange={(e) => this.props.updateField('can_edit_orders', e.target.checked)}>Edit orders</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Invoices'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_invoices} onChange={(e) => this.props.updateField('can_view_invoices', e.target.checked)}>View invoices</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_pay_invoices} onChange={(e) => this.props.updateField('can_pay_invoices', e.target.checked)}>Pay invoices</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_download_invoices} onChange={(e) => this.props.updateField('can_download_invoices', e.target.checked)}>Download invoices</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Credit notes'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_credit_notes} onChange={(e) => this.props.updateField('can_view_credit_notes', e.target.checked)}>View credit notes</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_download_credit_notes} onChange={(e) => this.props.updateField('can_download_credit_notes', e.target.checked)}>Download credit notes</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Transactions'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_transactions} onChange={(e) => this.props.updateField('can_view_transactions', e.target.checked)}>View transactions</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Payment methods'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_payment_methods} onChange={(e) => this.props.updateField('can_view_payment_methods', e.target.checked)}>View payment methods</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_payment_methods} onChange={(e) => this.props.updateField('can_add_payment_methods', e.target.checked)}>Add payment methods</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_payment_methods} onChange={(e) => this.props.updateField('can_edit_payment_methods', e.target.checked)}>Edit payment methods</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Cancellation requests'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_cancellation_requests} onChange={(e) => this.props.updateField('can_view_cancellation_requests', e.target.checked)}>View cancellation requests</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_cancellation_requests} onChange={(e) => this.props.updateField('can_add_cancellation_requests', e.target.checked)}>Add cancellation requests</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_cancellation_requests} onChange={(e) => this.props.updateField('can_edit_cancellation_requests', e.target.checked)}>Edit cancellation requests</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='VAT'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_vat} onChange={(e) => this.props.updateField('can_view_vat', e.target.checked)}>View VAT</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_vat} onChange={(e) => this.props.updateField('can_add_vat', e.target.checked)}>Add VAT</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_vat} onChange={(e) => this.props.updateField('can_edit_vat', e.target.checked)}>Edit VAT</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Descriptions.Item>

                    <Descriptions.Item label='Company' labelStyle={{width: labelWidth}}>
                        <Descriptions bordered size='small' layout='horizontal' column={1}>
                            <Descriptions.Item label='General' labelStyle={{width: labelWidth}}>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_switch_companies} onChange={(e) => this.props.updateField('can_switch_companies', e.target.checked)}>Switch between companies</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_company_info} onChange={(e) => this.props.updateField('can_view_company_info', e.target.checked)}>View info</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_company_profile} onChange={(e) => this.props.updateField('can_edit_company_profile', e.target.checked)}>Edit profile</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_company_emails} onChange={(e) => this.props.updateField('can_view_company_emails', e.target.checked)}>View emails</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_company_logs} onChange={(e) => this.props.updateField('can_view_company_logs', e.target.checked)}>View logs</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Users'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_company_users} onChange={(e) => this.props.updateField('can_view_company_users', e.target.checked)}>View users</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_company_users} onChange={(e) => this.props.updateField('can_add_company_users', e.target.checked)}>Add users</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_company_users} onChange={(e) => this.props.updateField('can_edit_company_users', e.target.checked)}>Edit users</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_company_users} onChange={(e) => this.props.updateField('can_delete_company_users', e.target.checked)}>Delete users</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='Contacts'>
                                <Row>
                                    <Col span={8}><Checkbox checked={this.props.data.can_view_company_contacts} onChange={(e) => this.props.updateField('can_view_company_contacts', e.target.checked)}>View contacts</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_add_company_contacts} onChange={(e) => this.props.updateField('can_add_company_contacts', e.target.checked)}>Add contacts</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_edit_company_contacts} onChange={(e) => this.props.updateField('can_edit_company_contacts', e.target.checked)}>Edit contacts</Checkbox></Col>
                                    <Col span={8}><Checkbox checked={this.props.data.can_delete_company_contacts} onChange={(e) => this.props.updateField('can_delete_company_contacts', e.target.checked)}>Delete contacts</Checkbox></Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        );
    }
}

export default connect(null, { })(CompanyUserPermissionsTable);