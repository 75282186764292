import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Switch} from "react-router-dom";
import DefaultRoute from "./shared/DefaultRoute";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import {Helmet} from "react-helmet";
import Recover from "../pages/auth/recover";
import VerifyContact from "../pages/auth/verify_contact";
import VerifyEmail from "../pages/auth/verify_email";
import {PageView} from "./PageView";
import Logout from "../pages/auth/logout";

class Auth extends React.Component {
    render() {
        return(
            <Fragment>
                <Helmet>
                    <title>{this.props.page.title}</title>
                </Helmet>

                <PageView />
                <Switch>
                    <DefaultRoute path='/auth/reset' component={Recover} />
                    <DefaultRoute path='/auth/verify/contact' component={VerifyContact} />
                    <DefaultRoute path='/auth/verify' component={VerifyEmail} />
                    <DefaultRoute path='/auth/logout' component={Logout} exact />

                    <DefaultRoute path='/auth/login' component={Login} exact />
                    <DefaultRoute path='/auth/register' component={Register} exact />
                    <DefaultRoute path='/auth/recover' component={Recover} exact />
                </Switch>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    page: state.page
});

export default connect(mapStateToProps, { })(Auth);