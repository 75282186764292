import React from "react";
import {connect} from "react-redux";
import {Badge, Button, Col, List, Popover, Row} from "antd";
import {CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, NotificationOutlined} from "@ant-design/icons";
import {Loading} from "../../libs/loading";
import {loadNotifications, markNotificationsRead} from "../../actions/account";
import {displayErrors} from "../../libs/utils";
import {DateTimeFromNow} from "./DateTimeFromNow";

class Notifications extends React.Component {
    state = {
        unreadNotifications: 0,
        notifications: [],
        loading: false,
        markingRead: false,
        loadingNotifications: false,
        isVisible: false,
        updaterEvent: null
    };

    updateNotifications = null;

    componentDidMount() {
        this.loadNotifications();

        this.updateNotifications = setInterval(() => {
            this.loadNotifications();
        }, 60*1000);
    }

    componentWillUnmount() {
        clearInterval(this.updateNotifications);
    }

    loadNotifications() {
        if(!this.state.markingRead && !this.state.isVisible) {
            this.setState({ loading: true });
        }

        this.props.loadNotifications((res) => {
            let unreadNotifications = 0;

            for(let i = 0; i < res.data.length; i++) {
                if(!res.data[i].seen) {
                    unreadNotifications++;
                }
            }

            this.setState({ notifications: res.data, unreadNotifications: unreadNotifications, loading: false, markingRead: false });
        }, (err) => {
            if(typeof err.response !== 'undefined' && err.response.status !== 401) {
                displayErrors(err.response.data);
                this.setState({ loading: false, notifications: [], unreadNotifications: 0 });
            }
        });
    }

    markNotificationsRead() {
        this.setState({ markingRead: true });

        this.props.markNotificationsRead(() => {
            this.setState({ unreadNotifications: 0 }, () => {
                this.loadNotifications();
            })
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ notifications: [], unreadNotifications: 0, markingRead: false });
            }
        });
    }

    notificationIcon(notification_type) {
        if(notification_type === 'SUCCESS') {
            return <CheckCircleFilled style={{color: '#22bb33'}} />;
        }

        if(notification_type === 'INFO') {
            return <ExclamationCircleFilled style={{color: '#5bc0de'}} />;
        }

        if(notification_type === 'WARNING') {
            return <ExclamationCircleFilled style={{color: '#f0ad4e'}} />;
        }

        if(notification_type === 'ERROR') {
            return <CloseCircleFilled style={{color: '#bb2124'}} />;
        }
    }

    render() {
        let content = <div className='text-center'><Loading /></div>;

        if(!this.state.loading) {
            content = <div>
                <div className='notifications-box'>
                    <List size='small' loading={this.state.markingRead}
                          dataSource={this.state.notifications}
                          renderItem={(item) => <List.Item key={item.guid} className={'notification-item' + (!item.seen ? ' notification-item-new' : '')}>
                              <Row justify="space-around" align="middle">
                                  <Col flex="auto">{this.notificationIcon(item.notification_type)}</Col>
                                  <Col xs={24} sm={15}>{item.message}</Col>
                                  <Col xs={24} sm={7} className='notification-timestamp'><DateTimeFromNow value={item.date_created} /></Col>
                              </Row>
                          </List.Item>}/>
                </div>
                <div className='text-right'>
                    <Button type='link' loading={this.state.markingRead} onClick={() => this.markNotificationsRead()}>Mark all read</Button>
                </div>
            </div>
        }

        return(
            <Badge count={this.state.unreadNotifications}>
                <Popover title='Notifications' placement="bottomRight" content={content}
                         onOpenChange={(visible) => this.setState({ isVisible: visible })} trigger='click' onClick={() => this.loadNotifications()}>
                    <Button className='header-user-notifications' type='text' icon={<NotificationOutlined />} />
                </Popover>
            </Badge>
        );
    }
}

export default connect(null, { loadNotifications, markNotificationsRead })(Notifications);