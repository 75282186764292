import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, InputNumber, Modal, Select, Space, message} from "antd";
import {displayErrors, isNumeric} from "../../libs/utils";
import {createDNSRecord, editDNSRecord} from "../../actions/domains";

class DNSRecordModal extends React.Component {
    state = {
        loading: false,
        name: '',
        domainName: '',
        domainGuid: '',
        type: '',
        value: [],
        ttl: 3600,
        dataLoaded: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            if(nextProps.data !== null && !prevState.dataLoaded){
                if(nextProps.type === 'SOA' &&  typeof nextProps.data !== 'undefined') {
                    let updatedProps = {...nextProps};

                    updatedProps['name'] = nextProps.data.name;

                    let data = nextProps.data.data;

                    updatedProps['value'] = [data['nameserver'] + ' ' + data['email'] + ' ' + data['serial'] + ' ' +
                    data['refresh'] + ' ' + data['retry'] + ' ' + data['expiry'] + ' ' + data['ttl']];

                    updatedProps['dataLoaded'] = true;

                    return updatedProps;
                }
                else
                {
                    let updatedProps = {...nextProps};

                    let subdomain = nextProps.data.name.replace(nextProps.domainName, '');

                    if(subdomain.substring(subdomain.length - 1, subdomain.length) === '.') {
                        subdomain = subdomain.substring(0, subdomain.length - 1);
                    }

                    updatedProps['name'] = subdomain;
                    updatedProps['value'] = nextProps.data.data.records;
                    updatedProps['ttl'] = parseInt(nextProps.data.data.ttl, 10);
                    updatedProps['dataLoaded'] = true;

                    return updatedProps;
                }
            }
            return nextProps;
        }

        return null;
    }

    closeModal(reload = false) {
        this.setState({
            loading: false,
            name: '',
            domainName: '',
            domainGuid: '',
            type: '',
            value: [],
            ttl: 3600,
            dataLoaded: false
        });

        this.props.closeModal();

        if(reload) {
            this.props.reloadZone();
        }
    }

    saveRecord() {
        if(this.state.domainGuid === '' || this.state.domainGuid === null) {
            return message.error('Please reload the page and try again!');
        }

        if(!Array.isArray(this.state.value) || this.state.value.length === 0) {
            return message.error('Please enter DNS record\'s value!');
        }

        let data = {
            name: this.state.name,
            type: this.state.type,
            value: this.state.value,
        };

        if(this.state.type !== 'SOA') {
            if(!isNumeric(this.state.ttl)) {
                return message.error('TTL must be a full number!');
            }

            let ttl = parseInt(this.state.ttl, 10);

            if(ttl > 592200 || ttl < 3600) {
                return message.error('Please enter a TTL value between 3600 and 592200!');
            }

            data['ttl'] = ttl;
        }

        this.setState({ loading: true });

        if(this.props.data !== null) {
            if(this.state.type !== 'SOA') {
                let subdomain = this.props.data.data.name.replace(this.props.domainName, '');

                if(subdomain.substring(subdomain.length - 1, subdomain.length) === '.') {
                    subdomain = subdomain.substring(0, subdomain.length - 1);
                }

                data['old_name'] = subdomain;
            }

            this.props.editDNSRecord(this.props.domainGuid, data, () => {
                message.success('DNS record successfully updated!');
                this.setState({ loading: false });
                this.closeModal(true);
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                    this.setState({ loading: false });
                }
            });
        } else {
            this.props.createDNSRecord(this.props.domainGuid, data, () => {
                message.success('DNS record successfully created!');
                this.setState({ loading: false });
                this.closeModal(true);
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                    this.setState({ loading: false });
                }
            });
        }
    }

    render() {
        const { TextArea } = Input;
        const { Option } = Select;
        let editing = this.props.data !== null;

        return(
            <Modal
                title={(editing ? 'Edit' : 'Add') + ' ' + this.props.type + ' Record'}
                open={this.props.showModal}
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                    <Button onClick={() => this.saveRecord()} loading={this.state.loading} disabled={this.state.loading} type='primary'>Save</Button>
                </Space>}>
                <Form layout='vertical'>
                    <Form.Item label='Name' extra={this.state.type === 'SOA' || this.state.type === 'NS' ? '' : <small>Valid characters: a-z, 0-9, ., -</small>}>
                        {this.state.type === 'SOA' || this.state.type === 'NS' ? this.props.domainName : <Input addonAfter={this.props.domainName} value={this.state.name} placeholder='Leave blank for root domain' onChange={(e) => this.setState({ name: e.target.value })} /> }
                    </Form.Item>
                    <Form.Item label='Value' extra={this.state.type === 'SOA' ? <small>Format: [nameserver] [email-address] [serial-number] [refresh-time] [retry-time] [expire-time] [ttl]</small> : <small>Enter multiple values on separate rows</small>}>
                        <TextArea rows={3} value={this.state.value.join('\n')} onChange={(e) => this.setState({ value: e.target.value.split('\n') })} />
                    </Form.Item>
                    <Form.Item label='Type' extra={<small>The DNS record type that will be returned</small>}>
                        <Select value={this.props.type} onChange={(value) => this.setState({ type: value })} disabled={this.state.type === 'SOA'}>
                            <Option value='A'>A - Address</Option>
                            <Option value='CNAME'>CNAME - Canonical name</Option>
                            <Option value='MX'>MX - Mail exchange</Option>
                            <Option value='NS'>NS - Name server</Option>
                            <Option value='TXT'>TXT - Text</Option>
                            <Option value='SOA' disabled>SOA – Start of authority record</Option>
                        </Select>
                    </Form.Item>
                    {this.state.type === 'SOA' ? '' : <Form.Item label='TTL (seconds)' extra={<small>The amount of time DNS records are cached. TTL means time to live.</small>}>
                        <Space>
                            <InputNumber min={600} max={172800} value={this.state.ttl} onChange={(value) => this.setState({ ttl: value })} />
                            <Button type='default' onClick={() => this.setState({ ttl: 3600 })}>1h</Button>
                            <Button type='default' onClick={() => this.setState({ ttl: 43200 })}>12h</Button>
                            <Button type='default' onClick={() => this.setState({ ttl: 86400 })}>1d</Button>
                        </Space>
                    </Form.Item>}
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { createDNSRecord, editDNSRecord })(DNSRecordModal);