import React from "react";
import {connect} from "react-redux";
import {Alert} from "antd";
import {displayErrors} from "../../libs/utils";
import {loadSharedHostingOverdueNotices} from "../../actions/hosting";

class SuspendedHostingAccountNotice extends React.Component {
    state = {
        accounts: []
    };

    updateNotice = null;

    componentDidMount() {
        this.loadNotice();

        this.updateNotice = setInterval(() => {
            this.loadNotice();
        }, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.updateNotice);
    }

    loadNotice() {
        this.props.loadSharedHostingOverdueNotices((res) => {
            this.setState({ accounts: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined' && err.response.status !== 401) {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.accounts.length > 0) {
            let accounts = [];

            for(let i = 0; i < this.state.accounts.length; i++) {
                accounts.push('<a href="/hosting/' + this.state.accounts[i]['guid'] + '"><b>' + this.state.accounts[i]['name'] + '</b></a>');
            }

            let message = '<b>Warning!</b> The following hosting account' + (this.state.accounts.length === 1 ? ' is' : 's are') + ' suspended and scheduled for termination: ' + accounts.join(', ') + '. Please renew your hosting account' + (this.state.accounts.length > 1 ? 's' : '') + ' to prevent <b>permanent loss of data</b>!';
            return <div style={{marginBottom: '10px'}}><Alert showIcon type='error' message={<div dangerouslySetInnerHTML={{__html: message}} />} /></div>;
        } else {
            return <></>
        }
    }
}

export default connect(null, { loadSharedHostingOverdueNotices })(SuspendedHostingAccountNotice);