import React, {Fragment} from "react";
import {connect} from "react-redux";
import {deleteSubdomain, loadSubdomains} from "../../actions/domains";
import {Button, Card, message, Popconfirm, Space, Table, Tooltip} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import SubdomainModal from "./SubdomainModal";
import {displayErrors} from "../../libs/utils";
import {ReloadOutlined, SettingOutlined} from "@ant-design/icons";
import {StatusIndicator} from "../shared/Status";
import AccessItem from "../permissions/AccessItem";
import {DEFAULT_PER_PAGE} from "../../config";

class SubdomainsOverview extends React.Component {
    state = {
        loading: false,
        showModal: false,
        selectedSubdomain: null,
        subdomains: [],
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 }
    };

    componentDidMount() {
        this.loadSubdomains();
    }

    loadSubdomains(page = null, per_page = null) {
        this.setState({ loading: true });

        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.props.loadSubdomains(this.props.guid, page, per_page, (res) => {
            this.setState({ loading: false, subdomains: res.data.data, pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true } });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    deleteSubdomain(guid) {
        this.setState({ loading: true });

        this.props.deleteSubdomain(guid, guid, () => {
            message.success('Subdomain\'s deletion successfully scheduled/deleted!')
            this.loadSubdomains();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    render() {
        const columns = [
            { title: 'Domain', dataIndex: 'domain', key: 'domain', render: (item, row) => row.domain},
            { title: 'Added', key: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Status', key: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator el='badge' status={item} />},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                    return row.status === 'ACTIVE' || row.status === 'PENDING' ? <Space>
                        <AccessItem scopes={['can_delete_domains']}>
                            <Popconfirm
                                onConfirm={() => this.deleteSubdomain(row.guid)}
                                placement='topRight'
                                title="Are you sure?"
                                okText="Delete"
                                disabled={row.status !== 'ACTIVE' && row.status !== 'PENDING'}
                                cancelText="Cancel">
                                <Tooltip title='Delete subdomain'>
                                    <Button size='small' disabled={row.status !== 'ACTIVE' && row.status !== 'PENDING'} danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        </AccessItem>
                        <Tooltip title='Manage subdomain'>
                            <Button size='small' icon={<SettingOutlined />} onClick={() => this.props.manageSubdomain(row.guid, row.hosting_type, row.domain)}>Manage</Button>
                        </Tooltip>
                    </Space> : '';
                }}
        ];

        return(
            <Fragment>
                <Card bordered={false} size='small'
                      title='Subdomains'
                      extra={<Space>
                          <AccessItem scopes={['can_add_domains']}>
                              <Button type='primary' size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showModal: true })}>Add Subdomain</Button>
                          </AccessItem>
                          <Tooltip title='Reload table'>
                              <Button size='small' icon={<ReloadOutlined />} onClick={() => this.loadSubdomains()} />
                          </Tooltip>
                      </Space>}>
                    <Table loading={this.state.loading}
                           columns={columns}
                           dataSource={this.state.subdomains}
                           pagination={this.state.pagination}
                           onChange={(pagination) => this.loadSubdomains(pagination.current, pagination.pageSize)}
                           size='small'
                           bordered={false} rowKey={(item) => item.guid} />
                </Card>
                <SubdomainModal
                    showModal={this.state.showModal}
                    rootDomainGuid={this.props.guid}
                    guid={this.state.selectedSubdomain}
                    domainName={this.props.domainName}
                    domainGroup={this.props.domainGroup}
                    closeModal={() => this.setState({ showModal: false, selectedSubdomain: null })}
                    reloadSubdomains={() => this.loadSubdomains()} />
            </Fragment>
        );
    }
}

export default connect(null, { loadSubdomains, deleteSubdomain })(SubdomainsOverview);