import React from "react";
import {connect} from "react-redux";
import {Select, Form, Input, message, Modal, Button, Space, Alert} from "antd";
import {createEmailAddress} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";

class EmailAddressModal extends React.Component {
    state = {
        loading: false,
        emailAddress: '',
        emailAddressType: 'FORWARDER',
        forwardToEmailAddress: '',
        isCatchall: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    resetForm() {
        this.setState({  });
    }

    closeModal() {
        this.resetForm();
        this.props.closeModal();
    }

    saveEmailAddress() {
        this.setState({ loading: true });

        let data = {
            'email_address': this.state.emailAddress.trim() + '@' + this.props.domainName,
            'email_address_type': this.state.emailAddressType.trim(),
            'forward_to_email_address': this.state.forwardToEmailAddress.trim(),
            'is_catchall': this.state.isCatchall
        };

        this.props.createEmailAddress(this.props.domain, data, () => {
            this.setState({ loading: false });
            message.success('Email address successfully created!');
            this.closeAndReloadEmailAddresses();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    setEmailAddress(e) {
        let value = e.target.value;
        this.setState({ emailAddress: value, isCatchall: value === '*' });
    }

    closeAndReloadEmailAddresses() {
        this.resetForm();

        this.props.reloadEmailAddresses();
        this.props.closeModal();
    }

    render() {
        const { Option } = Select;

        return(
            <Modal
                centered
                title={'Add Email Address'}
                open={this.props.showModal}
                onCancel={() => this.closeModal()}
                destroyOnClose={true}
                footer={<Space>
                    <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                    <Button type='primary' onClick={() => this.saveEmailAddress()} disabled={this.state.loading} loading={this.state.loading}>Create</Button>
                </Space>}>
                <Form layout='vertical'>
                    <Form.Item label='Type'>
                        <Select defaultValue={this.state.email_address_type}>
                            <Option value='FORWARDER'>Forwarder</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Email Address' extra={<small>Valid characters: a-z, 0-9, * for catch-all</small>}>
                        <Input value={this.state.emailAddress} onChange={(e) => this.setEmailAddress(e)} addonAfter={'@' + this.props.domainName} />
                    </Form.Item>
                    <Form.Item label='Forward to Email Address' extra={<small>Enter full email address like: username@domain.tld</small>}>
                        <Input value={this.state.forwardToEmailAddress} type='email' onChange={(e) => this.setState({ forwardToEmailAddress: e.target.value })} />
                    </Form.Item>
                </Form>
                <Alert showIcon={true} type='warning' message='We do not recommend using Gmail emails as destinations because of their aggressive spam protection.' />
            </Modal>
        );
    }
}

export default connect(null, { createEmailAddress })(EmailAddressModal);