import React, {Fragment} from "react";
import {connect} from "react-redux";
import {deleteUrlRedirect, deleteUrlRedirects, loadUrlRedirects, updateUrlRedirect} from "../../actions/domains";
import {Typography, Tooltip, Space, Button, Card, message, Popconfirm, Table, Switch} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import {DEFAULT_PER_PAGE} from "../../config";
import DomainRedirectModal from "./DomainRedirectModal";
import {displayErrors} from "../../libs/utils";
import {ReloadOutlined} from "@ant-design/icons";
import {StatusIndicator} from "../shared/Status";
import AccessItem from "../permissions/AccessItem";
import {hasPermission} from "../permissions/PermissionUtils";

class DomainRedirects extends React.Component {
    state = {
        loading: false,
        showModal: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        redirects: [],
        searchQuery: '',
        activeRedirect: '',
        updating: false,
        selectedRedirects: []
    };

    componentDidMount() {
        this.loadUrlRedirects();
    }

    loadUrlRedirects(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: true });

        this.props.loadUrlRedirects(this.props.guid, page, per_page, this.state.searchQuery, (res) => {
            this.setState({ loading: false, redirects: res.data.data, pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true } });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    toggleUrlRedirectStatus(guid, status) {
        let redirects = this.state.redirects.map((redirect) => {
            if(redirect.guid === guid) {
                let newStatus = status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE';
                return {...redirect, status: newStatus };
            }

            return redirect;
        });

        this.setState({ loading: true, redirects: redirects });

        let data = {
            status: status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE'
        };

        this.props.updateUrlRedirect(this.props.guid, guid, data, () => {
            message.success('Redirect successfully updated!');
            this.setState({ loading: false });

            this.loadUrlRedirects();

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    deleteUrlRedirect(guid) {
        this.setState({ loading: true });

        this.props.deleteUrlRedirect(this.props.guid, guid, () => {
            message.success('URL redirect\'s successfully deleted!')
            this.loadUrlRedirects();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    getResponseCodeText(responseCode) {
        let codes = {
            301: 'Moved Permanently',
            302: 'Found (Previously "Moved temporarily")',
            303: 'See Other',
            307: 'Temporary Redirect',
            308: 'Permanent Redirect'
        };

        return codes[responseCode];
    }

    deleteSelectedRedirects() {
        this.setState({ loading: true });

        this.props.deleteUrlRedirects(this.props.guid, this.state.selectedRedirects, () => {
            message.success('Selected URL redirects successfully deleted!');
            this.loadUrlRedirects();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.loadUrlRedirects();
            }
        });
    }

    render() {
        const { Text } = Typography;

        const columns = [];

        if(hasPermission(this.props.company.permissions, ['can_edit_domain_url_redirects'])) {
            columns.push({ title: '', render: (item, row) => {
                return <Tooltip title={row.status === 'ACTIVE' ? 'Disable redirect' : 'Enable redirect'}>
                    <Switch size='small' checked={row.status === 'ACTIVE'} onChange={() => this.toggleUrlRedirectStatus(row.guid, row.status)} />
                </Tooltip>;
            }});
        }

        columns.push({ title: 'URLs', dataIndex: 'domain', width: '40%', render: (item, row) => row.urls.map((url, i) => <div><Text key={i} code>{url}</Text></div>)})
        columns.push({ title: 'Destination', dataIndex: 'destination', render: (item, row) => row.destination})
        columns.push({ title: 'Status Code', dataIndex: 'response_code', align: 'center', render: (item, row) => <Tooltip title={this.getResponseCodeText(row.response_code)}>{row.response_code}</Tooltip>})
        columns.push({ title: 'Status', dataIndex: 'status', align: 'center', render: (item) => <StatusIndicator el='badge' status={item} /> })

        if(hasPermission(this.props.company.permissions, ['can_delete_domain_url_redirects'])) {
            columns.push({ title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Popconfirm
                        onConfirm={() => this.deleteUrlRedirect(row.guid)}
                        placement='topRight'
                        title="Are you sure?"
                        okText="Delete"
                        cancelText="Cancel">
                        <Tooltip placement='left' title='Delete redirect'>
                            <Button size='small' danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>;
                }});
        }

        return(
            <Fragment>
                <Card bordered={false} size='small' title='URL redirects' extra={<Space>
                        <AccessItem scopes={['can_add_domain_url_redirects']}>
                            <Button type='primary' size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showModal: true })}>Add URL Redirect</Button>
                        </AccessItem>
                        <Tooltip title='Reload table'>
                            <Button size='small' icon={<ReloadOutlined />} onClick={() => this.loadUrlRedirects()} />
                        </Tooltip>
                    </Space>}>
                    <Table
                        loading={this.state.loading}
                        columns={columns}
                        dataSource={this.state.redirects}
                        pagination={this.state.pagination}
                        onChange={(pagination) => this.loadUrlRedirects(pagination.current, pagination.pageSize)}
                        size='small'
                        bordered={false}
                        rowKey={(item) => item.guid} />
                </Card>

                <DomainRedirectModal
                    showModal={this.state.showModal}
                    rootDomainGuid={this.props.guid}
                    domainName={this.props.domainName}
                    closeModal={() => this.setState({ showModal: false })} reloadUrlRedirects={() => this.loadUrlRedirects()} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { loadUrlRedirects, deleteUrlRedirect,
    updateUrlRedirect, deleteUrlRedirects })(DomainRedirects);