import React from "react";
import {useLocation} from "react-router-dom";
import posthog from "posthog-js";

export const PageView = () => {
    let location = useLocation();

    React.useEffect(() => {
        posthog.capture('$pageview');
    }, [location]);

    return <></>
};