import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import qs from "qs";
import { message } from 'antd';
import {verifyEmail} from "../../actions/auth";
import {displayErrors} from "../../libs/utils";

class VerifyEmail extends React.Component  {
    componentDidMount() {
        let parsed = qs.parse(this.props.location.search.substring(1));

        if(typeof parsed !== 'undefined') {
            if(typeof parsed.hash !== 'undefined' && parsed.hash.length > 0) {
                this.props.verifyEmail({ token: parsed.hash }, (res) => {
                    message.success('Email address successfully verified!');
                    this.props.history.push('/auth/login');
                }, (err) => {
                    displayErrors(err.response.data);
                    this.props.history.push('/auth/login');
                });
            }
        }
    }

    render() {
        return(<Fragment />);
    }
}
export default connect(null, { verifyEmail })(VerifyEmail);