export const shuffleArray = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
};

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const randomUsername = () => {
    let adjectives = ['adorable','adventurous','agreeable','alert','alive','amused','angry','annoyed',
        'anxious','attractive','average','awful','beautiful','better','bewildered',
        'black','blue','blue-eyed','blushing','bored','brainy','brave','breakable','bright','busy','calm','careful',
        'cautious','charming','cheerful','clean','clear','clever','cloudy','colorful','combative','comfortable',
        'concerned','condemned','confused','cooperative','courageous','crowded','curious','cute',
        'dangerous','dark','delightful','determined','different','difficult',
        'distinct','disturbed','dizzy','doubtful','drab','dull','eager','easy','elated','elegant','embarrassed',
        'enchanting','encouraging','energetic','enthusiastic','envious','excited','expensive','exuberant','fair',
        'faithful','famous','fancy','fantastic','fierce','fine','fragile','frail','frantic','friendly',
        'frightened','funny','gentle','gifted','glamorous','gleaming','glorious','good','gorgeous','graceful',
        'grotesque','grumpy','handsome','happy','healthy','helpful','helpless','hilarious','homeless','homely',
        'hungry','hurt','important','impossible','inexpensive','innocent','inquisitive','itchy','jealous','jittery',
        'jolly','joyous','kind','lazy','light','lively','lonely','long','lovely','lucky','magnificent','misty','modern',
        'motionless','muddy','mushy','mysterious','nervous','nice','nutty','obedient','obnoxious','odd',
        'open','outrageous','outstanding','perfect','plain','pleasant','poised','poor','powerful',
        'precious','prickly','proud','puzzled','quaint','real','relieved','repulsive','rich','scary','selfish','shiny',
        'shy','sleepy','smiling','smoggy','sore','sparkling','splendid','spotless','stormy','strange','successful',
        'super','talented','tame','tender','tense','testy','thankful','thoughtful','thoughtless','tired','tough',
        'troubled','unusual','upset','uptight','vast','victorious','vivacious',
        'wandering','weary','wicked','wild','witty','yawning','yellowed','zealous'];

    let nouns = ['aardvark','addax','albatross','alligator','alpaca','anaconda','angelfish','anteater','antelope',
        'ant','ape','armadillo','baboon','badger','barracuda','bat','batfish','bear','beaver','bee','beetle','bird','bison',
        'boar','booby','buffalo','bug','butterfly','buzzard','caiman','camel','capuchin','capybara','caracal','cardinal',
        'caribou','cassowary','cat','caterpillar','centipede','chamois','cheetah','chicken','chimpanzee','chinchilla','chipmunk',
        'cicada','civet','cobra','cockroach','cod','constrictor','copperhead','cormorant','corncrake','cottonmouth','cowfish','cow',
        'coyote','crab','crane','crayfish','crocodile','crossbill','curlew','deer','dingo','dog','dogfish','dolphin','donkey',
        'dormouse','dotterel','dove','dragonfly','duck','dugong','dunlin','eagle','earthworm','echidna','eel','eland','elephant',
        'elk','emu','falcon','ferret','finch','fish','flamingo','flatworm','fly','fowl','fox','frog','gannet','gaur','gazelle',
        'gecko','gemsbok','gentoo','gerbil','gerenuk','gharial','gibbon','giraffe','gnat','gnu','goat','goldfinch','goosander',
        'goose','gorilla','goshawk','grasshopper','grebe','grivet','grouse','guanaco','gull','hamerkop','hamster','hare','hawk',
        'hedgehog','heron','herring','hippopotamus','hoopoe','hornet','horse','hummingbird','hyena','ibex','ibis','iguana','impala',
        'jackal','jaguar','jay','jellyfish','kangaroo','katipo','kea','kestrel','kingfisher','kinkajou','kitten','koala','kookaburra',
        'kouprey','kudu','ladybird','lapwing','lark','lemur','leopard','lion','lizard','llama','lobster','locust','loris','louse','lynx',
        'lyrebird','macaque','macaw','magpie','mallard','mamba','manatee','mandrill','mantis','manx','markhor','marten','meerkat',
        'millipede','mink','mockingbird','mole','mongoose','monkey','moose','mosquito','moth','mouse','narwhal','newt','nightingale',
        'ocelot','octopus','okapi','opossum','orangutan','oryx','osprey','ostrich','otter','owl','ox','oyster','oystercatcher','panda',
        'panther','parrot','partridge','peacock','peafowl','peccary','pelican','penguin','petrel','pheasant','pig','pigeon','pintail',
        'piranha','platypus','plover','polecat','pollan','pony','porcupine','porpoise','puffin','puma','pygmy','quagga','quail','quelea',
        'quetzal','quoll','rabbit','raccoon','rat','ratel','rattlesnake','raven','ray','reindeer','rhinoceros','rook','sable','salamander',
        'salmon','sandpiper','sardine','scarab','seahorse','seal','serval','shark','sheep','shrew','shrike','skimmer','skipper','skunk',
        'skylark','sloth','snail','snake','spider','squirrel','stag','starling','stoat','stork','swan','swiftlet','tamarin','tapir',
        'tarantula','tarsier','teira','termite','tern','thrush','tiger','toad','tortoise','toucan','trout','tuatara','turkey','turtle',
        'unicorn','vendace', 'vole','vulture','wallaby','walrus','warbler','wasp','weasel','weevil','whale','wildebeest','willet',
        'wolf','wolverine','wombat','worm','wren','wryneck','xenomorph','yacare','yak','zebra'];

    adjectives = shuffleArray(adjectives);
    nouns = shuffleArray(nouns);

    return adjectives.pop() + nouns.pop() + getRandomInt(10, 99);
};

export const randomPassword = () => {
    var generatePassword = (
        length = 20,
        wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@-#$"
    ) => Array(length)
        .fill('')
        .map(() => wishlist[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * wishlist.length)])
        .join('');

    return generatePassword(16);
};