import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, message, Modal, Space} from "antd";
import {disable2FA} from "../../actions/account";
import {displayErrors} from "../../libs/utils";

class DisableUser2FAModal extends React.Component {
    state = {
        password: '',
        loading: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal() {
        this.setState({
            password: '',
            loading: false,
        }, () => this.props.closeModal());
    }

    disableUser2FA() {
        let password = this.state.password;

        if(password === '') {
            return message.success('Please enter your password!')
        }

        this.setState({ loading: true });

        this.props.disable2FA({ password },  () => {
            this.setState({ loading: false });
            message.success('2FA successfully disabled!');
            this.closeModal();

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    render() {
        return <Modal
            open={this.props.showModal}
            footer={<Space>
                <Button onClick={() => this.closeModal()}>Close</Button>
                <Button type='primary' disabled={this.state.loading} loading={this.state.loading} onClick={() => this.disableUser2FA()}>Disable</Button>
            </Space>}
            onCancel={() => this.closeModal()} title='Disable 2FA'>
            <p>Please enter your password to confirm.</p>
            <Form layout='vertical'>
                <Form.Item>
                    <Input type='password' onChange={(e) => this.setState({ password: e.target.value })} />
                </Form.Item>
            </Form>
        </Modal>
    }
}
export default connect(null, { disable2FA })(DisableUser2FAModal);