import React from "react";
import {connect} from "react-redux";
import {Alert} from "antd";
import {displayErrors} from "../../libs/utils";
import {loadOverdueNotice} from "../../actions/billing";

class OverdueNotice extends React.Component {
    state = {
        invoices: []
    };

    updateNotice = null;

    componentDidMount() {
        this.loadNotice();

        this.updateNotice = setInterval(() => {
            this.loadNotice();
        }, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.updateNotice);
    }

    loadNotice() {
        this.props.loadOverdueNotice((res) => {
            this.setState({ invoices: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined' && err.response.status !== 401) {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.invoices.length > 0) {
            let invoices = [];

            for(let i = 0; i < this.state.invoices.length; i++) {
                invoices.push('<a href="/billing/invoices/' + this.state.invoices[i] + '">#' + this.state.invoices[i] + '</a>');
            }

            let message = 'The following invoice' + (this.state.invoices.length === 1 ? ' is' : 's are') + ' overdue: ' + invoices.join(', ') + '. Please pay them immediately to prevent unwanted downtime.';
            return <div style={{marginBottom: '10px'}}><Alert showIcon type='error' message={<div dangerouslySetInnerHTML={{__html: message}} />} /></div>;
        } else {
            return <></>
        }
    }
}

export default connect(null, { loadOverdueNotice })(OverdueNotice);