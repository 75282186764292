import React from "react";
import {connect} from "react-redux";
import {Descriptions, Select} from "antd";
import {DateTimeFormat} from "../shared/DateTimeFormat";

class CompanyUserPermissionsTable extends React.Component {
    state = {
        availableAccountTypes: [
            { label: 'Administrator', value: 'ADMIN' },
            { label: 'Staff', value: 'STAFF' },
            { label: 'Customer', value: 'CUSTOMER' },
        ],
        availableStatuses: [
            { label: 'Active', value: 'ACTIVE' },
            { label: 'Disabled', value: 'DISABLED' },
        ]
    };

    render() {
        const data = this.props.data;
        const labelWidth = '190px';
        const { Option } = Select;


        return(
            <Descriptions bordered size='small' layout='horizontal' column={1}>
                <Descriptions.Item label='First name' labelStyle={{width: labelWidth}}>{data.user.first_name}</Descriptions.Item>
                <Descriptions.Item label='Last name'>{data.user.last_name}</Descriptions.Item>
                <Descriptions.Item label='Email'>{data.user.email}</Descriptions.Item>
                <Descriptions.Item label='Created'><DateTimeFormat value={data.date_created} /></Descriptions.Item>
                <Descriptions.Item label='Account type'>
                    <Select value={data.account_type} style={{width: '100%'}} onChange={value => this.props.updateField('account_type', value)}>
                        {this.state.availableAccountTypes.map(accountType => {
                            return <Option value={accountType.value} key={accountType.value}>{accountType.label}</Option>
                        })}
                    </Select>
                </Descriptions.Item>
                <Descriptions.Item label='Status'>
                    <Select value={data.status} style={{width: '100%'}} onChange={value => this.props.updateField('status', value)}>
                        {this.state.availableStatuses.map(status => {
                            return <Option value={status.value} key={status.value}>{status.label}</Option>
                        })}
                    </Select>
                </Descriptions.Item>
            </Descriptions>
        );
    }
}

export default connect(null, { })(CompanyUserPermissionsTable);