import React from 'react';
import { connect } from 'react-redux';
import {Button, Card, Col, Form, Input, message, Row} from "antd";
import {displayErrors, getCookieValue} from "../../libs/utils";
import {Link, Redirect} from "react-router-dom";
import {updatePage} from "../../actions/page";
import {requestPasswordReset, resetPassword, validatePasswordResetToken} from "../../actions/auth";
import qs from "qs";
import {Loading} from "../../libs/loading";

class Recover extends React.Component  {
    state = {
        loading: false,
        validating: false,
        emailAddress: '',
        showPasswordForm: false,
        tokenIsAvailable: false,
        password: '',
        passwordRepeat: '',
        resetting: false,
        hash: ''
    };

    componentDidMount() {
        this.props.updatePage('Reset Password');

        let parsed = qs.parse(this.props.location.search.substring(1));

        if(typeof parsed !== 'undefined') {
            if(typeof parsed.hash !== 'undefined' && parsed.hash.length > 0) {
                this.validateToken(parsed.hash);
            }
        }
    }

    validateToken(token) {
        this.setState({ validating: true });

        this.props.validatePasswordResetToken(token, () => {
            this.setState({ validating: false, tokenIsAvailable: true });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ validating: false });
                displayErrors(err.response.data);
            }
        });
    }

    resetPassword() {
        let password = this.state.password.trim();
        let passwordRepeat = this.state.passwordRepeat.trim();

        if(password.length === 0) {
            return message.error('Please enter your password!');
        }

        if(passwordRepeat.length === 0) {
            return message.error('Please repeat your password!');
        }

        if(password !== passwordRepeat) {
            return message.error('Your passwords do not match!');
        }

        let parsed = qs.parse(this.props.location.search.substring(1));

        let data = {
            'hash': parsed.hash,
            'password': password,
            'password_repeat': passwordRepeat
        };

        this.setState({ resetting: true });

        this.props.resetPassword(data, () => {
            this.setState({ resetting: false });
            message.success('Password successfully reset!');
            this.props.history.push('/auth/login');
        }, (err) => {
            this.setState({ resetting: false });
            displayErrors(err.response.data);
        });
    }

    sendRecoverRequest() {
        let email_address = this.state.emailAddress;

        let data = { email_address };

        window.location.search.substring(1).split('&').forEach(value => {
            let pieces = value.split('=');
            data[pieces[0]] = pieces[1];
        });

        if(window.location.search === '') {
            ['utm_campaign', 'utm_source', 'utm_medium', 'utm_content', 'utm_term'].forEach(value => {
                if(getCookieValue(value) !== '') {
                    data[value] = getCookieValue(value);
                }
            })
        }

        this.setState({ loading: true });

        this.props.requestPasswordReset(data, () => {
            this.setState({ emailAddress: '', loading: false });
            message.success('If your email exists in our database, you will get an email very soon with instructions to reset your password!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        })
    }

    render() {
        if(this.props.isAuthenticated) {
            return <Redirect to='/' />;
        }

        let content;

        if(!this.state.validating && this.state.tokenIsAvailable) {
            content = <Form layout='vertical'>
                <Form.Item>
                    <Input type='password'
                           value={this.state.password}
                           placeholder='Password'
                           onKeyUp={(e) => e.key === 'Enter' ? this.resetPassword() : ''}
                           onChange={(e) => this.setState({ password: e.target.value })} />
                </Form.Item>
                <Form.Item>
                    <Input type='password'
                           value={this.state.passwordRepeat}
                           placeholder='Repeat password'
                           onKeyUp={(e) => e.key === 'Enter' ? this.resetPassword() : ''}
                           onChange={(e) => this.setState({ passwordRepeat: e.target.value })} />
                </Form.Item>
                <Form.Item className='auth-form-footer'>
                    <Row>
                        <Col span={24}>
                            <Button block type='primary'
                                    disabled={this.state.loading}
                                    loading={this.state.loading}
                                    onClick={() => this.resetPassword()}>Reset Password</Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>;
        } else if(this.state.validating && !this.state.tokenIsAvailable) {
            content = <div className='text-center'><Loading /></div>
        } else {
            content = <Form layout='vertical'>
                <Form.Item>
                    <Input type='email'
                           value={this.state.emailAddress}
                           placeholder='Email address'
                           onKeyUp={(e) => e.key === 'Enter' ? this.sendRecoverRequest() : ''}
                           onChange={(e) => this.setState({ emailAddress: e.target.value })} />
                </Form.Item>
                <Form.Item className='auth-form-footer'>
                    <Button block type='primary'
                            disabled={this.state.loading}
                            loading={this.state.loading}
                            onClick={() => this.sendRecoverRequest()}>Send Request</Button>
                </Form.Item>
                <Form.Item>
                    <Link to={'/auth/login' + window.location.search}>
                        <Button block>Login</Button>
                    </Link>
                </Form.Item>
            </Form>;
        }

        return(
        <div className='auth-wrapper'>
            <Card className='form-container login'>
                <div className='logo-container'>
                    <img alt='Priority Prospect' src='/img/logo-top-dark.png' />
                </div>

                {content}
            </Card>
        </div>
        );
    }
}
export default connect(null, { updatePage, requestPasswordReset, validatePasswordResetToken, resetPassword })(Recover);