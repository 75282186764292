import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space, message} from "antd";
import {editFirewallRuleset, loadFirewallRules, loadFirewallRuleset} from "../../actions/firewall";
import {displayErrors} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import AccessItem from "../permissions/AccessItem";

class EditFirewallRulesetModal extends React.Component {
    state = {
        name: '',
        rules: [],
        selectedRules: [],
        loadingRules: true,
        loadingData: false,
        dataLoaded: false,
        editing: false,
        rulesetLoaded: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.loadingData && this.props.showModal && !this.state.dataLoaded) {
            this.props.loadFirewallRuleset(this.props.guid, (res) => {
                let rules = res.data.rules.map((rule) => {
                    return rule.guid;
                });

                this.setState({ rulesetLoaded: true, name: res.data.name, selectedRules: rules });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ policyLoaded: true });
                    displayErrors(err.response.data);
                }
            });

            this.props.loadFirewallRules(1, 9999, (res) => {
                let rules = res.data.data.map((rule) => {
                    return { label: rule.name, value: rule.guid };
                });

                this.setState({ rules, loadingRules: false, dataLoaded: true });

            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingRules: false, dataLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal(reload = false) {
        this.props.close();

        this.setState({
            name: '',
            rules: [],
            selectedRules: [],
            loadingRules: true,
            loadingData: false,
            dataLoaded: false,
            editing: false,
            rulesetLoaded: false
        });

        if(reload) {
            this.props.reloadRulesets();
        }
    }

    editRuleset() {
        let name = this.state.name.trim();
        let rules = this.state.selectedRules;

        if(name.length === 0) {
            return message.error('Enter your ruleset\'s name!');
        }

        if(rules.length === 0) {
            return message.error('Select at least 1 firewall rule!');
        }

        this.setState({ editing: true });

        let data = { name, rules };

        this.props.editFirewallRuleset(this.props.guid, data, () => {
            message.success('Firewall ruleset successfully updated!');
            this.setState({ editing: true });
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ editing: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        let content;

        if(!this.state.rulesetLoaded) {
            content = <div className='text-center'><Loading /></div>;
        }
        else
        {
            content = <Form layout='vertical'>
                <Form.Item label='Name'>
                    <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                </Form.Item>
                <Form.Item label='Rules' extra={<small>Select 1 or more firewall rules.</small>}>
                    <Select
                        options={this.state.rules}
                        loading={this.state.loadingRules}
                        value={this.state.selectedRules}
                        mode='multiple' showSearch allowClear
                        onChange={(values) => this.setState({ selectedRules: values })}
                        filterOption={(input, option) => {
                            if(typeof option !== 'undefined') {
                                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                            return false;
                        }} />
                </Form.Item>
            </Form>;
        }

        return(
            <Modal
                title='Edit Firewall Ruleset'
                open={this.props.showModal}
                destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={() => this.closeModal()} disabled={this.state.editing}>Close</Button>
                        <AccessItem scopes={['can_edit_firewall_rulesets']}>
                            <Button type='primary' loading={this.state.editing} disabled={this.state.editing} onClick={() => this.editRuleset()}>Update</Button>
                        </AccessItem>
                    </Space>
                }
                onCancel={() => this.closeModal()}>
                {content}
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { loadFirewallRules, editFirewallRuleset,
    loadFirewallRuleset })(EditFirewallRulesetModal);