import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Card, Tabs} from "antd";
import CompanyLog from "../../components/account/CompanyLog";
import CompanyUsers from "../../components/account/CompanyUsers";
import {loadCompany} from "../../actions/account";
import {Loading} from "../../libs/loading";
import CompanyProfile from "../../components/account/CompanyProfile";
import VATTable from "../../components/account/VATTable";
import CompanyEmails from "../../components/account/CompanyEmails";
import CompanyContacts from "../../components/account/CompanyContacts";
import {hasPermission} from "../../components/permissions/PermissionUtils";
import PartnerDetails from "../../components/account/PartnerDetails";

class ManageCompany extends React.Component {
    state = {
        pages: ['profile', 'users', 'logs', 'vat', 'emails', 'contacts', 'partner-details'],
        titles: {
            profile: 'Profile', users: 'Users', vat: 'VAT', logs: 'Logs', emails: 'Emails', contacts: 'Contacts'
        },
        activeTab: 'profile',
        company: null,
        loadingCompany: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }
    
    getCompanyId() {
        let guid = this.props.match.params.guid;
        
        if(typeof this.props.match.params.guid === 'undefined') {
            guid = this.props.user.user.company.guid;
        }
        
        return guid;
    }

    componentDidMount() {
        let page = 'profile';

        if(typeof this.props.match.params.page !== 'undefined') {
            page = this.props.match.params.page;
        }

        this.setState({ activeTab: page })
    }

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            this.setState({ activeTab: page });

            this.props.updatePage(this.state.titles[page]);

            let url = '/company';

            if(typeof this.props.match.params.guid !== 'undefined') {
                url += '/' + this.getCompanyId() + '/' + page;
            } else {
                url += '/' + page;
            }

            this.props.history.push(url);
        }
    }

    render() {
        if(this.state.loadingCompany) {
            return <div className='text-center'><Loading /></div>;
        }

        const items = [
            { label: 'Profile', key: 'profile', children: <CompanyProfile match={this.props.match} companyGuid={this.getCompanyId()} /> },
        ];

        if(hasPermission(this.props.company.permissions, ['can_view_vat'])) {
            items.push({ label: 'VAT', key: 'vat', children: <VATTable match={this.props.match} companyGuid={this.getCompanyId()} /> });
        }

        if(hasPermission(this.props.company.permissions, ['can_view_company_users'])) {
            items.push({ label: 'Users', key: 'users', children: <CompanyUsers match={this.props.match} companyGuid={this.getCompanyId()} /> });
        }

        if(hasPermission(this.props.company.permissions, ['can_view_company_contacts'])) {
            items.push({ label: 'Contacts', key: 'contacts', children: <CompanyContacts match={this.props.match} companyGuid={this.getCompanyId()} /> });
        }

        if(hasPermission(this.props.company.permissions, ['can_view_company_emails'])) {
            items.push({ label: 'Emails', key: 'emails', children: <CompanyEmails match={this.props.match} companyGuid={this.getCompanyId()} /> });
        }

        if(hasPermission(this.props.company.permissions, ['can_view_company_logs'])) {
            items.push({ label: 'Logs', key: 'logs', children: <CompanyLog match={this.props.match} companyGuid={this.getCompanyId()} /> });
        }

        if(this.props.company.is_partner) {
            items.push({ label: 'Partner Details', key: 'partner-details', children: <PartnerDetails /> });
        }

        return(
          <Card size='small'>
              <Tabs activeKey={this.state.activeTab} onChange={(tab) => this.changeTab(tab)} destroyInactiveTabPane={true} items={items} />
          </Card>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    company: state.auth.user.user.company
});


export default connect(mapStateToProps, { updatePage, loadCompany })(ManageCompany);