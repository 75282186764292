import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, message, Modal, Space} from "antd";
import {Loading} from "../../libs/loading";
import {displayErrors} from "../../libs/utils";
import {getIpAddressGroupDetails, updateIPAddressGroup} from "../../actions/ip_addresses";
import AccessItem from "../permissions/AccessItem";

class GroupSettingsModal extends React.Component {
    state = {
        loading: false,
        groupLoaded: false,
        name: '',
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.groupLoaded && !this.state.loading && this.props.showModal) {
            this.setState({ loading: true });

            this.props.getIpAddressGroupDetails(this.props.group, (res) => {
                this.setState({ groupLoaded: true, loading: false, name: res.data.name });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ groupLoaded: true, loading: false});
                    displayErrors(err.response.data);
                }
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal(reload = false) {
        this.props.close();
        this.setState({
            loading: false,
            groupLoaded: false,
            name: '',
        });

        if(reload) {
            this.props.reloadGroup();
        }
    }

    saveSettings() {
        this.setState({ loading: true });

        let name = this.state.name.trim();

        if(name.length === 0) {
            return message.error('Please enter your group\'s name!');
        }

        let data = { name };

        this.props.updateIPAddressGroup(this.props.group, data, () => {
            message.success('Group successfully updated!');
            this.setState({ loading: false });
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    render() {
        let content;

        if(!this.state.groupLoaded) {
            content = <div className='text-center'><Loading /></div>;
        } else {
            content = <Form layout='vertical'>
                <Form.Item label='Group name'>
                    <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                </Form.Item>
            </Form>;
        }

        return(
            <Modal
                centered
                title={'Settings'}
                open={this.props.showModal}
                destroyOnClose={true}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <AccessItem scopes={['can_edit_ip_groups']}>
                        <Button type='primary' loading={this.state.loading} onClick={() => this.saveSettings()}>Save</Button>
                    </AccessItem>
                </Space>}
                confirmLoading={this.state.loading}
                onCancel={() => this.closeModal()}>
                {content}
            </Modal>
        );
    }
}

export default connect(null, { getIpAddressGroupDetails, updateIPAddressGroup })(GroupSettingsModal);