import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import {Button, Card, Col, Form, Input, message, Row, Select} from "antd";
import {
    fetchWhiteLabelData,
    loadUser,
    login,
    requestVerification,
    verifyUser2FA
} from "../../actions/auth";
import {displayErrors, getCookieValue} from "../../libs/utils";
import {Link, Redirect} from "react-router-dom";
import {updatePage} from "../../actions/page";

class Login extends React.Component  {
    state = {
        loading: false,
        step: 1,
        email: '',
        password: '',
        remember: false,
        emailNotVerified: false,
        requestingVerification: false,
        viewPassword: false,
        showWhiteLabelPage: false,
        whiteLabelDataLoaded: false,
        whiteLabelLogo: null,

        show2FA: false,
        user2FASessionId: '',
        user2FACode: '',
        verifying: false,
        user2FACodeType: 'CODE',
    };

    componentDidMount() {
        this.props.updatePage('Login');
        this.emailInput.focus();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.show2FA && this.tfaInput !== null) {
            this.tfaInput.focus();
        }
    }

    login() {
        let email = this.state.email.trim();
        let password = this.state.password.trim();

        if(email.length === 0) {
            return message.error('Please enter your email address!');
        }

        if(password.length === 0) {
            return message.error('Please enter your password!');
        }

        let data = { email, password };

        window.location.search.substring(1).split('&').forEach(value => {
           let pieces = value.split('=');
           data[pieces[0]] = pieces[1];
        });

        if(window.location.search === '') {
            ['utm_campaign', 'utm_source', 'utm_medium', 'utm_content', 'utm_term'].forEach(value => {
                if(getCookieValue(value) !== '') {
                    data[value] = getCookieValue(value);
                }
            })
        }

        this.setState({ loading: true });

        this.props.login(data, (res) => {
            if(typeof res.data.session_id !== 'undefined' && typeof res.data.verification !== 'undefined' && res.data.verification === '2fa') {
                this.setState({ user2FASessionId: res.data.session_id, show2FA: true });
            } else {
                this.props.loadUser();
            }
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                let state = { loading: false };
                if(typeof err.response.data.email_not_verified !== 'undefined') {
                    state['emailNotVerified'] = err.response.data.email_not_verified;
                }

                displayErrors(err.response.data);
                this.setState(state);
            }
        });
    }

    requestVerification() {
        this.setState({ requestingVerification: true });

        let email = this.state.email.trim();

        if(email.length === 0) {
            return message.error('Please enter your email address!');
        }

        let data = { email };

        this.props.requestVerification(data, () => {
            message.success('Verification successfully requested! Please check your mailbox!');
            this.setState({ requestingVerification: false, emailNotVerified: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ requestingVerification: false });
            }
        });
    }

    verify2Fa() {
        this.setState({ verifying: true });

        let code = this.state.user2FACode.trim();

        if(code === '') {
            return message.error('Please enter the 6 digit code!');
        }

        let data = { session_id: this.state.user2FASessionId, code: code.replaceAll(' ', ''), code_type: this.state.user2FACodeType };

        this.props.verifyUser2FA(data, () => {
            this.props.loadUser();
            this.setState({ verifying: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ verifying: false });
            }
        });
    }

    render() {
        if(this.props.isAuthenticated) {
            return <Redirect to='/' />;
        }

        const { Option } = Select;

        return(
            <div className='auth-wrapper'>
                <Card className='form-container login'>
                    <div className='logo-container'>
                        <img alt='Priority Prospect' src='/img/logo-top-dark.png' />
                    </div>

                    <Form layout='vertical'>
                        {this.state.show2FA ? <Fragment>
                            <Form.Item label='Select code type'>
                                <Select value={this.state.user2FACodeType} onChange={(value) => this.setState(({ user2FACodeType: value }))}>
                                    <Option value='CODE'>Code</Option>
                                    <Option value='BACKUP_CODE'>Backup code</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label='Please enter 6 digit code'>
                                <Input maxLength={this.state.user2FACodeType === 'CODE' ? 6 : 10}
                                       ref={(input) => this.tfaInput = input}
                                       onKeyUp={(e) => e.key === 'Enter' ? this.verify2Fa() : ''}
                                       onChange={(e) => this.setState({ user2FACode: e.target.value })} />
                            </Form.Item>
                            <Button type='primary' block
                                    disabled={this.state.verifying}
                                    loading={this.state.verifying}
                                    onClick={() => this.verify2Fa()}>Verify</Button>
                        </Fragment> : <Fragment>
                            <Form.Item>
                                <Input type='email'
                                       value={this.state.email}
                                       ref={(input) => this.emailInput = input}
                                       placeholder='Email address'
                                       onKeyUp={(e) => e.key === 'Enter' ? this.login() : ''}
                                       onChange={(e) => this.setState({ email: e.target.value })} />
                            </Form.Item>
                            <Form.Item>
                                <Input.Password
                                    ref={(input) => this.passwordInput = input}
                                    value={this.state.password}
                                    placeholder='Password'
                                    onKeyUp={(e) => e.key === 'Enter' ? this.login() : ''}
                                    onChange={(e) => this.setState({ password: e.target.value })} />
                            </Form.Item>
                            {this.state.emailNotVerified ? <Row>
                                <Col span={12} className='text-center'>
                                    <Button disabled={this.state.requestingVerification}
                                            loading={this.state.requestingVerification}
                                            type='primary'
                                            style={{marginBottom: '10px'}} block
                                            onClick={() => this.requestVerification()}>Request new verification email</Button>
                                </Col>
                            </Row>: ''}
                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type='primary' block
                                                disabled={this.state.loading}
                                                loading={this.state.loading}
                                                onClick={() => this.login()}>Login</Button>

                                        <div className='auth-mobile-button'>
                                            <Link to={'/auth/register' + window.location.search}>
                                                <Button block style={{marginTop: '15px'}}>Create Account</Button>
                                            </Link>
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>
                                    <Link to={'/auth/recover' + window.location.search}>Forgot password?</Link>
                                </Col>
                            </Row>
                        </Fragment>}
                    </Form>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.isLoading
});

export default connect(mapStateToProps, { updatePage, login, loadUser, requestVerification,
    fetchWhiteLabelData, verifyUser2FA })(Login);