import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Card, Col, Form, Input, message, Row, Select} from "antd";
import {loadCompany, updateCompany, updateCompanyInvoiceNotes} from "../../actions/account";
import {displayErrors} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {loadUser} from "../../actions/auth";
import countryList from "react-select-country-list";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import {hasPermission} from "../permissions/PermissionUtils";
import AccessItem from "../permissions/AccessItem";

class CompanyProfile extends React.Component {
    state = {
        loadingCompany: false,
        updatingCompany: false,
        companyLoaded: false,
        updatingInvoiceNotes: false,
        updatingNotes: false
    };

    countries = countryList().getData();

    componentDidMount() {
        this.props.updatePage('Profile');

        if(!this.state.companyLoaded) {
            this.loadCompany();
        }
    }

    loadCompany() {
        this.setState({ loadingCompany: true });

        this.props.loadCompany(this.props.companyGuid, (res) => {
            this.setState({ loadingCompany: false, ...res.data, companyLoaded: true });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCompany: false });
                displayErrors(err.response.data);
            }
        });
    }

    updateCompany() {
        this.setState({ updatingCompany: true });

        let name = this.state.name.trim();
        let address1 = this.state.address1.trim();

        let address2 = '';
        if(this.state.address2 !== null) {
            address2 = this.state.address2.trim();
        }

        let city = this.state.city.trim();
        let region = this.state.region.trim();
        let postal_code = this.state.postal_code.trim();
        let phone = this.state.phone.trim();
        let country = this.state.country.trim();

        if(name.length === 0) {
            return message.error('Please enter your company name!');
        }

        if(address1.length === 0) {
            return message.error('Please enter your address!');
        }

        if(city.length === 0) {
            return message.error('Please enter your city!');
        }

        if(region.length === 0) {
            return message.error('Please enter your region!');
        }

        if(postal_code.length === 0) {
            return message.error('Please enter your postal code!');
        }

        if(phone.length === 0) {
            return message.error('Please enter your phone!');
        }

        if(country.length === 0) {
            return message.error('Please enter your country!');
        }

        let data = {
            name, address1, address2, city, region, postal_code, phone, country
        };

        this.props.updateCompany(this.props.companyGuid, data, (res) => {
            this.setState({ updatingCompany: false, ...res.data });
            this.props.loadUser();

            message.success('Company successfully updated!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingCompany: false });
                displayErrors(err.response.data);
            }
        });
    }

    updateInvoiceNotes() {
        this.setState({ updatingInvoiceNotes: true });

        let invoice_notes = this.state.invoice_notes;

        if(invoice_notes !== null) {
            invoice_notes = invoice_notes.trim();
        }

        let data = { invoice_notes };

        this.props.updateCompanyInvoiceNotes(this.props.companyGuid, data, () => {
            this.setState({ updatingInvoiceNotes: false });
            message.success('Invoice notes successfully updated!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingInvoiceNotes: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.loadingCompany) {
            return <div className='text-center'><Loading /></div>;
        }

        let fieldsDisabled = this.state.updatingCompany;

        if(!hasPermission(this.props.company.permissions, ['can_edit_company_profile'])) {
            fieldsDisabled = true;
        }

        const { Option } = Select;

        return(
            <Fragment>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <Card title='Profile' size='small' extra={<AccessItem scopes={['can_edit_company_profile']}>
                            <Button type='primary' size='small' loading={this.state.updatingCompany} onClick={() => this.updateCompany()}>Update Profile</Button>
                        </AccessItem>}>
                            <Form layout='vertical'>
                                <Form.Item label='Name'>
                                    <Input value={this.state.name} disabled={fieldsDisabled} onChange={(e) => this.setState({ name: e.target.value })} />
                                </Form.Item>
                                <Form.Item label='Address'>
                                    <Input value={this.state.address1} disabled={fieldsDisabled} onChange={(e) => this.setState({ address1: e.target.value })} />
                                </Form.Item>
                                <Form.Item label='Address 2'>
                                    <Input value={this.state.address2} disabled={fieldsDisabled} onChange={(e) => this.setState({ address2: e.target.value })} />
                                </Form.Item>
                                <Form.Item label='City'>
                                    <Input value={this.state.city} disabled={fieldsDisabled} onChange={(e) => this.setState({ city: e.target.value })} />
                                </Form.Item>
                                <Form.Item label='Region'>
                                    <Input value={this.state.region} disabled={fieldsDisabled} onChange={(e) => this.setState({ region: e.target.value })} />
                                </Form.Item>
                                <Form.Item label='Postal code'>
                                    <Input value={this.state.postal_code} disabled={fieldsDisabled} onChange={(e) => this.setState({ postal_code: e.target.value })} />
                                </Form.Item>
                                <Form.Item label='Country'>
                                    <Select placeholder='Select country'  disabled={fieldsDisabled} value={this.state.country} onChange={(value) => this.setState({ country: value })}>
                                        {this.countries.map((key) => {
                                            return <Option key={key.value} value={key.value}>{key.label}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label='Phone'>
                                    <Input value={this.state.phone} disabled={fieldsDisabled} onChange={(e) => this.setState({ phone: e.target.value })} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Card title='Invoice Notes' size='small' className='card-bottom-margin' extra={<AccessItem scopes={['can_edit_company_profile']}>
                            <Button type='primary' size='small' loading={this.state.updatingInvoiceNotes} onClick={() => this.updateInvoiceNotes()}>Update</Button>
                        </AccessItem>}>
                            <Form layout='vertical'>
                                <Form.Item extra={<small><em>Custom notes are included under invoices.</em></small>}>
                                    <TextArea disabled={fieldsDisabled} value={this.state.invoice_notes} rows={3} onChange={(e) => this.setState({ invoice_notes: e.target.value })} />
                                </Form.Item>
                            </Form>
                        </Card>
                        <br />
                        <Card title='Support' size='small' className='card-bottom-margin'
                              extra={<a href='https://support.priorityprospect.com/tickets/create/step1' target='_blank' rel='noopener noreferrer'><Button size='small' type='primary'>Create support ticket</Button></a>}>
                            Your support pin is: <Text code copyable>{this.state.support_pin}</Text>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadUser, loadCompany, updateCompany,
    updateCompanyInvoiceNotes })(CompanyProfile);