import React from "react";
import {connect} from "react-redux";
import {Space, Button, Form, Input, Modal, Select, Divider, message, Checkbox} from "antd";
import countryList from "react-select-country-list";
import {createNewCard} from "../../actions/billing";
import {displayErrors} from "../../libs/utils";
import {openGettingStartedWizard, updateGettingStartedWizardStep} from "../../actions/getting_started_wizard";

class AddCardModal extends React.Component {
    state = {
        profileName: '',
        name: '',
        address: '',
        city: '',
        region: '',
        country: '',
        postalCode: '',
        cardNumber: '',
        cvv: '',
        expirationMonth: 1,
        expirationYear: new Date().getFullYear(),
        creatingCard: false,
        understandReservation: false
    };

    countries = countryList().getData();

    closeModal(reload = false, added = false) {
        this.setState({
            profileName: '',
            name: '',
            address: '',
            city: '',
            region: '',
            country: '',
            postalCode: '',
            cardNumber: '',
            cvv: '',
            expirationMonth: 1,
            expirationYear: new Date().getFullYear(),
            creatingCard: false,
            understandReservation: false,
        });

        this.props.close(added);

        if(reload) {
            this.props.reload(added);
        }
    }

    createProfile() {
        let data = {
            profile_name: this.state.profileName.trim(),
            name: this.state.name.trim(),
            address: this.state.address.trim(),
            city: this.state.city.trim(),
            region: this.state.region.trim(),
            country: this.state.country.trim(),
            postal_code: this.state.postalCode.trim(),
            card_number: this.state.cardNumber.trim(),
            cvv: this.state.cvv.trim(),
            expiration_month: this.state.expirationMonth,
            expiration_year: this.state.expirationYear,
            screen_width: window.innerWidth,
            screen_height: window.innerHeight,
            color_depth: window.screen.colorDepth,
            time_zone: new Date().getTimezoneOffset(),
            browser_language: window.navigator.language
        };

        for(let key in data) {
            if(data[key] === '') {
                return message.error('Please fill all fields!');
            }
        }

        this.setState({ creatingCard: true });

        this.props.createNewCard(data, (res) => {
           if(!res.data.requires_verification) {
               this.setState({ creatingCard: false });

               message.success('Card successfully added!');

               this.props.openGettingStartedWizard();
               this.props.updateGettingStartedWizardStep(this.props.company.guid, 4);

               this.closeModal(true, true);
           } else {
               window.location.href = res.data.verification_url;
           }

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ creatingCard: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;
        let months = []
        let years = [];

        for(let i = 1; i <= 12; i++) {
            months.push(i);
        }

        let currentYear = new Date().getFullYear();

        for(let i = 0; i <= 15; i++) {
            years.push(currentYear + i);
        }

        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        };

        return(
            <Modal
                centered
                title='Add New Card'
                open={this.props.showModal}
                destroyOnClose={true}
                closable={false}
                className='add-card-modal'
                onCancel={() => this.closeModal()}
                footer={
                    <Space>
                        <Button disabled={this.state.creatingCard} onClick={() => this.closeModal()}>Close</Button>
                        <Button type='primary' onClick={() => this.createProfile()} disabled={this.state.creatingCard || !this.state.understandReservation} loading={this.state.creatingCard}>Continue</Button>
                    </Space>
                }>

                <Form {...layout}>
                    <Form.Item label='Profile name'>
                        <Input value={this.state.profileName} onChange={(e) => this.setState({ profileName: e.target.value })} />
                    </Form.Item>
                    <Divider orientation="center" plain>
                        Billing Details
                    </Divider>
                    <Form.Item label='Name on card'>
                        <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Address'>
                        <Input value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='City'>
                        <Input value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Region'>
                        <Input value={this.state.region} onChange={(e) => this.setState({ region: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Country'>
                        <Select placeholder='Select country' showSearch
                                filterOption={(input, option) => {
                                    if(typeof option !== 'undefined') {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }}
                                onChange={(value) => this.setState({ country: value })}>
                            {this.countries.map((key) => {
                                return <Option key={key.value} value={key.value}>{key.label}</Option>;
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Postal code'>
                        <Input value={this.state.postalCode} onChange={(e) => this.setState({ postalCode: e.target.value })} />
                    </Form.Item>
                    <Divider orientation="center" plain>
                        Card Details
                    </Divider>
                    <Form.Item label='Card number'>
                        <Input value={this.state.cardNumber} onChange={(e) => this.setState({ cardNumber: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Expiration date'>
                        <Space>
                            <Select defaultValue={this.state.expirationMonth} onChange={(value) => this.setState({ expirationMonth: value })}>
                                {months.map((item) => <Option key={item} value={item}>{item}</Option>)}
                            </Select>

                            <Select defaultValue={this.state.expirationYear} onChange={(value) => this.setState({ expirationYear: value })}>
                                {years.map((item) => <Option key={item} value={item}>{item}</Option>)}
                            </Select>
                        </Space>
                    </Form.Item>
                    <Form.Item label='CVV'>
                        <Input value={this.state.cvv} onChange={(e) => this.setState({ cvv: e.target.value })} />
                    </Form.Item>
                    <Checkbox checked={this.state.understandReservation} onChange={(e) => this.setState({understandReservation: e.target.checked})}>
                        <p style={{color: 'red'}}><small>I understand that $1.00 USD (or local equivalent if you are not paying in USD) will be reserved from your card to verify your card. It will be
                            released back to your card within 14 days. The speed of the release depends on your card provider.</small></p>
                    </Checkbox>
                    <div className='text-center'><img src={'/img/cc.png'} alt='Card types' /></div>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    company: state.auth.user.user.company,
    wizard: state.getting_started_wizard
});

export default connect(mapStateToProps, { createNewCard, openGettingStartedWizard,
    updateGettingStartedWizardStep })(AddCardModal);