import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    getIpAddressGroupDetails,
    getIpAddressGroupIPAddresses,
    setDirectAccess,
    setHttpVersion
} from "../../actions/ip_addresses";
import {
    Button, Card, Checkbox,
    Col,
    ConfigProvider,
    message,
    Modal, Popconfirm,
    Popover,
    Progress,
    Row,
    Space, Switch,
    Tooltip
} from "antd";
import Table from "antd/lib/table";
import Flag from 'react-world-flags'
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DEFAULT_FLAG_SIZE, DEFAULT_PER_PAGE} from "../../config";
import {displayErrors} from "../../libs/utils";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import LoginOutlined from "@ant-design/icons/lib/icons/LoginOutlined";
import NewIPAddressesWizard from "../../components/ip_addresses/NewIPAddressesWizard";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import {
    BarsOutlined,
    EditOutlined,
    FolderOpenOutlined,
    SettingOutlined, UsergroupAddOutlined, UserOutlined
} from "@ant-design/icons";
import CancelIPAddressesModal from "../../components/ip_addresses/CancelIPAddressesModal";
import ModifyIPAddressesModal from "../../components/ip_addresses/ModifyIPAddressesModal";
import {StatusIndicator} from "../../components/shared/Status";
import ModifyIPAddressSignatureModal from "../../components/ip_addresses/ModifyIPAddressSignatureModal";
import MoveIPAddressModal from "../../components/ip_addresses/MoveIPAddressModal";
import GroupSettingsModal from "../../components/ip_addresses/GroupSettingsModal";
import ExportOutlined from "@ant-design/icons/lib/icons/ExportOutlined";
import ExportModal from "../../components/ip_addresses/ExportModal";
import BulkIPAddressActionsModal from "../../components/ip_addresses/BulkIPAddressActionsModal";
import AccessGate from "../../components/permissions/AccessGate";
import AccessItem from "../../components/permissions/AccessItem";
import {DateFormat} from "../../components/shared/DateFormat";
import {Link} from "react-router-dom";


class IPAddressGroup extends React.Component {
    state = {
        groupLoaded: false,
        loadingIPAddresses: false,
        name: '',
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        ipAddresses: [],
        selectedIPAddresses: [],
        showIPSignatureModal: false,
        activeSignature: null,
        activeIPAddress: null,
        showIPAddingWizard: false,
        showCancellationConfirmation: false,
        showCancellationModal: false,
        cancellationTermsAgreed: false,
        searching: false,
        searchQuery: [],
        showIPAddressModifyModal: false,
        showIPAddressSignatureModifyModal: false,
        showMoveIPAddressModal: false,
        showSettingsModal: false,
        showExportModal: false,
        showBulkActionsModal: false,
    };

    componentDidMount() {
        this.loadIPAddressGroupDetails();
        this.loadIPAddresses();
    }

    loadIPAddressGroupDetails() {
        this.props.getIpAddressGroupDetails(this.props.match.params.group, (response) => {
            this.setState({ groupLoaded: true});
            this.props.updatePage(response.data.name);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ groupLoaded: true});
                displayErrors(err.response.data);
            }
        });
    }

    loadIPAddresses(page = null, per_page = null) {
        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingIPAddresses: false});

        if(this.state.searchQuery.length > 0) {
            this.setState({ searching: true });
        }

        this.props.getIpAddressGroupIPAddresses(this.props.match.params.group, page, per_page,  this.state.searchQuery,(response) => {
            this.setState({ searching: false, ipAddresses: response.data.data, loadingIPAddresses: true,
                pagination: { current: response.data.page, pageSize: response.data.per_page, total: response.data.total, showSizeChanger: true } });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ groupLoaded: true, loadingIPAddresses: true});
                displayErrors(err.response.data);
            }
        });
    }

    startCancellationProcess() {
        for(let i = 0; i < this.state.selectedIPAddresses.length; i++) {
            for(let j = 0; j < this.state.ipAddresses.length; j++) {
                if(this.state.ipAddresses[j].guid === this.state.selectedIPAddresses[i]) {
                    if(this.state.ipAddresses[j].domain_count > 0) {
                        return message.error('Please uncheck IP addresses with active domains or remove domains from those IP addresses.');
                    } else if(this.state.ipAddresses[j].status === 'PENDING_CANCELLATION') {
                        return message.error('Please uncheck IP addresses that already have a cancellation request submitted!.');
                    }
                }
            }
        }

        this.setState({ showCancellationConfirmation: true });
    }

    getProgress(count, allowed) {
        let s = count === 1 ? '' : 's';
        let content = count + ' domain' + s + ' used out of ' + allowed + ' allowed.';

        return <Popover title='Domain Usage' content={content}>{count + '/' + allowed}</Popover>;
    }

    toggleHttpVersion(guid, version) {
        this.setState({ loadingIPAddresses: false });

        this.props.setHttpVersion(guid, { version }, () => {
            this.loadIPAddresses();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPAddresses: true});
                displayErrors(err.response.data);
            }
        });
    }

    toggleDirectAccess(guid, status) {
        this.setState({ loadingIPAddresses: false });

        this.props.setDirectAccess(guid, { status }, () => {
            this.loadIPAddresses();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPAddresses: true});
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'IP Address', render: (_, record) => <span>{record.ip_address_type === 'DEDICATED' ? <Tooltip title='Dedicated IP address'><UserOutlined className='ip-picker-icon' /></Tooltip> : <Tooltip title='Shared IP address'><UsergroupAddOutlined className='ip-picker-icon' /></Tooltip>} {record.ip_address}</span> },
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el={'badge'} /> },
            { title: 'HTTP/2', dataIndex: 'http_version', render: (item, record) => {
                    if(record.ip_address_type === 'SHARED') {
                        return <Tooltip title={<div className='text-center'>HTTP/2 is not available for shared IP addresses!</div>}>
                            <div><StatusIndicator status='UNAVAILABLE' el='badge' /></div>
                        </Tooltip>;
                    }

                    return <Space>
                        <AccessItem scopes={['can_edit_ip_addresses']}>
                            <Popconfirm title={(item === 1 ? 'Enable' : 'Disable') + ' HTTP/2 for this IP address?'} okText={item === 2 ? 'Disable' : 'Enable'} onConfirm={() => this.toggleHttpVersion(record.guid, (item === 1 ? 2 : 1))}>
                                <Switch checked={item === 2} size='small' />
                            </Popconfirm>
                        </AccessItem>
                        {item === 2 ? <StatusIndicator status='ACTIVE' el='badge' /> : <StatusIndicator status='DISABLED' el='badge' />}
                    </Space>
            }},
            /*{ title: 'Direct Access', align: 'center', dataIndex: 'direct_access', render: (item, record) => {
                    if(record.ip_address_type === 'SHARED') {
                        return <Tooltip title={<div className='text-center'>Direct access is not available for shared IP addresses!</div>}>
                            <div><StatusIndicator status='UNAVAILABLE' el='badge' /></div>
                        </Tooltip>;
                    } else if(record.domains_allowed > 1) {
                        return <Tooltip title={<div className='text-center'>Direct access is not available for IP addresses with more than one website slots!</div>}>
                            <div><StatusIndicator status='UNAVAILABLE' el='badge' /></div>
                        </Tooltip>;
                    }

                    return <Space>
                        <AccessItem scopes={['can_edit_ip_addresses']}>
                            <Popconfirm title={(!item ? 'Enable' : 'Disable') + ' direct for this IP address?'} okText={item ? 'Disable' : 'Enable'} onConfirm={() => this.toggleDirectAccess(record.guid, !item)}>
                                <Switch checked={item} size='small' />
                            </Popconfirm>
                        </AccessItem>
                        {item ? <StatusIndicator status='ACTIVE' el='badge' /> : <StatusIndicator status='DISABLED' el='badge' />}
                    </Space>
            }},*/
            { title: 'Location', dataIndex: 'country', render: (item, row) => <span><Flag height={DEFAULT_FLAG_SIZE} code={row.country_code} style={{marginTop: '-2px'}} /> {row.city + ', ' + row.country}</span> },
            { title: 'Domain Usage', render: (item, row) => <Progress format={() => this.getProgress(row.domain_count, row.domains_allowed)} status='normal' percent={Math.round((row.domain_count * 100) / row.domains_allowed)} size="small" /> },

            { title: 'Renewal', align: 'center', dataIndex: 'order', render: (item) => <DateFormat value={item.renewal_date} />},
            { title: 'Order', align: 'center', dataIndex: 'order', render: (item) => <Link to={'/billing/orders/' + item.order_id}>#{item.order_id}</Link>},
            { title: 'Server Signature', width: '25%', dataIndex: 'signature', render: (item, row) => {
                return <Space>
                    <span>{row.signature.signature.trim()}</span>
                    <AccessItem scopes={['can_edit_ip_addresses']}>
                        {row.ip_address_type === 'DEDICATED' ? <Button size='small' type='text' onClick={() => this.setState({ activeIPAddress: row.guid, showIPAddressSignatureModifyModal: true, selectedIPAddressSignature: row.signature.guid })} icon={<EditOutlined />} />
                        : <Tooltip title={<div className='text-center'>Editing signature is not available for shared IP addresses!</div>}><Button size='small' type='text' disabled={true} icon={<EditOutlined />} /></Tooltip>}
                    </AccessItem>
                </Space>;
            }},
        ];

        return(
            <AccessGate scopes={['can_view_ip_addresses']}>
                <Card size='small'>
                    <Row justify='space-between' style={{marginBottom: '10px'}}>
                        <Col>
                            <Space>
                                <AccessItem scopes={['can_add_ip_addresses']}>
                                    <Button type='primary' icon={<PlusOutlined />} onClick={() => this.setState({ showIPAddingWizard: true })}>Add IP Addresses</Button>
                                </AccessItem>
                                <AccessItem scopes={['can_edit_ip_addresses']}>
                                    <Button type='text' disabled={this.state.selectedIPAddresses.length === 0} icon={<BarsOutlined />} onClick={() => this.setState({ showBulkActionsModal: true})}>Bulk Actions</Button>
                                    <Button type='text' disabled={this.state.selectedIPAddresses.length === 0} icon={<LoginOutlined />} onClick={() => this.setState({ showMoveIPAddressModal: true })}>Move</Button>
                                    <Button type='text' disabled={this.state.selectedIPAddresses.length === 0} icon={<EditOutlined />} onClick={() => this.setState({ showIPAddressModifyModal: true })}>Modify</Button>
                                </AccessItem>
                                <AccessItem scopes={['can_add_cancellation_requests']}>
                                    <Button type='text' disabled={this.state.selectedIPAddresses.length === 0} icon={<DeleteOutlined />} onClick={() => this.startCancellationProcess()}>Cancel</Button>
                                </AccessItem>
                                <Button type='text' disabled={this.state.ipAddresses.length === 0} icon={<ExportOutlined />} onClick={() => this.setState({ showExportModal: true })}>Export</Button>
                                <AccessItem scopes={['can_edit_ip_groups']}>
                                    <Button type='text' icon={<SettingOutlined />} onClick={() => this.setState({ showSettingsModal: true })}>Settings</Button>
                                </AccessItem>
                            </Space>
                        </Col>
                        <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadIPAddresses()}/></Tooltip></Col>
                    </Row>

                    <ConfigProvider renderEmpty={() => <div style={{ textAlign: 'center' }}>
                        <FolderOpenOutlined style={{ fontSize: 40 }} />
                        <p>Nothing to see here...</p>
                        <Button icon={<PlusOutlined />} onClick={() => this.setState({ showIPAddingWizard: true})}>Add IP Addresses</Button>
                    </div>}>
                        <Table
                            dataSource={this.state.ipAddresses}
                            columns={columns}
                            onChange={(pagination) => this.loadIPAddresses(pagination.current, pagination.pageSize)}
                            rowKey={record => record.guid}
                            loading={!this.state.loadingIPAddresses}
                            pagination={this.state.pagination}
                            footer={this.state.selectedIPAddresses.length > 0 ? () => <span>{this.state.selectedIPAddresses.length} selected</span> : null }
                            rowSelection={{
                                type: 'checkbox',
                                selectedRowKeys: this.state.selectedIPAddresses,
                                onChange: (ipAddresses) => this.setState({ selectedIPAddresses: ipAddresses }),
                                getCheckboxProps: (record) => ({
                                    disabled: ['CANCELLED', 'PENDING_CANCELLATION', 'REPLACED'].indexOf(record.status) > -1
                                }),
                            }} />
                    </ConfigProvider>
                </Card>

                <ExportModal
                    showModal={this.state.showExportModal}
                    group={this.props.match.params.group}
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    closeModal={() => this.setState({ showExportModal: false, selectedIPAddresses: [] })} />

                <GroupSettingsModal
                    showModal={this.state.showSettingsModal}
                    group={this.props.match.params.group}
                    reloadGroup={() => this.loadIPAddressGroupDetails()}
                    close={() => this.setState({ showSettingsModal: false })} />

                <ModifyIPAddressesModal
                    history={this.props.history}
                    showModal={this.state.showIPAddressModifyModal}
                    ipAddresses={this.state.ipAddresses}
                    close={() => this.setState({ showIPAddressModifyModal: false, selectedIPAddresses: [] })}
                    selectedIPAddresses={this.state.selectedIPAddresses} />

                <NewIPAddressesWizard
                    history={this.props.history}
                    show={this.state.showIPAddingWizard}
                    group={this.props.match.params.group}
                    close={() => this.setState({ showIPAddingWizard: false })} />

                <CancelIPAddressesModal
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    show={this.state.showCancellationModal}
                    close={() => {
                        this.setState({ showCancellationModal: false, selectedIPAddresses: [] });
                        this.loadIPAddresses();
                    }} />

                <ModifyIPAddressSignatureModal
                    show={this.state.showIPAddressSignatureModifyModal}
                    activeIPAddress={this.state.activeIPAddress}
                    selectedIPAddressSignature={this.state.selectedIPAddressSignature}
                    close={(reload) => {
                        this.setState({ showIPAddressSignatureModifyModal: false });
                        if(reload) {
                            this.loadIPAddresses();
                        }
                    }} />

                <MoveIPAddressModal
                    show={this.state.showMoveIPAddressModal}
                    activeIPGroup={this.props.match.params.group}
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    close={(reload) => {
                        this.setState({ showMoveIPAddressModal: false });

                        if(reload) {
                            this.setState({ selectedIPAddresses: [] });
                            this.loadIPAddresses();
                        }
                    }} />

                <BulkIPAddressActionsModal
                    show={this.state.showBulkActionsModal}
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    close={() => this.setState({ showBulkActionsModal: false })}
                    reload={() => this.setState({ selectedIPAddresses: [] }, () => this.loadIPAddresses())}
                />

                <Modal
                    open={this.state.showCancellationConfirmation}
                    title='Are you sure?'
                    footer={<Space>
                        <Button onClick={() => this.setState({ showCancellationConfirmation: false })}>Close</Button>
                        <Button danger disabled={!this.state.cancellationTermsAgreed}
                                onClick={() => this.setState({ showCancellationConfirmation: false, showCancellationModal: true })}>I understand and agree</Button>
                    </Space>}>
                    <p>You are about to cancel some of your IP addresses.</p>
                    <p>Once your IP addresses are cancelled, they cannot be restored!</p>
                    <p><strong style={{color:'red'}}><u>Your IP addresses will be cancelled at the end of your order's billing cycle.</u></strong></p>
                    <p><Checkbox onChange={(e) => this.setState({ cancellationTermsAgreed: e.target.checked })} /> <small>All cancellation requests are processed according to Priority Prospect OÜ's <a target='_blank' rel='noreferrer' href={'https://priorityprospect.com/legal/terms-of-service/'}><b><u>terms of service</u></b></a>.
                        For more information see <a target='_blank' rel='noreferrer' href={'https://priorityprospect.com/legal/terms-of-service/#refund-policy-and-billing-disputes'}><b><u>6. Refund Policy and Billing Disputes</u></b></a>
                        &nbsp; and <a target='_blank' rel='noreferrer' href={'https://priorityprospect.com/legal/terms-of-service/#cancellations-of-service'}><b><u>8. Cancellation of Services</u></b></a>.</small></p>
                </Modal>

            </AccessGate>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});


export default connect(mapStateToProps, { updatePage, getIpAddressGroupDetails,
    getIpAddressGroupIPAddresses, setHttpVersion, setDirectAccess })(IPAddressGroup);