import {httpGet} from "../libs/http";

export const getErrorMessage = (code, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/system/errors/' + code + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const loadAnnouncements = ( onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/system/announcements/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const loadIssues = ( onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/system/issue-notifications/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const loadIssuesList = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/system/issues/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
}

export const loadIssue = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/system/issues/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
}