import React from 'react';
import ReactDOM from 'react-dom'
import {BrowserRouter, Switch} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';

import './style/antd.less';
import './style/theme.less';

import {loadUser} from "./actions/auth";
import PrivateRoute from "./components/shared/PrivateRoute";
import DefaultRoute from "./components/shared/DefaultRoute";

import Auth from "./components/auth";
import App from "./components/app";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import TagManager from 'react-gtm-module'
import posthog from "posthog-js";
import {PostHogProvider} from "posthog-js/react";

import "driver.js/dist/driver.css";

const tagManagerArgs = {
    gtmId: 'GTM-P3VQSM5'
}

TagManager.initialize(tagManagerArgs);

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    capture_pageview: false
});

Sentry.init({
    dsn: "https://b2b4a9e868d441e1ac9eca2fe8eda438@o339554.ingest.sentry.io/6010021",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

class PBNDashboardV4 extends React.Component {
    componentDidMount() {
        store.dispatch(loadUser());
    }

    render() {
        return(
            <PostHogProvider client={posthog}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Switch>
                            <DefaultRoute path='/auth/*' component={Auth} />
                            <PrivateRoute path='/*' component={App} />
                        </Switch>
                    </BrowserRouter>
                </Provider>
            </PostHogProvider>
        );
    }
}

ReactDOM.render(
    <PBNDashboardV4 />,
    document.getElementById('app')
);