import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {loadAffiliateOverview, loadAffiliateRecentReferralActivity} from "../../actions/affiliate";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Card, Col, ConfigProvider, Row, Statistic, Table, Tooltip, Typography} from "antd";
import {Loading} from "../../libs/loading";
import {UserOutlined} from "@ant-design/icons";
import {MoneyField} from "../shared/MoneyField";
import {DateFormat} from "../shared/DateFormat";
import {StatusIndicator} from "../shared/Status";

class AffiliateOverview extends React.Component {
    state = {
        loading: false,
        data: null,
        activity: []
    };

    componentDidMount() {
        this.props.updatePage('Overview');
        this.loadOverview();
        this.loadRecentActivity();
    }

    loadOverview() {
        this.setState({ loading: true });

        this.props.loadAffiliateOverview((res) => {
            this.setState({ loading: false, data: res.data });
        }, (err) => {
            this.setState({ loading: false });
            displayErrors(err.response.data);
        });
    }

    loadRecentActivity() {
        this.setState({ loadingActivity: true });

        this.props.loadAffiliateRecentReferralActivity((res) => {
            this.setState({ loadingActivity: false, activity: res.data });
        }, (err) => {
            this.setState({ loadingActivity: false });
            displayErrors(err.response.data);
        });
    }

    render() {
        const { Text } = Typography;

        if(this.state.loading || this.state.data === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const columns = [
            { title: 'Reference', width: '320px', dataIndex: 'guid', render: (item) => <Text code>{item}</Text>},
            { title: 'Payout', align: 'center', dataIndex: 'payout', render: (item) => <MoneyField amount={item} currency='USD' />},
            { title: 'Type', align: 'center', dataIndex: 'payout_type', render: (item) => {
                if(item === 'NEW') {
                    return <Tooltip title='First payment of a new referral'>{normalizeEnum(item)}</Tooltip>;
                } else if(item === 'RECURRING') {
                    return <Tooltip title='Recurring payment of an existing referral'>{normalizeEnum(item)}</Tooltip>;
                }
            }},
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateFormat value={item} />},
            { title: 'Available', dataIndex: 'date_clears', render: (item) => <DateFormat value={item} />},
        ];

        const { data } = this.state;

        return <Fragment>
            <Card bordered={false} size='small' title='Last 30 days'>
                <Row gutter={[16, 16]}>
                    <Col sm={24} md={8}>
                        <Statistic title="Referrals" value={data.referrals_count_last_30_days} loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={8}>
                        <Statistic title="Visits" value={data.visits_count_last_30_days} loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={8}>
                        <Statistic title="Conversion rate" value={data.conversion_rate_last_30_days} precision={2} suffix='%' loading={this.state.loading} />
                    </Col>
                </Row>
            </Card>
            <br />
            <Card bordered={false} size='small' title='All-time'>
                <Row gutter={[16, 16]}>
                    <Col sm={24} md={4}>
                        <Statistic title="Referrals" value={data.referrals_count} loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={4}>
                        <Statistic title="Visits" value={data.visits_count} loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={4}>
                        <Statistic title="Conversion rate" value={data.conversion_rate}  precision={2} suffix='%' loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={4}>
                        <Statistic title="Total earnings" value={data.total_earnings} precision={2} prefix="$" loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={4}>
                        <Statistic title="Clearing" value={data.clearing_earnings} precision={2} prefix="$" loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={4}>
                        <Statistic title="Unpaid" value={data.unpaid_earnings} precision={2} prefix="$" loading={this.state.loading} />
                    </Col>
                </Row>
            </Card>
            <br />
            <Card bordered={false} size='small' title='Recent referral activity'>
                <ConfigProvider renderEmpty={() => <div style={{ textAlign: 'center' }}>
                    <UserOutlined style={{ fontSize: 40 }} />
                    <p>No activity, yet!</p>
                </div>}>
                    <Table
                        dataSource={this.state.activity}
                        columns={columns}
                        rowKey={(item) => item.guid}
                        pagination={false}
                        loading={this.state.loadingActivity} />
                </ConfigProvider>
            </Card>
        </Fragment>;
    }
}

export default connect(null, { updatePage, loadAffiliateOverview,
    loadAffiliateRecentReferralActivity })(AffiliateOverview);