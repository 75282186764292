import React from 'react';
import {Col, Form, Input, message, Modal, Row, Select} from "antd";
import {connect} from "react-redux";
import {createCompany} from "../../actions/account";
import countryList from 'react-select-country-list'
import {displayErrors} from "../../libs/utils";

class AddCompanyModal extends React.Component
{
    state = {
        creatingCompany: false,
        name: '',
        address1: '',
        address2: '',
        city: '',
        region: '',
        postal_code: '',
        country: '',
        phone: '',
        currency: 'USD'
    };

    countries = countryList().getData();

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal() {
        this.setState({
            showModal: false,
            creatingCompany: false,
            name: '',
            address1: '',
            address2: '',
            city: '',
            region: '',
            postal_code: '',
            country: '',
            phone: '',
            currency: 'USD'
        });

        this.props.closeModal();
    }

    createCompany() {
        let name = this.state.name.trim();
        let address1 = this.state.address1.trim();
        let address2 = this.state.address2.trim();
        let city = this.state.city.trim();
        let region = this.state.region.trim();
        let postal_code = this.state.postal_code.trim();
        let country = this.state.country.trim();
        let phone = this.state.phone.trim();
        let currency = this.state.currency.trim();

        if(name.length === 0) {
            return message.error('Please enter name!');
        }

        if(address1.length === 0) {
            return message.error('Please enter address!');
        }

        if(city.length === 0) {
            return message.error('Please enter city!');
        }

        if(region.length === 0) {
            return message.error('Please enter region!');
        }

        if(postal_code.length === 0) {
            return message.error('Please enter postal code!');
        }

        if(country.length === 0) {
            return message.error('Please enter country!');
        }

        if(phone.length === 0) {
            return message.error('Please enter phone!');
        }

        if(currency.length === 0) {
            return message.error('Please enter currency!');
        }

        let data = {
            name, address1, address2, city, region, postal_code, country, phone, currency
        };

        this.setState({ creatingCompany: true});

        this.props.createCompany(data, () => {
            this.setState({ creatingCompany: false });
            message.success('Company successfully created!');
            this.props.closeModal(true);

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ creatingCompany: false });
                displayErrors(err.response.data);
            }
        });
    };

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { Option } = Select;

        return (
            <Modal
                centered
                title='Add Company'
                open={this.props.showModal}
                destroyOnClose={true}
                onOk={() => this.createCompany()}
                confirmLoading={this.state.creatingCompany}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Name'>
                        <Input value={this.state.name} name='name' onChange={(e) => this.onChange(e)}/>
                    </Form.Item>

                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label='Address'>
                                <Input value={this.state.address1} name='address1' onChange={(e) => this.onChange(e)}/>
                            </Form.Item>
                            <Form.Item label='Address 2'>
                                <Input value={this.state.address2} name='address2' onChange={(e) => this.onChange(e)}/>
                            </Form.Item>
                            <Form.Item label='Postal code'>
                                <Input value={this.state.postal_code} name='postal_code' onChange={(e) => this.onChange(e)}/>
                            </Form.Item>
                            <Form.Item label='Phone'>
                                <Input value={this.state.phone} name='phone' onChange={(e) => this.onChange(e)}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='City'>
                                <Input value={this.state.city} name='city' onChange={(e) => this.onChange(e)}/>
                            </Form.Item>
                            <Form.Item label='Region'>
                                <Input value={this.state.region} name='region' onChange={(e) => this.onChange(e)}/>
                            </Form.Item>
                            <Form.Item label='Country'>
                                <Select placeholder='Select country'
                                        showSearch
                                        value={this.state.country}
                                        filterOption={(input, option) => {
                                            if(typeof option !== 'undefined') {
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }
                                            return false;
                                        }}
                                        onChange={(value) => this.setState({ country: value })}>
                                    {this.countries.map((key) => {
                                        return <Option key={key.value} value={key.value}>{key.label}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label='Currency'>
                                <Select value={this.state.currency} onChange={(value) => this.setState({ currency: value })}>
                                    <Option value='USD'>USD</Option>
                                    <Option value='EUR'>EUR</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Modal>
        )
    }
}

export default connect(null, { createCompany })(AddCompanyModal);