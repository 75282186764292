const initialState = {
    showWizard: false,
    currentStep: 1,
    isMinimized: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case 'START_GETTING_STARTED_WIZARD':
            return {
                ...state,
                showWizard: true,
                currentStep: action.payload
            };
        case 'OPEN_GETTING_STARTED_WIZARD':
            return {
                ...state,
                showWizard: true,
                isMinimized: false
            };
        case 'CLOSE_GETTING_STARTED_WIZARD':
            return {
                ...state,
                showWizard: false
            };

        case 'UPDATE_GETTING_STARTED_WIZARD_STEP':
            return {
                ...state,
                currentStep: state.currentStep + 1
            };
        case 'STOP_GETTING_STARTED_WIZARD':
            return {
                ...state,
                currentStep: 1,
                showWizard: false
            };
        case 'MINIMIZE_GETTING_STARTED_WIZARD':
            return {
                ...state,
                showWizard: false,
                isMinimized: true
            };
        default:
            return state;
    }
}