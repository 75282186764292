import Dashboard from "./pages/dashboard";
import Account from "./pages/account/account";
import Billing from "./pages/billing/billing";
import Domains from "./pages/domains/domains";
import DomainGroup from "./pages/domains/domain_group";
import Firewall from "./pages/firewall/firewall";
import IPAddresses from "./pages/ip_addresses/ip_addresses";
import IPAddressGroup from "./pages/ip_addresses/ip_address_group";
import CompanySelector from "./components/account/CompanySelector";
import InvoiceView from "./components/billing/InvoiceView";
import OrderView from "./components/billing/OrderView";
import CreditNoteView from "./components/billing/CreditNoteView";
import HostingAccounts from "./pages/hosting/hosting_accounts";
import ManageCompany from "./pages/account/manage_company";
import HostingDetails from "./pages/hosting/hosting_details";
import FirewallRuleDetail from "./pages/firewall/firewall_rule_detail";
import {RiDashboardLine} from "react-icons/ri";
import {BsGlobe, BsShieldFill} from "react-icons/bs";
import {HiLocationMarker, HiShoppingCart} from "react-icons/hi";
import {FaBook, FaServer} from "react-icons/fa";
import {BiUser} from "react-icons/bi";
import {IoAnalytics, IoBusinessOutline, IoGitNetworkOutline} from "react-icons/io5";
import React from "react";
import {MdSupport} from "react-icons/md";
import Affiliate from "./pages/affiliate/affiliate";
import AffiliateSignup from "./components/affiliate/AffiliateSignup";
import AffiliateInvoice from "./pages/affiliate/affiliate_invoice";
import Analytics from "./pages/analytics/analytics";
import AnalyticsDetails from "./pages/analytics/analytics_details";

export const sidebarMenu = {
    '/': {
        name: 'Dashboard',
        page: Dashboard,
        exact: true,
        icon: <RiDashboardLine style={{marginBottom: '-2px'}} />,
        scopes: []
    },
    '/domains': {
        name: 'Domains',
        page: Domains,
        exact: true,
        icon: <BsGlobe style={{marginBottom: '-2px'}} />,
        scopes: ['can_view_domains']
    },
    '/domains/:group': {
        page: DomainGroup,
        exact: false,
        subpage: true,
        name: 'Domain Group',
        scopes: ['can_view_domains']
    },
    '/ip-addresses': {
        name: 'IP Addresses',
        page: IPAddresses,
        exact: true,
        subpage: false,
        icon: <HiLocationMarker style={{marginBottom: '-2px'}} />,
        scopes: ['can_view_ip_addresses'],
    },
    '/ip-addresses/:group': {
        page: IPAddressGroup,
        exact: false,
        subpage: true,
        name: 'IP Address Group',
        scopes: ['can_view_ip_addresses']
    },
    '/hosting': {
        name: 'Hosting',
        page: HostingAccounts,
        exact: true,
        subpage: false,
        icon: <FaServer style={{marginBottom: '-2px'}} />,
        scopes: ['can_view_hosting']
    },
    '/hosting/:guid': {
        page: HostingDetails,
        exact: false,
        subpage: true,
        scopes: ['can_view_hosting']
    },
    '/analytics': {
        name: 'Analytics',
        page: Analytics,
        exact: true,
        subpage: false,
        icon: <IoAnalytics style={{marginBottom: '-2px'}} />,
        scopes: ['can_view_analytics']
    },
    '/analytics/:guid': {
        page: AnalyticsDetails,
        exact: false,
        subpage: true,
        scopes: ['can_view_analytics']
    },
    '/firewall': {
        name: 'Firewall',
        page: Firewall,
        exact: true,
        subpage: false,
        icon: <BsShieldFill style={{marginBottom: '-2px'}} />,
        scopes: ['can_view_firewall']
    },
    '/firewall/add': {
        name: 'Firewall' ,
        page: FirewallRuleDetail,
        exact: true,
        subpage: true,
        scopes: ['can_view_firewall']
    },
    '/firewall/edit/:guid': {
        name: 'Firewall' ,
        page: FirewallRuleDetail,
        exact: true,
        subpage: true,
        scopes: ['can_view_firewall']
    },
    '/firewall/:page': {
        page: Firewall,
        exact: true,
        subpage: true,
        scopes: ['can_view_firewall']
    },
    '/billing': {
        name: 'Billing' ,
        page: Billing,
        exact: true,
        subpage: false,
        icon: <HiShoppingCart style={{marginBottom: '-2px'}} />,
        scopes: ['can_view_billing']
    },
    '/billing/orders/:id': {
        name: 'Billing' ,
        page: OrderView,
        exact: false,
        subpage: true,
        scopes: ['can_view_billing', 'can_view_orders']
    },
    '/billing/invoices/:id': {
        name: 'Billing' ,
        page: InvoiceView,
        exact: false,
        subpage: true,
        scopes: ['can_view_billing', 'can_view_invoices']
    },
    '/billing/credit_notes/:id': {
        name: 'Billing' ,
        page: CreditNoteView,
        exact: false,
        subpage: true,
        scopes: ['can_view_billing']
    },
    '/billing/:page': {
        name: 'Billing' ,
        page: Billing,
        exact: false,
        subpage: true,
        scopes: ['can_view_billing']
    },
    '/account/select-company/:guid': {
        page: CompanySelector,
        exact: false,
        subpage: true,
        scopes: ['can_switch_companies'],
        allow_customer: false
    },
    '/account/company/:guid/:page': {
        page: ManageCompany,
        exact: false,
        subpage: true,
        scopes: ['can_view_company_info'],
        allow_customer: false
    },
    '/account/company/:guid': {
        page: ManageCompany,
        exact: false,
        subpage: true,
        scopes: ['can_view_company_info'],
        allow_customer: false
    },
    '/account/:page/:key': {
        name: 'Account',
        page: Account,
        exact: false,
        subpage: true,
        show_user: true,
        scopes: [],
        allow_customer: false
    },
    '/account': {
        name: 'Account',
        page: Account,
        exact: true,
        show_user: true,
        scopes: [],
        icon: <BiUser style={{marginBottom: '-2px'}} />,
        allow_customer: false
    },
    '/account/:page': {
        name: 'Account',
        page: Account,
        exact: false,
        subpage: true,
        show_user: true,
        scopes: [],
        allow_customer: false
    },
    '/company': {
        name: 'Company',
        page: ManageCompany,
        exact: true,
        subpage: false,
        scopes: ['can_view_company_info'],
        icon: <IoBusinessOutline style={{marginBottom: '-2px'}} />,
        allow_customer: false
    },
    '/company/:guid/:page': {
        name: 'Company',
        page: ManageCompany,
        exact: false,
        subpage: true,
        scopes: ['can_view_company_info'],
        allow_customer: false
    },
    '/company/:page': {
        name: 'Company',
        page: ManageCompany,
        exact: false,
        subpage: true,
        scopes: ['can_view_company_info'],
        allow_customer: false
    },
    '/company/:guid': {
        name: 'Company',
        page: ManageCompany,
        exact: false,
        subpage: true,
        scopes: ['can_view_company_info'],
        allow_customer: false
    },
    '/affiliate': {
        name: 'Affiliate',
        page: Affiliate,
        exact: true,
        subpage: false,
        scopes: [],
        icon: <IoGitNetworkOutline style={{marginBottom: '-2px'}} />,
        allow_customer: false
    },
    '/affiliate/create': {
        name: 'Affiliate',
        page: AffiliateSignup,
        exact: false,
        subpage: true,
        scopes: [],
        allow_customer: false
    },
    '/affiliate/billing/invoice/:id': {
        page: AffiliateInvoice,
        exact: false,
        subpage: true,
        scopes: [],
        allow_customer: false
    },
    '/affiliate/:page': {
        name: 'Affiliate',
        page: Affiliate,
        exact: false,
        subpage: true,
        scopes: [],
        allow_customer: false
    },
    'https://support.priorityprospect.com/tickets': {
        name: 'Support',
        page: null,
        external: true,
        subpage: false,
        scopes: [],
        icon: <MdSupport style={{marginBottom: '-2px'}} />,
        allow_customer: false,
    },
    'https://support.priorityprospect.com/knowledgebase': {
        name: 'Knowledge Base',
        page: null,
        external: true,
        subpage: false,
        scopes: [],
        icon: <FaBook style={{marginBottom: '-2px'}} />,
        allow_customer: false,
    }
};