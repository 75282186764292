import React from "react";
import {connect} from "react-redux";
import {Alert, Button, Form, Input, message, Modal, Space, Typography} from "antd";
import {generate2FABackupCodes, get2FABackupCodes} from "../../actions/account";
import {displayErrors} from "../../libs/utils";

class User2FABackupCodesModal extends React.Component {
    state = {
        loading: false,
        backupCodesLoaded: false,
        backupCodes: [],
        password: '',
        showPasswordModal: false,
        regenerating: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.showModal && this.props.showModal && !this.state.backupCodesLoaded) {
            this.loadBackupCodes();
        }
    }

    loadBackupCodes() {
        this.setState({ loading: true });

        this.props.get2FABackupCodes((res) => {
            this.setState({ loading: false, backupCodes: res.data.codes });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false })
            }
        });
    }

    closeModal() {
        this.setState({
            loading: false,
            backupCodesLoaded: false,
            backupCodes: [],
            password: '',
            showPasswordModal: false,
            regenerating: false,
        }, () => this.props.closeModal())
    }

    regenerateBackupCodes() {
        let password = this.state.password;

        if(password === '') {
            return message.error('Please enter your password!');
        }

        this.setState({ regenerating: true });

        this.props.generate2FABackupCodes({ password }, () => {
            this.setState({ regenerating: false, showPasswordModal: false, password: '' });
            this.loadBackupCodes();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ regenerating: false })
            }
        });
    }

    render() {

        const { Paragraph } = Typography;

        return <Modal
            title='2FA Backup Codes'
            open={this.props.showModal}
            footer={false}
            onCancel={() => this.closeModal()}>
            <Alert type='warning' showIcon message='Here are your backup codes in case you lose your device. Please keep them safe, ideally printed out!' />
            <Paragraph>
            <pre style={{marginTop: '10px'}}>{this.state.backupCodes.map((value, index) => {
                if((index-1)%2 === 0) {
                    return value + '\n';
                }

                return value + '\t\t';
            })}</pre>
            </Paragraph>

            <Button type='primary' onClick={() => this.setState( { showPasswordModal: true })}>Re-generate</Button>

            <Modal title='Re-generate 2FA Backup Codes'
                   open={this.state.showPasswordModal}
                   footer={<Space>
                       <Button onClick={() => this.closeModal()} loading={this.state.regenerating} disabled={this.state.regenerating}>Close</Button>
                       <Button type='primary' loading={this.state.regenerating} disabled={this.state.regenerating} onClick={() => this.regenerateBackupCodes()}>Generate</Button>
                   </Space>}
                   onCancel={() => this.setState({ showPasswordModal: false, password: '' })}>
                <Form layout='vertical'>
                    <Form.Item label='Please enter your password to confirm.'>
                        <Input type='password' onChange={(e) => this.setState({ password: e.target.value })} />
                    </Form.Item>
                </Form>
            </Modal>
        </Modal>
    }
}

export default connect(null, { get2FABackupCodes, generate2FABackupCodes })(User2FABackupCodesModal);