import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Button, Descriptions, message, Input, Modal, Space} from "antd";
import {getSoftware, getSoftwarePassword} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {StatusIndicator} from "../shared/Status";
import {EyeOutlined} from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";

class SoftwareInfoModal extends React.Component {
    state = {
        loadingSoftwareInfo: false,
        softwareInfoLoaded: false,
        data: null,
        password: null,
        showPasswordModal: false,
        accountPassword: ''
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingSoftwareInfo && !this.state.softwareInfoLoaded) {
            this.setState({ loadingSoftwareInfo: true });

            this.props.getSoftware(this.props.domain, (res) => {
                this.setState({ loadingSoftwareInfo: false, softwareInfoLoaded: true, data: res.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                    this.setState({ loadingSoftwareInfo: false, softwareInfoLoaded: true });
                }
            });
        }
    }

    closeModal() {
        this.setState({
            loadingSoftwareInfo: false,
            softwareInfoLoaded: false,
            data: null,
            password: null,
            showPasswordModal: false,
            accountPassword: ''
        });

        this.props.close();
    }

    closePasswordModal() {
        this.setState({
            showPasswordModal: false,
            accountPassword: ''
        });
    }

    getSoftwarePassword() {
        let password = this.state.accountPassword.trim();

        if(password.length === 0) {
            return message.error('Please enter your account\'s password!');
        }

        let data = { password };

        this.props.getSoftwarePassword(this.props.domain, data, (res) => {
            this.setState({ showPasswordModal: false, accountPassword: '', password: res.data.password });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingSoftwareInfo: false, softwareInfoLoaded: true });
            }
        });
    }

    render() {
        let content;

        if(this.state.loadingSoftwareInfo || this.state.data === null) {
            content = <div className='text-center'><Loading /></div>;
        } else {
            let password;

            if(this.state.data.username === null && this.state.password === null) {
                password = <Paragraph style={{margin:0}}>Password is unavailable!</Paragraph>;
            } else if(this.state.password === null) {
                password = <Button size='small' icon={<EyeOutlined />} onClick={() => this.setState({ showPasswordModal: true })}>Show password</Button>
            } else {
                password = <Paragraph copyable style={{margin:0}}>{this.state.password}</Paragraph>;
            }

            content = <Descriptions bordered size='small' column={1}>
                <Descriptions.Item style={{width: '50%'}} label='Software'>{this.state.data.software_name}</Descriptions.Item>
                <Descriptions.Item label='Created'><DateTimeFormat value={this.state.data.date_created} /></Descriptions.Item>
                <Descriptions.Item label='Status'><StatusIndicator el='badge' status={this.state.data.status} /></Descriptions.Item>
                {this.state.data.is_imported ? <Descriptions.Item label='Imported'>This installation was imported and username and password are not available!</Descriptions.Item> : ''}
                {!this.state.data.is_imported ? <Fragment>
                    <Descriptions.Item label='Username'>{this.state.data.username === null ? 'Username is unavailable!' : <Paragraph copyable style={{margin:0}}>{this.state.data.username}</Paragraph> }</Descriptions.Item>
                    <Descriptions.Item label='Password'>{password}</Descriptions.Item>
                </Fragment> : ''}
            </Descriptions>
        }

        return(
            <Modal
                title='Software Info'
                open={this.props.showModal}
                footer={<Button onClick={() => this.closeModal()}>Close</Button>}
                onCancel={() => this.closeModal()}>
                {content}

                <Modal
                    footer={<Space>
                        <Button onClick={() => this.closePasswordModal()}>Close</Button>
                        <Button onClick={() => this.getSoftwarePassword()} type='primary'>Continue</Button>
                    </Space>}
                    onCancel={() => this.closePasswordModal()}
                    open={this.state.showPasswordModal}>
                    <p>Enter your account password:</p>
                    <Input type='password' value={this.state.accountPassword}
                           onKeyDown={(e) => {if(e.key === 'Enter') { this.getSoftwarePassword(); }}}
                           onChange={(e) => this.setState({ accountPassword: e.target.value })} />
                </Modal>
            </Modal>
        );
    }
}

export default connect(null, { getSoftware, getSoftwarePassword })(SoftwareInfoModal);