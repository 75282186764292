import React from "react";
import {CheckCircleOutlined, ExclamationCircleOutlined, SyncOutlined} from "@ant-design/icons";
import {Badge, Tag} from "antd";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import {normalizeEnum} from "../../libs/utils";

export const StatusIndicator = function(props) {
    let icon = null;
    let color = 'default';
    let el = 'tag';

    if(typeof props.el !== 'undefined') {
        el = props.el;
    }

    let status = props.status;

    if(typeof status === 'boolean') {
        if(status) {
            status = 'ACTIVE';
        } else {
            status = 'INACTIVE';
        }
    }

    const processing = ['PENDING', 'INSTALLING', 'UNINSTALLING', 'ADDING', 'SCHEDULED', 'UPDATING', 'VERIFICATION', 'DELETING', 'INVESTIGATING', 'FIXING', 'CLEARING', 'UPDATING_HOSTING'];
    const success = ['ACTIVE', 'PAID', 'PROCESSED', 'RESOLVED', 'COMPLETED', 'ACCEPTED', 'PAID'];
    const error = ['ERROR', 'SUSPENDED', 'INACTIVE', 'UNPAID', 'DELETED', 'FAILURE', 'DISABLED', 'PENDING_CANCELLATION', 'REVOKED', 'DECLINED', 'REVERSED', 'REFUNDED'];
    const warning = ['PARTIALLY_PAID', 'ON_HOLD'];

    if(processing.indexOf(status) > -1)
    {
        icon = <SyncOutlined spin />;
        color = 'processing';
    }
    else if(success.indexOf(status) > -1)
    {
        icon = <CheckCircleOutlined />;
        color = 'success';
    }
    else if(error.indexOf(status) > -1)
    {
        icon = <CloseCircleOutlined />;
        color = 'error';
    }
    else if(warning.indexOf(status) > -1)
    {
        icon = <ExclamationCircleOutlined />;
        color = 'warning';
    }

    if(el === 'tag') {
        return <Tag color={color} icon={icon}>{normalizeEnum(status)}</Tag>
    }

    if(el === 'badge') {
        return <Badge status={color} text={normalizeEnum(status)} />
    }
};