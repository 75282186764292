import {httpDelete, httpGet, httpGetDownload, httpPost} from "../libs/http";


export const checkAffiliateExists = (onSuccess, onFailure) => dispatch => {
    httpGet('/affiliate/exists/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAffiliateOverview = (onSuccess, onFailure) => dispatch => {
    httpGet('/affiliate/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAffiliateRecentReferralActivity = (onSuccess, onFailure) => dispatch => {
    httpGet('/affiliate/recent-referral-activity/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const submitAffiliateApplication = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/affiliate/create/',  data,(res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAffiliateLinks = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/affiliate/links/?page=' + page + '&per_month=' + per_page,  (res) => onSuccess(res), (err) => onFailure(err));
};

export const createAffiliateLink = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/affiliate/links/',  data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const disableAffiliateLink = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/affiliate/links/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAffiliateBillingInfo = (onSuccess, onFailure) => dispatch => {
    httpGet('/affiliate/billing/',  (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAffiliateBillingInvoices = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/affiliate/billing/invoices/?page=' + page + '&per_page=' + per_page,  (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAffiliateBillingInvoice = (id, onSuccess, onFailure) => dispatch => {
    httpGet('/affiliate/billing/invoices/' + id + '/',  (res) => onSuccess(res), (err) => onFailure(err));
};

export const downloadAffiliateInvoice = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGetDownload('/affiliate/billing/invoices/' + id + '/pdf/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAffiliateReports = (start_date, end_date, onSuccess, onFailure) => dispatch => {
    httpGet('/affiliate/reports/?start=' + start_date + '&end=' + end_date,  (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAffiliatePaymentDetails = (onSuccess, onFailure) => dispatch => {
    httpGet('/affiliate/billing/payment-details/',  (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateAffiliatePaymentDetails = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/affiliate/billing/payment-details/', data,  (res) => onSuccess(res), (err) => onFailure(err));
};