import React, {Fragment} from 'react';
import {Button, Descriptions, Modal, Typography} from "antd";
import {connect} from "react-redux";
import {loadEmail} from "../../actions/account";
import {displayErrors} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {DateTimeFormat} from "../shared/DateTimeFormat";

class EmailViewModal extends React.Component
{
    state = {
        showModal: false,
        emailLoaded: false,
        loadingEmail: false,
        subject: ''
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if(!this.state.emailLoaded && this.state.showModal && !this.state.loadingEmail) {
            this.setState({ loadingEmail: true}, () => {
                this.props.loadEmail(this.props.guid, (res) => {
                    this.setState({...this.state, ...res.data, emailLoaded: true, loadingEmail: false});
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ emailLoaded: true, loadingEmail: false });
                        displayErrors(err.response.data);
                    }
                });
            });
        }
    }

    closeModal() {
        this.setState({ emailLoaded: false, loadingEmail: false });
        this.props.close()
    }

    render() {
        let content;

        if(this.state.loadingEmail) {
            content = <div className='text-center'><Loading /></div>
        } else {
            const { Paragraph } = Typography;

            content = <Fragment>
                <Descriptions bordered size='small' layout='horizontal' column={1}>
                    <Descriptions.Item style={{width: '30%'}} label='To:'>{this.state.name + '<' + this.state.email + '>'}</Descriptions.Item>
                    <Descriptions.Item label='Date sent:'><DateTimeFormat value={this.state.date_sent} /></Descriptions.Item>
                    <Descriptions.Item label='Is sent'>{this.state.is_sent ? 'Yes' : 'Scheduled'}</Descriptions.Item>
                </Descriptions>
                <Paragraph style={{marginTop: '15px'}}>
                    <div dangerouslySetInnerHTML={{__html: this.state.content}} />
                </Paragraph>
            </Fragment>;
        }

        return (
            <Modal
                centered
                title={this.state.subject}
                open={this.props.showModal}
                width={700}
                onCancel={() => this.closeModal()}
                footer={<Button onClick={() => this.closeModal()}>Close</Button>}
                destroyOnClose={true}>
                {content}
            </Modal>
        )
    }
}

export default connect(null, { loadEmail })(EmailViewModal);