import React from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Alert} from "antd";
import {hasPermission} from "./PermissionUtils";

const AccessGate = ({children, scopes, permissions}) => {
    if(!hasPermission(permissions, scopes)) {
        return <Alert type='error' showIcon message='You do not have access to this feature. Contact your account manager for more information!' />;
    }

    return <>{children}</>;
}

const mapStateToProps = state => ({
    permissions: state.auth.user.user.company.permissions,
});

export default withRouter(connect(mapStateToProps, null)(AccessGate));