const initialState = {
    sideMenuCollapsed: localStorage.getItem('sideMenuCollapsed') === 'true',
    darkMode: true
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case 'APP_TOGGLE_SIDE_MENU':
            let sideMenuCollapsed = localStorage.getItem('sideMenuCollapsed');

            if(sideMenuCollapsed === null) {
                sideMenuCollapsed = !state.sideMenuCollapsed;
            } else {
                if(sideMenuCollapsed === 'true') {
                    sideMenuCollapsed = true;
                } else {
                    sideMenuCollapsed = false;
                }

                sideMenuCollapsed = !sideMenuCollapsed;
            }

            localStorage.setItem('sideMenuCollapsed', sideMenuCollapsed ? 'true' : 'false');

            return {
                ...state,
                sideMenuCollapsed
            };
        default:
            return state;
    }
}