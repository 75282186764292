import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, message, Modal, Select} from "antd";
import { createSoftwarePresetGroup} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";

class AddSoftwarePresetGroupModal extends React.Component {
    state = {
        loading: false,
        name: '',
        selectedType: 'PLUGINS',
        typeOptions: [
            { name: 'Plugins', value: 'PLUGINS' },
            { name: 'Themes', value: 'THEMES' },
        ],
        installAction: 'RANDOM',
        installActionOptions: [
            { name: 'Install random', value: 'RANDOM', availableFor: ['PLUGINS', 'THEMES'] },
            { name: 'Install all', value: 'ALL', availableFor: ['PLUGINS'] },
        ]
    };

    closeModal() {
        this.setState({
            loading: false,
            name: '',
            selectedSoftware: null,
        });

        this.props.close();
    }

    createPresetGroup() {
        let name = this.state.name.trim();
        let group_type = this.state.selectedType;
        let install_action = this.state.installAction;

        if(name.length === 0) {
            return message.error('Please enter preset\'s name!');
        }

        if(group_type === null || group_type.length === 0) {
            return message.error('Please choose the group type!');
        }

        if(install_action === null || install_action.length === 0) {
            return message.error('Please choose the installation action!');
        }

        let data = { name, group_type, install_action };

        this.setState({ loading: true });

        this.props.createSoftwarePresetGroup(this.props.activePreset, data, (res) => {
            this.closeModal();
            this.props.reloadPresets();
            this.props.setActivePresetGroup(res.data.guid);
            this.setState({ loading: false});

            message.success('Software preset group successfully created!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false});
                displayErrors(err.response.data);
            }
        });
    }

    selectGroupType(value) {
        this.setState({ selectedType: value });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal
                centered
                title='Add Software Preset Group'
                open={this.props.showModal}
                onCancel={() => this.closeModal()}
                footer={<div>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Cancel</Button>
                    <Button disabled={this.state.loading} loading={this.state.loading} type='primary' onClick={() => this.createPresetGroup()}>Create</Button>
                </div>}
                destroyOnClose={true}>
                <Form layout='vertical'>
                    <Form.Item label='Name:'>
                        <Input autoFocus onChange={(e) => this.setState({ name: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Type:' help={<em><small>Choose what type of items are in this group.</small></em>}>
                        <Select value={this.state.selectedType} onChange={(value) => this.selectGroupType(value)}>
                            {this.state.typeOptions.map((_type) => {
                                return <Option key={_type.name} value={_type.value}>{_type.name}</Option>;
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Action:' help={<em><small>Choose if all items are installed or a random item is installed.</small></em>}>
                        <Select value={this.state.installAction} onChange={(value) => this.setState({ installAction: value })}>
                            {this.state.installActionOptions.filter(action => action.availableFor.indexOf(this.state.selectedType) > -1).map((action) => {
                                return <Option key={action.name} value={action.value}>{action.name}</Option>;
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { createSoftwarePresetGroup })(AddSoftwarePresetGroupModal);