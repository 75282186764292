import React from 'react';
import { connect } from 'react-redux';
import {Button, Form, message, Modal, Select, Space} from "antd";
import {loadHostingAccounts} from "../../actions/hosting";
import {displayErrors} from "../../libs/utils";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {updateHostingAccount} from "../../actions/domains";

class HostingAccountModifyModal extends React.Component  {
    state = {
        updatingHostingAccount: false,
        loadingHostingAccounts: false,
        hostingAccountsLoaded: false,
        selectedHostingAccount: '',
        accounts: []
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingHostingAccounts && !this.state.hostingAccountsLoaded) {
            console.log(this.props.showModal);
            this.props.loadHostingAccounts(1, 9999, (res) => {
                this.setState({ loadingHostingAccounts: false, hostingAccountsLoaded: true, accounts: res.data.data,
                    selectedHostingAccount: this.props.activeHostingAccount });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                    this.setState({ loadingHostingAccounts: false, hostingAccountsLoaded: true })
                }
            });
        }
    }

    closeModal(reload = false) {
        this.setState({
            updatingHostingAccount: false,
            loadingHostingAccounts: false,
            hostingAccountsLoaded: false,
            selectedHostingAccount: '',
            accounts: []
        });

        this.props.close();

        if(reload) {
            this.props.loadOverview();
        }
    }

    updateHostingAccount() {
        const { confirm } = Modal;

        confirm({
            title: 'Attention',
            centered: true,
            okText: 'Change',
            okType: 'danger',
            okButtonProps: {
                loading: this.state.updatingHostingAccount
            },
            icon: <ExclamationCircleOutlined />,
            content: <span>Changing the hosting account will forward your domain's traffic to the selected hosting account, but it <strong style={{color:'red'}}><u>will not transfer your files, folders or databases associated with that domain.</u></strong> If you have not set up your domain to the new hosting account then your website will not work!</span>,
            onOk: () => {
                this.setState({ updatingHostingAccount: true });

                let data = {
                    hosting_account: this.state.selectedHostingAccount
                };

                this.props.updateHostingAccount(this.props.domain, data, () => {
                    this.closeModal(true);
                    this.setState({ updatingHostingAccount: false });
                    message.success('Hosting account successfully changed!');
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                        this.setState({ updatingHostingAccount: false });
                    }
                });
            }
        })
    }

    render() {
        const { Option } = Select;

        return (
            <Modal
                centered
                title='Modify Hosting Account'
                open={this.props.showModal}
                okText='Modify'
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button type='primary' onClick={() => this.updateHostingAccount()}>Update</Button>
                </Space>}
                destroyOnClose={true}>

                <Form layout='vertical'>
                    <Form.Item label='Select hosting account:'>
                        <Select
                            value={this.state.selectedHostingAccount}
                            loading={this.state.loadingHostingAccounts}
                            disabled={this.state.loadingHostingAccounts}
                            showSearch
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            onChange={(value) => this.setState({ selectedHostingAccount: value})}>
                            {this.state.accounts.map((account, i) => {
                                return <Option key={i} value={account.guid}>{account.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { loadHostingAccounts, updateHostingAccount })(HostingAccountModifyModal);