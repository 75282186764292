import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Button, Card, Col, Descriptions, Input, Popover, Row, Switch, message} from "antd";
import {SketchPicker} from "react-color";
import {updatePage} from "../../actions/page";
import {Loading} from "../../libs/loading";
import {loadPartnerDetails, updatePartnerDetails} from "../../actions/account";
import {displayErrors} from "../../libs/utils";

class PartnerDetails extends React.Component {
    state = {
        loading: false,

        custom_domain: '',
        custom_logo: '',

        show_support_info: false,
        support_name: '',
        support_email: '',
        support_phone: '',
        support_url: '',

        use_custom_styles: false,
        primary_color: '',
        header_background_color: '',
        primary_button_color: '',
        primary_button_hover_color: '',
        primary_button_text_color: '',
        primary_button_hover_text_color: '',

        use_custom_styles_updated: false,

        updating: false,
    };

    componentDidMount() {
        this.props.updatePage('Partner Details');

        this.props.loadPartnerDetails((res) => {
            this.setState({ loading: false, custom_domain: res.data.custom_domain, custom_logo: res.data.custom_logo, ...res.data.support, ...res.data.custom_styles });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    save() {
        let data = {
            custom_domain: this.state.custom_domain,
            custom_logo: this.state.custom_logo,
            show_support_info: this.state.show_support_info,
            support_name: this.state.support_name,
            support_email: this.state.support_email,
            support_phone: this.state.support_phone,
            support_url: this.state.support_url,
            use_custom_styles: this.state.use_custom_styles,
            primary_color: this.state.primary_color,
            header_background_color: this.state.header_background_color,
            primary_button_color: this.state.primary_button_color,
            primary_button_hover_color: this.state.primary_button_hover_color,
            primary_button_text_color: this.state.primary_button_text_color,
            primary_button_hover_text_color: this.state.primary_button_hover_text_color,
        };

        this.setState({ updating: true });

        this.props.updatePartnerDetails(data, () => {
            message.success('Details successfully updated!');
            this.setState({ updating: false });

            if(this.state.use_custom_styles_updated) {
                setTimeout(() => window.location.reload(), 500);
            }
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ updating: false });
            }
        });
    }

    render() {
        if(this.state.loading) {
            return <div className='text-center'><Loading /></div>;
        }

        const labelWidth = '300px';

        const showColorEditor = false;
        let colorEditor;

        if(showColorEditor) {
            colorEditor = <Col sm={12} xs={24}>
                <Card title='Custom colors' size='small'>
                    <Descriptions bordered size='small' layout='horizontal' column={1}>
                        <Descriptions.Item label='Use custom colors' labelStyle={{width: labelWidth}}>
                            <Switch size='small' checked={this.state.use_custom_styles} onChange={value => this.setState({ use_custom_styles: value, use_custom_styles_updated: true })} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Primary color'>
                            <Popover
                                content={<SketchPicker disableAlpha={true} color={ this.state.primary_color } onChange={value => this.setState({ primary_color: value.hex })} />}
                                trigger="click">
                                <div className='color-picker-choice' style={{backgroundColor: this.state.primary_color}} />
                            </Popover>
                        </Descriptions.Item>
                        <Descriptions.Item label='Header color'>
                            <Popover
                                content={<SketchPicker disableAlpha={true} color={ this.state.header_background_color } onChange={value => this.setState({ header_background_color: value.hex })} />}
                                trigger="click">
                                <div className='color-picker-choice' style={{backgroundColor: this.state.header_background_color}} />
                            </Popover>
                        </Descriptions.Item>
                        <Descriptions.Item label='Primary button color'>
                            <Popover
                                content={<SketchPicker disableAlpha={true} color={ this.state.primary_button_color } onChange={value => this.setState({ primary_button_color: value.hex })} />}
                                trigger="click">
                                <div className='color-picker-choice' style={{backgroundColor: this.state.primary_button_color}} />
                            </Popover>
                        </Descriptions.Item>
                        <Descriptions.Item label='Primary button hover color'>
                            <Popover
                                content={<SketchPicker disableAlpha={true} color={ this.state.primary_button_hover_color } onChange={value => this.setState({ primary_button_hover_color: value.hex })} />}
                                trigger="click">
                                <div className='color-picker-choice' style={{backgroundColor: this.state.primary_button_hover_color}} />
                            </Popover>
                        </Descriptions.Item>
                        <Descriptions.Item label='Primary button text color'>
                            <Popover
                                content={<SketchPicker disableAlpha={true} color={ this.state.primary_button_text_color } onChange={value => this.setState({ primary_button_text_color: value.hex })} />}
                                trigger="click">
                                <div className='color-picker-choice' style={{backgroundColor: this.state.primary_button_text_color}} />
                            </Popover>
                        </Descriptions.Item>
                        <Descriptions.Item label='Primary button hover text color'>
                            <Popover
                                content={<SketchPicker disableAlpha={true} color={ this.state.primary_button_hover_text_color } onChange={value => this.setState({ primary_button_hover_text_color: value.hex })} />}
                                trigger="click">
                                <div className='color-picker-choice' style={{backgroundColor: this.state.primary_button_hover_text_color}} />
                            </Popover>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </Col>;
        }

        return(
            <Fragment>
                <Row gutter={[16, 16]}>
                    <Col sm={12} xs={24}>
                        <Card title='White label' size='small'>
                            <Descriptions bordered size='small' layout='horizontal' column={1}>
                                <Descriptions.Item label='Custom domain' labelStyle={{width: labelWidth}}>
                                    <Input value={this.state.custom_domain} disabled={this.state.updating} onChange={(e) => this.setState({ custom_domain: e.target.value })} />
                                </Descriptions.Item>
                                <Descriptions.Item label='Custom logo'>
                                    <Input value={this.state.custom_logo} disabled={this.state.updating} onChange={(e) => this.setState({ custom_logo: e.target.value })} />
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        <br />
                        <Card title='Support' size='small'>
                            <Descriptions bordered size='small' layout='horizontal' column={1}>
                                <Descriptions.Item label='Show support info' labelStyle={{width: labelWidth}}>
                                    <Switch size='small' checked={this.state.show_support_info} onChange={value => this.setState({ show_support_info: value })} />
                                </Descriptions.Item>
                                <Descriptions.Item label='Name'>
                                    <Input value={this.state.support_name} disabled={this.state.updating} onChange={(e) => this.setState({ support_name: e.target.value })} />
                                </Descriptions.Item>
                                <Descriptions.Item label='Email'>
                                    <Input value={this.state.support_email} disabled={this.state.updating} onChange={(e) => this.setState({ support_email: e.target.value })} />
                                </Descriptions.Item>
                                <Descriptions.Item label='Phone'>
                                    <Input value={this.state.support_phone} disabled={this.state.updating} onChange={(e) => this.setState({ support_phone: e.target.value })} />
                                </Descriptions.Item>
                                <Descriptions.Item label='URL'>
                                    <Input value={this.state.support_url} disabled={this.state.updating} onChange={(e) => this.setState({ support_url: e.target.value })} />
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                    {colorEditor}
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        <Button type='primary' loading={this.state.updating} onClick={() => this.save()}>Save Details</Button>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, loadPartnerDetails, updatePartnerDetails })(PartnerDetails);