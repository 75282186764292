import React from 'react';
import { connect } from 'react-redux';
import {Button, Form, message, Modal, Select, Space} from "antd";
import {loadAllIPSignatures, updateIPAddressServerSignature} from "../../actions/ip_addresses";
import AccessItem from "../permissions/AccessItem";

class ModifyIPAddressSignatureModal extends React.Component  {

    state = {
        loadingSignatures: false,
        signaturesLoaded: false,
        selectedIPSignature: this.props.selectedIPAddressSignature,
        signatures: [],
        saving: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.show && !this.state.loadingSignatures && !this.state.signaturesLoaded) {
            this.setState({ loadingSignatures: true });

            this.props.loadAllIPSignatures((response) => {
                this.setState({
                    signatures: response.data.data,
                    loadingSignatures: false,
                    signaturesLoaded: true
                });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingSignatures: false, signaturesLoaded: true });
                    message.error(err.response.error);
                }
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal(reload = false) {
        this.setState({ loadingSignatures: false, signaturesLoaded: false, signatures: [] });
        this.props.close(reload);
    }

    updateIpAddressSignature() {
        let signature = this.state.selectedIPSignature.trim();
        let ip_address = this.props.activeIPAddress.trim();

        if(signature.length === 0) {
            return message.error('Please select the signature!');
        }

        if(ip_address.length === 0) {
            return message.error('Please pick the IP address!');
        }

        let data = { signature };

        this.setState({ saving: true });

        this.props.updateIPAddressServerSignature(ip_address, data, () => {
            message.success('IP address signature successfully updated!');
            this.setState({ saving: false });
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ saving: false });
                message.error(err.response.error);
            }
        });
    }

    render() {
        const { Option } = Select;

        return (
            <Modal
                centered
                title='Modify IP Address Signature'
                open={this.props.show}
                footer={<Space>
                    <Button disabled={this.state.saving} onClick={() => this.closeModal()}>Close</Button>
                    <AccessItem scopes={['can_edit_ip_addresses']}>
                        <Button disabled={this.state.saving} loading={this.state.saving} type='primary' onClick={() => this.updateIpAddressSignature()}>Update</Button>
                    </AccessItem>
                </Space>}
                onCancel={() => this.closeModal()}
                destroyOnClose={true}>

                <Form layout='vertical'>
                    <Form.Item label='Select IP signature'>
                        <Select
                            style={{width: '100%'}}
                            value={this.state.selectedIPSignature}
                            defaultValue={this.props.selectedIPAddressSignature}
                            loading={this.state.loadingSignatures}
                            disabled={this.state.loadingSignatures}
                            showSearch
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            onChange={(value) => this.setState({ selectedIPSignature: value})}>
                            {this.state.signatures.map(signature => {
                                return <Option key={signature.guid} value={signature.guid}>{signature.signature}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Form>

            </Modal>
        )
    }
}

export default connect(null, { loadAllIPSignatures, updateIPAddressServerSignature })(ModifyIPAddressSignatureModal);