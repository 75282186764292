import React from 'react';
import {Button, Form, Input, message, Modal, Space} from "antd";
import {displayErrors} from "../../libs/utils";
import {addVat} from "../../actions/account";
import {connect} from "react-redux";

class AddVatModal extends React.Component
{
    state = {
        addingVat: false,
        vatId: ''
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    addVat() {
        let vat_id = this.state.vatId.trim();

        let data = { vat_id };

        this.setState({ addingVat: true });

        this.props.addVat(this.props.companyGuid, data, () => {
            this.setState({ addingVat: false });
            message.success('VAT id successfully added!');

            this.closeModal();
            this.props.reloadTable();

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ addingVat: false });
                displayErrors(err.response.data);
            }
        });
    }

    closeModal() {
        this.props.close();
    }

    render() {
        return (
            <Modal
                centered
                title='Add VAT ID'
                open={this.state.showModal}
                destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={() => this.closeModal()}>Close</Button>
                        <Button type='primary' onClick={() => this.addVat()} loading={this.state.addingVat} disabled={this.state.addingVat}>Add</Button>
                    </Space>
                }
                onCancel={() => this.closeModal()}>
                <p>Enter the VAT id in format: EE123456789</p>
                <Form layout='vertical'>
                    <Form.Item>
                        <Input value={this.state.vatId} onChange={(e) => this.setState({ vatId: e.target.value })}/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { addVat })(AddVatModal);