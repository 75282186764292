import React from 'react';
import { connect } from 'react-redux';
import {Button, Form, message, Modal, Select, Space} from "antd";
import {loadIPAddressGroups, moveIPAddresses} from "../../actions/ip_addresses";
import AccessItem from "../permissions/AccessItem";

class MoveIPAddressModal extends React.Component  {

    state = {
        loadingGroups: false,
        groupsLoaded: false,
        selectedGroup: '',
        groups: [],
        saving: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.show && !this.state.loadingGroups && !this.state.groupsLoaded) {
            this.setState({ loadingGroups: true });

            this.props.loadIPAddressGroups(1, 9999, null, (response) => {
                let groups = response.data.data.filter(item => item.guid !== this.props.activeIPGroup);

                this.setState({
                    groups: groups,
                    loadingGroups: false,
                    groupsLoaded: true
                });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingGroups: false, groupsLoaded: true });
                    message.error(err.response.error);
                }
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal(moved = false, reload = false) {
        this.setState({ loadingGroups: false, groupsLoaded: false, groups: [] });
        this.props.close(moved, reload);
    }

    moveIPAddresses() {
        let group = this.state.selectedGroup.trim();

        if(group.length === 0) {
            return message.error('Please select the signature!');
        }

        let data = { action: 'MOVE', ip_addresses: this.props.selectedIPAddresses, destination_group: group };

        this.setState({ saving: true });

        this.props.moveIPAddresses(data, () => {
            message.success('IP addresses successfully moved!');
            this.setState({ saving: false });
            this.closeModal( true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ saving: false });
                message.error(err.response.error);
            }
        });
    }

    render() {
        const { Option } = Select;

        return (
            <Modal
                centered
                title='Move IP Addresses'
                open={this.props.show}
                footer={<Space>
                    <Button disabled={this.state.saving} onClick={() => this.closeModal()}>Close</Button>
                    <AccessItem scopes={['can_edit_ip_addresses']}>
                        <Button disabled={this.state.saving} loading={this.state.saving} type='primary' onClick={() => this.moveIPAddresses()}>Move</Button>
                    </AccessItem>
                </Space>}
                onCancel={() => this.closeModal()}
                destroyOnClose={true}>

                <Form layout='vertical'>
                    <Form.Item label='Destination group'>
                        <Select
                            style={{width: '100%'}}
                            value={this.state.selectedGroup}
                            loading={this.state.loadingGroups}
                            disabled={this.state.loadingGroups}
                            showSearch
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            onChange={(value) => this.setState({ selectedGroup: value})}>
                            {this.state.groups.map(group => {
                                return <Option key={group.guid} value={group.guid}>{group.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Form>

            </Modal>
        )
    }
}

export default connect(null, { loadIPAddressGroups, moveIPAddresses })(MoveIPAddressModal);