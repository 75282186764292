import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Link} from "react-router-dom";
import {DEFAULT_PER_PAGE} from "../../config";
import {Card, Col, ConfigProvider, Row, Select, Table, Tooltip} from "antd";
import Button from "antd/es/button";
import {displayErrors} from "../../libs/utils";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {FolderOpenOutlined} from "@ant-design/icons";
import AccessGate from "../../components/permissions/AccessGate";
import {loadSearchableDomains} from "../../actions/domains";

class Analytics extends React.Component {
    state = {
        domains: [],
        domainsLoaded: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        filters: null,
        searchQuery: [],
        searching: false,
    };

    componentDidMount() {
        this.props.updatePage('Analytics');
        this.loadDomains();
    }

    loadDomains(page = null, per_page = null, filters = null) {
        window.scrollTo(0, 0);

        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        } else {
            if(per_page !== this.state.pagination.pageSize) {
                page = 1;
            }
        }

        if(filters === null) {
            filters = this.state.filters;
        }

        if(this.state.searchQuery.length > 0) {
            page = 1;
            this.setState({ searching: true });
        }

        this.setState({ domainsLoaded: false });

        this.props.loadSearchableDomains(page, per_page, this.state.searchQuery, filters, (response) => {
            this.setState({ domainsLoaded: true, searching: false, domains: response.data.data,
                pagination: { current: response.data.page, pageSize: response.data.per_page, total: response.data.total, showSizeChanger: true } });

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ domainsLoaded: true, searching: false })
            }
        });
    }

    handleTableChange = (pagination, filters) => {
        this.setState({ filters });
        this.loadDomains(pagination.current, pagination.pageSize, filters);
    };

    clearSearchAndLoadDomains() {
        this.setState({ searchQuery: []}, () => {
            this.loadDomains();
        });
    }

    updateSearchQuery(value) {
        if(value.length === 0) {
            return this.setState({ searchQuery: [] }, () => this.loadDomains());
        }

        let query = [];

        for(let i = 0; i < value.length; i++) {
            let item = value[i].trim();

            if(item !== '') {
                query.push(item)
            }
        }

        this.setState({ searchQuery: query });

        if(query.length === 0) {
            this.loadDomains();
        }
    }

    runSearch() {
        if(this.state.searchQuery.length > 0) {
            this.loadDomains();
        }
    }

    render() {
        const columns = [
            { title: 'Domain', render: (item, record) => <strong><Link to={'/analytics/' + record.guid}>{record.domain}</Link></strong>},
        ];

        return(
            <AccessGate scopes={['can_view_analytics']}>
                <Card size='small'>
                    <Row justify='space-between' style={{paddingBottom: '10px'}}>
                        <Col>
                            <Select
                                value={this.state.searchQuery}
                                maxTagCount={50}
                                placeholder='Enter domain'
                                open={false}
                                maxTagTextLength={10}
                                onClear={() => this.clearSearchAndLoadDomains()}
                                onInputKeyDown={(e) => { if(e.key === 'Enter') { this.runSearch() }}}
                                allowClear loading={this.state.searching}
                                mode="tags" style={{ width: '250px' }}
                                onChange={(value) => this.updateSearchQuery(value)} tokenSeparators={[',', ' ']} />
                        </Col>
                        <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadDomains()}/></Tooltip></Col>
                    </Row>

                    <ConfigProvider renderEmpty={() => <div style={{ textAlign: 'center' }}>
                        <FolderOpenOutlined style={{ fontSize: 40 }} />
                        <p>Please add a domain first!</p>
                    </div>}>
                        <Table
                            dataSource={this.state.domains}
                            columns={columns}
                            rowKey={(item) => item.guid}
                            pagination={this.state.pagination}
                            onChange={this.handleTableChange}
                            loading={!this.state.domainsLoaded} />
                    </ConfigProvider>
                </Card>
            </AccessGate>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadSearchableDomains })(Analytics);