import React, {Fragment} from "react";
import {connect} from "react-redux";
import {DEFAULT_FLAG_SIZE, DEFAULT_PER_PAGE} from "../../config";
import {Badge, Button, Col, Row, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {updatePage} from "../../actions/page";
import {loadLogins} from "../../actions/account";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import Flag from "react-world-flags";
import {DateTimeFormat} from "../shared/DateTimeFormat";

class Logins extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        logins: [],
        loadingLogins: true
    };

    componentDidMount() {
        this.props.updatePage('Logins');
        this.loadLogins();
    }

    loadLogins(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingLogins: true });

        this.props.loadLogins(page, per_page, null, (res) => {
            this.setState({ logins: res.data.data, loadingLogins: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingLogins: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(data) {
        this.loadLogins(data.current, data.pageSize);
    }

    render() {
        const columns = [
            { title: 'Timestamp', dataIndex: 'timestamp', render: (item, row) => <DateTimeFormat value={row.timestamp} />},
            { title: 'IP Address', dataIndex: 'ip_address' },
            { title: 'Useragent', dataIndex: 'user_agent' },
            { title: 'Location', render: (item, row) => row.country !== null ? <span><Flag height={DEFAULT_FLAG_SIZE} code={row.country.code.toLocaleString()} /> {row.city + ', ' + row.country.name}</span> : 'Unknown' },
            { title: 'Status', dataIndex: 'status', render: (item, row) => {
                if(item === 'SUCCESS') {
                    return <Badge status='success' text='Success' />;
                } else if (item === 'FAILURE') {
                    return <Badge status='error' text='Failure' />;
                }

                return normalizeEnum(item);
            }}
        ];

        return(
            <Fragment>
                <Row justify='end'>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadLogins()}/></Tooltip></Col>
                </Row>

                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.logins}
                    loading={this.state.loadingLogins}
                    pagination={this.state.pagination}
                    onChange={(e) => this.changePage(e)}
                />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, loadLogins })(Logins);