import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    deleteDomain,
    getExpiringDomainsInGroup,
    loadDomainGroup,
    loadDomains,
    loadGroupTasks,
    loginTocPanel,
    loginToSoftware
} from "../../actions/domains";
import {DEFAULT_DOMAIN_OVERVIEW_DRAWER_WIDTH, DEFAULT_PER_PAGE} from "../../config";
import {
    Space,
    Select,
    Button,
    Drawer,
    Table,
    Tabs,
    Tooltip,
    Typography,
    message,
    Tag,
    Popover,
    Descriptions,
    ConfigProvider, Alert, Row, Col, Popconfirm, Card
} from "antd";
import {Loading} from "../../libs/loading";
import SafetyCertificateOutlined from "@ant-design/icons/lib/icons/SafetyCertificateOutlined";
import DomainOverview from "../../components/domain/DomainOverview";
import DNSZone from "../../components/domain/DNSZone";
import SubdomainsOverview from "../../components/domain/SubdomainsOverview";
import EmailAddressOverview from "../../components/domain/EmailAddressOverview";
import Flag from 'react-world-flags'
import ExportOutlined from "@ant-design/icons/lib/icons/ExportOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {isMobile} from 'react-device-detect';
import MiscDomainActions from "../../components/domain/MiscDomainActions";
import ExportModal from "../../components/domain/ExportModal";
import DomainRedirects from "../../components/domain/DomainRedirects";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import GroupTasksTable from "../../components/domain/GroupTasksTable";
import {
    BarsOutlined,
    ClockCircleOutlined, DeleteOutlined,
    ExclamationCircleOutlined, PlusOutlined,
    FolderOpenOutlined, LoginOutlined,
    MinusCircleOutlined, QuestionCircleOutlined,
    SettingOutlined, WarningOutlined, CloseCircleOutlined
} from "@ant-design/icons";
import GroupSettingsModal from "../../components/domain/GroupSettingsModal";
import AddDomainsForm from "../../components/domain/AddDomainsForm";
import DomainBulkUpdateModal from "../../components/domain/DomainBulkActionsModal";
import {StatusIndicator} from "../../components/shared/Status";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";
import SoftwarePresets from "../../components/domain/SoftwarePresets";
import AccessItem from "../../components/permissions/AccessItem";
import {hasPermission} from "../../components/permissions/PermissionUtils";
import AccessGate from "../../components/permissions/AccessGate";
import {MdEditNote} from "react-icons/md";
import DomainNotes from "../../components/domain/DomainNotes";

class DomainGroup extends React.Component {
    state = {
        domains: [],
        domainsLoaded: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        filters: null,
        showActions: false,
        activeDomain: '',
        activeDomainName: '',
        selectedDomains: [],
        showDomainAddModal: false,
        domainOverviewDrawerWidth: isMobile ? window.innerWidth : DEFAULT_DOMAIN_OVERVIEW_DRAWER_WIDTH,
        showExportModal: false,
        searchQuery: [],
        searching: false,
        activeTab: 'domains',
        tasksSocket: null,
        isSubdomain: false,
        hostingType: 'SHARED_HOSTING',
        showSettingsModal: false,
        showBulkActionsModal: false,
        showExpirationWarning: false,
        domainOverViewActiveTab: 'domain'
    };

    componentDidMount() {
        this.loadDomainGroupData();
    }

    loadDomains(page = null, per_page = null, filters = null) {
        window.scrollTo(0, 0);

        // Todo: Load domains again when domain group id changes

        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        } else {
            if(per_page !== this.state.pagination.pageSize) {
                page = 1;
            }
        }

        if(filters === null) {
            filters = this.state.filters;
        }

        if(this.state.searchQuery.length > 0) {
            page = 1;
            this.setState({ searching: true });
        }

        this.setState({ domainsLoaded: false });

        this.props.loadDomains(this.props.match.params.group, page, per_page, this.state.searchQuery, filters, (response) => {
            this.setState({ domainsLoaded: true, searching: false, domains: response.data.data,
                pagination: { current: response.data.page, pageSize: response.data.per_page, total: response.data.total, showSizeChanger: true } });

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ domainsLoaded: true, searching: false })
            }
        });
    }

    loadDomainGroupData(page = null, per_page = null) {
        this.setState({ domainsLoaded: false });

        this.props.loadDomainGroup(this.props.match.params.group, (res) => {
            this.props.updatePage(res.data.name);
            this.loadDomains(page, per_page);

            this.setState({ defaultHostingAccount: res.data.default_hosting_account })

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ domainsLoaded: true })
            }
        });

        this.props.getExpiringDomainsInGroup(this.props.match.params.group, (res) => {
            this.setState({ showExpirationWarning: res.data.expiring_domains > 0 })
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ showExpirationWarning: false })
            }
        });
    }

    handleTableChange = (pagination, filters) => {
        this.setState({ filters });
        this.loadDomains(pagination.current, pagination.pageSize, filters);
    };

    tabChange = (key) => {
        if(isMobile) {
            this.setState({ domainOverviewDrawerWidth: window.innerWidth, domainOverViewActiveTab: key });
        } else {
            if(key === 'dns_zone' || key === 'email_addresses' || key === 'redirects' || key === 'subdomains') {
                this.setState({ domainOverviewDrawerWidth: '1000px', domainOverViewActiveTab: key });
            } else {
                this.setState({ domainOverviewDrawerWidth: DEFAULT_DOMAIN_OVERVIEW_DRAWER_WIDTH, domainOverViewActiveTab: key });
            }
        }
    };

    updateSearchQuery(value) {
        if(value.length === 0) {
            return this.setState({ searchQuery: [] }, () => this.loadDomains());
        }

        let query = [];

        for(let i = 0; i < value.length; i++) {
            let item = value[i].trim();

            if(item !== '') {
                query.push(item)
            }
        }

        this.setState({ searchQuery: query });

        if(query.length === 0) {
            this.loadDomains();
        }
    }

    clearSearchAndLoadDomains() {
        this.setState({ searchQuery: []}, () => {
            this.loadDomains();
        });
    }

    loginToCpanel(guid) {
        message.info('Creating cPanel login URL...');

        this.props.loginTocPanel(guid, (res) => {
            window.open(res.data.redirect_url);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    loginToSoftware(guid) {
        message.info('Creating software login URL...');

        this.props.loginToSoftware(guid, (res) => {
            window.open(res.data.redirect_url);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    onTabChange(tab) {
        this.setState({ activeTab: tab });
    }

    switchToTab(tab) {
        this.setState({ activeTab: tab });
    }

    runSearch() {
        if(this.state.searchQuery.length > 0) {
            this.loadDomains();
        }
    }

    closeOverview(onClose = null) {
        this.setState({
            domainOverViewActiveTab: 'domain',
            showActions: false,
            activeDomain: '',
            domainOverviewDrawerWidth: DEFAULT_DOMAIN_OVERVIEW_DRAWER_WIDTH
        }, () => {
            if(onClose !== null) {
                onClose();
            }
        });
    }

    deleteDomain(guid) {
        this.props.deleteDomain(guid, () => {
            this.loadDomains();
            message.success('Domain deletion successfully scheduled!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'IP Address', dataIndex: 'ip_address', responsive: ['md'], width: '180px', key: 'ip_address', render: (item, record) => <Tooltip title={record.ip_address.country + ', ' + record.ip_address.city}><Flag height='13' code={record.ip_address.country_code} style={{marginTop: '-2px'}} /> {record.ip_address.ip_address}</Tooltip>},
            { title: 'Domain', dataIndex: 'domain', key: 'domain', render: (item, record) => {
                let expirationWarning = '';

                if(record.whois !== null && record.whois.days_until_expiration !== null) {
                    if(record.whois.days_until_expiration < 0) {
                        expirationWarning = <Tooltip title='This domain is expired!'><Tag icon={<ExclamationCircleOutlined />} color='error'>Expired</Tag></Tooltip>;
                    } else if(record.whois.days_until_expiration <= 7) {
                        expirationWarning = <Tooltip title='This domain expires in less than 7 days!'><Tag icon={<ExclamationCircleOutlined />} color='warning'>Expiring</Tag></Tooltip>;
                    } else if(record.whois.days_until_expiration <= 30) {
                        expirationWarning = <Tooltip title='This domain expires in less than 30 days!'><Tag icon={<ExclamationCircleOutlined />} color='warning'>Expiring</Tag></Tooltip>;
                    }
                }

                return <Row justify='space-between'>
                    <Col>
                        <a target='_blank' rel='noopener noreferrer' href={(record.has_ssl_certificate ? 'https://' : 'http://') + record.domain}>{record.domain}</a> {expirationWarning}
                    </Col>
                    <Col>
                        {record.has_notes ? <Tooltip title='Click to view domain notes!'>
                            <MdEditNote className='domain-notes' size='17' onClick={() => this.setState({ showActions: true, domainOverViewActiveTab: 'notes', activeDomain: record.guid })} />
                        </Tooltip> : ''}
                    </Col>
                </Row>
            }},
            { title: 'Health', dataIndex: 'health', responsive: ['md'], align: 'center', render: (item, record) => {

                if(typeof record.google_safe_browsing !== 'undefined' && record.google_safe_browsing !== null && record.google_safe_browsing.status !== null) {
                    return <Tooltip title={'Google Safe Browsing marked your domain unsafe!'}>{record.google_safe_browsing.status.map((status, i) => <Tag key={i} icon={<WarningOutlined />} color="error">{normalizeEnum(status)}</Tag>)}</Tooltip>;
                }

                if(record.is_subdomain) {
                    return <Tooltip title={"Subdomains are excluded from health checks!"}><Tag icon={<MinusCircleOutlined />} color="default">Subdomain</Tag></Tooltip>;
                }

                if(item === null || item.date_health_checked === null) {
                    return <Tooltip title={'Your domain\'s health has not been checked, yet!'}><Tag icon={<ClockCircleOutlined />} color="default">Pending check</Tag></Tooltip>;
                }

                if(item.is_nxdomain) {
                    return <Tooltip title={"Your domain's nameservers don't respond. Contact your DNS host/registrar."}><Tag icon={<CloseCircleOutlined />} color="error">NXDOMAIN</Tag></Tooltip>;
                }

                    if(item.is_expired) {
                        return <Tooltip title={"Your domain is expired!"}><Tag icon={<CloseCircleOutlined />} color="error">Expired</Tag></Tooltip>;
                    }

                    if(record.ip_address.ip_address !== item.resolved_ip_address || record.ip_address.ip_address !== item.resolved_www_ip_address) {
                        let resolvedIPAddressMessage = item.resolved_ip_address;

                        if(!item.resolved_ip_address || item.resolved_ip_address !== record.ip_address.ip_address) {
                            if(!item.resolved_ip_address) {
                                resolvedIPAddressMessage = <Row>
                                    <Col style={{width: '20px'}}><WarningOutlined style={{color: 'red'}} /></Col>
                                    <Col flex="auto">Missing!</Col>
                                    <Col><Tooltip title='Could not resolve your domain. Make sure your nameservers are set up correctly!'><QuestionCircleOutlined /></Tooltip></Col>
                                </Row>;
                            } else if(item.resolved_ip_address !== record.ip_address.ip_address) {
                                resolvedIPAddressMessage = <Row>
                                    <Col style={{width: '20px'}}><WarningOutlined style={{color: 'red'}} /></Col>
                                    <Col flex="auto">{item.resolved_ip_address}</Col>
                                    <Col><Tooltip title={'Your domain "' + record.domain + '" resolves to a wrong IP address. It must resolve to ' + record.ip_address.ip_address + '! Check your DNS zone records.'}><QuestionCircleOutlined /></Tooltip></Col>
                                </Row>;
                            }
                        }

                        let resolvedWWWIPAddressMessage = item.resolved_www_ip_address;

                        if(!item.resolved_www_ip_address || item.resolved_www_ip_address !== record.ip_address.ip_address) {
                            if(!item.resolved_www_ip_address) {
                                resolvedWWWIPAddressMessage = <Row>
                                    <Col style={{width: '20px'}}><WarningOutlined style={{color: 'red'}} /></Col>
                                    <Col flex="auto">Missing!</Col>
                                    <Col><Tooltip title='Could not resolve your domain. Make sure your nameservers are set up correctly!'><QuestionCircleOutlined /></Tooltip></Col>
                                </Row>;
                            } else if(item.resolved_www_ip_address !== record.ip_address.ip_address) {
                                resolvedWWWIPAddressMessage = <Row>
                                    <Col style={{width: '20px'}}><WarningOutlined style={{color: 'red'}} /></Col>
                                    <Col flex="auto">{item.resolved_www_ip_address}</Col>
                                    <Col><Tooltip title={'Your domain "www.' + record.domain + '" resolves to a wrong IP address. It must resolve to ' + record.ip_address.ip_address + '! Check your DNS zone records.'}><QuestionCircleOutlined /></Tooltip></Col>
                                </Row>;
                            }
                        }

                        let content = <Descriptions bordered size='small' column={1} style={{width: '500px'}}>
                            <Descriptions.Item style={{width: '50%'}} label='Assigned IP address'>{record.ip_address.ip_address}</Descriptions.Item>
                            <Descriptions.Item label='Resolved IP address'>{resolvedIPAddressMessage}</Descriptions.Item>
                            <Descriptions.Item label='Resolved IP address (www)'>{resolvedWWWIPAddressMessage}</Descriptions.Item>
                            <Descriptions.Item label='Checked'><DateTimeFormat value={item.date_health_checked} /></Descriptions.Item>
                        </Descriptions>;

                        return <Popover content={content} title='Domain Health' trigger="click">
                            <Tooltip title='Click for more information!'><Tag icon={<ExclamationCircleOutlined />} color="warning" style={{cursor: 'pointer'}}>Attention</Tag></Tooltip>
                        </Popover>;
                    }

                    return <Tooltip title='Everything is fine!'><Tag color='success'>Healthy</Tag></Tooltip>
            }},
            { title: 'Status', dataIndex: 'status', responsive: ['md'], filters: [
                    { text: 'Adding', value: 'ADDING'},
                    { text: 'Active', value: 'ACTIVE'},
                    { text: 'Pending', value: 'PENDING'},
                    { text: 'Deleting', value: 'DELETING'},
                    { text: 'Scheduled', value: 'SCHEDULED'},
                    { text: 'Updating', value: 'UPDATING'},
                    { text: 'Updating Hosting', value: 'UPDATING_HOSTING'}
                    ], key: 'status', align: 'center', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'TF', align: 'center', responsive:['md'], render: (item, record) => record.metrics !== null && record.metrics.tf !== null ? record.metrics.tf : <Tooltip title='Updating'><Loading /></Tooltip>},
            { title: 'DA', align: 'center', responsive:['md'], render: (item, record) => record.metrics !== null && record.metrics.da !== null ? record.metrics.da : <Tooltip title='Updating'><Loading /></Tooltip>},
            { title: 'SSL', dataIndex: 'ssl_certificate', filterMultiple: false, filters: [
                { text: 'Installed', value: 'INSTALLED'},
                    { text: 'Not installed', value: 'NOT_INSTALLED'}
                ], align: 'center', responsive:['lg'], render: (item, record) => {
                    if(record.ssl_certificate === null) {
                        return <Tooltip title='SSL certificate not installed'><SafetyCertificateOutlined style={{color:'lightgray'}} /></Tooltip>;
                    }

                    if(record.ssl_certificate.status === 'ACTIVE') {
                        return <Tooltip title='SSL certificate installed'><SafetyCertificateOutlined style={{color:'green'}} /></Tooltip>;
                    } else {
                        return <Loading />;
                    }
                }},
            { title: 'Software', align: 'center', render: (item, record) => {
                if(record.software === null) {
                    return <Tooltip title='Software not installed'><LoginOutlined style={{color:'lightgray'}} /></Tooltip>;
                }

                if(record.software.status === 'ACTIVE') {
                    if(hasPermission(this.props.company.permissions, ['can_login_to_software'])) {
                        return <Tooltip title={'Login to ' + record.software.software_name}>
                            <LoginOutlined style={{color:'green'}} onClick={() => this.loginToSoftware(record.guid)} />
                        </Tooltip>;
                    } else {
                        return <Tooltip title='Automatic login not allowed!'><LoginOutlined style={{color:'lightgray'}} /></Tooltip>;
                    }
                } else {
                    return <Tooltip title='Software is busy'><LoginOutlined style={{color:'lightgray'}} /></Tooltip>;
                }
            }},
            { title: '', dataIndex: 'actions', align: 'right', render: (item, record) => {
                let disabled = true;

                if(['ACTIVE', 'SUSPENDED'].indexOf(record.status) > -1) {
                    disabled = false;
                }

                    return <Space>
                        <Button size='small' icon={<SettingOutlined />} onClick={() => this.setState({ activeDomain: record.guid, isSubdomain: record.is_subdomain, hostingType: record.hosting_type, activeDomainName: record.domain, showActions: true})}>Actions</Button>
                        <AccessItem scopes={['can_delete_domains']}>
                            <Popconfirm
                                onConfirm={() => this.deleteDomain(record.guid)}
                                placement='topRight'
                                title="Are you sure you want to delete this domain?"
                                okText="Delete Domain"
                                okType='danger'
                                disabled={disabled}
                                cancelText="Cancel">
                                <Button disabled={disabled} size='small' icon={<DeleteOutlined />} danger />
                            </Popconfirm>
                        </AccessItem>
                    </Space>;
                }
            }
            ];

        const { TabPane } = Tabs;
        const { Title } = Typography;

        // TODO: Tabulate tabs into object

        return(
            <Card size='small'>
                <Tabs activeKey={this.state.activeTab} onChange={(tab) => this.onTabChange(tab)} destroyInactiveTabPane={true}>
                    <TabPane tab='Domains' key='domains'>
                        <Row justify='space-between' style={{marginBottom: '10px'}}>
                            <Col span={22}>
                                <Space>
                                    <AccessItem scopes={['can_edit_domains']}>
                                        <Tooltip title={this.state.selectedDomains.length === 0 ? 'Select at least one domain' : 'Execute bulk actions on selected domains'}>
                                            <Button disabled={this.state.selectedDomains.length === 0} icon={<BarsOutlined />} onClick={() => this.setState({ showBulkActionsModal: true})}>Bulk Actions</Button>
                                        </Tooltip>
                                    </AccessItem>
                                    <Tooltip title='Export domains'>
                                        <Button disabled={this.state.domains.length === 0} icon={<ExportOutlined />} onClick={() => this.setState({ showExportModal: true})}>Export</Button>
                                    </Tooltip>
                                    <AccessItem scopes={['can_edit_domain_groups']}>
                                        <Tooltip title='Edit domain group settings'>
                                            <Button icon={<SettingOutlined />} onClick={() => this.setState({ showSettingsModal: true})}>Settings</Button>
                                        </Tooltip>
                                    </AccessItem>
                                    <Select
                                        value={this.state.searchQuery}
                                        maxTagCount={50}
                                        placeholder='Search'
                                        open={false}
                                        maxTagTextLength={10}
                                        onClear={() => this.clearSearchAndLoadDomains()}
                                        onInputKeyDown={(e) => { if(e.key === 'Enter') { this.runSearch() }}}
                                        allowClear loading={this.state.searching}
                                        mode="tags" style={{ width: '250px' }}
                                        onChange={(value) => this.updateSearchQuery(value)} tokenSeparators={[',', ' ']} />
                                </Space>
                            </Col>
                            <Col>
                                <Tooltip title='Reload table'>
                                    <Button type='text' icon={<UndoOutlined />} onClick={() => this.loadDomains()}/>
                                </Tooltip>
                            </Col>
                        </Row>

                        <ConfigProvider renderEmpty={() => <div style={{ textAlign: 'center' }}>
                            <FolderOpenOutlined style={{ fontSize: 40 }} />
                            <p>Nothing to see here...</p>
                            <AccessItem scopes={['can_add_domains']}>
                                <Button type='primary' icon={<PlusOutlined />} onClick={() => this.setState({ activeTab: 'add_domains'})}>Add Domains</Button>
                            </AccessItem>
                        </div>}>
                            {this.state.showExpirationWarning ? <Alert type='warning' showIcon message={'There are one or more domains that will expire in less than 10 days or are already expired!'} /> : '' }
                            <Table
                                rowSelection={{
                                    type: 'checkbox',
                                    selectedRowKeys: this.state.selectedDomains,
                                    onChange: (domains) => this.setState({ selectedDomains: domains }),
                                    getCheckboxProps: (record) => ({
                                        disabled: record.status !== 'ACTIVE'
                                    }),
                                }}
                                onChange={this.handleTableChange}
                                dataSource={this.state.domains}
                                columns={columns}
                                footer={this.state.selectedDomains.length > 0 ? () =>  <span>{this.state.selectedDomains.length} selected</span> : null }
                                rowKey={(item) => item.guid}
                                pagination={this.state.pagination}
                                loading={!this.state.domainsLoaded} />
                        </ConfigProvider>

                        <Drawer
                            placement="right"
                            className='domain-overview-drawer'
                            destroyOnClose={true}
                            closable={isMobile}
                            width={this.state.domainOverviewDrawerWidth}
                            onClose={() => this.closeOverview()}
                            open={this.state.showActions}>

                            <Title level={4}>{this.state.activeDomainName}</Title>

                            <Tabs defaultActiveKey={this.state.domainOverViewActiveTab}
                                  activeKey={this.state.domainOverViewActiveTab}
                                  onChange={(key) => this.tabChange(key)} destroyInactiveTabPane={true}>
                                <TabPane tab='Domain' key='domain'>
                                    <DomainOverview
                                        isSubdomain={this.state.isSubdomain}
                                        hostingType={this.state.hostingType}
                                        history={this.props.history}
                                        closeOverview={() => this.closeOverview()}
                                        downloadSoftwareBackup={(guid) => this.downloadSoftwareBackup(guid)}
                                        loginToSoftware={(guid) => this.loginToSoftware(guid)}
                                        loginTocPanel={(guid) => this.loginToCpanel(guid)}
                                        guid={this.state.activeDomain}
                                        reloadDomains={() => this.loadDomainGroupData()} />
                                </TabPane>
                                {!this.state.isSubdomain ? <Fragment>
                                    <TabPane tab='DNS Zone' key='dns_zone'>
                                        <DNSZone guid={this.state.activeDomain} />
                                    </TabPane>
                                    <TabPane tab='Subdomains' key='subdomains'>
                                        <SubdomainsOverview manageSubdomain={(guid, hosting_type, domain) => {
                                            this.setState({
                                                activeDomain: guid, isSubdomain: true, domainOverViewActiveTab: 'domain',
                                                domainOverviewDrawerWidth: DEFAULT_DOMAIN_OVERVIEW_DRAWER_WIDTH,
                                                hostingType: hosting_type, activeDomainName: domain, showActions: true});
                                        }} guid={this.state.activeDomain} domainGroup={this.props.match.params.group} domainName={this.state.activeDomainName} />
                                    </TabPane>
                                    <TabPane tab='Email Addresses' key='email_addresses'>
                                        <EmailAddressOverview guid={this.state.activeDomain}  domainName={this.state.activeDomainName} />
                                    </TabPane>
                                </Fragment>: ''}
                                <TabPane tab='URL Redirects' key='redirects'>
                                    <DomainRedirects guid={this.state.activeDomain}  domainName={this.state.activeDomainName} />
                                </TabPane>
                                <TabPane tab='Misc' key='misc'>
                                    <MiscDomainActions guid={this.state.activeDomain} />
                                </TabPane>
                                <TabPane tab='Notes' key='notes'>
                                    <DomainNotes guid={this.state.activeDomain} />
                                </TabPane>
                            </Tabs>
                        </Drawer>

                        <GroupSettingsModal
                            showModal={this.state.showSettingsModal}
                            group={this.props.match.params.group}
                            reloadGroup={() => {
                                this.loadDomains();
                                this.loadDomainGroupData();
                            }}
                            close={() => this.setState({ showSettingsModal: false })}/>

                        <ExportModal showModal={this.state.showExportModal}
                                     group={this.props.match.params.group}
                                     selecteDomains={this.state.selectedDomains}
                                     closeModal={() => this.setState({ showExportModal: false })} />

                        <DomainBulkUpdateModal
                            selectedDomains={this.state.selectedDomains}
                            showModal={this.state.showBulkActionsModal}
                            domainGroup={this.props.match.params.group}
                            reloadDomains={() => this.loadDomains()}
                            deselectDomains={() => this.setState({ selectedDomains: [] })}
                            close={() => this.setState({ showBulkActionsModal: false })} />

                    </TabPane>
                    <TabPane tab='Add Domains' key='add_domains'>
                        <AccessGate scopes={['can_add_domains']}>
                            <AddDomainsForm
                                defaultHostingAccount={this.state.defaultHostingAccount}
                                group={this.props.match.params.group}
                                closeAddSwitchToDomainTab={() => {
                                    this.loadDomainGroupData();
                                    this.switchToTab('domains');
                                }}/>
                        </AccessGate>
                    </TabPane>
                    <TabPane tab='Tasks' key='tasks'>
                        <GroupTasksTable
                            group={this.props.match.params.group}
                            socket={this.state.tasksSocket} />
                    </TabPane>
                    <TabPane tab='Software Presets' key='software_presets'>
                        <AccessGate scopes={['can_view_software_presets']}>
                            <SoftwarePresets />
                        </AccessGate>
                    </TabPane>
                </Tabs>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadDomainGroup, loadDomains, loadGroupTasks,
    loginTocPanel, loginToSoftware, getExpiringDomainsInGroup, deleteDomain })(DomainGroup);