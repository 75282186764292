import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, message, Modal, Select} from "antd";
import {createSoftwarePreset} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";

class AddSoftwarePresetModal extends React.Component {
    state = {
        loading: false,
        name: '',
        selectedSoftware: null,
        softwareOptions: [{ name: 'WordPress', value: 'WordPress'}]
    };

    closeModal() {
        this.setState({
            loading: false,
            name: '',
            selectedSoftware: null,
        });

        this.props.close();
    }

    createSoftwarePreset() {
        let name = this.state.name.trim();
        let software_name = this.state.selectedSoftware;

        if(name.length === 0) {
            return message.error('Please enter preset\'s name!');
        }

        if(software_name.length === 0) {
            return message.error('Please choose the software!');
        }

        let data = { name, software_name };

        this.setState({ loading: true });

        this.props.createSoftwarePreset(data, () => {
            this.closeModal();
            this.props.reloadPresets();
            this.setState({ loading: false});

            message.success('Software preset successfully created!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false});
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal
                centered
                title='Add Software Preset'
                open={this.props.showModal}
                onCancel={() => this.closeModal()}
                footer={<div>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Cancel</Button>
                    <Button disabled={this.state.loading} loading={this.state.loading} type='primary' onClick={() => this.createSoftwarePreset()}>Create</Button>
                </div>}
                destroyOnClose={true}>
                <Form layout='vertical'>
                    <Form.Item label='Name:'>
                        <Input autoFocus onChange={(e) => this.setState({ name: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Software:'>
                        <Select onChange={(value) => this.setState({ selectedSoftware: value })}>
                            {this.state.softwareOptions.map((software) => {
                                return <Option key={software.name} value={software.value}>{software.name}</Option>;
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { createSoftwarePreset })(AddSoftwarePresetModal);