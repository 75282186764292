import {httpDelete, httpGet, httpPatch, httpPost} from "../libs/http";

export const loadIPAddressGroups = (page, per_page, filter = null, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/ip_addresses/groups/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const getIpAddressGroupDetails = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/ip_addresses/groups/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateIPAddressGroup = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/ip_addresses/groups/' + guid + '/',  data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const getIpAddressGroupIPAddresses = (guid, page, per_page, searchQuery, onSuccess, onFailure) => dispatch => {
    let search = [];

    for(let i = 0; i < searchQuery.length; i++) {
        search.push('search=' + searchQuery[i]);
    }

    let search_param = '';

    if(search.length > 0) {
        search_param = '&' + search.join('&')
    }

    httpGet('/ip_addresses/?group=' + guid + '&page=' + page + '&per_page=' + per_page + search_param, (res) => onSuccess(res), (err) => onFailure(err));
};


export const createIpAddressGroup = (name, onSuccess, onFailure) => dispatch => {
    httpPost('/ip_addresses/groups/', { name }, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteIPAddressGroup = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/ip_addresses/groups/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAllIPAddresses = (onSuccess, onFailure) => dispatch => {
    httpGet('/ip_addresses/?page=1&per_page=99999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAllIPSignatures = (onSuccess, onFailure) => dispatch => {
    httpGet('/ip_addresses/signatures/?per_page=99999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAvailableIPAddresses = (ip_type, ip_filter, excluded_ip_groups, currency, onSuccess = null, onFailure = null) => dispatch => {
    let excluded = [];

    for(let i = 0; i < excluded_ip_groups.length; i++) {
        excluded.push('exclude=' + excluded_ip_groups[i]);
    }

    let excluded_param = '';

    if(excluded.length > 0) {
        excluded_param = '&' + excluded.join('&')
    }

    httpGet('/ip_addresses/marketplace/?ip_type=' + ip_type + '&ip_filter=' + ip_filter + '&currency=' + currency + excluded_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const createIPAddressOrder = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/billing/orders/ip_addresses/create/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadIPAddressMapData = (onSuccess, onFailure) => dispatch => {
    httpGet('/ip_addresses/map/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateIPAddressServerSignature = (ip_address, data, onSuccess, onFailure) => dispatch => {
    httpPatch('/ip_addresses/' + ip_address + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const moveIPAddresses = (data, onSuccess, onFailure) => dispatch => {
    httpPatch('/ip_addresses/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const exportIPAddressData = (guid, fields, selectedIPAddresses, type, sortField, sortDir, onSuccess, onFailure) => dispatch => {
    let urlFields = [];

    for(let i = 0; i < fields.length; i++) {
        urlFields.push('fields=' + fields[i]);
    }

    let domains = [];

    for(let i = 0; i < selectedIPAddresses.length; i++) {
        domains.push('selected_ip_addresses=' + selectedIPAddresses[i]);
    }

    httpGet('/ip_addresses/export/?group=' + guid + '&output_type=' + type + '&' + urlFields.join('&') + '&' + domains.join('&') + '&sort_field=' + sortField + '&sort_dir=' + sortDir, (res) => onSuccess(res), (err) => onFailure(err));
};

export const setHttpVersion = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/ip_addresses/' + guid + '/http_version/', data, (res) => onSuccess(res), (err) => onFailure(err));
};
export const setDirectAccess = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/ip_addresses/' + guid + '/direct_access/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const bulkIPAddressActions = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/ip_addresses/bulk/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadIPAddressBillingCycles = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/orders/ip_addresses/options/', (res) => onSuccess(res), (err) => onFailure(err));
};
