import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Col, Row, Space, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors} from "../../libs/utils";
import {EyeOutlined, FilePdfOutlined} from "@ant-design/icons";
import {downloadCreditNote, loadCredit} from "../../actions/billing";
import Button from "antd/es/button";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {Link} from "react-router-dom";
import {MoneyField} from "../shared/MoneyField";
import AccessGate from "../permissions/AccessGate";
import AccessItem from "../permissions/AccessItem";

class CreditNotesTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        credit: [],
        loadingCredit: true
    }

    componentDidMount() {
        this.props.updatePage('Credit Notes');
        this.loadCredit();
    }

    loadCredit(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingCredit: true });

        this.props.loadCredit(page, per_page, this.state.filter, (res) => {
            this.setState({ credit: res.data.data, loadingCredit: false, pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCredit: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(pagination, filters) {
        let filter = [];

        if(filters.status !== null) {
            filter = filters.status;
        }

        this.setState({ filter: filter }, () => {
            this.loadCredit(pagination.current, pagination.pageSize);
        });
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id', render: (item) => <strong><Link to={'/billing/credit_notes/' + item}>#{item}</Link></strong> },
            { title: 'Balance', align: 'center', dataIndex: 'balance', render: (item, record) => <MoneyField amount={record.balance} currency={record.currency} />  },
            { title: 'Date Created', dataIndex: 'date_created', render: (item, record) => <DateTimeFormat value={record.date_created} /> },
            { title: '', align: 'right', render: (item, record) => {
                return <Space>
                    <AccessItem scopes={['can_download_credit_notes']}>
                        <Button size='small' icon={<FilePdfOutlined />} onClick={() => this.props.downloadCreditNote(record.id)}>Download PDF</Button>
                    </AccessItem>
                    <Button size='small' icon={<EyeOutlined />} onClick={() => this.props.history.push('/billing/credit_notes/' + record.id)}>View</Button>
                </Space>;
            }}
        ];

        return(
            <AccessGate scopes={['can_view_credit_notes']}>
                <Row justify='end' style={{paddingBottom: '10px'}}>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadCredit()}/></Tooltip></Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.id}
                    dataSource={this.state.credit}
                    loading={this.state.loadingCredit}
                    pagination={this.state.pagination}
                    onChange={(pagination, filters, sorter, extra) => this.changePage(pagination, filters, sorter, extra)}
                />
            </AccessGate>
        )
    }
}

export default connect(null, { updatePage, loadCredit, downloadCreditNote })(CreditNotesTable);