import React from "react";
import {Button, Form, message, Modal, Select, Space} from "antd";
import {connect} from "react-redux";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {displayErrors} from "../../libs/utils";
import {bulkIPAddressActions} from "../../actions/ip_addresses";
import AccessItem from "../permissions/AccessItem";

class BulkIPAddressActionsModal extends React.Component {
    state = {
        loading: false,
        selectedAction: null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal(reload = false) {
        this.setState({
            loading: false,
            selectedAction: null
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    runAction() {
        if(this.state.selectedAction === null) {
            return message.error('Please select an action!');
        }

        if(this.props.selectedIPAddresses.length === 0) {
            return message.error('Please select at least 1 IP address!');
        }

        const confirmations = {
            'ENABLE_HTTP2': 'Are you sure you wish to enable HTTP/2 for selected IP addresses?',
            'DISABLE_HTTP2': 'Are you sure you wish to disable HTTP/2 for selected IP addresses?',
        };

        const { confirm } = Modal;

        confirm({
            title: 'Attention',
            centered: true,
            icon: <ExclamationCircleOutlined />,
            content: confirmations[this.state.selectedAction],
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    action: this.state.selectedAction,
                    ip_addresses: this.props.selectedIPAddresses
                };

                this.props.bulkIPAddressActions(data, () => {
                    this.closeModal(true);
                    this.setState({ loading: false });
                    message.success('IP addresses successfully updated!');
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                        this.setState({ loading: false });
                    }
                });
            }
        })
    }

    render() {
        const { Option } = Select;

        return <Modal
            title='Bulk Actions'
            open={this.props.show}
            destroyOnClose={true}
            footer={<Space>
                <Button type='default' disabled={this.state.loading} onClick={() => this.closeModal()}>Cancel</Button>
                <AccessItem scopes={['can_edit_ip_addresses']}>
                    <Button type='primary' disabled={this.state.loading} loading={this.state.loading} onClick={() => this.runAction()}>Continue</Button>
                </AccessItem>
            </Space>}
            onCancel={() => this.closeModal()}>
            <Form layout='vertical'>
                <Form.Item label='Select action'>
                    <Select onChange={(value) => this.setState({ selectedAction: value})}>
                        <Option value='ENABLE_HTTP2'>Enable HTTP/2</Option>
                        <Option value='DISABLE_HTTP2'>Disable HTTP/2</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>;
    }
}

export default connect(null, { bulkIPAddressActions })(BulkIPAddressActionsModal);