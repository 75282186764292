import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Col, Collapse, message, Popconfirm, Row, Space, Table} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import Button from "antd/es/button";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {deleteSoftwarePresetGroup, deleteSoftwarePresetGroupItem} from "../../actions/domains";
import AccessItem from "../permissions/AccessItem";

class SoftwarePresetGroupTable extends React.Component {
    state = {
        loading: false,
        activeGroup: null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    deletePresetGroup() {
        this.props.deleteSoftwarePresetGroup(this.props.activePreset, this.state.activeGroup, () => {
            this.setState({ loading: false });
            message.success('Software preset group successfully deleted!');
            this.props.reloadPresets();
        }, (err) => {
            this.setState({ loading: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    getItemUrl(slug, itemType) {
        if(itemType === 'THEME') {
            return 'https://wordpress.org/themes/' + slug + '/';
        } else if(itemType === 'PLUGIN') {
            return 'https://wordpress.org/plugins/' + slug + '/';
        }
    }

    deleteItem(guid) {
        this.props.deleteSoftwarePresetGroupItem(this.props.activePreset, this.state.activeGroup, guid, () => {
            this.setState({ loading: false });
            message.success('Software preset group item successfully deleted!');
            this.props.reloadPresets();
        }, (err) => {
            this.setState({ loading: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'Name', dataIndex: 'name', render: (item, row) => <a href={this.getItemUrl(row.slug, row.item_type)} target='_blank' rel='noopener noreferrer'>{item}</a>},
            { title: 'Type', align: 'center', dataIndex: 'item_type', render: (item) => normalizeEnum(item)},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: '', align: 'right', render: (item, row) => <Space>
                    <a href={this.getItemUrl(row.slug, row.item_type)} target='_blank' rel='noopener noreferrer'>
                        <Button icon={<EyeOutlined />} size='small'>Preview</Button>
                    </a>
                    <AccessItem scopes={['can_edit_software_presets']}>
                        <Popconfirm
                            title="Are you sure you want to delete this item?"
                            onConfirm={() => this.deleteItem(row.guid)}
                            okText='Delete'
                            placement='topRight'
                            okButtonProps={{danger: true}}
                            cancelText='Cancel'>
                            <Button icon={<DeleteOutlined />} danger size='small' />
                        </Popconfirm>
                    </AccessItem>
                </Space>}
        ];

        const { Panel } = Collapse;

        return(
            <Fragment>
                <Row>
                    <Col span={24}>
                        <AccessItem scopes={['can_edit_software_presets']}>
                            <Button icon={<PlusOutlined />} onClick={() => this.props.addGroup() }>Add Group</Button>
                        </AccessItem>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        {this.props.data.groups.length > 0 ? <Collapse accordion activeKey={this.state.activeGroup} onChange={(guid) => this.props.setActiveGroup(guid) }>
                            {this.props.data.groups.map(group => {
                                return <Panel header={<span>{group.name} <em><small>({normalizeEnum(group.group_type)} - install {normalizeEnum(group.install_action).toLowerCase()})</small></em></span>} key={group.guid}>
                                    <AccessItem scopes={['can_edit_software_presets']}>
                                        <Button icon={<PlusOutlined />} onClick={() => this.props.addItems(group.guid, group.group_type) }>Add {group.group_type === 'PLUGINS' ? 'Plugins' : 'Themes'}</Button>
                                        <br />
                                        <br />
                                    </AccessItem>

                                    <Table
                                        dataSource={group.items}
                                        columns={columns} size='small'
                                        rowKey={(item) => item.guid}
                                        pagination={false} />

                                    <AccessItem scopes={['can_edit_software_presets']}>
                                        <br />
                                        <Popconfirm
                                            title="Are you sure you want to delete this preset group?"
                                            onConfirm={() => this.deletePresetGroup(group.guid)}
                                            okText='Delete'
                                            placement='topLeft'
                                            okButtonProps={{danger: true}}
                                            cancelText='Cancel'>
                                            <Button icon={<DeleteOutlined />} danger>Delete Group</Button>
                                        </Popconfirm>
                                    </AccessItem>
                                </Panel>;
                            })}
                        </Collapse> : ''}
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default connect(null, { deleteSoftwarePresetGroupItem,
    deleteSoftwarePresetGroup })(SoftwarePresetGroupTable);