import React, {Fragment} from "react";
import {connect} from "react-redux";
import {loadDomainMeta, updateDomainMeta} from "../../actions/domains";
import {Switch, Card, Descriptions, message, Tooltip} from "antd";
import {Loading} from "../../libs/loading";
import {displayErrors} from "../../libs/utils";
import {hasPermission} from "../permissions/PermissionUtils";
import AccessItem from "../permissions/AccessItem";
import {StatusIndicator} from "../shared/Status";

class MiscDomainActions extends React.Component {
    state = {
        loading: false
    };

    componentDidMount() {
        this.loadDomainDetail();
    }

    loadDomainDetail() {
        this.setState({ loading: true });

        this.props.loadDomainMeta(this.props.guid, (response) => {
            this.setState({ ...response.data, loading: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false })
            }
        });
    }

    saveDomainMeta() {
        let data = {
            redirect_to_https: this.state.redirect_to_https,
            redirect_to_http: this.state.redirect_to_http,
            redirect_to_www: this.state.redirect_to_www,
            redirect_to_nonwww: this.state.redirect_to_nonwww,
            cache_enabled: this.state.cache_enabled,
        };

        this.props.updateDomainMeta(this.props.guid, data, () => {
            message.success('Domain\'s meta successfully updated! Allow up to 5 minutes for changes to take effect!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false })
            }
        });
    }

    updateMeta(field, value) {
        if(field === 'redirect_to_https' && value && this.state.redirect_to_http) {
            this.setState({ redirect_to_http: false }, () => this.setState({ [field]: value}, () => this.saveDomainMeta()));
        } else if(field === 'redirect_to_http' && value && this.state.redirect_to_https) {
            this.setState({ redirect_to_https: false }, () => this.setState({ [field]: value}, () => this.saveDomainMeta()));
        } else if(field === 'redirect_to_www' && value && this.state.redirect_to_nonwww) {
            this.setState({ redirect_to_nonwww: false }, () => this.setState({ [field]: value}, () => this.saveDomainMeta()));
        } else if(field === 'redirect_to_nonwww' && value && this.state.redirect_to_www) {
            this.setState({ redirect_to_www: false }, () => this.setState({ [field]: value}, () => this.saveDomainMeta()));
        } else {
            this.setState({ [field]: value}, () => this.saveDomainMeta());
        }
    }

    getStatus(value, verb) {
        if(this.state.status !== 'ACTIVE') {
            return 'This domain is currently busy!';
        }

        if(value) {
            return 'Disable ' + verb;
        }

        return 'Enable ' + verb;
    }

    render() {
        if(this.state.loading) {
            return <div className='text-center'><Loading /></div>;
        }

        return(
            <Fragment>
                <Card bordered={false} size='small' title='Domain redirects'>
                    <Descriptions bordered size='small' column={1}>
                        <Descriptions.Item style={{width: '50%'}} label='Redirect HTTP to HTTPS'>
                            <AccessItem scopes={['can_edit_domain_redirects']}>
                                <Tooltip title={this.getStatus(this.state.redirect_to_https, 'redirect to HTTPS')}>
                                    <Switch disabled={this.state.status !== 'ACTIVE'} size='small' checked={this.state.redirect_to_https} onChange={(value) => this.updateMeta('redirect_to_https', value)} />
                                </Tooltip>
                            </AccessItem>
                            {!hasPermission(this.props.company.permissions, ['can_edit_domain_redirects']) ? <StatusIndicator status={this.state.redirect_to_https} el='badge' /> : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label='Redirect HTTPS to HTTP'>
                            <AccessItem scopes={['can_edit_domain_redirects']}>
                                <Tooltip title={this.getStatus(this.state.redirect_to_http, 'redirect to HTTP')}>
                                    <Switch disabled={this.state.status !== 'ACTIVE'} size='small' checked={this.state.redirect_to_http} onChange={(value) => this.updateMeta('redirect_to_http', value)} />
                                </Tooltip>
                            </AccessItem>
                            {!hasPermission(this.props.company.permissions, ['can_edit_domain_redirects']) ? <StatusIndicator status={this.state.redirect_to_http} el='badge' /> : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label='Redirect to "WWW"'>
                            <AccessItem scopes={['can_edit_domain_redirects']}>
                                <Tooltip title={this.getStatus(this.state.redirect_to_www, 'redirect to www')}>
                                    <Switch disabled={this.state.status !== 'ACTIVE'} size='small' checked={this.state.redirect_to_www} onChange={(value) => this.updateMeta('redirect_to_www', value)} />
                                </Tooltip>
                            </AccessItem>
                            {!hasPermission(this.props.company.permissions, ['can_edit_domain_redirects']) ? <StatusIndicator status={this.state.redirect_to_www} el='badge' /> : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label='Redirect to "non-WWW"'>
                            <AccessItem scopes={['can_edit_domain_redirects']}>
                                <Tooltip title={this.getStatus(this.state.redirect_to_nonwww, 'redirect to non-www')}>
                                    <Switch disabled={this.state.status !== 'ACTIVE'} size='small' checked={this.state.redirect_to_nonwww} onChange={(value) => this.updateMeta('redirect_to_nonwww', value)} />
                                </Tooltip>
                            </AccessItem>
                            {!hasPermission(this.props.company.permissions, ['can_edit_domain_redirects']) ? <StatusIndicator status={this.state.redirect_to_nonwww} el='badge' /> : ''}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
                <Card bordered={false} size='small' title='Caching'>
                    <Descriptions bordered size='small' column={1}>
                        <Descriptions.Item style={{width: '50%'}} label='Domain caching'>
                            <AccessItem scopes={['can_edit_domain_caching']}>
                                <Tooltip title={this.state.cache_enabled ? 'Disable caching' : 'Enable caching'}>
                                    <Switch size='small' checked={this.state.cache_enabled} onChange={(value) => this.updateMeta('cache_enabled', value)} />
                                </Tooltip>
                            </AccessItem>
                            {!hasPermission(this.props.company.permissions, ['can_edit_domain_caching']) ? <StatusIndicator status={this.state.cache_enabled} el='badge' /> : ''}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { loadDomainMeta, updateDomainMeta })(MiscDomainActions);