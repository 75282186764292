import React from "react";
import {connect} from "react-redux";
import {Select} from "antd";
import {loadDomainGroups} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";

class DomainGroupSelector extends React.Component {
    state = {
        groups: [],
        selectedGroup: '',
        loadingGroups: true
    };

    componentDidMount() {
        this.loadDomainGroups();
    }

    loadDomainGroups() {
        this.props.loadDomainGroups(1, 9999, null, (res) => {
            this.setState({ groups: res.data.data, loadingGroups: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingGroups: false });
            }
        });
    }

    updateValue(value) {
        this.setState({ selectedGroup: value });
        this.props.onChange(value);
    }

    render() {
        const { Option } = Select;

        return(
            <Select
                loading={this.state.loadingGroups}
                disabled={this.state.loadingGroups}
                style={{width: '100%'}}
                onChange={(value) => this.updateValue(value)}>
            {this.state.groups.map((group) => {
                return <Option value={group.guid} key={group.guid}>{group.name}</Option>
            })}
        </Select>)
    }
}

export default connect(null, { loadDomainGroups })(DomainGroupSelector);