import React from "react";
import {connect} from "react-redux";
import {Card, Tabs} from "antd";
import IPAddressMap from "../../components/ip_addresses/IPAddressMap";
import IPGroupsTable from "../../components/ip_addresses/IPGroupsTable";

class IPAddresses extends React.Component {
    render() {
        const items = [
            { label: 'Groups', key: 'ip-groups', children: <IPGroupsTable history={this.props.history} />},
            { label: 'Available Stock', key: 'ip-map', children: <IPAddressMap /> }
        ]

        return(
            <Card size='small'>
                <Tabs items={items} />
            </Card>
        );
    }
}

export default connect(null, {  })(IPAddresses);