import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Alert, Descriptions, Select} from "antd";
import {loadDomainGroups, loadSoftwarePresetsMinimal} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";
import {loadIPAddressGroups} from "../../actions/ip_addresses";
import {loadHostingAccounts} from "../../actions/hosting";

class CompanyUserAccessTable extends React.Component {
    state = {
        loadingIPGroups: false,
        loadingDomainGroups: false,
        loadingHostingAccounts: false,
        loadingSoftwarePresets: false,
        availableIPGroups: [],
        availableDomainGroups: [],
        availableHostingAccounts: [],
        availableSoftwarePresets: []
    }

    componentDidMount() {
        this.setState({ loadingIPGroups: true, loadingDomainGroups: true, loadingHostingAccounts: true });

        this.props.loadIPAddressGroups(1, 9999, [{status:'ACTIVE'}], (res) => {
            this.setState({ availableIPGroups: res.data.data, loadingIPGroups: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPGroups: false });
                displayErrors(err.response.data);
            }
        });

        this.props.loadDomainGroups(1, 9999, [{status:'ACTIVE'}], (res) => {
            this.setState({ availableDomainGroups: res.data.data, loadingDomainGroups: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDomainGroups: false });
                displayErrors(err.response.data);
            }
        });

        this.props.loadHostingAccounts(1, 9999, (res) => {
            this.setState({ availableHostingAccounts: res.data.data, loadingHostingAccounts: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingHostingAccounts: false });
                displayErrors(err.response.data);
            }
        });

        this.props.loadSoftwarePresetsMinimal((res) => {
            this.setState({ availableSoftwarePresets: res.data.data, loadingSoftwarePresets: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingSoftwarePresets: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;
        const labelWidth = '190px';
        const { allowed_ip_groups, allowed_domain_groups, allowed_hosting_accounts, allowed_software_presets } = this.props.data;

        return(
            <Fragment>
                <Alert showIcon message='If fields below are empty then user has access to all IP and domain groups!' />
                <br />
                <Descriptions bordered size='small' layout='horizontal' column={1}>
                    <Descriptions.Item label='Allow IP groups' labelStyle={{width: labelWidth}}>
                        <Select placeholder='All IP groups allowed' value={allowed_ip_groups} loading={this.state.loadingIPGroups} mode='multiple'
                                style={{width: '100%'}} onChange={value => this.props.updateField('allowed_ip_groups', value)}>
                            {this.state.availableIPGroups.map(group => {
                                return <Option value={group.guid} key={group.guid}>{group.name}</Option>
                            })}
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label='Allow domain groups'>
                        <Select placeholder='All domain groups allowed' value={allowed_domain_groups} loading={this.state.loadingDomainGroups} mode='multiple'
                                style={{width: '100%'}} onChange={value => this.props.updateField('allowed_domain_groups', value)}>
                            {this.state.availableDomainGroups.map(group => {
                                return <Option value={group.guid} key={group.guid}>{group.name}</Option>
                            })}
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label='Allow hosting accounts'>
                        <Select placeholder='All hosting accounts allowed' value={allowed_hosting_accounts} loading={this.state.loadingHostingAccounts} mode='multiple'
                                style={{width: '100%'}} onChange={value => this.props.updateField('allowed_hosting_accounts', value)}>
                            {this.state.availableHostingAccounts.map(group => {
                                return <Option value={group.guid} key={group.guid}>{group.name}</Option>
                            })}
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label='Allow software presets'>
                        <Select placeholder='All software presets allowed' value={allowed_software_presets} loading={this.state.loadingSoftwarePresets} mode='multiple'
                                style={{width: '100%'}} onChange={value => this.props.updateField('allowed_software_presets', value)}>
                            {this.state.availableSoftwarePresets.map(group => {
                                return <Option value={group.guid} key={group.guid}>{group.name}</Option>
                            })}
                        </Select>
                    </Descriptions.Item>
                </Descriptions>
            </Fragment>
        );
    }
}

export default connect(null, { loadDomainGroups, loadIPAddressGroups,
    loadHostingAccounts, loadSoftwarePresetsMinimal })(CompanyUserAccessTable);