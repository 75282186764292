import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Card, Col, Row, Table} from "antd";
import {DateFormat} from "../../components/shared/DateFormat";
import {MoneyField} from "../../components/shared/MoneyField";
import {downloadAffiliateInvoice, loadAffiliateBillingInvoice} from "../../actions/affiliate";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {FilePdfOutlined} from "@ant-design/icons";

class AffiliateInvoice extends React.Component {
    state = {
        loading: false,
        invoice: null,
    };

    componentDidMount() {
        this.loadInvoice();
    }

    loadInvoice() {
        this.setState({ loading: true });

        this.props.loadAffiliateBillingInvoice(this.props.match.params.id, (res) => {
            this.setState({ loading: false, invoice: res.data });
        }, (err) => {
            this.setState({ loading: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        })
    }

    render() {
        if(this.state.loading || this.state.invoice === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const { invoice } = this.state;

        const columns = [
            { title: 'Description', dataIndex: 'description' },
            { title: 'Amount (USD)', width: '15%', align: 'center', dataIndex: 'payout', render: (item) => <MoneyField amount={item} currency='USD' />}
        ];

        return <Card size='small'>
            <Row>
                <Col offset={3} span={18} style={{paddingTop: '50px', paddingBottom: '50px'}}>
                    <Row className='invoice-content'>
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col sm={12} xs={24}>
                                    <img src='/img/logo-top-dark.png' style={{maxHeight: '50px'}} alt='Priority Prospect' className='invoice-logo' />
                                </Col>
                                <Col sm={12} xs={24} align='right'>
                                    <Button onClick={() => this.props.downloadAffiliateInvoice(this.props.match.params.id)} type='default' icon={<FilePdfOutlined />}>Download</Button>
                                </Col>
                            </Row>
                            <br />
                            <Row gutter={[16, 16]}>
                                <Col span={18} xs={24} sm={18} style={{verticalAlign:'middle'}}>
                                    <b>Priority Prospect OÜ</b><br />
                                    Vandu tee 6-2, Hulja<br />
                                    Lääne Virumaa, Estonia<br />
                                    45203<br />
                                    Registration No. 14059959<br />
                                    VAT No. EE101884183
                                </Col>
                                <Col xs={24} sm={6} className='text-right' style={{verticalAlign:'middle'}}>
                                    <h2>Invoice<br /><small>#{invoice.id}</small></h2>
                                </Col>
                            </Row>
                            <br />
                            <Row gutter={[16, 16]} style={{marginBottom: '15px'}}>
                                <Col xs={24} sm={18}>
                                    Beneficiary:<br />
                                    <b>{invoice.company_name}</b><br />
                                    ATTN: {invoice.first_name} {invoice.last_name}<br />
                                    {invoice.address1}
                                    {invoice.address2 !== null && invoice.address2 !== '' ? <span>{invoice.address2}<br /></span> : ''}
                                    <br />{invoice.region}, {invoice.country}<br />
                                    {invoice.postal_code}
                                    {invoice.vat !== null ? <span><br />{invoice.vat.vat_id}</span> : ''}
                                </Col>
                                <Col xs={24} sm={6} className='text-right' style={{verticalAlign: 'middle'}}>
                                    Issue date: <DateFormat value={invoice.date_created} /><br />
                                    Payment terms: {normalizeEnum(invoice.payment_terms.payment_term)}, {normalizeEnum(invoice.payment_terms.billing_cycle)}<br />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={24} gutter={[16, 16]}>
                                    <Table size='middle'
                                           columns={columns}
                                           dataSource={invoice.items}
                                           pagination={false}
                                           rowKey={(item) => item.guid}
                                           summary={() => {
                                               return <Fragment>
                                                   <Table.Summary.Row key='gross_amount'>
                                                       <Table.Summary.Cell index={1} className='text-right no-border-cell'><b>Gross amount</b></Table.Summary.Cell>
                                                       <Table.Summary.Cell index={2} className='text-center no-border-cell' style={{width: '15%'}}><b><MoneyField amount={invoice.gross_amount} currency='USD' /></b></Table.Summary.Cell>
                                                   </Table.Summary.Row>
                                                   <Table.Summary.Row key='tax'>
                                                       <Table.Summary.Cell index={3} className='text-right no-border-cell'>Tax ({invoice.tax_rate}%)</Table.Summary.Cell>
                                                       <Table.Summary.Cell index={4} className='text-center no-border-cell'><MoneyField amount={invoice.tax} currency='USD' /></Table.Summary.Cell>
                                                   </Table.Summary.Row>
                                                   <Table.Summary.Row key='net_amount'>
                                                       <Table.Summary.Cell index={5} className='text-right no-border-cell'><b>Net amount</b></Table.Summary.Cell>
                                                       <Table.Summary.Cell index={6} className='text-center no-border-cell'><b><MoneyField amount={invoice.net_amount} currency='USD' /></b></Table.Summary.Cell>
                                                   </Table.Summary.Row>
                                               </Fragment>;
                                           }}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>;
    }
}

export default connect(null, { updatePage, loadAffiliateBillingInvoice,
    downloadAffiliateInvoice })(AffiliateInvoice);