import {SEARCH_COMPLETED, SEARCH_STARTED} from "../actions/types";

const initialState = {
    isLoading: false,
    results: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case SEARCH_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case SEARCH_COMPLETED:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
}