import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Card, Col, Progress, Row, Table, Timeline, Tooltip, Typography} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {CheckCircleOutlined, ClockCircleOutlined, LoadingOutlined, RollbackOutlined, WarningOutlined} from "@ant-design/icons";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DEFAULT_PER_PAGE} from "../../config";
import {loadGroupTasks} from "../../actions/domains";
import Button from "antd/es/button";
import moment from 'moment';
import 'moment-timezone';

class GroupTasksTable extends React.Component {
    state = {
        tasksPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        loadingTasks: false,
        tasks: [],
        socket: null,
        eventCreated: false
    };

    componentDidMount() {
        this.loadTasks();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.socket !== null && !this.state.eventCreated) {
            this.setState({ eventCreated: true });

            this.props.socket.addEventListener('message', (e) => {
                let data = JSON.parse(e.data);

                let newTasks = this.state.tasks.map((task) => {
                    for(let i = 0; i < data.length; i++) {
                        if(task.guid === data[i].guid) {
                            return data[i];
                        }
                    }

                    return task;
                });

                this.setState({ tasks: newTasks });
            });
        }
    }

    loadTasks(page = null, per_page = null) {
        if(page === null) {
            page = this.state.tasksPagination.current;
        }

        if(per_page === null) {
            per_page = this.state.tasksPagination.pageSize;
        }

        this.setState({ loadingTasks: true });

        this.props.loadGroupTasks(this.props.group, page, per_page, (response) => {
            this.setState({ loadingTasks: false, tasks: response.data.data,
                tasksPagination: { current: response.data.page, pageSize: response.data.per_page, total: response.data.total, showSizeChanger: true } });

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingTasks: false });
            }
        });
    }

    progressBarStatus(record) {
        if(record.status === 'FAILURE') {
            return 'exception';
        }

        if(record.status === 'ACTIVE') {
            return 'active';
        }
    }

    handleTasksTableChange = (pagination, filters) => {
        this.loadTasks(pagination.current, pagination.pageSize, filters);
    };

    getTaskDuration(date_created, date_completed) {
        let start = moment(date_created);
        let end = moment(date_completed ? date_completed : 'now');

        return moment.duration(end.utc().diff(start, 'seconds'), 'seconds').format('m [minute], s [second]', {
            trim: false
        });
    }

    render() {
        const { Text } = Typography;

        const tasksTableColumns = [
            { title: 'ID', dataIndex: 'guid', render: (item) => item },
            { title: 'Created', render: (item, record) => <DateTimeFormat value={record.date_created} /> },
            { title: 'Completed', render: (item, record) => record.date_completed !== null ? <DateTimeFormat value={record.date_completed} /> : '-' },
            { title: 'Status', render: (item, record) => normalizeEnum(record.status) },
            { title: 'Progress', width: '40%', render: (item, record) => <Progress percent={record.progress_percentage.toFixed(0)} status={this.progressBarStatus(record)} /> },
        ];

        return(
            <Fragment>
                <Row justify='end' style={{paddingBottom: '10px'}}>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadTasks()}/></Tooltip></Col>
                </Row>
                <Table
                    dataSource={this.state.tasks}
                    columns={tasksTableColumns}
                    loading={this.state.loadingTasks}
                    rowKey={(item) => item.guid}
                    pagination={this.state.tasksPagination}
                    onChange={this.handleTasksTableChange}
                    expandable={{
                        expandedRowRender: record => <Card size='small' style={{paddingTop: '30px'}}>
                            <Timeline mode='left'>
                                {record.items.map((item) => {
                                    let icon = null;
                                    let color = '';

                                    if(item.status === 'ACTIVE') {
                                        icon = <LoadingOutlined size='16px' />;
                                        color='blue'
                                    } else if(item.status === 'PENDING') {
                                        icon = <ClockCircleOutlined size='16px' />;
                                        color='gray'
                                    } else if(item.status === 'COMPLETED') {
                                        icon = <CheckCircleOutlined size='16px' />;
                                        color='green'
                                    } else if(item.status === 'FAILURE') {
                                        icon = <WarningOutlined size='16px' />;
                                        color='red'
                                    } else if(item.status === 'RETRYING') {
                                        icon = <RollbackOutlined size='16px' />;
                                        color='gray'
                                    }

                                    return <Timeline.Item
                                        key={item.guid}
                                        label={<span>{normalizeEnum(item.action)}<br /><small><Text disabled>{this.getTaskDuration(item.date_created, item.date_completed)}</Text></small></span>}
                                        dot={<Tooltip title={normalizeEnum(item.status)}>{icon}</Tooltip>}
                                        color={color}>
                                        {item.details}<br />
                                        <small><Text disabled><DateTimeFormat value={item.date_created} /></Text></small>
                                        {item.error ? <small><br />Error: <Text type="danger">{item.error}</Text></small> : ''}
                                    </Timeline.Item>;
                                })}
                            </Timeline>
                        </Card>,
                        rowExpandable: record => record.items.length > 0,
                    }}
                />
            </Fragment>
        )
    }
}

export default connect(null, { loadGroupTasks })(GroupTasksTable);