import React, {Fragment} from "react";
import {Col, Descriptions, Drawer, Input, List, Row, Table} from "antd";
import {connect} from "react-redux";
import {performSearch} from "../actions/search";
import {message} from "antd";
import {displayErrors} from "../libs/utils";
import {Link} from "react-router-dom";

const utilizeFocus = () => {
    const ref = React.createRef()
    const setFocus = () => {ref.current &&  ref.current.focus()}

    return {setFocus, ref}
}

class GlobalSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            loading: false,
            showResults: false,
            results: [],
            searchType: 'DOMAIN',
            showSearchDrawer: false,
            inputRef: null
        };

        this.inputFocus = utilizeFocus();
    }


    runSearch() {
        if(this.state.query.trim() === '') {
            message.error('Please enter a domain or an IP address!');
            return;
        }

        this.setState({ loading: true });

        this.props.performSearch(this.state.query, (res) => {
            this.setState({ loading: false });

            if(res.data.results > 0) {
                this.setState({ results: res.data.data, showResults: true, searchType: res.data.search_type });
            } else {
                this.setState({ results: [], showResults: false });
                return message.info('No results!');
            }
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false, results: [], showResults: false });
                displayErrors(err.response.data);
            }
        });
    }

    onFocus() {
        if(!this.state.showResults && this.state.results.length > 0) {
            this.setState({ showResults: true });
        }
    }

    onBlur() {
        this.setState({ showResults: false });
    }

    onSearchQueryChange(value) {
        let data = {
            query: value,
            showResults: false,
            results: []
        }

        if(value.match(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)) {
            data['searchType'] = 'IP_ADDRESS';
            this.setState({ searchType: 'IP_ADDRESS'});
        } else {
            data['searchType'] = 'DOMAIN';
            this.setState({ searchType: 'DOMAIN'});
        }

        this.setState(data);
    }

    render() {
        let searchResults = '';

        if(this.state.results.length > 0) {
            searchResults = <List dataSource={this.state.results} size='small' renderItem={item => {
                let description;

                if(this.state.searchType === 'IP_ADDRESS') {
                    let columns = [
                        { title: 'Domain', dataIndex: 'domain' },
                        { title: 'Group', render: (item, record) => <Link onClick={() => this.setState({ showSearchDrawer: false })} to={'/domains/' + record.group.guid}>{record.group.name}</Link>}
                    ];

                    description = <Descriptions bordered size='small' column={1}>
                        <Descriptions.Item label='IP address group'>
                            <Link onClick={() => this.setState({ showSearchDrawer: false })} to={'/ip-addresses/' + item.ip_address.group.guid}>{item.ip_address.group.name}</Link>
                        </Descriptions.Item>
                        <Descriptions.Item label='Domains'>
                            <Table dataSource={item.domains} columns={columns} bordered rowKey={item => item.domain} size='small' pagination={false} />
                        </Descriptions.Item>
                    </Descriptions>;
                } else {
                    description = <Descriptions bordered size='small' column={1}>
                        <Descriptions.Item style={{width: '40%'}} label='Domain'>{item.domain.domain}</Descriptions.Item>
                        <Descriptions.Item label='IP address'>{item.ip_address.ip_address}</Descriptions.Item>
                        <Descriptions.Item label='Domain group'>
                            <Link onClick={() => this.setState({ showSearchDrawer: false })} to={'/domains/' + item.domain.group.guid}>{item.domain.group.name}</Link>
                        </Descriptions.Item>
                        <Descriptions.Item label='IP address group'>
                            <Link onClick={() => this.setState({ showSearchDrawer: false })} to={'/ip-addresses/' + item.ip_address.group.guid}>{item.ip_address.group.name}</Link>
                        </Descriptions.Item>
                    </Descriptions>;
                }

                return <List.Item>
                    <List.Item.Meta description={description} />
                </List.Item>;
            }}  />;
        }

        return(
            <Fragment>
                <Drawer open={this.state.showSearchDrawer} placement='top' title='Search for domains or IP addresses'
                        onClose={() => this.setState({ showSearchDrawer: false })}
                        afterOpenChange={(isVisible) => isVisible ? this.inputFocus.setFocus() : false }>
                    <div style={{margin: '0 auto', width: '100%'}}>
                        <Row>
                            <Col span={24}>
                                <Input autoFocus
                                    placeholder="Search..."  bordered={false}
                                    style={{borderBottom: '1px solid #ccc'}} size='large'
                                    onFocus={() => this.onFocus()}
                                    onFocusCapture={() => this.onFocus()}
                                    onBlur={() => this.onBlur()} ref={this.inputFocus.ref}
                                    onChange={(e) => this.onSearchQueryChange(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' ? this.runSearch() : null} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>{searchResults}</Col>
                        </Row>
                    </div>
                </Drawer>
                <Input placeholder='Search...' readOnly style={{cursor: 'pointer'}} onClick={() => this.setState({ showSearchDrawer: true })} />
            </Fragment>
        );
    }
}

export default connect(null, { performSearch })(GlobalSearch);