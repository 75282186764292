export const hasPermission = (permissions, scopes) => {
    for(let i = 0; i < scopes.length; i++) {
        if(permissions.indexOf(scopes[i]) === -1) {
            return false;
        }
    }

    return true;

    //return scopes.every(scope => permissions.includes(scope));
};