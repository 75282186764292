import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, message, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {cancelCancellationRequest, loadCancellationRequests} from "../../actions/billing";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import CancellationRequestDetailModal from "./CancellationRequestDetailModal";
import {StatusIndicator} from "../shared/Status";
import AccessGate from "../permissions/AccessGate";
import {hasPermission} from "../permissions/PermissionUtils";

class CancellationRequestsTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        cancellationRequests: [],
        loadingCancellationRequests: true,
        showCancellationRequestDetailModal: false,
        selectedCancellationRequest: ''
    }

    componentDidMount() {
        this.props.updatePage('Cancellation Requests');
        this.loadCancellationRequests();
    }

    loadCancellationRequests(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingCancellationRequests: true });

        this.props.loadCancellationRequests(page, per_page, (res) => {
            this.setState({ cancellationRequests: res.data.data, loadingCancellationRequests: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCancellationRequests: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(pagination, filters) {
        let filter = [];

        if(filters.status !== null) {
            filter = filters.status;
        }

        this.setState({ filter: filter }, () => {
            this.loadCancellationRequests(pagination.current, pagination.pageSize);
        });
    }

    cancelRequest(guid) {
        this.setState({ loadingCancellationRequests: true });

        this.props.cancelCancellationRequest(guid, () => {
            this.loadCancellationRequests();
            message.success('Cancellation request successfully cancelled!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCancellationRequests: false });
                displayErrors(err.response.data);
            }
        });
    }

    viewRequestDetails(guid) {
        this.setState({ selectedCancellationRequest: guid }, () => {
            this.setState({ showCancellationRequestDetailModal: true });
        });
    }

    render() {
        const columns = [
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator el='badge' status={item} /> },
            { title: 'Cancellation time', dataIndex: 'cancellation_time', render: (item) => normalizeEnum(item) },
            { title: 'Date updated', dataIndex: 'date_updated', render: (item) => item !== null ? <DateTimeFormat value={item} /> : '-'},
            { title: 'IP Addresses', dataIndex: 'ip_address_count', align: 'center' },
        ];

        if(hasPermission(this.props.company.permissions, ['can_edit_cancellation_requests'])) {
            columns.push({ title: '', align: 'right', render: (item, record) => {
                    return <Space>
                        {record.status === 'PENDING' && record.can_cancel ? <Popconfirm
                            title="Are you sure you want to cancel this request?"
                            onConfirm={() => this.cancelRequest(record.guid)}
                            okText='Cancel'
                            placement='topRight'
                            okButtonProps={{danger: true}}
                            disabled={record.status !== 'PENDING'}
                            cancelText='Cancel'>
                            <Button danger size='small' disabled={record.status !== 'PENDING'} icon={<DeleteOutlined />} type='primary'>Cancel</Button>
                        </Popconfirm>: ''}
                        <Button size='small' icon={<EyeOutlined />} onClick={() => this.viewRequestDetails(record.guid)}>View</Button>
                    </Space>;
                }});
        }

        return(
            <AccessGate scopes={['can_view_cancellation_requests']}>
                <Row justify='end' style={{paddingBottom: '10px'}}>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadCancellationRequests()}/></Tooltip></Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.cancellationRequests}
                    loading={this.state.loadingCancellationRequests}
                    pagination={this.state.pagination}
                    onChange={(pagination, filters, sorter, extra) => this.changePage(pagination, filters, sorter, extra)}
                />

                <CancellationRequestDetailModal
                    showModal={this.state.showCancellationRequestDetailModal}
                    cancellationRequest={this.state.selectedCancellationRequest}
                    close={() => this.setState({ showCancellationRequestDetailModal: false, selectedCancellationRequest: ''})}
                />
            </AccessGate>
        )
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadCancellationRequests,
    cancelCancellationRequest })(CancellationRequestsTable);