import {httpDelete, httpGet, httpPatch, httpPost} from "../libs/http";

export const loadHostingAccounts = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/hosting/accounts/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadHostingAccount = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/hosting/accounts/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadSharedHostingPlans = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/shared-hosting/plans/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadSharedHostingLocations = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/shared-hosting/locations/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const addCustomHost = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/hosting/custom_hosts/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const addSharedHosting = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/billing/orders/shared_hosting/create/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const getSharedHostingPassword = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/hosting/shared_hosting/' + guid + '/password/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loginTocPanel = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/hosting/shared_hosting/' + guid + '/cpanel/login/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadHostingAccountDomains = (guid, page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/hosting/accounts/' + guid + '/domains/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const changeCpanelPassword = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/hosting/shared_hosting/' + guid + '/change_password/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateHostingAccountField = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/hosting/accounts/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteHostingAccount = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/hosting/accounts/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadSharedHostingOverdueNotices = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/hosting/suspended-notice/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadSharedHostingBillingCycles = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/orders/shared_hosting/options/', (res) => onSuccess(res), (err) => onFailure(err));
};