import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Alert, Card, Tabs} from "antd";
import AffiliateOverview from "../../components/affiliate/AffiliateOverview";
import AffiliateReports from "../../components/affiliate/AffiliateReports";
import AffiliateBilling from "../../components/affiliate/AffiliateBilling";
import {checkAffiliateExists} from "../../actions/affiliate";
import AffiliateLinks from "../../components/affiliate/AffiliateLinks";

class Affiliate extends React.Component {
    state = {
        accountExists: false,
        activeTab: 'overview',
        pages: ['overview', 'reports', 'billing', 'links'],
        titles: {
            overview: 'Overview',
            reports: 'Reports',
            billing: 'Billing',
            links: 'Links',
        },
        errors: []
    }

    componentDidMount() {
        this.props.updatePage('Affiliate');

        this.props.checkAffiliateExists(() => {
            this.setState({ accountExists: true, errors: [] });
        }, (err) => {
            if(err.response.status === 404) {
                this.setState({ accountExists: false }, () => this.props.history.push('/affiliate/create'));
            } else {
                this.setState({ accountExists: true, errors: err.response.data.errors });
            }
        });

        let page = 'overview';

        if(typeof this.props.match.params.page !== 'undefined') {
            page = this.props.match.params.page;
        }

        this.setState({ activeTab: page })
    }

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            this.setState({ activeTab: page });

            this.props.updatePage(this.state.titles[page]);

            let url = '/affiliate';

            if(page !== 'overview') {
                url = '/affiliate/' + page;
            }

            this.props.history.push(url);
        }
    }

    render() {
        if(!this.state.accountExists) {
            return <></>;
        }

        if(this.state.errors.length > 0) {
            return <Card size='small'>
                {this.state.errors.map((error, id) => <Alert key={id} message={error} type='error' />)}
            </Card>
        }

        const items = [
            { label: 'Overview', key: 'overview', children: <AffiliateOverview /> },
            { label: 'Links', key: 'links', children: <AffiliateLinks /> },
            { label: 'Reports', key: 'reports', children: <AffiliateReports /> },
            { label: 'Billing', key: 'billing', children: <AffiliateBilling /> },
        ];

        return <Card size='small'>
            <Tabs activeKey={this.state.activeTab} onChange={(tab) => this.changeTab(tab)} destroyInactiveTabPane={true} items={items} />
        </Card>;
    }
}

export default connect(null, { updatePage, checkAffiliateExists })(Affiliate);