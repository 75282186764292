import React from "react";
import {connect} from "react-redux";
import {loadDomainNotes, updateDomainNotes} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";
import {Button, Card, Input, message} from "antd";
import { SaveOutlined } from "@ant-design/icons";

class DomainNotes extends React.Component {
    state = {
        loading: false,
        saving: false,
        notes: ''
    };

    componentDidMount() {
        this.loadDomainNotes();
    }

    loadDomainNotes() {
        this.setState({ loading: true });

        this.props.loadDomainNotes(this.props.guid, (res) => {
            this.setState({ loading: false, notes: res.data.notes ? res.data.notes : '' });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    updateDomainNotes() {
        this.setState({ saving: true });

        let notes = this.state.notes.trim();

        this.props.updateDomainNotes(this.props.guid, { notes }, () => {
            this.setState({ saving: false });
            message.success('Notes successfully updated!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ saving: false });
            }
        });
    }

    render() {
        const { TextArea } = Input;

        return <Card bordered={false} size='small' title='Domain notes'
                     extra={<Button size='small' type='primary' loading={this.state.saving}
                                    onClick={() => this.updateDomainNotes()}
                                    icon={<SaveOutlined />} disabled={this.state.saving}>Save</Button>}>
            <TextArea bordered={false} rows={10} value={this.state.notes} disabled={this.state.loading}
                      onChange={(e) => this.setState({ notes: e.target.value })}
                      placeholder='Enter your notes here!'  maxLength={1000} showCount />
        </Card>;
    }
}

export default connect(null, { loadDomainNotes, updateDomainNotes })(DomainNotes);