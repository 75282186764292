import axios from 'axios';
import {API_HOST} from "../config";

function getConfig() {
    const token = localStorage.getItem('token');
    const company = localStorage.getItem('company');

    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };

    if(token) {
        config.headers['Authorization'] = 'Token ' + token;
    }

    if(company) {
        config.headers['Company'] = company;
    }

    return config;
}

function getUrl(url) {
    return window.location.protocol + '//' + API_HOST + url;
}

function redirectToLogin(status) {
    if(typeof status !== 'undefined') {
        let loginPage = '/auth/login';

        if(status === 401 && window.location.pathname.substring(0, loginPage.length) !== loginPage) {
            window.location.href = loginPage;
        }
    }
}

export const httpGetDownload = (url, onSuccess, onFailure) => {
    let config = getConfig();
    config['responseType'] = 'blob';

    axios.get(getUrl(url), config)
         .then(res => {
             redirectToLogin(res.status);

             const url = window.URL.createObjectURL(new Blob([res.data]));
             const link = document.createElement('a');
             link.href = url;
             link.download = res.headers['x-suggested-filename'];
             link.click();

         })
         .catch(err => {
             if(typeof err.response !== 'undefined') {
                 redirectToLogin(err.response);
             }
             onFailure(err);
         });
};


export const httpGet = (url, onSuccess, onFailure) => {
    axios.get(getUrl(url), getConfig())
         .then(res => {
             redirectToLogin(res);
             onSuccess(res);
         })
         .catch(err => {
             if(typeof err.response !== 'undefined') {
                 redirectToLogin(err.response);
             }
             onFailure(err);
         });
};

export const httpGetExternal = (url, onSuccess, onFailure) => {
    axios.get(url)
         .then(res => {
             onSuccess(res);
         })
         .catch(err => {
             onFailure(err);
         });
};

export const httpPost = (url, data, onSuccess, onFailure) => {
    axios.post(getUrl(url), JSON.stringify(data), getConfig())
         .then(res => {
             redirectToLogin(res);
             onSuccess(res);
         })
         .catch(err => {
             if(typeof err.response !== 'undefined') {
                 redirectToLogin(err.response);
             }
             onFailure(err);
         });
};

export const httpPut = (url, data, onSuccess, onFailure) => {
    axios.put(getUrl(url), JSON.stringify(data), getConfig())
         .then(res => {
             redirectToLogin(res);
             onSuccess(res);
         })
         .catch(err => {
             if(typeof err.response !== 'undefined') {
                 redirectToLogin(err.response);
             }
             onFailure(err);
         });
};

export const httpDelete = (url, onSuccess, onFailure) => {
    axios.delete(getUrl(url), getConfig())
         .then(res => {
             redirectToLogin(res);
             onSuccess(res);
         })
         .catch(err => {
             if(typeof err.response !== 'undefined') {
                 redirectToLogin(err.response);
             }
             onFailure(err);
         });
};

export const httpPatch = (url, data, onSuccess, onFailure) => {
    axios.patch(getUrl(url), JSON.stringify(data), getConfig())
         .then(res => {
             redirectToLogin(res);
             onSuccess(res);
         })
         .catch(err => {
             if(typeof err.response !== 'undefined') {
                 redirectToLogin(err.response);
             }
             onFailure(err);
         });
};