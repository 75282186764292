import React from "react";
import {Col, Input, Row, Select} from "antd";

class FirewallRuleCondition extends React.Component {
    state = {
        field: 'http.request.uri',
        name: '',
        operator: 'EQUALS',
        value: ''
    };

    fields = [
        { value: 'http.host', label: 'Host' },
        { value: 'http.request.uri', label: 'URI path' },
        { value: 'http.request.uri.query', label: 'Query string' },
        { value: 'http.request.method', label: 'Request method' },
        { value: 'http.request.referer', label: 'Referer' },
        { value: 'http.request.user_agent', label: 'User agent' },
        { value: 'http.request.cookie', label: 'HTTP cookie' },
        //{ value: 'http.request.header', label: 'HTTP header' },
        { value: 'http.request.x_forwarded_for', label: 'X-Forwarded-For' },
        { value: 'ip.src', label: 'IP address' },
        { value: 'ip.cidr', label: 'CIDR' },
        { value: 'ip.geoip.as', label: 'Autonomous Number' },
        { value: 'ip.geoip.country', label: 'Country' },
        { value: 'bots.is_bot', label: 'Is bot' },
        { value: 'bots.predefined_bot', label: 'Predefined bot' },
    ];

    operators = [
        { value: 'EQUALS', label: 'Equals' },
        { value: 'NOT_EQUALS', label: 'Does not equal' },
        { value: 'CONTAINS', label: 'Contains' },
        { value: 'NOT_CONTAINS', label: 'Does not contain' },
        { value: 'STARTS_WITH', label: 'Starts with' },
        { value: 'ENDS_WITH', label: 'Ends with' },
        { value: 'REGEX', label: 'Regex' },
    ];

    http_methods = [
        { value: 'GET', label: 'GET' },
        { value: 'HEAD', label: 'HEAD' },
        { value: 'POST', label: 'POST' },
        { value: 'PUT', label: 'PUT' },
        { value: 'DELETE', label: 'DELETE' },
        { value: 'OPTIONS', label: 'OPTIONS' },
        { value: 'PATCH', label: 'PATCH' },
    ]

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidMount() {
        this.setState({...this.props.condition});
    }

    updateField(name, value) {
        let data = {
            [name]: value
        };

        if(name === 'field') {
            let listFields = ['http.request.method', 'ip.geoip.country', 'bots.predefined_bot', 'bots.is_bot', 'ip.src', 'ip.cidr', 'http.request.header'];

            if(listFields.indexOf(value) !== -1) {
                data['value'] = [];

                this.operators = [
                    { value: 'EQUALS', label: 'Equals' },
                    { value: 'NOT_EQUALS', label: 'Does not equal' },
                    { value: 'CONTAINS', label: 'Contains', disabled: true },
                    { value: 'NOT_CONTAINS', label: 'Does not contain', disabled: true },
                    { value: 'STARTS_WITH', label: 'Starts with', disabled: true },
                    { value: 'ENDS_WITH', label: 'Ends with', disabled: true },
                    { value: 'REGEX', label: 'Regex', disabled: true },
                ];
            } else {
                data['value'] = '';

                this.operators = [
                    { value: 'EQUALS', label: 'Equals' },
                    { value: 'NOT_EQUALS', label: 'Does not equal' },
                    { value: 'CONTAINS', label: 'Contains', disabled: false },
                    { value: 'NOT_CONTAINS', label: 'Does not contain', disabled: false },
                    { value: 'STARTS_WITH', label: 'Starts with', disabled: false },
                    { value: 'ENDS_WITH', label: 'Ends with', disabled: false },
                    { value: 'REGEX', label: 'Regex', disabled: false },
                ];
            }
        }

        this.setState(data, () => {
            this.props.onChange({ field: this.state.field, name: this.state.name, operator: this.state.operator, value: this.state.value });
        });
    }

    render() {
        let valueField = '';
        let conditionField = '';
        let conditionWidth = 5;
        let valueWidth = 14;

        const { Option } = Select;

        if(this.state.field === 'http.request.method') {
            valueField = <Select options={this.http_methods} value={this.state.value} allowClear mode='multiple' onChange={(value) => this.updateField('value', value)} />;
        } else if(this.state.field === 'ip.geoip.country') {
            valueField = <Select allowClear mode='multiple'  value={this.state.value} onChange={(values) => this.updateField('value', values)}>
                {this.props.countries.map((key) => {
                    return <Option key={key.value} value={key.value}>{key.label}</Option>;
                })}
            </Select>;
        } else if(this.state.field === 'bots.is_bot') {
            valueField = <Select value={this.state.value} onChange={(values) => this.updateField('value', values)}>
                <Option value='TRUE'>True</Option>
                <Option value='FALSE'>False</Option>
            </Select>;
        } else if(this.state.field === 'bots.predefined_bot') {
            valueField = <Select allowClear mode='multiple' options={this.props.predefinedBots} value={this.state.value} onChange={(values) => this.updateField('value', values)} />;
        } else if(this.state.field === 'ip.src' || this.state.field === 'ip.cidr') {
            valueField = <Select allowClear mode='tags' open={false} value={this.state.value} onChange={(values) => this.updateField('value', values)} />;
        } else {
            valueField = <Input value={this.state.value} onChange={(e) => this.updateField('value', e.target.value)} />;
        }

        if(this.state.field === 'http.request.header') {
            conditionWidth = 7;
            valueWidth = 12;

            conditionField = <Row gutter={[16, 16]}>
                <Col span={14}>
                    <Input style={{width: '100%'}} placeholder='Header name, eg: content-type' value={this.state.name} onChange={(e) => this.updateField('name', e.target.value)} />
                </Col>
                <Col span={10}>
                    <Select style={{width: '100%'}} options={this.operators} value={this.state.operator} onChange={(value) => this.updateField('operator', value)} />
                </Col>
            </Row>;
        } else {
            conditionWidth = 5;
            valueWidth = 14;

            conditionField = <Select options={this.operators} value={this.state.operator} onChange={(value) => this.updateField('operator', value)} />;
        }

        return(
            <Row gutter={[16, 16]}>
               <Col span={5}>
                   <Select options={this.fields} value={this.state.field} onChange={(value) => this.updateField('field', value)} />
               </Col>
                <Col span={conditionWidth}>
                    {conditionField}
                </Col>
                <Col span={valueWidth}>
                    {valueField}
                </Col>
            </Row>
        );
    }
}

export default FirewallRuleCondition;