import React, {Fragment} from "react";
import {connect} from "react-redux";
import {
    Input,
    Space,
    Popover,
    Descriptions,
    InputNumber,
    Table,
    Button,
    Select,
    Modal,
    Radio,
    message, Tooltip, Row, Col, Tag, Form
} from "antd";
import {
    createIPAddressOrder,
    loadAvailableIPAddresses,
    loadIPAddressBillingCycles,
    loadIPAddressGroups
} from "../../actions/ip_addresses";
import {displayErrors, isNumeric, normalizeEnum} from "../../libs/utils";
import {MoneyField} from "../shared/MoneyField";
import QuestionCircleOutlined from "@ant-design/icons/lib/icons/QuestionCircleOutlined";
import Flag from "react-world-flags";
import {DEFAULT_FLAG_SIZE} from "../../config";
import {Loading} from "../../libs/loading";
import AccessItem from "../permissions/AccessItem";
import {FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons";

class NewIPAddressesWizard extends React.Component {
    state = {
        page: 1,
        ipType: 'DEDICATED',
        ipFilter: 'C',
        maxSites: 10,
        loadingAvailableIPAddresses: false,
        data: [],
        selectedIPAddresses: [],
        ipGroups: [],
        excludedIPGroups: [],
        ipCount: 0,
        cityCount: 0,
        countryCount: 0,
        billingCycle: 'ANNUALLY',
        coupon: '',
        creatingOrder: false,
        loadingIPGroups: false,
        ipGroupsLoaded: false,
        loadingBillingCycles: false,
        availableBillingCycles: [],
        expandedIPAddressRows: [],
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.show && !this.state.loadingIPGroups && !this.state.ipGroupsLoaded) {
            this.setState({ excludedIPGroups: [this.props.group] });

            this.loadIPAddressGroups();
            this.loadBillingCycles();
        }
    }

    loadIPAddressGroups() {
        this.setState({ loadingIPGroups: true });

        this.props.loadIPAddressGroups(1, 99999, null, (res) => {
            let ipGroups = [];

            for(let i = 0; i < res.data.data.length; i++) {
                ipGroups.push({ guid: res.data.data[i].guid, name: res.data.data[i].name});
            }

            this.setState({ ipGroups: ipGroups, loadingIPGroups: false, ipGroupsLoaded: true });
        }, (err) => {
            this.setState({ loadingIPGroups: false, ipGroupsLoaded: true });
            displayErrors(err.response.data);
        });
    }

    loadBillingCycles() {
        this.setState({ loadingBillingCycles: true });

        this.props.loadIPAddressBillingCycles((res) => {
            this.setState({ loadingBillingCycles: false, availableBillingCycles: res.data });
        }, (err) => {
            this.setState({ loadingBillingCycles: false });
            displayErrors(err.response.data);
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal() {
        this.setState({
            page: 1,
            ipType: 'DEDICATED',
            ipFilter: 'C',
            data: [],
            selectedIPAddresses: [],
            excludedIPGroups: [],
            loadingIPGroups: false,
            ipGroupsLoaded: false
        });

        this.props.close();
    }

    loadAvailableIPAddresses() {
        this.setState({ loadingAvailableIPAddresses: true });

        this.props.loadAvailableIPAddresses(this.state.ipType, this.state.ipFilter, this.state.excludedIPGroups, this.props.user.user.company.currency, (res) => {
            let countryCount = 0;
            let cityCount = 0;
            let ipCount = 0;

            for(let i = 0; i < res.data.length; i++) {
                countryCount++;

                let cities = [];

                for(let j = 0; j < res.data[i].locations.length; j++) {
                    if(cities.indexOf(res.data[i].locations[j].city) === -1) {
                        cities.push(res.data[i].locations[j].city);
                        cityCount++;
                    }

                    ipCount += res.data[i].locations[j].ip_address_count;
                }
            }

            if(ipCount === 0) {
                message.warning('No available IP addresses found! Please change your targeting preferences!', 5);
                this.setState({ page: 1 });
            }
            else
            {
                this.setState({ loadingAvailableIPAddresses: false, data: res.data, ipCount: ipCount, cityCount: cityCount, countryCount: countryCount });
            }

        }, (err) => {
            this.setState({ loadingAvailableIPAddresses: false, page: 1 });
            displayErrors(err.response.data);
        });
    }

    calculateIPAddressCount(data) {
        let amount = 0;

        for(let i = 0; i < data.locations.length; i++) {
            amount += data.locations[i].ip_address_count;
        }

        return amount + ' IP addresses';
    }

    updateItemCountValue(record, count, field) {
        // TODO: Get account limits

        if(field === 'site_count' && !isNumeric(count)) {
            return message.error('Site count must be a number!');
        }

        if(field === 'ip_address_count' && !isNumeric(count)) {
            return message.error('IP address count must be a number!');
        }

        if(field === 'site_count' && count > 10) {
            return message.error('Site count cannot be larger than 10!');
        }

        if(field === 'ip_address_count' && count > record.ip_address_count) {
            return message.error('IP address count cannot be larger than ' + record.ip_address_count + '!');
        }

        if(field === 'ip_address_count' && count === 0)
        {
            let newData = this.state.selectedIPAddresses.filter((data) => data.location !== record.location && data.id !== record.id);
            this.setState({ selectedIPAddresses: newData });
        }
        else
        {
            let item = null;

            for(let i = 0; i < this.state.selectedIPAddresses.length; i++) {
                if(this.state.selectedIPAddresses[i].location === record.location && this.state.selectedIPAddresses[i].id === record.id) {
                    item = this.state.selectedIPAddresses[i];
                    break;
                }
            }

            if(item === null) {
                item = {...record, ip_address_count: 0, site_count: 1}
            }

            if(field === 'site_count' && count === 0) {
                count = 1;
            }

            item[field] = count;

            if(field === 'site_count' && item.ip_address_count === 0)
            {
                let newData = this.state.selectedIPAddresses.filter((data) => data.location !== record.location && data.id !== record.id);
                return this.setState({ selectedIPAddresses: newData });
            }

            let newData = this.state.selectedIPAddresses.map((ip) => {
                if(ip.location === record.location && ip.id === record.id) {
                    return item;
                }

                return ip;
            });

            let itemFound = false;
            for(let i = 0; i < newData.length; i++) {
                if(newData[i].location === record.location && newData[i].id === record.id) {
                    itemFound = true;
                    break;
                }
            }

            if(!itemFound) {
                newData.push(item);
            }

            this.setState({ selectedIPAddresses: newData });
        }
    }

    getItemCount(record, field) {
        let value = 0;

        for(let i = 0; i < this.state.selectedIPAddresses.length; i++) {
            if(this.state.selectedIPAddresses[i].location === record.location && this.state.selectedIPAddresses[i].id === record.id) {
                return this.state.selectedIPAddresses[i][field];
            }
        }

        return value;
    }

    placeOrder() {
        let locations = this.state.selectedIPAddresses.map((data) => {
            return {
                pid: data.id,
                location: data.location,
                ip_address_count: data.ip_address_count,
                site_count: data.site_count
            }
        });

        if(locations.length === 0) {
            return message.error('Please choose at least 1 location!');
        }

        let coupon = this.state.coupon.trim();

        let data = {
            group: this.props.group,
            billing_cycle: this.state.billingCycle,
            locations: locations,
            ip_address_filter: this.state.ipFilter,
            ip_address_type: this.state.ipType,
            excluded_groups: this.state.excludedIPGroups,
            coupon: coupon.length !== 0 ? coupon : null
        };

        this.setState({ creatingOrder: true });

        this.props.createIPAddressOrder(data, (res) => {
            message.success('Order successfully placed, redirecting to invoice...', 5);
            this.props.history.push('/billing/invoices/' + res.data.invoice_id);
            this.setState({ creatingOrder: false });
        }, (err) => {
            this.setState({ creatingOrder: false });
            displayErrors(err.response.data);
        });
    }

    chooseIPAddressFilter(value) {
        if(value === 'NONE') {
            const { confirm } = Modal;

            confirm({
                centered: true,
                okText: 'Yes, I am sure!',
                okType: 'danger',
                icon: false,
                title: <strong>Are you sure?</strong>,
                content: <div>
                    <p>Disabling filtering means that you will receive random IP addresses <u><b>from any subnet</b></u>.
                        You may receive IP addresses from subnets <u><b>that already exist in your IP address group(s)</b></u>.</p>
                    <p>Proceed at your caution!</p>
                </div>,
                onOk: () => {
                    this.setState({ ipFilter: value });
                }
            });
        } else {
            this.setState({ ipFilter: value });
        }
    }

    updateExpandedIPAddressRows(expanded, data) {
        let newExpandedIPAddressRows = this.state.expandedIPAddressRows;

        if(expanded) {
            newExpandedIPAddressRows.push(data.country_code);
        } else {
            newExpandedIPAddressRows = newExpandedIPAddressRows.filter(row => row !== data.country_code);
        }

        this.setState({ expandedIPAddressRows: newExpandedIPAddressRows });
    }

    expandAllIPAddressRows() {
        let newExpandedIPAddressRows = this.state.data.map(data => data.country_code);
        this.setState({ expandedIPAddressRows: newExpandedIPAddressRows });
    }

    closeAllExpandedIPAddressRows() {
        this.setState({ expandedIPAddressRows: [] });
    }

    render() {
        if(this.state.loadingIPGroups) {
            return <div className='text-center'><Loading /></div>;
        }

        let content = '';
        let footerButtons = '';

        let { Option } = Select;

        if(this.state.page === 1)
        {
            content = <Fragment>
                <p>Please select filters for IP addresses that you wish to order. Click on question mark icon for more information.</p>
                <Descriptions bordered size='small' column={1}>
                    <Descriptions.Item label='IP address type:'>
                        <Radio.Group defaultValue={this.state.ipType} onChange={(e) => this.setState({ ipType: e.target.value, maxSites: (e.target.value === 'DEDICATED' ? 10 : 1) })}>
                            <Radio value='DEDICATED' style={{display: 'block'}}>
                                <Space>
                                    <span>Dedicated IP addresses</span>
                                    <Popover content={<Fragment>IP address where only your domains will be hosted.<br />These should be used for any sized network.</Fragment>} title="Dedicated IP address">
                                        <QuestionCircleOutlined />
                                    </Popover>
                                </Space>
                            </Radio>
                            <Radio value='SHARED' style={{display: 'block'}}>
                                <Space>
                                    <span>Shared IP addresses</span>
                                    <Popover content={<Fragment>IP address where you share the IP address with other domains.<br />These should be used when building a tier 2+ network.<br /><span style={{color: 'red', textDecoration: 'underline'}}>Shared IP addresses are not recommended for your main PBN.</span></Fragment>} title="Shared IP address">
                                        <QuestionCircleOutlined />
                                    </Popover>
                                </Space>
                            </Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label='IP address subnet filter:'>
                        <Radio.Group value={this.state.ipFilter} onChange={(e) => this.chooseIPAddressFilter(e.target.value)}>
                            <Radio value='NONE' style={{display: 'block'}}>No filter</Radio>
                            <Radio value='C' style={{display: 'block'}}>"C" classes</Radio>
                            <Radio value='B' style={{display: 'block'}}>"B" classes</Radio>
                            <Radio value='A' style={{display: 'block'}}>"A" classes</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item style={{width: '50%'}} label='Exclude subnets in IP groups:'>
                        <Select defaultValue={this.state.excludedIPGroups} mode='multiple'
                                dropdownStyle={{width: '100%'}} style={{width: '100%'}}
                                onChange={(value) => this.setState({ excludedIPGroups: value })}>
                            {this.state.ipGroups.map((item) => {
                                return <Option key={item.guid} value={item.guid}>{item.name}</Option>
                            })}
                        </Select>
                    </Descriptions.Item>
                </Descriptions>
            </Fragment>;

            footerButtons = <Fragment>
                <Button type='default' onClick={() => this.closeModal()}>Cancel</Button>
                <Button type='primary' onClick={() => this.setState({ page: 2 }, () => this.loadAvailableIPAddresses())}>Next</Button>
            </Fragment>;
        }
        else if(this.state.page === 2)
        {
            const columns = [
                { title: 'Location', render: (record) => <Fragment><Flag code={record.country_code} height={DEFAULT_FLAG_SIZE} /> {record.country_name}</Fragment> },
                { title: 'Available', render: (record) => this.calculateIPAddressCount(record) },
            ];

            const subTableColumns = [
                { title: 'City', dataIndex: 'city' },
                { title: 'Available', dataIndex: 'ip_address_count', align: 'center', width: '15%' },
                { title: 'Price', dataIndex: 'price', align: 'center', width: '15%', render: (record, item) => {
                        if(item.currency === 'EUR') {
                            return <Tooltip title={<span>The base rate is in USD. The conversion rate is updated daily. Fine final conversion rate is displayed on an invoice.</span>}>≈ <MoneyField amount={item.price} currency={item.currency} /></Tooltip>;
                        } else {
                            return <MoneyField amount={item.price} currency={item.currency} />;
                        }
                }},
                { title: 'IP count', align: 'center', width: '15%', render: (record) => <InputNumber min={0} max={record.ip_address_count} value={this.getItemCount(record, 'ip_address_count')} onChange={(value) => this.updateItemCountValue(record, value, 'ip_address_count')} />},
                { title: 'Site count', align: 'center', width: '15%', render: (record) => <InputNumber disabled={this.state.ipType === 'SHARED'} min={0} max={this.state.maxSites} value={this.getItemCount(record, 'site_count')} onChange={(value) => this.updateItemCountValue(record, value, 'site_count')} />}
            ];

            const summaryColumns = [
                { title: 'Location', render: (item, record) => <span><Flag code={record.country_code} height='12px' /> {record.country + ', ' + record.city}</span> },
                { title: 'IP count', align: 'center', dataIndex: 'ip_address_count' },
                { title: 'Extra sites', align: 'center', dataIndex: 'site_count', render: (item) => (item - 1) },
                { title: 'Total sites', align: 'center', render: (item, record) => (record.ip_address_count * record.site_count) },
                { title: 'Monthly total', align: 'center', render: (item, record) => <MoneyField amount={(record.ip_address_count * record.price) + (((record.site_count-1) * record.site_price) * record.ip_address_count)} currency={record.currency} />}
            ];

            content = <Fragment>
                <p>We found <b>{this.state.ipCount}</b> IP address{this.state.ipCount > 1 ? 'es' : 's'} from <b>{this.state.countryCount}</b> countr{this.state.countryCount > 1 ? 'ies' : 'y'} and <b>{this.state.cityCount}</b> cit{this.state.cityCount > 1 ? 'ies' : 'y'}. Below you can choose IP addresses and locations. Some cities may have IP addresses with different prices.</p>
                <div style={{marginBottom: '10px'}}>
                    <Space>
                        <Button size='small' icon={<FullscreenOutlined />} onClick={() => this.expandAllIPAddressRows()}>Expand All</Button>
                        <Button size='small' icon={<FullscreenExitOutlined />} onClick={() => this.closeAllExpandedIPAddressRows()}>Close All</Button>
                    </Space>
                </div>
                <Table
                    columns={columns}
                    dataSource={this.state.data}
                    loading={this.state.loadingAvailableIPAddresses}
                    expandable={{
                        expandRowByClick: true,
                        expandedRowKeys: this.state.expandedIPAddressRows,
                        expandedRowRender: record => <Table size='small'
                                                            pagination={false}
                                                            columns={subTableColumns}
                                                            dataSource={record.locations}
                                                            rowKey={(item) => item.hash} />,
                        rowExpandable: record => true,
                        onExpandedRowsChange: (records) => console.log(records),
                        onExpand: (expanded, record) => this.updateExpandedIPAddressRows(expanded, record)
                    }}
                    size='small'
                    rowKey={(item) => item.country_code}
                    pagination={false}
                />
                {this.state.selectedIPAddresses.length > 0 ? <Fragment>
                    <br/>
                    <Table
                        bordered
                        columns={summaryColumns}
                        dataSource={this.state.selectedIPAddresses}
                        rowKey={(item) => item.location + '-' + item.id}
                        pagination={false}
                        size='small'
                        summary={data => {
                            let totalSum = 0;
                            let currency = null;
                            let totalIPs = 0;
                            let totalSites = 0;
                            let totalWebsites = 0;

                            for (let i = 0; i < data.length; i++) {
                                if (currency === null) {
                                    currency = data[i].currency;
                                }

                                totalIPs += data[i].ip_address_count;
                                totalSites += (data[i].site_count - 1);
                                totalWebsites += data[i].ip_address_count * data[i].site_count;
                                totalSum += (data[i].ip_address_count * data[i].price) + (((data[i].site_count - 1) * data[i].site_price) * data[i].ip_address_count);
                            }

                            return <Fragment>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell/>
                                    <Table.Summary.Cell className='text-center'>{totalIPs}</Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-center'>{totalSites}</Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-center'>{totalWebsites}</Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-center'><MoneyField amount={totalSum}
                                                                                            currency={currency}/></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Fragment>
                        }}
                    />
                </Fragment>: ''}

                </Fragment>;

                    footerButtons = <Fragment>
                    <Button type='default' disabled={this.state.loadingAvailableIPAddresses}
                onClick={() => this.closeModal()}>Cancel</Button>
            <Button type='default' disabled={this.state.loadingAvailableIPAddresses}
                    onClick={() => this.setState({page: 1, selectedIPAddresses: []})}>Previous</Button>
            <Button type='primary'
                    disabled={this.state.selectedIPAddresses.length === 0 || this.state.loadingAvailableIPAddresses}
                    onClick={() => this.setState({page: 3})}>Next</Button>
        </Fragment>
            ;
        }
        else if(this.state.page === 3)
        {

            const columns = [
                {
                    title: 'Location',
                    render: (item, record) => <span><Flag code={record.country_code}
                                                          height='12px'/> {record.country + ', ' + record.city}</span>
                },
                {title: 'IP count', align: 'center', dataIndex: 'ip_address_count'},
                {title: 'Extra sites', align: 'center', dataIndex: 'site_count', render: (item) => (item - 1)},
                {
                    title: 'Total sites',
                    align: 'center',
                    render: (item, record) => (record.ip_address_count * record.site_count)
                },
                {
                    title: 'Monthly total',
                    align: 'center',
                    render: (item, record) => <MoneyField
                        amount={(record.ip_address_count * record.price) + (((record.site_count - 1) * record.site_price) * record.ip_address_count)}
                        currency={record.currency}/>
                }
            ];

            content = <Fragment>
                <p>Below you can see the overview of your order summary.</p>
                <Table
                    bordered
                    columns={columns}
                    dataSource={this.state.selectedIPAddresses}
                    rowKey={(item) => item.location + '-' + item.id}
                    pagination={false}
                    size='small'
                    summary={data => {
                        let totalSum = 0;
                        let currency = null;
                        let totalIPs = 0;
                        let totalSites = 0;
                        let totalWebsites = 0;

                        for(let i = 0; i < data.length; i++) {
                            if(currency === null) {
                                currency = data[i].currency;
                            }

                            totalIPs += data[i].ip_address_count;
                            totalSites += (data[i].site_count - 1);
                            totalWebsites += data[i].ip_address_count * data[i].site_count;
                            totalSum += (data[i].ip_address_count * data[i].price) + (((data[i].site_count-1) * data[i].site_price)*data[i].ip_address_count);
                        }

                        return <Fragment>
                            <Table.Summary.Row>
                                <Table.Summary.Cell/>
                                <Table.Summary.Cell className='text-center'>{totalIPs}</Table.Summary.Cell>
                                <Table.Summary.Cell className='text-center'>{totalSites}</Table.Summary.Cell>
                                <Table.Summary.Cell className='text-center'>{totalWebsites}</Table.Summary.Cell>
                                <Table.Summary.Cell className='text-center'><MoneyField amount={totalSum} currency={currency} /></Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Fragment>
                    }}
                />
                <br />
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form layout='vertical'>
                            <Form.Item label='Coupon' extra={this.state.coupon !== '' && this.state.billingCycle === 'ANNUALLY' ? <span style={{color: 'red'}}><em><small>Coupon will override the default discount!</small></em></span> : <span>&nbsp;</span>}>
                                <Input type='text' style={{display: 'block'}} value={this.state.coupon}
                                       onChange={(e) => this.setState({coupon: e.target.value})}/>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form layout='vertical'>
                            <Form.Item label='Billing cycle'>
                                <Select style={{width: '100%'}} defaultValue={this.state.billingCycle}
                                        disabled={this.state.loadingBillingCycles} loading={this.state.loadingBillingCycles}
                                        onChange={(value) => this.setState({billingCycle: value})}>
                                    {this.state.availableBillingCycles.map((cycle, index) => {
                                        let discount = '';

                                        if(cycle.discount !== null) {
                                            if(cycle.discount.discount_type === 'PERCENTAGE') {
                                                discount = <Tag color='green'>{parseFloat(cycle.discount.percentage_value, 2)}% off</Tag>
                                            }
                                        }

                                        return <Option key={index} value={cycle.name}>
                                            <Row justify='space-between'>
                                                <Col>{normalizeEnum(cycle.name)} ({cycle.value} month{cycle.value > 1 ? 's': ''})</Col>
                                                {this.state.coupon === '' ? <Col>{discount}</Col> : ''}
                                            </Row>
                                        </Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Fragment>;

            footerButtons = <Fragment>
                <Button type='default' disabled={this.state.creatingOrder}
                        onClick={() => this.closeModal()}>Cancel</Button>
                <Button type='default' disabled={this.state.creatingOrder} onClick={() => this.setState({ page: 2 })}>Previous</Button>
                <AccessItem scopes={['can_add_ip_addresses']}>
                    <Button type='primary' disabled={this.state.creatingOrder} loading={this.state.creatingOrder} onClick={() => this.placeOrder()}>Place Order</Button>
                </AccessItem>
            </Fragment>;
        }

        return(
            <Modal
                centered
                title='IP Address Wizard'
                open={this.props.show}
                footer={footerButtons}
                destroyOnClose={true}
                width={800}
                onCancel={() => this.closeModal()}>
                {content}
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});


export default connect(mapStateToProps, { loadAvailableIPAddresses, loadIPAddressGroups,
    createIPAddressOrder, loadIPAddressBillingCycles })(NewIPAddressesWizard);