import React from "react";
import {connect} from "react-redux";
import FirewallRules from "../../components/firewall/FirewallRules";
import {Card, Tabs} from "antd";
import FirewallPolicies from "../../components/firewall/FirewallPolicies";
import FirewallRulesets from "../../components/firewall/FirewallRulesets";
import AccessGate from "../../components/permissions/AccessGate";

class Firewall extends React.Component {
    state = {
        pages: ['rules', 'policies', 'rulesets']
    };

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            if(page === 'rules') {
                this.props.history.push('/firewall');
            } else {
                this.props.history.push('/firewall/' + page);
            }
        }
    }

    render() {
        let page = 'rules';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        const items = [
            { label: 'Rules', key: 'rules', children: <FirewallRules history={this.props.history} /> },
            { label: 'Policies', key: 'policies', children: <FirewallPolicies history={this.props.history} /> },
            { label: 'Rulesets', key: 'rulesets', children: <FirewallRulesets history={this.props.history} /> }
        ];

        return(
            <AccessGate scopes={['can_view_firewall_rules']}>
                <Card size='small'>
                    <Tabs defaultActiveKey={page} destroyInactiveTabPane={true} onChange={(e) => this.changeTab(e)} items={items} />
                </Card>
            </AccessGate>
        );
    }
}

export default connect(null, {} )(Firewall);