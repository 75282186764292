import React from 'react';
import { connect } from 'react-redux';
import {Alert, Form, message, Modal, Select} from "antd";
import {loadAllIPAddresses, loadAllIPSignatures} from "../../actions/ip_addresses";
import {updateDomainsIPAddress} from "../../actions/domains";

class IPAddressModifyModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: props.showModal,
            domain: props.domain,
            loadingIPAddresses: false,
            loadingIPSignatures: false,
            ipAddresses: [],
            selectedIPAddress: null,
            selectedIPSignature: null,
            signatures: [],
            updatingDomain: false,
            selectedHTTPVersion: props.httpVersion,
            ipAddressType: 'SHARED'
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.loadingIPAddresses && this.state.ipAddresses.length === 0) {
            this.loadIPAddresses();
            this.loadIPSignatures();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    loadIPAddresses() {
        this.setState({ loadingIPAddresses: true, ipAddressType: this.props.ipAddress.ip_address_type });

        this.props.loadAllIPAddresses((response) => {
            this.setState({ ipAddresses: response.data.data, loadingIPAddresses: false,
                selectedIPAddress: this.props.ipAddress.guid, selectedIPSignature: this.props.ipAddress.signature.guid });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPAddresses: false });
                message.error(err.response.error);
            }
        });
    }

    loadIPSignatures() {
        this.setState({ loadingIPSignatures: true });

        this.props.loadAllIPSignatures((response) => {
            this.setState({ signatures: response.data.data, loadingIPSignatures: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPSignatures: false });
                message.error(err.response.error);
            }
        });
    }

    updateDomain() {
        let data = {
            ip_address: this.state.selectedIPAddress,
            signature: this.state.selectedIPSignature
        };

        if(this.state.selectedHTTPVersion !== '') {
            data['http_version'] = this.state.selectedHTTPVersion;
        }

        this.setState({ updatingDomain: true });

        this.props.updateDomainsIPAddress(this.state.domain, data, () => {
            message.success('Domain\'s IP address successfully updated!');
            this.setState({ updatingDomain: false });

            this.onClose();

            this.props.loadOverview();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                message.error(err.response.error);
                this.setState({ updatingDomain: false });
            }
        });
    }

    updateIpAddressSignature(guid) {
        for(let i = 0; i < this.state.ipAddresses.length; i++) {
            if(this.state.ipAddresses[i].guid === guid) {
                this.setState({ selectedIPSignature: this.state.ipAddresses[i].signature.guid, selectedIPAddress: guid, ipAddressType: this.state.ipAddresses[i].ip_address_type });
                break;
            }
        }
    }

    onClose() {
        this.setState({
            selectedIPAddress: this.props.ipAddress.guid,
            selectedIPSignature: this.props.ipAddress.signature.guid,
            ipAddressType: this.props.ipAddress.ip_address_type,
            loadingIPAddresses: false,
            loadAllIPSignatures: false,
            ipAddresses: []
        });

        this.props.closeModal();
    }

    render() {
        const { Option } = Select;

        return (
            <Modal
                centered
                title='Modify IP Address'
                open={this.state.showModal}
                okText='Modify'
                onOk={() => this.updateDomain()}
                confirmLoading={this.state.updatingDomain}
                cancelButtonProps={{disabled: this.state.updatingDomain}}
                onCancel={() => this.onClose()}
                destroyOnClose={true}>

                <Form layout='vertical'>
                    {this.state.ipAddressType === 'SHARED' ? <Alert type='warning' showIcon={true} message='Cannot edit IP signature or HTTP version of shared IP addresses!' style={{marginBottom: '10px'}} /> : ''}
                    <Form.Item label='Select IP address'>
                        <Select
                            style={{width: '100%'}}
                            value={this.state.selectedIPAddress}
                            loading={this.state.loadingIPAddresses}
                            disabled={this.state.loadingIPAddresses}
                            onChange={(value) => this.updateIpAddressSignature(value)}>
                            {this.state.ipAddresses.map(ip_address => {
                                return <Option key={ip_address.guid} value={ip_address.guid} disabled={ip_address.domain_count >= ip_address.domains_allowed}>{ip_address.ip_address}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Select IP signature'>
                        <Select
                            style={{width: '100%'}}
                            value={this.state.selectedIPSignature}
                            loading={this.state.loadingIPSignatures}
                            disabled={this.state.loadingIPSignatures || this.state.ipAddressType === 'SHARED'}
                            showSearch
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            onChange={(value) => this.setState({ selectedIPSignature: value})}>
                            {this.state.signatures.map(signature => {
                                return <Option key={signature.guid} value={signature.guid}>{signature.signature}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='HTTP protocol'>
                        <Select
                            style={{width: '100%'}} defaultValue={this.props.ipAddress.http_version}
                            onChange={(value) => this.setState({ selectedHTTPVersion: value})}
                            disabled={this.state.ipAddressType === 'SHARED'}
                            value={this.state.selectedHTTPVersion}>
                            <Option value={1}>HTTP/1.1</Option>
                            <Option value={2}>HTTP/2</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { loadAllIPAddresses, loadAllIPSignatures, updateDomainsIPAddress })(IPAddressModifyModal);