import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Loading} from "../../libs/loading";
import {validateCompany} from "../../actions/account";
import {message} from "antd";
import {loadUser} from "../../actions/auth";
import {displayErrors} from "../../libs/utils";

class CompanySelector extends React.Component {
    componentDidMount() {
        this.props.updatePage('Please wait...');

        this.props.validateCompany(this.props.match.params.guid, (res) => {
            message.success('Company successfully seleted!', 1);
            localStorage.setItem('company', this.props.match.params.guid);
            this.props.loadUser();
            this.props.history.push('/');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.props.history.push('/');
            }
        });
    }

    render() {
        return(<div className='text-center'><Loading/></div>);
    }
}

export default connect(null, { loadUser, updatePage, validateCompany })(CompanySelector);