import React from "react";
import {connect} from "react-redux";
import {Descriptions, Form, Modal, Select, Tooltip} from "antd";
import {displayErrors} from "../../libs/utils";
import {loadSharedHostingPlans} from "../../actions/hosting";
import {MoneyField} from "../shared/MoneyField";

class ModifySharedHostingOrderModal extends React.Component {
    state = {
        activePackage: null,
        loadingHostingPlans: false,
        hostingPlansLoaded: false,
        selectedHostingPlan: null,
        hostingPlans: []
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.show && !this.state.loadingHostingPlans && !this.state.hostingPlansLoaded) {
            this.setState({ loadingHostingPlans: true, selectedHostingPlan: this.props.activePackage });

            this.props.loadSharedHostingPlans((res) => {
                this.setState({
                    loadingHostingPlans: false,
                    hostingPlansLoaded: true,
                    hostingPlans: res.data.data
                });

            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingHostingPlans: false, hostingPlansLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal() {
        this.setState({
        });

        this.props.close();
    }

    selectNewPlan(id) {
        for(let i = 0; i < this.state.hostingPlans.length; i++) {
            if(this.state.hostingPlans[i].guid === id) {
                this.setState({ activePackage: this.state.hostingPlans[i], selectedHostingPlan: id });
                break;
            }
        }
    }

    render() {
        const { Option } = Select;

        return <Modal
            open={this.props.show}
            destroyOnClose={true}
            onCancel={() => this.closeModal()}
            title='Modify Hosting Plan'>

            <Form layout='vertical'>
                <Form.Item label='Current hosting plan'>
                    <strong>{this.props.activePackage.name}</strong>
                </Form.Item>
                <Form.Item label='New hosting plan'>
                    <Select loading={this.state.loadingHostingPlans}
                            value={this.state.selectedHostingPlan}
                            disabled={this.state.loadingHostingPlans}
                            onChange={(value) => this.selectNewPlan(value)}>
                        {this.state.hostingPlans.map((plan) => {
                            return <Option key={plan.guid} value={plan.guid}>{plan.name}</Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item hidden={this.state.activePackage === null}>
                    {this.state.activePackage !== null ? <Descriptions column={1} bordered size='small'>
                        <Descriptions.Item label={<span>Package</span>}>{this.state.activePackage.name}</Descriptions.Item>
                        <Descriptions.Item label={<span>Monthly price</span>}>
                            {this.props.user.user.company.currency === 'EUR' ? <Tooltip title={'The base rate is $' + this.state.activePackage.price_usd + ' USD. The conversion rate is updated daily. The final conversion rate is displayed on an invoice.'}>
                                <span style={{borderBottom: '1px dotted #ccc', cursor: 'pointer'}}>≈ <MoneyField amount={this.state.activePackage.price_eur} currency='EUR' /></span>
                            </Tooltip> : <MoneyField amount={this.state.activePackage.price_usd} currency='USD' /> }

                        </Descriptions.Item>
                        <Descriptions.Item label={<span>Storage</span>}>{this.state.activePackage.storage}</Descriptions.Item>
                        <Descriptions.Item label={<span>Bandwidth</span>}>{this.state.activePackage.bandwidth}</Descriptions.Item>
                        <Descriptions.Item label={<span>Inodes</span>}>{this.state.activePackage.inodes}</Descriptions.Item>
                        <Descriptions.Item label={<span>RAM</span>}>{this.state.activePackage.memory}</Descriptions.Item>
                        <Descriptions.Item label={<span>CPU</span>}>{this.state.activePackage.cpu}</Descriptions.Item>
                        <Descriptions.Item label={<span>Entry processes</span>}>{this.state.activePackage.entry_processes}</Descriptions.Item>
                        <Descriptions.Item label={<span>Active processes</span>}>{this.state.activePackage.active_processes}</Descriptions.Item>
                        <Descriptions.Item label={<span>Email accounts</span>}>{this.state.activePackage.email_accounts}</Descriptions.Item>
                        <Descriptions.Item label={<span>Databases</span>}>{this.state.activePackage.databases}</Descriptions.Item>
                        <Descriptions.Item label={<span>Subdomains</span>}>{this.state.activePackage.subdomains}</Descriptions.Item>
                        <Descriptions.Item label={<span>Parked domains</span>}>{this.state.activePackage.parked_domains}</Descriptions.Item>
                        <Descriptions.Item label={<span>Addon domains</span>}>{this.state.activePackage.addon_domains}</Descriptions.Item>
                    </Descriptions> : ''}
                </Form.Item>
            </Form>
        </Modal>
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps, { loadSharedHostingPlans })(ModifySharedHostingOrderModal);