import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Card, Tabs} from "antd";
import OrdersTable from "../../components/billing/OrdersTable";
import InvoicesTable from "../../components/billing/InvoicesTable";
import CancellationRequestsTable from "../../components/billing/CancellationRequestsTable";
import CreditNotesTable from "../../components/billing/CreditNotesTable";
import PaymentMethodsTable from "../../components/billing/PaymentMethodsTable";
import TransactionsTable from "../../components/billing/TransactionsTable";
import {hasPermission} from "../../components/permissions/PermissionUtils";

class Billing extends React.Component {
    state = {
        pages: ['orders', 'invoices', 'payment-methods', 'tax', 'credit-notes', 'cancellation-requests', 'transactions'],
        titles: {
            'orders': 'Orders',
            'invoices': 'Invoices',
            'payment-methods': 'Payment Methods',
            'tax': 'Tax',
            'credit-notes': 'Credit Notes',
            'cancellation-requests': 'Cancellation Requests',
            'transactions': 'Transactions'
        }
    };

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            this.props.updatePage(this.state.titles[page]);

            if(page === 'orders') {
                this.props.history.push('/billing');
            } else {
                this.props.history.push('/billing/' + page);
            }
        }
    }

    render() {
        let page = 'orders';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        const items = [];

        if(hasPermission(this.props.company.permissions, ['can_view_orders'])) {
            items.push({ label: 'Orders', key: 'orders', children: <OrdersTable history={this.props.history} /> });
        }

        if(hasPermission(this.props.company.permissions, ['can_view_invoices'])) {
            items.push({ label: 'Invoices', key: 'invoices', children: <InvoicesTable history={this.props.history} /> });
        }

        if(hasPermission(this.props.company.permissions, ['can_view_credit_notes'])) {
            items.push({ label: 'Credit Notes', key: 'credit-notes', children: <CreditNotesTable history={this.props.history} /> });
        }

        if(hasPermission(this.props.company.permissions, ['can_view_payment_methods'])) {
            items.push({ label: 'Payment Methods', key: 'payment-methods', children: <PaymentMethodsTable history={this.props.history} /> });
        }

        if(hasPermission(this.props.company.permissions, ['can_view_transactions'])) {
            items.push({ label: 'Transactions', key: 'transactions', children: <TransactionsTable history={this.props.history} /> });
        }

        if(hasPermission(this.props.company.permissions, ['can_view_cancellation_requests'])) {
            items.push({ label: 'Cancellation Requests', key: 'cancellation-requests', children: <CancellationRequestsTable history={this.props.history} /> });
        }

        return(
            <Card size='small'>
                <Tabs defaultActiveKey={page} onChange={(e) => this.changeTab(e)} items={items} />
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage })(Billing);