export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_IN_PROGRESS = 'LOGOUT_IN_PROGRESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REGISTER_IN_PROGRESS = 'REGISTER_IN_PROGRESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const GET_USER = 'GET_USER';

export const SEARCH_STARTED = 'SEARCH_STARTED';
export const SEARCH_COMPLETED = 'SEARCH_COMPLETED';

export const PAGE_LOADING = 'PAGE_LOADING';
export const PAGE_UPDATED = 'PAGE_UPDATED';
export const PAGE_WHITE_LABEL_DATA_UPDATED = 'PAGE_WHITE_LABEL_DATA_UPDATED';
export const APP_TOGGLE_SIDE_MENU = 'APP_TOGGLE_SIDE_MENU';

export const START_GETTING_STARTED_WIZARD = 'START_GETTING_STARTED_WIZARD';
export const OPEN_GETTING_STARTED_WIZARD = 'OPEN_GETTING_STARTED_WIZARD';
export const CLOSE_GETTING_STARTED_WIZARD = 'CLOSE_GETTING_STARTED_WIZARD';
export const UPDATE_GETTING_STARTED_WIZARD_STEP = 'UPDATE_GETTING_STARTED_WIZARD_STEP';
export const STOP_GETTING_STARTED_WIZARD = 'STOP_GETTING_STARTED_WIZARD';
export const MINIMIZE_GETTING_STARTED_WIZARD = 'MINIMIZE_GETTING_STARTED_WIZARD';

