import React, {Fragment} from "react";
import {connect} from "react-redux";
import {displayErrors} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {Alert, Table} from "antd";
import Flag from "react-world-flags";
import {DEFAULT_FLAG_SIZE} from "../../config";
import {loadIPAddressMapData} from "../../actions/ip_addresses";


class IPAddressMap extends React.Component {
    state = {
        dataLoaded: false,
        loadingData: true,
        locations: [],
        subnets: []
    };

    componentDidMount() {
        if(!this.state.dataLoaded && this.state.loadingData) {
            this.props.loadIPAddressMapData((res) => {
                this.setState({ dataLoaded: true, loadingData: false, locations: res.data.locations, subnets: res.data.subnets, counts: res.data.counts });
            }, (err) => {
                this.setState({ dataLoaded: true, loadingData: false });

                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                }
            });
        }
    }

    render() {
        if(this.state.loadingData) {
            return <div className='text-center'><Loading /></div>;
        }

        const columns = [
            { title: 'Country', dataIndex: 'country', render: (item, record) => <span><Flag height={DEFAULT_FLAG_SIZE} code={record.country_code.toLowerCase()} /> {record.country}</span> },
            { title: 'City', dataIndex: 'city' },
            { title: 'Total', dataIndex: 'ip_address_count', align: 'center' },
            { title: 'A classes', dataIndex: 'a_classes', align: 'center' },
            { title: 'B classes', dataIndex: 'b_classes', align: 'center' },
            { title: 'C classes', dataIndex: 'c_classes', align: 'center' },
        ];

        return(
            <Fragment>
                <Alert showIcon type='warning' message='IP availability depends on IP addresses that are already in your IP groups. IP stock changes in real time.' />
                <Table
                    dataSource={this.state.subnets}
                    size='small'
                    rowKey={item => item.city} pagination={false}
                    columns={columns} summary={() => {
                    return <Table.Summary.Row>
                        <Table.Summary.Cell/>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell align='center'>{this.state.counts.ip_address_count}<br /><small>IP addresses</small></Table.Summary.Cell>
                        <Table.Summary.Cell align='center'>{this.state.counts.a_classes_count}<br /><small>A classes</small></Table.Summary.Cell>
                        <Table.Summary.Cell align='center'>{this.state.counts.b_classes_count}<br /><small>B classes</small></Table.Summary.Cell>
                        <Table.Summary.Cell align='center'>{this.state.counts.c_classes_count}<br /><small>C classes</small></Table.Summary.Cell>
                    </Table.Summary.Row>;
                }} />
            </Fragment>
        );
    }
}

export default connect(null, { loadIPAddressMapData })(IPAddressMap);