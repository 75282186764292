import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {downloadCreditNote, loadCreditNote} from "../../actions/billing";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Button, Card, Col, Row, Table} from "antd";
import {Loading} from "../../libs/loading";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {ArrowLeftOutlined, FilePdfOutlined} from "@ant-design/icons";
import {MoneyField} from "../shared/MoneyField";
import AccessItem from "../permissions/AccessItem";

class CreditNoteView extends React.Component {
    state = {
        credit: null,
        loadingCreditNote: true
    };

    componentDidMount() {
        this.props.updatePage('Credit Note #' + this.props.match.params.id);

        this.props.loadCreditNote(this.props.match.params.id, (res) => {
            this.setState({ loadingCreditNote: false, credit: res.data });
        }, (err) => {
            this.setState({ loadingCreditNote: false });
            displayErrors(err.response.data);
        });
    }

    render() {
        if(this.state.loadingCreditNote) {
            return <Loading />;
        }

        const { credit } = this.state;

        const itemTableColumns = [
            { title: 'Description', width: '60%', dataIndex: 'description', render: (item) => {
                return item.split('\n').map(line => <p>{line}</p>)
            }},
            { title: 'Amount', dataIndex: 'amount', align: 'center', render: (item, record) => <MoneyField amount={record.amount} currency={record.currency} />  },
            { title: 'Tax', align: 'center', render: (item, record) => <MoneyField amount={record.tax} currency={record.currency} /> },
            { title: 'Tax Rate', align: 'center', dataIndex: 'tax_rate', render: (item) => <span>{item}%</span> },
            { title: 'Total', align: 'center', render: (item, record) => <MoneyField amount={parseFloat(record.amount) + parseFloat(record.tax)} currency={record.currency} /> },
        ];

        const creditTransactionsTableColumns = [
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Action', dataIndex: 'action', render: (item) => normalizeEnum(item) },
            { title: 'Amount', dataIndex: 'amount', render: (item, record) => <MoneyField amount={record.amount} currency={record.currency} /> },
            { title: 'Currency', dataIndex: 'currency' },
        ];

        const transactionsTableColumns = [
            { title: 'Amount', dataIndex: 'amount', render: (item, record) => <MoneyField amount={record.amount} currency={record.currency} /> },
            { title: 'Currency', dataIndex: 'currency' },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Payment method', dataIndex: 'payment_method', render: (item) => normalizeEnum(item) },
            { title: 'Transaction ID', dataIndex: 'transaction_id' },
        ];

        // TODO: Fix credit note view

        return(
            <Card size='small'>
                <Row>
                    <Col offset={3} span={18}>
                        <Row gutter={[16, 16]} className='credit-note-content-no-bg'>
                            <Col>
                                <Button type="text" onClick={() => this.props.history.push('/billing/credit_notes')} icon={<ArrowLeftOutlined />}> Credit notes list</Button>
                            </Col>
                            <Col flex='auto' />
                            <AccessItem scopes={['can_download_credit_notes']}>
                                <Col>
                                    <Button type='default' icon={<FilePdfOutlined />} onClick={() => this.props.downloadCreditNote(this.props.match.params.id)}>Download PDF</Button>
                                </Col>
                            </AccessItem>
                        </Row>
                        <Row className='credit-note-content'>
                            <Col span={24}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <img src='/img/logo-top-dark.png' style={{maxHeight: '50px'}} alt='Priority Prospect' className='invoice-logo' />
                                    </Col>
                                </Row>
                                <br />
                                <Row gutter={[16, 16]}>
                                    <Col span={12} xs={24} sm={12} style={{verticalAlign:'middle'}}>
                                        <b>Priority Prospect OÜ</b><br />
                                        Vandu tee 6-2, Hulja<br />
                                        Lääne Virumaa, Estonia<br />
                                        45203<br />
                                        Registration No. 14059959<br />
                                        VAT No. EE101884183
                                    </Col>
                                    <Col xs={24} sm={12} className='text-right' style={{verticalAlign:'middle'}}>
                                        <h2>CREDIT NOTE<br /><small>#{credit.id}</small></h2>
                                    </Col>
                                </Row>
                                <br />
                                <Row gutter={[16, 16]} align="middle">
                                    <Col xs={24}>
                                        Bill to:<br />
                                        <b>{credit.company_name}</b><br />
                                        {credit.first_name} {credit.last_name}<br />
                                        {credit.address1}<br />
                                        {credit.address2 !== null && credit.address2 !== '' ? <span>{credit.address2}<br /></span> : ''}
                                        {credit.region}, {credit.country}<br />
                                        {credit.postal_code}
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={24} gutter={[16, 16]}>
                                        <Table size='middle' columns={itemTableColumns} dataSource={credit.items} pagination={false} summary={() => {
                                            return <>
                                                <Table.Summary.Row key='initial_balance'>
                                                    <Table.Summary.Cell colSpan={3} className='no-border-cell' />
                                                    <Table.Summary.Cell className='text-center no-border-cell'>Initial Balance</Table.Summary.Cell>
                                                    <Table.Summary.Cell className='text-center no-border-cell'><b><MoneyField amount={credit.initial_balance} currency={credit.currency}/></b></Table.Summary.Cell>
                                                </Table.Summary.Row>
                                                <Table.Summary.Row key='initial_tax'>
                                                    <Table.Summary.Cell colSpan={3} className='no-border-cell' />
                                                    <Table.Summary.Cell className='text-center no-border-cell'>Tax</Table.Summary.Cell>
                                                    <Table.Summary.Cell className='text-center no-border-cell'><b><MoneyField amount={credit.initial_tax} currency={credit.currency}/></b></Table.Summary.Cell>
                                                </Table.Summary.Row>
                                                <Table.Summary.Row key='total_balance'>
                                                    <Table.Summary.Cell colSpan={3} className='no-border-cell' />
                                                    <Table.Summary.Cell className='text-center no-border-cell'>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell className='text-center no-border-cell'><b><MoneyField amount={parseFloat(credit.initial_balance) + parseFloat(credit.initial_tax)} currency={credit.currency}/></b></Table.Summary.Cell>
                                                </Table.Summary.Row>
                                                <Table.Summary.Row key='available_balance'>
                                                    <Table.Summary.Cell colSpan={3} className='no-border-cell' />
                                                    <Table.Summary.Cell className='text-center gray-cell no-border-cell'><b>Balance Available</b></Table.Summary.Cell>
                                                    <Table.Summary.Cell className='text-center gray-cell no-border-cell'><b><MoneyField amount={credit.balance} currency={credit.currency}/></b></Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>;
                                        }}/>
                                    </Col>
                                </Row>
                                {credit.transaction !== null ? <Row span={24} gutter={[16, 16]}>
                                    <Col span={24}>
                                        <p><b>Associated Transaction:</b></p>
                                        <Table size='small' pagination={false} columns={transactionsTableColumns} dataSource={[credit.transaction]} />
                                    </Col>
                                </Row> : ''}
                                {credit.credit_transactions.length > 0 ? <Row span={24} gutter={[16, 16]}>
                                    <Col span={24}>
                                        <p><b>Credit Transactions:</b></p>
                                        <Table size='small' pagination={false} columns={creditTransactionsTableColumns} dataSource={credit.credit_transactions} />
                                    </Col>
                                </Row> : ''}
                                {credit.custom_notes !== null ? <Row span={24} gutter={[16, 16]}>
                                    <Col span={24}>
                                        <b>Custom notes:</b>
                                        <p>{credit.custom_notes}</p>
                                    </Col>
                                </Row> : ''}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>);
    }
}

export default connect(null, { updatePage, loadCreditNote, downloadCreditNote })(CreditNoteView);