import React from "react";
import {connect} from "react-redux";
import {Button, Select, Form, Input, Modal, message, Col, Row} from "antd";
import {Loading} from "../../libs/loading";
import {displayErrors} from "../../libs/utils";
import {exportIPAddressData} from "../../actions/ip_addresses";

class ExportModal extends React.Component {
    state = {
        showResultsModal: false,
        loading: false,
        results: '',
        outputType: 'CSV',
        fields: [],
        sortField: 'ip_address',
        sortDir: 'ASC'
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    generateResults() {
        if(this.state.fields.length === 0) {
            return message.error('Please select at least 1 field!');
        }

        this.setState({ loading: true, showResultsModal: true });

        this.props.exportIPAddressData(this.props.group, this.state.fields, this.props.selectedIPAddresses,
            this.state.outputType, this.state.sortField, this.state.sortDir, (res) => {
            if(this.state.outputType === 'JSON') {
                this.setState({ loading: false, results: JSON.stringify(res.data) });
            } else {
                this.setState({ loading: false, results: res.data });
            }

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: true })
            }
        });
    }

    closeResultsModal() {
        this.setState( { showResultsModal: false});
    }

    closeModal() {
        this.setState({ showResultsModal: false, results: '', outputType: 'CSV', fields: []})
        this.props.closeModal();
    }

    render() {
        const { Option } = Select;
        const { TextArea } = Input;

        let results = <div className='text-center'><Loading /></div>;

        if(!this.state.loading) {
            results = <TextArea rows={10} value={this.state.results} />;
        }

        return(
            <Modal
                centered
                title={'Export'}
                open={this.props.showModal}
                destroyOnClose={true}
                footer={<Button type='primary' onClick={() => this.generateResults()}>Export</Button>}
                confirmLoading={this.state.loading}
                onCancel={() => this.closeModal()}>

                <Form layout='vertical'>
                    <Form.Item label='Select fields:'>
                        <Select mode="multiple" allowClear onChange={(value) => this.setState({ fields: value })}>
                            <Option value='ip_address'>IP address</Option>
                            <Option value='ip_address_city'>IP address city</Option>
                            <Option value='ip_address_country'>IP address country</Option>
                            <Option value='ip_address_type'>IP address type</Option>
                            <Option value='ip_address_status'>IP address status</Option>
                            <Option value='order_id'>Order ID</Option>
                            <Option value='website_slots_available'>Website slots available</Option>
                            <Option value='website_slots_used'>Website slots used</Option>
                            <Option value='server_signature'>Server signature</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Sort by:'>
                        <Row gutter={[16, 16]}>
                            <Col span={17}>
                                <Select value={this.state.sortField} onChange={(value) => this.setState({ sortField: value })}>
                                    <Option value='ip_address'>IP address</Option>
                                    <Option value='ip_address_city'>IP address city</Option>
                                    <Option value='ip_address_country'>IP address country</Option>
                                    <Option value='ip_address_type'>IP address type</Option>
                                    <Option value='ip_address_status'>IP address status</Option>
                                    <Option value='order_id'>Order ID</Option>
                                    <Option value='website_slots_available'>Website slots available</Option>
                                    <Option value='website_slots_used'>Website slots used</Option>
                                    <Option value='server_signature'>Server signature</Option>
                                </Select>
                            </Col>
                            <Col span={7}>
                                <Select value={this.state.sortDir} onChange={(value) => this.setState({ sortDir: value })}>
                                    <Option value='ASC'>Ascending</Option>
                                    <Option value='DESC'>Descending</Option>
                                </Select>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label='Output type:'>
                        <Select defaultValue={this.state.outputType} onChange={(value) => this.setState({ outputType: value })}>
                            <Option value='CSV'>CSV - Comma separated values</Option>
                            <Option value='JSON'>JSON</Option>
                        </Select>
                    </Form.Item>
                </Form>
                <Modal
                    centered
                    title={'Results'}
                    open={this.state.showResultsModal}
                    destroyOnClose={true}
                    width='800px'
                    onCancel={() => this.closeResultsModal()}
                    footer={<Button type='primary' onClick={() => this.closeResultsModal()}>Close</Button>}>

                    {results}
                </Modal>
            </Modal>
        );
    }
}

export default connect(null, { exportIPAddressData })(ExportModal);