import {httpDelete, httpGet, httpPatch, httpPost} from "../libs/http";

export const loadFirewallRules = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/firewall/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadFirewallRule = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/firewall/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadPredefinedBots = (onSuccess, onFailure) => dispatch => {
    httpGet('/firewall/predefined-bots/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createFirewallRule = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/firewall/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const editFirewallRule = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/firewall/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateFirewallRuleStatus = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPatch('/firewall/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteFirewallRule = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/firewall/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createFirewallPolicy = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/firewall/policies/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadFirewallPolicies = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/firewall/policies/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateFirewallPolicyStatus = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPatch('/firewall/policies/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteFirewallPolicy = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/firewall/policies/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadFirewallPolicy = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/firewall/policies/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const editFirewallPolicy = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/firewall/policies/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const createFirewallRuleset = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/firewall/rulesets/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadFirewallRulesets = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/firewall/rulesets/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateFirewallRulesetStatus = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPatch('/firewall/rulesets/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteFirewallRuleset = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/firewall/rulesets/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadFirewallRuleset = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/firewall/rulesets/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const editFirewallRuleset = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/firewall/rulesets/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const bulkDeleteFirewallRules = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/firewall/delete/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const bulkDeleteFirewallRulesets = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/firewall/rulesets/delete/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const bulkDeleteFirewallPolicies = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/firewall/policies/delete/', data, (res) => onSuccess(res), (err) => onFailure(err));
};