import React from 'react';
import {Button, Checkbox, Descriptions, Form, Input, message, Modal, Space} from "antd";
import {connect} from "react-redux";
import {createContact} from "../../actions/account";
import {displayErrors, normalizeEnum} from "../../libs/utils";

class AddCompanyContactModal extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        events: [
            {
                name: 'Billing',
                fields: ['new_invoice', 'invoice_payment_reminder', 'new_payment', 'new_payment_method',
                    'payment_method_disabled', 'new_order', 'order_suspended', 'order_termination_warning',
                    'order_terminated', 'new_hosting_account', 'card_charge_failed']
            },
            {
                name: 'Domains',
                fields: ['new_domain', 'domain_deleted', 'software_install', 'software_uninstall',
                    'ssl_install', 'ssl_uninstall', 'ssl_renewal']
            }
        ],
        selectedEvents: [],
        creatingContact: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal() {
        this.setState({firstName: '', lastName: '', email: '', selectedEvents: [], creatingContact: false});
        this.props.close();
    }

    createContact() {
        let first_name = this.state.firstName.trim();
        let last_name = this.state.lastName.trim();
        let email_address = this.state.email.trim();
        let events = this.state.selectedEvents;

        if(first_name.length === 0) {
            return message.error('Please enter first name!');
        }

        if(last_name.length === 0) {
            return message.error('Please enter last name!');
        }

        if(email_address.length === 0) {
            return message.error('Please enter email address!');
        }

        let data = { first_name, last_name, email_address, events };

        this.setState({creatingContact: true});

        this.props.createContact(this.props.guid, data, () => {
            message.success('Contact successfully created! Please check contact\'s inbox for a verification email!');

            this.setState({creatingContact: false});
            this.closeModal();
            this.props.reloadContacts();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({creatingContact: false});
                displayErrors(err.response.data);
            }
        });
    }

    updateSelectedEvents(field, value) {
        let selectedEvents = this.state.selectedEvents;

        if(value) {
            selectedEvents.push(field);
        }
        else
        {
            selectedEvents = selectedEvents.filter(e => e !== field);
        }

        this.setState({ selectedEvents });
    }

    render() {
        return (
            <Modal
                title='Add Contact'
                open={this.state.showModal}
                destroyOnClose={true}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button type='primary' disabled={this.state.creatingContact} loading={this.state.creatingContact} onClick={() => this.createContact()}>Create</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='First name'>
                        <Input value={this.state.firstName} onChange={(e) => this.setState({firstName: e.target.value})}/>
                    </Form.Item>
                    <Form.Item label='Last name'>
                        <Input value={this.state.lastName} onChange={(e) => this.setState({lastName: e.target.value})}/>
                    </Form.Item>
                    <Form.Item label='Email address'>
                        <Input type='email' value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
                    </Form.Item>
                    <Form.Item label='Events' extra={<small>Select events when the contact receives an email. If left empty, contact will receive all emails including transactional emails.</small>}>
                        <Descriptions bordered column={1}>
                            {this.state.events.map((item, i) => {
                                return <Descriptions.Item key={i} label={item.name}>
                                    {item.fields.map((field, j) => {
                                        return <div key={j}><Checkbox key={j} onChange={(e) => this.updateSelectedEvents(field, e.target.checked)}>{normalizeEnum(field, false)}</Checkbox></div>;
                                    })}
                                </Descriptions.Item>;
                            })}
                        </Descriptions>

                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, {createContact})(AddCompanyContactModal);