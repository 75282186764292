import React from "react";
import {connect} from "react-redux";
import {Button, Select, Form, Input, Modal, message, Row, Col} from "antd";
import {Loading} from "../../libs/loading";
import {exportDomainData} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";

class ExportModal extends React.Component {
    state = {
        showResultsModal: false,
        loading: false,
        results: '',
        outputType: 'CSV',
        fields: [],
        sortField: 'domain',
        sortDir: 'ASC'
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    generateResults() {
        if(this.state.fields.length === 0) {
            return message.error('Please select at least 1 field!');
        }

        this.setState({ loading: true, showResultsModal: true });

        this.props.exportDomainData(this.props.group, this.state.fields, this.props.selecteDomains,
            this.state.outputType, this.state.sortField, this.state.sortDir, (res) => {
            if(this.state.outputType === 'JSON') {
                this.setState({ loading: false, results: JSON.stringify(res.data) });
            } else {
                this.setState({ loading: false, results: res.data });
            }

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: true })
            }
        });
    }

    closeResultsModal() {
        this.setState( { showResultsModal: false});
    }

    closeModal() {
        this.setState({ showResultsModal: false, results: '', outputType: 'CSV', fields: []})
        this.props.closeModal();
    }

    render() {
        const { Option } = Select;
        const { TextArea } = Input;

        let results;

        if(this.state.loading) {
            results = <div className='text-center'><Loading /></div>;
        } else {
            results = <TextArea rows={10} value={this.state.results.trim()} />;
        }

        return(
            <Modal
                centered
                title={'Export'}
                open={this.props.showModal}
                destroyOnClose={true}
                footer={<Button type='primary' onClick={() => this.generateResults()}>Export</Button>}
                confirmLoading={this.state.loading}
                onCancel={() => this.closeModal()}>

                <Form layout='vertical'>
                    <Form.Item label='Select fields:'>
                        <Select mode="multiple" allowClear onChange={(value) => this.setState({ fields: value })}>
                            <Option value='domain'>Domain</Option>
                            <Option value='domain_group_name'>Domain group name</Option>
                            <Option value='ip_address'>IP address</Option>
                            <Option value='ip_address_city'>IP address city</Option>
                            <Option value='ip_address_country'>IP address country</Option>
                            <Option value='ip_address_http_version'>IP address HTTP version</Option>
                            <Option value='ip_address_group_name'>IP address group name</Option>
                            <Option value='ip_address_price'>IP address price</Option>
                            <Option value='ip_address_order_id'>IP address order ID</Option>
                            <Option value='ip_address_order_renewal_date'>IP address order renewal date</Option>
                            <Option value='domain_date_created'>Domain's date added</Option>
                            <Option value='software'>Software</Option>
                            <Option value='software_username'>Software username</Option>
                            <Option value='software_password'>Software password</Option>
                            <Option value='ssl_certificate_status'>SSL certificate status</Option>
                            <Option value='tf'>TF</Option>
                            <Option value='da'>DA</Option>
                            <Option value='whois_expires'>Whois expires</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Sort by:'>
                        <Row gutter={[16, 16]}>
                            <Col span={17}>
                                <Select value={this.state.sortField} onChange={(value) => this.setState({ sortField: value })}>
                                    <Option value='domain'>Domain</Option>
                                    <Option value='ip_address'>IP address</Option>
                                    <Option value='ip_address_city'>IP address city</Option>
                                    <Option value='ip_address_country'>IP address country</Option>
                                    <Option value='ip_address_http_version'>IP address HTTP version</Option>
                                    <Option value='domain_date_created'>Domain's date added</Option>
                                    <Option value='software'>Software</Option>
                                    <Option value='ssl_certificate_status'>SSL certificate status</Option>
                                    <Option value='tf'>TF</Option>
                                    <Option value='da'>DA</Option>
                                    <Option value='whois_expires'>Whois expires</Option>
                                </Select>
                            </Col>
                            <Col span={7}>
                                <Select value={this.state.sortDir} onChange={(value) => this.setState({ sortDir: value })}>
                                    <Option value='ASC'>Ascending</Option>
                                    <Option value='DESC'>Descending</Option>
                                </Select>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label='Output type:'>
                        <Select defaultValue={this.state.outputType} onChange={(value) => this.setState({ outputType: value })}>
                            <Option value='CSV'>CSV - Comma separated values</Option>
                            <Option value='JSON'>JSON - JavaScript Object Notation</Option>
                        </Select>
                    </Form.Item>
                </Form>
                <Modal
                    centered
                    title={'Results'}
                    open={this.state.showResultsModal}
                    destroyOnClose={true}
                    width='800px'
                    onCancel={() => this.closeResultsModal()}
                    footer={<Button type='primary' onClick={() => this.closeResultsModal()}>Close</Button>}>
                    {results}
                </Modal>
            </Modal>
        );
    }
}

export default connect(null, { exportDomainData })(ExportModal);