import React from "react";
import {connect} from "react-redux";
import {Alert, Button, Checkbox, Col, Descriptions, Modal, Row, Select, Space, Tag} from "antd";
import {displayErrors} from "../../libs/utils";
import {extendOrder, loadOrderExtensionOptions} from "../../actions/billing";
import moment from "moment/moment";

class ExtendSharedHostingOrderModal extends React.Component {
    state = {
        loadingOrderRenewalOptions: false,
        orderRenewalOptionsLoaded: false,
        extensionOptions: null,
        selectedPeriod: null,
        nextRenewalDate: null,
        loading: false,
        agreesTos: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.show && !this.state.loadingOrderRenewalOptions && !this.state.orderRenewalOptionsLoaded) {
            this.setState({ loadingOrderRenewalOptions: true });

            this.props.loadOrderExtensionOptions(this.props.orderId, (res) => {
                this.setState({
                    loadingOrderRenewalOptions: false,
                    orderRenewalOptionsLoaded: true,
                    extensionOptions: res.data
                });

            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingOrderRenewalOptions: false, orderRenewalOptionsLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal(invoiceId = null) {
        this.setState({
            loadingOrderRenewalOptions: false,
            orderRenewalOptionsLoaded: false,
            extensionOptions: null,
            selectedPeriod: null,
            nextRenewalDate: null,
            loading: false,
            agreesTos: false,
        }, () => this.props.close(invoiceId));
    }

    updateNextRenewalDate(value) {
        let nextRenewalDate = moment(this.state.extensionOptions.available_periods.find(cycle => cycle.value === value).next_renewal_date).format('Do MMMM, YYYY');
        this.setState({ selectedPeriod: value, nextRenewalDate  });
    }

    extendOrder() {
        this.setState({ loading: true });

        let data = {
            'period': this.state.selectedPeriod
        }

        this.props.extendOrder(this.props.orderId, data, (res) => {
            this.closeModal(res.data.invoice_id);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false  });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        if(this.state.extensionOptions === null) {
            return <></>;
        }

        return <Modal
            open={this.props.show}
            destroyOnClose={true}
            footer={<Space>
                <Button onClick={() => this.closeModal()}>Close</Button>
                <Button type='primary' disabled={!this.state.agreesTos || this.state.selectedPeriod === null}
                        loading={this.state.loading}
                        onClick={() => this.extendOrder()}>Create Invoice</Button>
            </Space>}
            onCancel={() => this.closeModal()}
            title='Extend Order'>

            <Alert showIcon type='warning' message={<span><b>Attention!</b> The order will renew at the default price at the end of the billing cycle. Also, extending the order will not change it's billing cycle! </span>} />
            <br />

            <Descriptions bordered size='small' layout='horizontal' column={1} style={{width:'100%'}}>
                <Descriptions.Item label='Current renewal date' style={{width: '50%'}}>{moment(this.state.extensionOptions.current_renewal_date).format('Do MMMM, YYYY')}</Descriptions.Item>
                <Descriptions.Item label='Select the renewal period'>
                    <Select value={this.state.selectedPeriod} placeholder='Select period' style={{width: '100%'}} onChange={(value) => this.updateNextRenewalDate(value)}>
                        {this.state.extensionOptions.available_periods.map((cycle, index) => {
                            let discount = '';

                            if(cycle.discount !== null) {
                                if(cycle.discount.discount_type === 'PERCENTAGE') {
                                    discount = <Tag color='green'>{parseFloat(cycle.discount.percentage_value, 2)}% off</Tag>
                                }
                            }

                            return <Option key={index} value={cycle.value}>
                                <Row justify='space-between'>
                                    <Col>{cycle.value} months</Col>
                                    <Col>{discount}</Col>
                                </Row>
                            </Option>;
                        })}
                    </Select>
                </Descriptions.Item>
                <Descriptions.Item label='Next renewal date'>
                    {this.state.selectedPeriod !== null ? this.state.nextRenewalDate : '-'}
                </Descriptions.Item>
            </Descriptions>
            <br />
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <p><Checkbox onChange={(e) => this.setState({ agreesTos: e.target.checked })}/>
                        <small>&nbsp;
                            <b>I confirm that I understand and agree</b> that all extension requests are processed according to Priority Prospect OÜ's&nbsp;
                        <a target='_blank' rel='noreferrer' href={'https://priorityprospect.com/legal/terms-of-service/'}><b><u>terms
                            of service</u></b></a>. For more information see <a target='_blank' rel='noreferrer' href={'https://priorityprospect.com/legal/terms-of-service/#refund-policy-and-billing-disputes'}><b><u>6.
                            Refund Policy and Billing Disputes</u></b></a>
                        &nbsp; and <a target='_blank' rel='noreferrer' href={'https://priorityprospect.com/legal/terms-of-service/#cancellations-of-service'}><b><u>8. Cancellation of Services</u></b></a>.</small></p>
                </Col>
            </Row>
        </Modal>
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps, {loadOrderExtensionOptions, extendOrder})(ExtendSharedHostingOrderModal);