import React from "react";
import {Spin} from "antd";
import { LoadingOutlined } from '@ant-design/icons';

export const Loading = (params = null) => {
    if(typeof params === 'undefined' || params === null) {
        params = {
            fontSize: 24
        }
    }

    return <Spin indicator={<LoadingOutlined style={{ fontSize: params.fontSize }} spin />} />;
};