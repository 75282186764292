import React from "react";
import {connect} from "react-redux";
import {Avatar, Card} from "antd";
import {GoMail} from "react-icons/go";
import {FaPhoneAlt} from "react-icons/fa";

class AccountManagerProfile extends React.Component {
    render() {
        const  { account_manager } = this.props.company;

        let avatar = <Avatar className='text'>{account_manager.first_name.substring(0, 1)}</Avatar>;

        if(account_manager.avatar !== null) {
            avatar = <Avatar src={account_manager.avatar} />;
        }

        return(
            <Card className='account-manager-container' title={<span className='account-manager-title'>Account Manager</span>} bordered={false} size='small'>
                <div className='account-manager-avatar'>{avatar}</div>
                <div className='account-manager-data'>
                    <p><strong>{account_manager.first_name + ' ' + account_manager.last_name}</strong></p>
                    <p><GoMail style={{marginBottom: '-2px'}} /> {account_manager.email}</p>
                    <p><FaPhoneAlt style={{marginBottom: '-2px'}} /> {account_manager.phone}</p>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, {})(AccountManagerProfile);