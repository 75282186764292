import React from "react";
import {Button, message, Modal, Space, Tabs} from "antd";
import {connect} from "react-redux";
import {Loading} from "../../libs/loading";
import {displayErrors} from "../../libs/utils";
import {loadCompanyUser, updateCompanyUser} from "../../actions/account";
import CompanyUserPermissionsTable from "./CompanyUserPermissionsTable";
import CompanyUserDetailTable from "./CompanyUserDetailTable";
import CompanyUserAccessTable from "./CompanyUserAccessTable";

class CompanyUserPermissionsModal extends React.Component {
    state = {
        loading: false,
        loadingUser: false,
        userLoaded: false,
        data: null,
        modalSize: 600,
        defaultModalSize: 600,
    };

    presets = {
        'STAFF': ['can_switch_companies', 'can_view_company_info', 'can_view_company_emails', 'can_view_company_logs',
            'can_view_vat', 'can_add_vat', 'can_edit_vat', 'can_view_billing', 'can_add_orders', 'can_view_orders',
            'can_edit_orders', 'can_view_invoices', 'can_pay_invoices', 'can_view_payment_methods', 'can_add_payment_methods',
            'can_edit_payment_methods', 'can_view_cancellation_requests', 'can_add_cancellation_requests',
            'can_edit_cancellation_requests', 'can_view_firewall', 'can_add_firewall_rules', 'can_edit_firewall_rules',
            'can_view_firewall_rules', 'can_delete_firewall_rules', 'can_add_firewall_policies',
            'can_edit_firewall_policies', 'can_view_firewall_policies', 'can_delete_firewall_policies',
            'can_add_firewall_rulesets', 'can_edit_firewall_rulesets', 'can_view_firewall_rulesets',
            'can_delete_firewall_rulesets', 'can_view_hosting', 'can_add_hosting_accounts', 'can_edit_hosting_accounts',
            'can_view_hosting_accounts', 'can_delete_hosting_accounts', 'can_login_to_hosting_accounts', 'can_view_ip_groups',
            'can_add_ip_groups', 'can_edit_ip_groups', 'can_delete_ip_groups', 'can_add_ip_addresses', 'can_edit_ip_addresses',
            'can_view_ip_addresses', 'can_view_domain_groups', 'can_add_domain_groups', 'can_edit_domain_groups',
            'can_delete_domain_groups', 'can_view_domains', 'can_add_domains', 'can_edit_domains', 'can_delete_domains',
            'can_edit_domain_caching', 'can_add_domain_url_redirects', 'can_delete_domain_url_redirects',
            'can_edit_domain_url_redirects', 'can_edit_domain_redirects', 'can_add_domain_dns_zone_records',
            'can_edit_domain_dns_zone_records', 'can_delete_domain_dns_zone_records', 'can_reset_domain_dns_zone',
            'can_install_software', 'can_uninstall_software', 'can_login_to_software', 'can_edit_software_installation',
            'can_view_software_presets', 'can_add_software_presets', 'can_edit_software_presets', 'can_delete_software_presets',
            'can_install_ssl_certificates', 'can_uninstall_ssl_certificates', 'can_add_email_addresses',
            'can_edit_email_addresses', 'can_delete_email_addresses'],

        'ADMINISTRATOR': ['can_switch_companies', 'can_view_company_info', 'can_edit_company_profile', 'can_view_company_emails',
            'can_view_company_logs', 'can_view_vat', 'can_add_vat', 'can_edit_vat', 'can_view_company_users',
            'can_add_company_users', 'can_edit_company_users', 'can_delete_company_users', 'can_view_company_contacts',
            'can_add_company_contacts', 'can_edit_company_contacts', 'can_delete_company_contacts', 'can_view_billing',
            'can_add_orders', 'can_view_orders', 'can_edit_orders', 'can_view_invoices', 'can_pay_invoices',
            'can_download_invoices', 'can_view_credit_notes', 'can_download_credit_notes', 'can_view_payment_methods',
            'can_add_payment_methods', 'can_edit_payment_methods', 'can_view_transactions', 'can_view_cancellation_requests',
            'can_add_cancellation_requests', 'can_edit_cancellation_requests', 'can_view_firewall', 'can_add_firewall_rules',
            'can_edit_firewall_rules', 'can_view_firewall_rules', 'can_delete_firewall_rules', 'can_add_firewall_policies',
            'can_edit_firewall_policies', 'can_view_firewall_policies', 'can_delete_firewall_policies',
            'can_add_firewall_rulesets', 'can_edit_firewall_rulesets', 'can_view_firewall_rulesets', 'can_delete_firewall_rulesets',
            'can_view_hosting', 'can_add_hosting_accounts', 'can_edit_hosting_accounts', 'can_view_hosting_accounts',
            'can_delete_hosting_accounts', 'can_login_to_hosting_accounts', 'can_view_ip_groups', 'can_add_ip_groups',
            'can_edit_ip_groups', 'can_delete_ip_groups', 'can_add_ip_addresses', 'can_edit_ip_addresses', 'can_view_ip_addresses',
            'can_view_domain_groups', 'can_add_domain_groups', 'can_edit_domain_groups', 'can_delete_domain_groups',
            'can_view_domains', 'can_add_domains', 'can_edit_domains', 'can_delete_domains', 'can_edit_domain_caching',
            'can_add_domain_url_redirects', 'can_delete_domain_url_redirects', 'can_edit_domain_url_redirects',
            'can_edit_domain_redirects', 'can_add_domain_dns_zone_records', 'can_edit_domain_dns_zone_records',
            'can_delete_domain_dns_zone_records', 'can_reset_domain_dns_zone', 'can_install_software',
            'can_uninstall_software', 'can_login_to_software', 'can_edit_software_installation', 'can_view_software_presets',
            'can_add_software_presets', 'can_edit_software_presets', 'can_delete_software_presets', 'can_install_ssl_certificates',
            'can_uninstall_ssl_certificates', 'can_add_email_addresses', 'can_edit_email_addresses', 'can_delete_email_addresses'],

        'BILLING': ['can_view_vat', 'can_add_vat', 'can_edit_vat', 'can_view_billing',
            'can_view_orders', 'can_edit_orders', 'can_view_invoices', 'can_pay_invoices',
            'can_download_invoices', 'can_view_credit_notes', 'can_download_credit_notes',
            'can_view_payment_methods', 'can_add_payment_methods', 'can_edit_payment_methods',
            'can_view_transactions'],

        'CUSTOMER': ['can_view_hosting', 'can_view_hosting_accounts', 'can_login_to_hosting_accounts', 'can_view_ip_groups',
            'can_view_ip_addresses', 'can_view_domain_groups', 'can_view_domains', 'can_login_to_software',
            'can_edit_software_installation', 'can_view_software_presets']
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if(!this.state.userLoaded && this.props.open && !this.state.loadingUser) {
            this.setState({ loadingUser: true}, () => {
                this.props.loadCompanyUser(this.props.companyGuid, this.props.selectedUser, (res) => {
                    this.setState({data: res.data, userLoaded: true, loadingUser: false});
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ userLoaded: true, loadingUser: false });
                        displayErrors(err.response.data);
                    }
                });
            });
        }
    }

    closeModal(reload = false) {
        this.props.close(reload);

        this.setState({
            loading: false,
            loadingUser: false,
            userLoaded: false,
            data: null,
            modalSize: this.state.defaultModalSize,
        });
    }
    updatePermission(key, value) {
        let newPermissions = {};
        let keys = Object.keys(this.state.data.permissions);

        for(let i = 0; i < keys.length; i++) {
            if(key === keys[i]) {
                newPermissions[keys[i]] = value;
            } else {
                newPermissions[keys[i]] = this.state.data.permissions[keys[i]];
            }
        }

        let data = this.state.data;
        data['permissions'] = newPermissions;

        this.setState({ data });
    }

    updateUser(key, value) {
        let data = {};
        let keys = Object.keys(this.state.data);

        for(let i = 0; i < keys.length; i++) {
            if(key === keys[i]) {
                data[keys[i]] = value;
            } else {
                data[keys[i]] = this.state.data[keys[i]];
            }
        }

        this.setState({ data });
    }

    saveUser() {
        this.setState({ loading: true });

        this.props.updateCompanyUser(this.props.companyGuid, this.props.selectedUser, this.state.data, () => {
            message.success('User successfully updated!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    onTabChange(tab) {
        if(tab === 'permissions') {
            this.setState({ modalSize: 1100 });
        } else {
            this.setState({ modalSize: this.state.defaultModalSize });
        }
    }

    setAllPermissions(value) {
        let newPermissions = {};
        let keys = Object.keys(this.state.data.permissions);

        for(let i = 0; i < keys.length; i++) {
            newPermissions[keys[i]] = value;
        }

        let data = this.state.data;
        data['permissions'] = newPermissions;

        this.setState({ data });
    }

    selectPreset(preset) {
        this.setAllPermissions(false);

        let permissions = this.presets[preset];

        for(let i = 0; i < permissions.length; i++) {
            this.updatePermission(permissions[i], true);
        }
    }

    render() {
        let content;

        if(!this.state.userLoaded) {
            content = <div className='text-center'><Loading /></div>;
        } else {
            const items = [
                { label: 'Details', key: 'details', children: <CompanyUserDetailTable data={this.state.data} updateField={(field, value) => this.updateUser(field, value)} /> },
                { label: 'Permissions', key: 'permissions', children: <CompanyUserPermissionsTable data={this.state.data.permissions}
                                                                                                   presets={this.presets}
                                                                                                   selectPreset={(value) => this.selectPreset(value)}
                                                                                                   updateField={(field, value) => this.updatePermission(field, value)}
                                                                                                   selectAll={() => this.setAllPermissions(true)}
                                                                                                   clearAll={() => this.setAllPermissions(false)} />  },
                { label: 'Access', key: 'access', children: <CompanyUserAccessTable data={this.state.data} updateField={(field, value) => this.updateUser(field, value)} /> }
            ];

            content = <Tabs items={items} onChange={tab => this.onTabChange(tab)} destroyInactiveTabPane={true} />;
        }

        return(
            <Modal
                open={this.state.open}
                onCancel={() => this.closeModal()}
                width={this.state.modalSize}
                className='edit-company-user-modal'
                footer={<Space>
                    <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                    <Button type='primary' onClick={() => this.saveUser()} disabled={this.state.loading} loading={this.state.loading}>Save</Button>
                </Space>}
                title='Edit User'>
                {content}
        </Modal>
        );
    }
}

export default connect(null, { loadCompanyUser, updateCompanyUser })(CompanyUserPermissionsModal);