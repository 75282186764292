import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import {logout} from "../../actions/auth";
import posthog from "posthog-js";

class Logout extends React.Component
{
    componentDidMount() {
        posthog.reset();

        setTimeout(() => {
            this.props.logout(() => {
                window.location.href = '/auth/login';
            });
        }, 1500);
    }

    render() {
        return(<Fragment/>);
    }
}

export default connect(null, { logout })(Logout);