import React, {Fragment} from "react";
import {connect} from "react-redux";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, Row, Space, Table, Tag, Tooltip, Typography} from "antd";
import {updatePage} from "../../actions/page";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadEmails} from "../../actions/account";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DeleteOutlined, EyeOutlined, FilterOutlined} from "@ant-design/icons";
import EmailViewModal from "./EmailViewModal";
import EmailsTableFilter from "../EmailsTableFilter";

class Emails extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        emails: [],
        filters: [],
        loadingEmails: true,
        activeEmail: null,
        showEmailModal: false,
        showFiltersModal: false
    };

    componentDidMount() {
        this.props.updatePage('Emails');
        this.loadEmails();
    }

    loadEmails(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingEmails: true });

        this.props.loadEmails(page, per_page, this.state.filters, (res) => {
            this.setState({ emails: res.data.data, loadingEmails: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingEmails: false });
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.loadEmails());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        let pagination = {...this.state.pagination, current: 1};

        this.setState({ filters, pagination }, () => this.loadEmails());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.loadEmails());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value = null;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    render() {
        const { Link } = Typography;

        const columns = [
            { title: 'Subject', dataIndex: 'subject', render: (item, record) => <strong><Link onClick={() => this.setState({ activeEmail: record.guid, showEmailModal: true })}>{item}</Link></strong>},
            { title: 'Sent', dataIndex: 'date_sent', render: (item, record) => record.is_sent ? <DateTimeFormat value={item} /> : 'Scheduled' },
            { align: 'right', render: (item, record) => <Button size='small' onClick={() => this.setState({ activeEmail: record.guid, showEmailModal: true })} icon={<EyeOutlined />}>View</Button>}
        ];

        return(
            <Fragment>
                <p>{this.showFilters()}</p>

                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button type='text' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                            <Button type='text' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadEmails()}/></Tooltip>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.emails}
                    loading={this.state.loadingEmails}
                    pagination={this.state.pagination}
                    onChange={(pagination) => this.loadEmails(pagination.current, pagination.pageSize)}
                />

                <EmailViewModal
                    guid={this.state.activeEmail}
                    showModal={this.state.showEmailModal}
                    close={() => this.setState({ showEmailModal: false, activeEmail: null })} />

                <EmailsTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, loadEmails })(Emails);