import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Select, Form, Modal, Space, Button, Alert, message} from "antd";
import DomainGroupSelector from "./DomainGroupSelector";
import {displayErrors} from "../../libs/utils";
import {runBulkDomainActions} from "../../actions/domains";

class DomainBulkActionsModal extends React.Component {
    state = {
        step: 1,
        selectedAction: 'CHANGE_GROUP',
        destinationDomainGroup: '',
        loading: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal() {
        this.props.close();
        this.resetForm();
    }

    closeModalSuccess() {
        this.closeModal();
        this.props.reloadDomains();
        this.props.deselectDomains();
    }

    nextStep(step = null) {
        if(step === null) {
            this.setState({ step: this.state.step + 1 });
        }
        else
        {
            this.setState({ step });
        }
    }

    resetForm() {
        this.setState({ step: 1, selectedAction: 'CHANGE_GROUP', loading: false, destinationDomainGroup: '' });
    }

    runBulkAction() {
        this.setState({ loading: true });

        let data = {
            domain_list: this.props.selectedDomains,
            action: this.state.selectedAction,
            group: this.props.domainGroup
        };

        if(this.state.selectedAction === 'CHANGE_GROUP')
        {
            data['destination_group'] = this.state.destinationDomainGroup;
        }

        this.props.runBulkDomainActions(data, () => {
            this.setState({ loading: false });
            message.success('Action successfully performed!');
            this.closeModalSuccess();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option, OptGroup } = Select;

        let content = '';
        let actionButton = <Button type='primary' onClick={() => this.nextStep()}>Next</Button>;

        if(this.state.step === 1)
        {
            content = <Form layout='vertical'>
                <Form.Item label='Select action'>
                    <Select value={this.state.selectedAction} onChange={(value) => this.setState({ selectedAction: value })}>
                        <OptGroup label='Domain'>
                            <Option value='CHANGE_GROUP'>Move to other group</Option>
                            <Option value='TOGGLE_CACHING'>Toggle caching</Option>
                            <Option value='DELETE_DOMAINS'>Delete domains</Option>
                        </OptGroup>
                        <OptGroup label='SSL Certificate'>
                            <Option value='INSTALL_LETS_ENCRYPT'>Install Let's Encrypt</Option>
                            <Option value='UNINSTALL_SSL_CERTIFICATE'>Uninstall SSL certificate</Option>
                        </OptGroup>
                        <OptGroup label='Software'>
                            <Option value='INSTALL_SOFTWARE' disabled>Install software</Option>
                            <Option value='UNINSTALL_SOFTWARE'>Uninstall software</Option>
                        </OptGroup>
                        <OptGroup label='Redirects'>
                            <Option value='TOGGLE_REDIRECT_TO_WWW'>Toggle the redirect to www subdomain</Option>
                            <Option value='TOGGLE_REDIRECT_TO_NONWWW'>Toggle the redirect to root domain</Option>
                            <Option value='TOGGLE_REDIRECT_TO_HTTP'>Toggle the redirect to HTTP</Option>
                            <Option value='TOGGLE_REDIRECT_TO_HTTPS'>Toggle the redirect to HTTPS</Option>
                        </OptGroup>
                    </Select>
                </Form.Item>
            </Form>;
        }
        else if(this.state.step === 2)
        {
            if(this.state.selectedAction === 'CHANGE_GROUP') {
                content = <Fragment>
                    <p>Please select the destination group:</p>
                    <DomainGroupSelector onChange={(value) => this.setState({ destinationDomainGroup: value })} />
                </Fragment>
            }
            else if(this.state.selectedAction === 'DELETE_DOMAINS')
            {
                content = <Alert type='warning' showIcon description={'Are you sure you wish to delete these domains?'} />;
            }
            else if(this.state.selectedAction === 'INSTALL_LETS_ENCRYPT')
            {
                content = <Alert type='warning' showIcon description={'Are you sure you wish to install Let\'s Encrypt to these domains?'} />;
            }
            else if(this.state.selectedAction === 'UNINSTALL_SSL_CERTIFICATE')
            {
                content = <Alert type='warning' showIcon description={'Are you sure you wish to uninstall SSL certificates from these domains?'} />;
            }
            else if(this.state.selectedAction === 'UNINSTALL_SOFTWARE')
            {
                content = <Alert type='warning' showIcon description={'Are you sure you wish to uninstall software from these domains?'} />;
            } else if(this.state.selectedAction === 'TOGGLE_CACHING') {
                content = <Alert type='warning' showIcon description={'Are you sure you wish to update caching for these domains?'} />;
            } else if(this.state.selectedAction === 'TOGGLE_REDIRECT_TO_WWW') {
                content = <Alert type='warning' showIcon description={'Are you sure you wish to toggle redirection of these domains to www subdomain?'} />;
            } else if(this.state.selectedAction === 'TOGGLE_REDIRECT_TO_NONWWW') {
                content = <Alert type='warning' showIcon description={'Are you sure you wish to toggle redirection of these domains to the root domain?'} />;
            } else if(this.state.selectedAction === 'TOGGLE_REDIRECT_TO_HTTP') {
                content = <Alert type='warning' showIcon description={'Are you sure you wish to toggle redirection of these domains to the HTTP protocol?'} />;
            } else if(this.state.selectedAction === 'TOGGLE_REDIRECT_TO_HTTPS') {
                content = <Alert type='warning' showIcon description={'Are you sure you wish to toggle redirection of these domains to the HTTPS protocol?'} />;
            }

        }
        else if(this.state.step === 3)
        {
            if(this.state.selectedAction === 'CHANGE_GROUP')
            {
                actionButton = <Button type='primary' loading={this.state.loading} disabled={this.state.loading} onClick={() => this.runBulkAction()}>Move Domains</Button>;
                content = <Alert type='info' message='Click on "Move Domains" to process your request.' showIcon />;
            }
            else if(this.state.selectedAction === 'DELETE_DOMAINS')
            {
                actionButton = <Button type='primary' danger loading={this.state.loading} disabled={this.state.loading} onClick={() => this.runBulkAction()}>Delete Domains</Button>;
                content = <Alert type='info' message='Click on "Delete Domains" to process your request.' showIcon />;
            }
            else if(this.state.selectedAction === 'INSTALL_LETS_ENCRYPT')
            {
                actionButton = <Button type='primary' loading={this.state.loading} disabled={this.state.loading} onClick={() => this.runBulkAction()}>Install</Button>;
                content = <Alert type='info' message='Click on "Install" to process your request.' showIcon />;
            }
            else if(this.state.selectedAction === 'UNINSTALL_SSL_CERTIFICATE')
            {
                actionButton = <Button type='primary' loading={this.state.loading} disabled={this.state.loading} onClick={() => this.runBulkAction()}>Uninstall</Button>;
                content = <Alert type='info' message='Click on "Uninstall" to process your request.' showIcon />;
            }
            else if(this.state.selectedAction === 'UNINSTALL_SOFTWARE')
            {
                actionButton = <Button type='primary' loading={this.state.loading} disabled={this.state.loading} onClick={() => this.runBulkAction()}>Uninstall</Button>;
                content = <Alert type='info' message='Click on "Uninstall" to process your request.' showIcon />;
            }
            else if(['TOGGLE_CACHING', 'TOGGLE_REDIRECT_TO_WWW', 'TOGGLE_REDIRECT_TO_NONWWW', 'TOGGLE_REDIRECT_TO_HTTP', 'TOGGLE_REDIRECT_TO_HTTPS'].indexOf(this.state.selectedAction) !== -1)
            {
                actionButton = <Button type='primary' loading={this.state.loading} disabled={this.state.loading} onClick={() => this.runBulkAction()}>Toggle</Button>;
                content = <Alert type='info' message='Click on "Toggle" to process your request.' showIcon />;
            }
        }

        return(
            <Modal
                centered
                title='Bulk Update'
                open={this.props.showModal}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    {actionButton}
                </Space>}
                onCancel={() => this.closeModal()}
                destroyOnClose={true}>
                {content}
            </Modal>
        );
    }
}

export default connect(null, { runBulkDomainActions })(DomainBulkActionsModal);