import React from "react";
import {connect} from "react-redux";
import {createCompanyUser} from "../../actions/account";
import {Alert, Button, Form, Input, message, Modal, Space, Tooltip} from "antd";
import {displayErrors, generateRandomString} from "../../libs/utils";
import {RetweetOutlined} from "@ant-design/icons";

class CompanyUserCreationModal extends React.Component {
    state = {
        creatingUser: false,

        firstName: '',
        lastName: '',
        email: '',
        password: '',
    };

    closeModal(reload = false) {
        this.setState({
            creatingUser: false,
            firstName: '',
            lastName: '',
            email: '',
            password: ''
        });

        this.props.close(reload);
    }

    createUser() {
        if(this.state.firstName.trim().length === 0) {
            return message.error('Please enter the first name!');
        }

        if(this.state.lastName.trim().length === 0) {
            return message.error('Please enter the last name!');
        }

        if(this.state.email.trim().length === 0) {
            return message.error('Please enter the email!');
        }

        if(this.state.password.trim().length === 0) {
            return message.error('Please enter the password!');
        }

        let data = {
            first_name: this.state.firstName.trim(),
            last_name: this.state.lastName.trim(),
            email: this.state.email.trim().toLowerCase(),
            password: this.state.password.trim()
        };

        this.setState({ creatingUser: true });

        this.props.createCompanyUser(this.props.companyGuid, data, () => {
            this.setState({ creatingUser: true });
            message.success('User successfully created!');

            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ invitingUser: false });
            }
        });
    }

    render() {
        return(
            <Modal
                title='Create User'
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()} loading={this.state.creatingUser} disabled={this.state.creatingUser}>Close</Button>
                    <Button type='primary' onClick={() => this.createUser()} loading={this.state.creatingUser} disabled={this.state.creatingUser}>Create User</Button>
                </Space>}
                open={this.props.open}>

                <Alert type='warning' showIcon message={<span>The created user can only be used within the company: <b>{this.props.companyName}</b> and it cannot own or create new companies.</span>} />
                <br />
                <Form layout='vertical'>
                    <Form.Item label='First name'>
                        <Input value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Last name'>
                        <Input value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Email address'>
                        <Input type='email' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Password'>
                        <Input.Password addonAfter={<Tooltip title='Generate password'><Button size='small' type='text' onClick={() => this.setState({ password: generateRandomString()})} icon={<RetweetOutlined />} /></Tooltip>} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { createCompanyUser })(CompanyUserCreationModal);