import React from "react";
import {connect} from "react-redux";
import {Alert, Button, Modal, Space, Typography} from "antd";

class DomainImportOptionsModal extends React.Component {
    render() {
        const { Text } = Typography;

        return(
            <Modal
                centered
                title='Import Options'
                open={this.props.showModal}
                destroyOnClose={true}
                footer={<Space>
                    <Button onClick={() => this.props.close()}>Close</Button>
                </Space>}
                onCancel={() => this.props.close()}>
                <p>Below you can find the format that you need to use when you are importing domains from clipboard or from file.</p>
                <p><Text code>DOMAIN,IP ADDRESS,SSL (true or 1/false or 0),REDIRECT TO HTTPS (true or 1/false or 0), SOFTWARE,PROTOCOL,SOFTWARE PRESET (UUID)</Text></p>
                <p>For example: add the domain "priorityprospect.com" to the IP address: "10.0.0.1", install Let's Encrypt, redirect to HTTPS and install WordPress.</p>
                <p><Text code>priorityprospect.com,10.0.0.1,true,true,WORDPRESS,https://</Text></p>
                <p>If you don't wish to install SSL, but still wish to install WordPress, you can leave SSL and REDIRECT TO HTTPS parameters empty like:</p>
                <p><Text code>priorityprospect.com,10.0.0.1,,,WORDPRESS</Text></p>
                <p>If you don't wish to install SSL or software and don't wish to specify the hosting account, you can use the following format:</p>
                <p><Text code>priorityprospect.com,10.0.0.1</Text></p>
                <Alert message='When importing domains like this, keep 1 domain per row!' showIcon type='warning' />
            </Modal>
        );
    }
}

export default connect(null, { })(DomainImportOptionsModal);