import React from 'react';
import { connect } from 'react-redux';
import {Alert, Button, Col, Form, Input, message, Row} from "antd";
import qs from "qs";
import {updatePage} from "../../actions/page";
import {displayErrors} from "../../libs/utils";
import {verifyContactEmail} from "../../actions/account";
import {Link} from "react-router-dom";

class VerifyContact extends React.Component  {
    state = {
        loading: false,
        hash: null,
        code: '',
        success: false
    };

    componentDidMount() {
        this.props.updatePage('Verify Email Address');

        let parsed = qs.parse(this.props.location.search.substring(1));

        if(typeof parsed !== 'undefined') {
            if(typeof parsed.hash !== 'undefined' && parsed.hash.length > 0) {
                this.setState({ hash: parsed.hash });
            }
        }
    }

    sendVerification() {
        this.setState({ loading: true });

        let code = this.state.code.trim();

        if(code.length === 0) {
            return message.error('Please enter the verification code from the email!');
        }

        let data = {
            hash: this.state.hash,
            verification_code: code
        };

        this.props.verifyContactEmail(data, (res) => {
            this.setState({ loading: false, success: true });
        }, (err) => {
            this.setState({ loading: false});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        let content = '';

        if(this.state.success)
        {
            content = <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Alert showIcon type='success' message='Email successfully verified! You may close this browser window now.' />
                </Col>
            </Row>;
        }
        else
        {
            content = <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form layout='vertical'>
                        <Form.Item label='Enter verification code:'>
                            <Input value={this.state.code} onChange={(e) => this.setState({ code: e.target.value })} />
                        </Form.Item>
                        <Button type='primary' disabled={this.state.loading} loading={this.state.loading} onClick={() => this.sendVerification()} block>Verify</Button>
                    </Form>
                </Col>
            </Row>;
        }

        return(
            <Row className='recover-wrapper'>
                <Col xs={24} sm={12}>
                    <div className='register-form'>
                        <img className='auth-mobile-logo' alt='Priority Prospect' src='/img/logo-top-dark.png' />
                        <h1 className='text-center'>Verify Contact</h1>
                        <Alert showIcon message={<span>By verifying your email address, you agree to our&nbsp;
                            <a href='https://priorityprospect.com/legal/terms-of-service/' rel='noopener noreferrer' target='_blank'>terms of service</a> and&nbsp;
                            <a href='https://priorityprospect.com/legal/privacy-policy/' rel='noopener noreferrer'>privacy policy</a>.</span>} />
                        <div className='text-left'>
                            {content}
                        </div>
                    </div>
                </Col>
                <Col xs={0} sm={12} className='theme-auth-side'>
                    <div className='auth-side-container'>
                        <h1>Already have an account?</h1>
                        <img src='/img/logo-square.png' style={{width: '100%'}} alt='Priority Prospect' />
                        <p style={{marginTop: '25px'}}>
                            <Link to={'/auth/login'}>
                                <Button type='primary'>Log-in Now!</Button>
                            </Link>
                        </p>
                    </div>
                </Col>
            </Row>
        );
    }
}
export default connect(null, { updatePage, verifyContactEmail })(VerifyContact);