import React from "react";
import {connect} from "react-redux";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Table} from "antd";
import {displayErrors} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {loadHostingAccountDomains} from "../../actions/hosting";
import {Link} from "react-router-dom";
import {StatusIndicator} from "../shared/Status";
import {LinkOutlined} from "@ant-design/icons";
import AccessGate from "../permissions/AccessGate";

class HostingAccountDomainsTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        domains: [],
        loadingDomains: true
    }

    componentDidMount() {
        this.loadDomains();
    }

    loadDomains(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingDomains: true });

        this.props.loadHostingAccountDomains(this.props.guid, page, per_page, (res) => {
            this.setState({ domains: res.data.data, loadingDomains: false, pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDomains: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'Domain', dataIndex: 'domain', render: (item) => <a target='_blank' rel='noopener noreferrer' href={'http://' + item}>{item}</a>},
            { title: 'IP address', dataIndex: 'ip_address', render: (item) => item.ip_address },
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el={'badge'} /> },
            { title: 'Domain group', dataIndex: 'group', render: (item) => <Link to={'/domains/' + item.guid}><strong>{item.name}</strong></Link>},
            { title: 'Created', dataIndex: 'date_created', render: (item, record) => <DateTimeFormat value={record.date_created} /> },
            { align: 'right', render: (item, record) => <a target='_blank' rel='noopener noreferrer'  href={'https://' + record.domain}><Button icon={<LinkOutlined />} size='small'>Visit</Button></a>}
        ];

        return(
            <AccessGate scopes={['can_view_domains']}>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.domains}
                    loading={this.state.loadingDomains}
                    pagination={this.state.pagination}
                    onChange={(pagination) => this.loadDomains(pagination.current, pagination.pageSize)}
                />
            </AccessGate>
        )
    }
}

export default connect(null, { loadHostingAccountDomains })(HostingAccountDomainsTable);