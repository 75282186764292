import React, {Fragment} from "react";
import {connect} from "react-redux";
import {deleteEmailAddress, loadEmailAddresses} from "../../actions/domains";
import {Button, Card, message, Popconfirm, Space, Table, Tooltip} from "antd";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import EmailAddressModal from "./EmailAddressModal";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {ReloadOutlined} from "@ant-design/icons";
import {StatusIndicator} from "../shared/Status";
import AccessItem from "../permissions/AccessItem";
import {hasPermission} from "../permissions/PermissionUtils";

class EmailAddressOverview extends React.Component {
    state = {
        loading: false,
        showModal: false,
        selectedEmailAddress: null,
        emails: []
    };

    componentDidMount() {
        this.loadEmailAddresses();
    }

    loadEmailAddresses() {
        this.setState({ loading: true });

        this.props.loadEmailAddresses(this.props.guid, (res) => {
            this.setState({ loading: false, emails: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    deleteEmailAddress(guid) {
        this.setState({ loading: true });

        this.props.deleteEmailAddress(guid, () => {
            message.success('Email address deletion successfully scheduled!')
            this.loadEmailAddresses();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    render() {
        const columns = [
            { title: 'Email', dataIndex: 'address', key: 'address', render: (item, row) => row.address},
            { title: 'Type', dataIndex: 'email_type', key: 'email_type', render: (item, row) => normalizeEnum(row.email_type)},
            { title: 'Forward To', dataIndex: 'forward_address', key: 'forward_address', render: (item, row) => row.forward_address},
            { title: 'Created', key: 'records', render: (item, row) => <DateTimeFormat value={row.date_created} />},
            { title: 'Status', key: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator el='badge' status={item} />},
        ];

        if(hasPermission(this.props.company.permissions, ['can_delete_email_addresses'])) {
            columns.push({ title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Popconfirm
                        onConfirm={() => this.deleteEmailAddress(row.guid)}
                        placement='topRight'
                        title="Are you sure?"
                        okText="Delete"
                        cancelText="Cancel">
                        <Tooltip placement='left' title='Delete email address'>
                            <Button size='small' disabled={row.status !== 'ACTIVE' && row.status !== 'PENDING'} danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>;
                }});
        }

        return(
            <Fragment>
                <Card bordered={false} size='small' title='Email Addresses' extra={<Space>
                    <AccessItem scopes={['can_add_email_addresses']}>
                        <Button type='primary' size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showModal: true })}>Add Email Address</Button>
                    </AccessItem>
                    <Tooltip title='Reload table'>
                        <Button size='small' icon={<ReloadOutlined />} onClick={() => this.loadEmailAddresses()} />
                    </Tooltip>
                </Space>}>
                    <Table loading={this.state.loading}
                           columns={columns}
                           dataSource={this.state.emails}
                           pagination={false} size='small'
                           bordered={false} rowKey={(item) => item.guid} />
                </Card>

                <EmailAddressModal
                    showModal={this.state.showModal}
                    domain={this.props.guid}
                    guid={this.state.selectedEmailAddress}
                    domainName={this.props.domainName}
                    closeModal={() => this.setState({ showModal: false, selectedEmailAddress: null })} reloadEmailAddresses={() => this.loadEmailAddresses()} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { loadEmailAddresses, deleteEmailAddress })(EmailAddressOverview);