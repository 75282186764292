import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Card, Col, Form, Input, Row, Radio, Select, Checkbox, Button, message} from "antd";
import countryList from "react-select-country-list";
import {submitAffiliateApplication} from "../../actions/affiliate";
import {displayErrors} from "../../libs/utils";

class AffiliateSignup extends React.Component {
    state = {
        account_type: 'COMPANY',
        first_name: '',
        last_name: '',
        company_name: '',
        address1: '',
        address2: '',
        city: '',
        region: '',
        postal_code: '',
        country: '',
        phone: '',
        promotion_platforms: '',
        promotion_methods: '',

        agreeAffiliateTerms: false,
    };

    countries = countryList().getData();

    componentDidMount() {
        this.props.updatePage('Create Affiliate Account');
    }

    submitApplication() {
        let account_type = this.state.account_type;
        let first_name = this.state.first_name.trim();
        let last_name = this.state.last_name.trim();
        let company_name = this.state.company_name.trim();
        let address1 = this.state.address1.trim();
        let address2 = this.state.address2.trim();
        let city = this.state.city.trim();
        let region = this.state.region.trim();
        let postal_code = this.state.postal_code.trim();
        let country = this.state.country.trim();
        let phone = this.state.phone.trim();
        let promotion_platforms = this.state.promotion_platforms.trim();
        let promotion_methods = this.state.promotion_methods.trim();

        if(account_type === 'PERSONAL') {
            company_name = first_name + ' ' + last_name;
        }

        if(first_name.length === 0) {
            return message.error('Please enter your first name!');
        }

        if(last_name.length === 0) {
            return message.error('Please enter your last name!');
        }

        if(address1.length === 0) {
            return message.error('Please enter your address!');
        }

        if(city.length === 0) {
            return message.error('Please enter your city!');
        }

        if(region.length === 0) {
            return message.error('Please enter your region!');
        }

        if(postal_code.length === 0) {
            return message.error('Please enter your postal code!');
        }

        if(country.length === 0) {
            return message.error('Please select your postal country!');
        }

        if(phone.length === 0) {
            return message.error('Please enter your postal phone!');
        }

        if(promotion_platforms.length === 0) {
            return message.error('Please describe which platforms you plan to use.');
        }

        if(promotion_methods.length === 0) {
            return message.error('Please describe which promotional methods you plan to use.');
        }

        let data = { account_type, first_name, last_name, company_name, address1, address2, city, region,
            postal_code, country, phone, promotion_platforms, promotion_methods };

        this.setState({ loading: true });

        this.props.submitAffiliateApplication(data, () => {
            message.success('Your application has been successfully submitted! It will be reviewed by our team within 2 working days.')
            this.history.push('/affiliate/');

        }, (err) => {
            this.setState({ loading: false });
            displayErrors(err.response.data);
        });
    }

    render() {
        const { Option } = Select;
        const { TextArea } = Input;

        const accountTypes = [
            { label: 'Company', value: 'COMPANY' },
            { label: 'Personal', value: 'PERSONAL' },
        ];

        return <Row>
            <Col md={{ span: 12, offset: 6}} sm={24}>
                <Card size='small' title='Create Affiliate Account' className='card-bottom-margin'>
                    <Form layout='vertical'>
                        <Form.Item label='Account type'>
                            <Radio.Group options={accountTypes} onChange={({ target: { value }}) => this.setState({ account_type: value })} value={this.state.account_type} />
                        </Form.Item>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label='First name'>
                                    <Input value={this.state.first_name} onChange={(e) => this.setState({ first_name: e.target.value })} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label='Last name'>
                                    <Input value={this.state.last_name} onChange={(e) => this.setState({ last_name: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {this.state.account_type === 'COMPANY' ? <Form.Item label='Company name' >
                            <Input value={this.state.company_name} onChange={(e) => this.setState({ company_name: e.target.value })} />
                        </Form.Item> : ''}

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label='Address'>
                                    <Input value={this.state.address1} onChange={(e) => this.setState({ address1: e.target.value })} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label='Address 2'>
                                    <Input value={this.state.address2} placeholder='Optional' onChange={(e) => this.setState({ address2: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label='Region'>
                                    <Input value={this.state.region} onChange={(e) => this.setState({ region: e.target.value })} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label='Country'>
                                    <Select
                                        placeholder='Select country'
                                        showSearch
                                        filterOption={(input, option) => {
                                            if(typeof option !== 'undefined') {
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }
                                            return false;
                                        }}
                                        onChange={(value) => this.setState({ country: value })}>
                                        {this.countries.map((key) => {
                                            return <Option key={key.value} value={key.value}>{key.label}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label='City'>
                                    <Input value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label='Postal code'>
                                    <Input value={this.state.postal_code} onChange={(e) => this.setState({ postal_code: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label='Phone'>
                            <Input value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                        </Form.Item>
                        <Form.Item label='On which platforms you would promote us?' extra={<small>List platforms and websites where you plan to promote our service. Be as detailed as possible.</small>}>
                            <TextArea value={this.state.promotion_platforms} rows={4} onChange={(e) => this.setState({ promotion_platforms: e.target.value })} />
                        </Form.Item>
                        <Form.Item label='How you would promote us?' extra={<small>Please describe your methods how you plan to promote our service. Be as detailed as possible.</small>}>
                            <TextArea value={this.state.promotion_methods} rows={4} onChange={(e) => this.setState({ promotion_methods: e.target.value })} />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox onChange={(e) => this.setState({ agreeAffiliateTerms: e.target.checked }) }>
                                I hereby acknowledge that I have read, understand and agree Priority Prospect OÜ's <a href='https://priorityprospect.com/legal/affiliate-agreement/' rel="noopener noreferrer"  target='_blank'><b><u>affiliate agreement</u></b></a>.
                            </Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button disabled={!this.state.agreeAffiliateTerms} onClick={() => this.submitApplication()} type='primary'>Submit Application</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>;
    }
}

export default connect(null, { updatePage, submitAffiliateApplication })(AffiliateSignup);