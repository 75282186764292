import React from 'react';
import { connect } from 'react-redux';
import {Alert, Button, Col, message, Modal, Radio, Result, Row, Switch} from "antd";
import {installLetsEncrypt} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";

class SSLCertificateInstallModal extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            selectedType: 0,
            installingLetsEncrypt: false,
            radioButtonsDisabled: false,
            include_www_subdomain: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal() {
        this.props.closeModal();
        this.setState({ step: 1, installLetsEncrypt: false, radioButtonsDisabled: false });
    }

    installLetsEncrypt() {
        this.setState({ installingLetsEncrypt: true, radioButtonsDisabled: true });

        let data = {
            is_lets_encrypt: true,
            include_www_subdomain: this.state.include_www_subdomain
        };

        this.props.installLetsEncrypt(this.props.domain, data, () => {
            this.props.closeModal();
            this.props.loadOverview();

            this.setState({ page: 2, installingLetsEncrypt: false });

            message.success('Let\'s Encrypt installation successfully scheduled!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ installingLetsEncrypt: false });
            }
        });
    };

    closeOnSuccess() {
        this.props.loadOverview();
        this.closeModal();
    }

    onTypeSelect(e) {
        this.setState( { selectedType: e.target.value });
    }

    render() {
        let footerButtons = '';

        if(this.state.page === 1 && this.state.selectedType === 0) {
            footerButtons = <div>
                <Button onClick={() => this.props.closeModal()} disabled={this.state.installingLetsEncrypt}>Cancel</Button>
                <Button type='primary' onClick={() => this.installLetsEncrypt()} loading={this.state.installingLetsEncrypt}>Install Let's Encrypt</Button>
            </div>;
        } else if(this.state.page === 1 && this.state.selectedType === 1) {
            footerButtons = <div>
                <Button onClick={() => this.props.closeModal()}>Cancel</Button>
                <Button type='primary' onClick={() => this.setState({ step: 3 })}>Next</Button>
            </div>;
        }

        let content = '';

        if(this.state.page === 1) {
            let style = {
                display: 'block'
            };

            content = <div>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <p>Select which SSL certificate you wish to install.</p>
                        <Radio.Group onChange={(e) => this.onTypeSelect(e)} value={this.state.selectedType}>
                            <Radio disabled={this.state.radioButtonsDisabled} style={style} value={0}>Let's Encrypt</Radio>
                            <Radio disabled={true} style={style} value={1}>Custom SSL certificate</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginTop: '10px'}} hidden={this.props.domainType === 'DOMAIN'}>
                    <Col span={2}>
                        <Switch size='small' onChange={(checked) => this.setState({ include_www_subdomain: checked })} />
                    </Col>
                    <Col span={22}>
                        <p>
                            Include the "www" subdomain.<br />
                            <small><em>This will activate the SSL certificate for the www subdomain as well.</em></small>
                        </p>
                    </Col>
                </Row>
                <Row hidden={!this.state.include_www_subdomain}>
                    <Col span={24}>
                        <Alert showIcon={true} type='warning' message={<span>You must have a DNS record created for the "www" subdomain in your DNS zone <strong>(www.{this.props.domainName})</strong>! If not the installation will fail.</span>} />
                    </Col>
                </Row>
            </div>;
        } else if(this.state.page === 2) {
            content = <Result
                status="success"
                title="Success!"
                subTitle="SSL certificate successfully requested! It will be installed within 15 minutes."
                extra={[<Button type="primary" onClick={() => this.closeOnSuccess()}> Close</Button>]}
            />;
        }

        return (
            <Modal
                centered
                title='Install SSL Certificate'
                open={this.props.showModal}
                onCancel={() => this.closeModal()}
                footer={footerButtons}
                destroyOnClose={true}>
                {content}
            </Modal>
        )
    }
}

export default connect(null, { installLetsEncrypt })(SSLCertificateInstallModal);