import React from "react";
import {connect} from "react-redux";
import {Alert, Button, Form, Input, message, Modal, Select, Space, Tooltip, Typography} from "antd";
import {createSubdomain, loadDomainGroupIPAddresses} from "../../actions/domains";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {UsergroupAddOutlined, UserOutlined} from "@ant-design/icons";
import {loadHostingAccounts} from "../../actions/hosting";

class SubdomainModal extends React.Component {
    state = {
        loading: false,
        subdomain: '',
        ipAddress: null,
        ipAddresses: [],
        loadingIPAddresses: false,
        ipAddressesLoaded: false,
        loadingHostingAccounts: true,
        hostingAccountsLoaded: false,
        selectedHostingAccount: null,
        hostingAccounts: [],
        hostingAccountsList: []
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.showModal && !this.state.loadingIPAddresses && !this.state.ipAddressesLoaded) {
            this.loadIPAddresses();
            this.loadHostingAccounts();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    resetForm() {
        this.setState({
            loading: false,
            subdomain: '',
            ipAddress: null,
            ipAddresses: [],
            loadingIPAddresses: false,
            ipAddressesLoaded: false,
            loadingHostingAccounts: true,
            hostingAccountsLoaded: false,
            selectedHostingAccount: null,
            hostingAccounts: [],
            hostingAccountsList: []
        });
    }

    loadHostingAccounts() {
        this.props.loadHostingAccounts(1, 9999, (res) => {
            this.setState({ loadingHostingAccounts: false, hostingAccountsLoaded: true, hostingAccounts: res.data.data,
                selectedHostingAccount: this.props.activeHostingAccount });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingHostingAccounts: false, hostingAccountsLoaded: true })
            }
        });
    }

    loadIPAddresses() {
        this.setState({ loadingIPAddresses: true });

        this.props.loadDomainGroupIPAddresses(this.props.domainGroup, (res) => {
            this.setState({ ipAddresses: res.data.data, loadingIPAddresses: false, ipAddressesLoaded: true });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true });
                displayErrors(err.response.data);
            }
        });
    }

    closeModal() {
        this.resetForm();
        this.props.closeModal();
    }

    saveSubdomain() {
        this.setState({ loading: true });

        let subdomain = this.state.subdomain.trim();

        if(subdomain.length === 0) {
            return message.error('Please enter a subdomain!');
        }

        if(this.state.selectedHostingAccount === null) {
            return message.error('Please select a hosting account!');
        }

        if(this.state.ipAddress === null) {
            return message.error('Please select an IP address!');
        }

        let data = {
            'subdomain': subdomain,
            'is_wildcard': subdomain === '*',
            'ip_address': this.state.ipAddress,
            'hosting_account': this.state.selectedHostingAccount,
        };

        this.props.createSubdomain(this.props.rootDomainGuid, data, () => {
            this.setState({ loading: false });
            message.success('Subdomain successfully created!');
            this.closeAndReloadSubdomains();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    closeAndReloadSubdomains() {
        this.resetForm();

        this.props.reloadSubdomains();
        this.props.closeModal();
    }

    updateSubdomain(value) {
        const { Option } = Select;

        /*if(value.trim() === '*') {
            let hostingAccounts = [];

            for(let i = 0; i < this.state.hostingAccounts.length; i++) {
                if(this.state.hostingAccounts[i].account_type === 'CUSTOM_HOST') {
                    let account = this.state.hostingAccounts[i];

                    hostingAccounts.push(<Option key={account.guid} value={account.guid}>{account.name} <small>({normalizeEnum(account.account_type) + ' - ' + normalizeEnum(account.status)})</small></Option>);
                }
            }

            this.setState({subdomain: value.trim(), hostingAccountsList: hostingAccounts });
        } else {
            let hostingAccounts = [];

            for(let i = 0; i < this.state.hostingAccounts.length; i++) {
                let account = this.state.hostingAccounts[i];

                hostingAccounts.push(<Option key={account.guid} value={account.guid}>{account.name} <small>({normalizeEnum(account.account_type) + ' - ' + normalizeEnum(account.status)})</small></Option>);
            }

            this.setState({ subdomain: value.toLowerCase().replace(/[^a-z0-9-]/g, ''), hostingAccountsList: hostingAccounts });
        }*/

        let hostingAccounts = [];

        for(let i = 0; i < this.state.hostingAccounts.length; i++) {
            let account = this.state.hostingAccounts[i];

            hostingAccounts.push(<Option key={account.guid} value={account.guid}>{account.name} <small>({normalizeEnum(account.account_type) + ' - ' + normalizeEnum(account.status)})</small></Option>);
        }

        this.setState({ subdomain: value.toLowerCase().replace(/[^a-z0-9-*]/g, ''), hostingAccountsList: hostingAccounts });
    }

    render() {
        const { Paragraph } = Typography;
        const { Option, OptGroup } = Select;

        let ipAddresses = {};

        for(let i = 0; i < this.state.ipAddresses.length; i++) {
            let ipAddress = this.state.ipAddresses[i];

            if(typeof ipAddresses[ipAddress.country] === 'undefined') {
                ipAddresses[ipAddress.country] = [];
            }

            ipAddresses[ipAddress.country].push(ipAddress);
        }

        let options = Object.keys(ipAddresses).map((country) => {
            let ipOptions = ipAddresses[country].map((ip_address) => {
                return <Option disabled={ip_address.domain_count >= ip_address.domains_allowed} key={ip_address.guid} value={ip_address.guid}>
                    {ip_address.ip_address_type === 'DEDICATED' ? <Tooltip title='Dedicated'><UserOutlined className='ip-picker-icon' /></Tooltip> : <Tooltip title='Shared'><UsergroupAddOutlined className='ip-picker-icon' /></Tooltip> }
                    {ip_address.ip_address} <small>({ip_address.domain_count + '/' + ip_address.domains_allowed}) {ip_address.city}</small>
                </Option>;
            });

            return <OptGroup key={country} label={country}>
                {ipOptions.map((option) => {
                    return option;
                })}
            </OptGroup>;
        });

        return(
            <Modal
                title='Add a Subdomain'
                open={this.props.showModal}
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                    <Button  type='primary' onClick={() => this.saveSubdomain()} disabled={this.state.loading} loading={this.state.loading}>Create</Button>
                </Space>}>
                <Form layout='vertical'>
                    <Form.Item label='Name' extra={<small>Valid characters: a-z, 0-9, -, *</small>}>
                        <Input value={this.state.subdomain} onChange={(e) => this.updateSubdomain(e.target.value)} addonAfter={'.' + this.props.domainName} />
                    </Form.Item>
                    <Form.Item label='IP address' >
                        <Select value={this.state.ipAddress}
                                onChange={(value) => this.setState({ ipAddress: value })}
                                showSearch loading={this.state.loadingIPAddresses}
                                filterOption={(input, option) => {
                                    if(typeof option !== 'undefined' && Array.isArray(option.children)) {
                                        return option.children[1].indexOf(input.trim()) >= 0;
                                    }
                                    return false;
                                }}>
                            {options}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Hosting account'>
                        {/*this.state.subdomain.trim() === '*' ? <div><Alert showIcon message='Wildcard domains can only be used with custom hosts!' type='warning' /><br /></div> : ''*/}
                        <Select
                            value={this.state.selectedHostingAccount}
                            loading={this.state.loadingHostingAccounts}
                            disabled={this.state.loadingHostingAccounts}
                            showSearch
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            onChange={(value) => this.setState({ selectedHostingAccount: value})}>
                            {this.state.hostingAccountsList}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Preview'>
                        {this.state.subdomain !== '' ?  <Paragraph><pre>{this.state.subdomain}.{this.props.domainName}</pre></Paragraph> : <Alert message='Please enter your subdomain to see the preview!' type='info' />}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { createSubdomain,
    loadDomainGroupIPAddresses, loadHostingAccounts })(SubdomainModal);