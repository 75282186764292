import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {Col, Popconfirm, Row, Table, Tooltip, message, Space} from "antd";
import Button from "antd/es/button";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import AddSoftwarePresetModal from "./AddSoftwarePresetModal";
import {deleteSoftwarePreset, loadSoftwarePresets} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";
import {DEFAULT_DOMAINS_PER_PAGE} from "../../config";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import AddSoftwarePresetGroupModal from "./AddSoftwarePresetGroup";
import SoftwarePresetGroupTable from "./SoftwarePresetGroupTable";
import AddItemToSoftwarePresetGroupModal from "./AddItemToSoftwarePresetGroupModal";
import AccessItem from "../permissions/AccessItem";

class SoftwarePresets extends React.Component {
    state = {
        showAddModal: false,
        presetsLoaded: false,
        presets: [],
        pagination: { current: 1, pageSize: DEFAULT_DOMAINS_PER_PAGE, showSizeChanger: true, total: 0 },
        activeSoftwarePreset: null,
        showAddGroupModal: false,
        activeSoftwarePresetGroup: null,
        activeSoftwarePresetGroupType: null
    };

    componentDidMount() {
        this.loadPresets();
    }

    loadPresets(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ presetsLoaded: false });

        this.props.loadSoftwarePresets(page, per_page, (res) => {
            this.setState({ presets: res.data.data,
                pagination: { current: res.data.page, pageSize: res.data.per_page, showSizeChanger: true, total: res.data.total }, presetsLoaded: true });
        }, (err) => {
            this.setState({ presetsLoaded: true});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    deletePreset(guid) {
        this.setState({ presetsLoaded: false });

        this.props.deleteSoftwarePreset(guid, () => {
            message.success('Software preset successfully deleted!');
            this.loadPresets();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'guid', width: '350px', render: (item) => <code>{item}</code>},
            { title: 'Name', dataIndex: 'name'},
            { title: 'Software', dataIndex: 'software_name', align: 'center'},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: '', align: 'right', render: (item, record) => (
                    <Space>
                        <Button onClick={() => this.setState({ activeSoftwarePreset: record.guid})} icon={<EyeOutlined />} size='small'>View</Button>
                        <AccessItem scopes={['can_delete_software_presets']}>
                            <Popconfirm
                                title="Are you sure you want to delete this preset?"
                                onConfirm={() => this.deletePreset(record.guid)}
                                okText='Delete'
                                placement='topRight'
                                okButtonProps={{danger: true}}
                                cancelText='Cancel'>
                                <Button icon={<DeleteOutlined />} danger size='small' />
                            </Popconfirm>
                        </AccessItem>
                    </Space>
                )}
        ];

        return(
            <Fragment>
                <Row justify='space-between' style={{paddingBottom: '10px'}}>
                    <Col>
                        <AccessItem scopes={['can_add_software_presets']}>
                            <Button type='primary' icon={<PlusOutlined />} onClick={() => this.setState({ showAddModal: true })}>Add Software Preset</Button>
                        </AccessItem>
                    </Col>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadPresets()}/></Tooltip></Col>
                </Row>
                <Table
                    dataSource={this.state.presets}
                    columns={columns}
                    rowKey={(item) => item.guid}
                    pagination={this.state.pagination}
                    expandable={{
                        expandedRowRender: (record) => {
                            return <SoftwarePresetGroupTable
                                addGroup={() => this.setState({ showAddGroupModal: true })}
                                activePreset={this.state.activeSoftwarePreset}
                                reloadPresets={() => this.loadPresets()}
                                activeGroup={this.state.activeSoftwarePresetGroup}
                                setActiveGroup={(guid) => this.setState({ activeSoftwarePresetGroup: guid })}
                                addItems={(groupId, groupType) => this.setState({ showAddItemsModal: true, activeSoftwarePresetGroup: groupId, activeSoftwarePresetGroupType: groupType })}
                                data={record} />;
                        },
                        rowExpandable: () => true,
                        onExpand: (open, record) => {
                            if(open) {
                                this.setState({ activeSoftwarePreset: record.guid });
                            } else {
                                this.setState({ activeSoftwarePreset: null });
                            }
                        },
                        expandedRowKeys: [this.state.activeSoftwarePreset],
                    }}
                    onChange={(pagination) => this.loadPresets(pagination.current, pagination.pageSize)}
                    loading={!this.state.presetsLoaded} />

                <AddSoftwarePresetModal
                    reloadPresets={() => this.loadPresets()}
                    showModal={this.state.showAddModal}
                    close={() => this.setState({ showAddModal: false })} />

                <AddSoftwarePresetGroupModal
                    reloadPresets={() => this.loadPresets()}
                    showModal={this.state.showAddGroupModal}
                    activePreset={this.state.activeSoftwarePreset}
                    setActivePresetGroup={(guid) => {
                        this.setState({ activeSoftwarePresetGroup: guid });
                        setTimeout(() => {
                            document.querySelector('.ant-collapse-item-active').scrollIntoView({
                                behavior: 'smooth'
                            });
                        }, 100);
                    }}
                    close={() => this.setState({ showAddGroupModal: false })} />

                <AddItemToSoftwarePresetGroupModal
                    reloadPresets={() => this.loadPresets()}
                    showModal={this.state.showAddItemsModal}
                    activePreset={this.state.activeSoftwarePreset}
                    activeGroup={this.state.activeSoftwarePresetGroup}
                    activeGroupType={this.state.activeSoftwarePresetGroupType}
                    close={() => this.setState({ showAddItemsModal: false })} />
            </Fragment>
        );
    }
}


export default connect(null, { loadSoftwarePresets, deleteSoftwarePreset })(SoftwarePresets);