import React from "react";
import {connect} from "react-redux";
import {Alert} from "antd";
import {loadIssues} from "../../actions/system";
import {displayErrors} from "../../libs/utils";

class Issues extends React.Component {
    state = {
        issues: []
    };

    updateIssues = null;

    componentDidMount() {
        this.loadIssues();

        this.updateIssues = setInterval(() => {
            this.loadIssues();
        }, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.updateIssues);
    }

    loadIssues() {
        this.props.loadIssues((res) => {
            this.setState({ issues: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined' && err.response.status !== 401) {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        return(this.state.issues.map((item, i) => {
            return <Alert type='error' key={i} showIcon
                          message={<span dangerouslySetInnerHTML={{__html: item.message }} />}
                          style={{marginBottom: '10px'}} />;
        }));
    }
}

export default connect(null, { loadIssues })(Issues);