import React from "react";
import {connect} from "react-redux";
import {inviteUser} from "../../actions/account";
import {Button, Input, message, Modal, Space} from "antd";
import {displayErrors} from "../../libs/utils";

class CompanyUserInvitationModal extends React.Component {
    state = {
        invitingUser: false,
        invitationCode: '',
    };

    closeModal() {
        this.setState({ invitingUser: false, invitationCode: '' });
        this.props.reloadUsers();
        this.props.close();
    }

    inviteUser() {
        // TODO: Remove this
        let invitationCode = this.state.invitationCode.trim();

        if(invitationCode.length === 0) {
            return message.error('Please enter the invitation code!');
        }

        let data = {
            'invitation_code': invitationCode
        };

        this.setState({ invitingUser: true });

        this.props.inviteUser(this.props.companyGuid, data, () => {
            this.closeModal();
            this.loadUsers();

            message.success('User successfully invited!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ invitingUser: false });
            }
        });
    }

    render() {
        return(
            <Modal
                title='Invite User'
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()} loading={this.state.invitingUser} disabled={this.state.invitingUser}>Close</Button>
                    <Button type='primary' onClick={() => this.inviteUser()} loading={this.state.invitingUser} disabled={this.state.invitingUser}>Invite User</Button>
                </Space>}
                open={this.props.open}>
                <p>Please enter the invitation code to the input below:</p>
                <Input value={this.state.invitationCode} onChange={(e) => this.setState({ invitationCode: e.target.value })} />
            </Modal>
        );
    }
}

export default connect(null, { inviteUser })(CompanyUserInvitationModal);