import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Card, Tabs} from "antd";
import Profile from "../../components/account/Profile";
import Emails from "../../components/account/Emails";
import Logins from "../../components/account/Logins";
import Logs from "../../components/account/Logs";
import Companies from "../../components/account/Companies";
import Invitations from "../../components/account/Invitations";

class Account extends React.Component {
    state = {
        pages: ['profile', 'emails', 'notifications', 'logins', 'logs', 'companies', 'invitations'],
        titles: {
            profile: 'Profile', emails: 'Emails', notifications: 'Notifications', logins: 'Logins',
            logs: 'Logs', companies: 'Companies', invitations: 'Invitations'
        }
    };

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            if(page === 'profile') {
                this.props.history.push('/account');
            } else {
                this.props.updatePage(this.state.titles[page]);
                this.props.history.push('/account/' + page);
            }
        }
    }

    render() {
        let page = 'profile';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        let activeCompany = null;

        if(page === 'companies' && typeof this.props.match.params.key !== 'undefined') {
            activeCompany = this.props.match.params.key;
        }

        const items = [
            { label: 'Profile', key: 'profile', children: <Profile /> },
        ];

        if(this.props.company.user_type !== 'SUBUSER') {
            items.push({ label: 'Companies', key: 'companies', children: <Companies activeCompany={activeCompany} history={this.props.history} /> });
            items.push({ label: 'Invitations', key: 'invitations', children: <Invitations /> });
        }

        items.push({ label: 'Emails', key: 'emails', children: <Emails /> });
        items.push({ label: 'Logins', key: 'logins', children: <Logins /> });
        items.push({ label: 'Logs', key: 'logs', children: <Logs /> });

        return(
            <Card size='small'>
                <Tabs defaultActiveKey={page} onChange={(e) => this.changeTab(e)} items={items} destroyInactiveTabPane={true} />
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage })(Account);