import React from "react";
import {connect} from "react-redux";
import {Space, Button, Select, Form, Input, Modal, message} from "antd";
import {createUrlRedirect} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";
import AccessItem from "../permissions/AccessItem";

class DomainRedirectModal extends React.Component {
    state = {
        loading: false,
        responseCode: 301,
        urls: [],
        destination: '',
        requiresUpdate: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    createUrlRedirect(close = false) {
        let data = {
            response_code: this.state.responseCode,
            urls: this.state.urls,
            destination: this.state.destination.trim().toLowerCase()
        };

        if(this.state.urls.length === 0) {
            return message.error('Please enter at least one URL!');
        }

        if(this.state.destination.trim() === '') {
            return message.error('Please enter destination!');
        }

        this.setState({ loading: true });

        this.props.createUrlRedirect(this.props.rootDomainGuid, data, () => {
            message.success('URL redirect' + (this.state.urls.length > 1 ? 's' : '') + ' successfully created!');

            this.setState({ urls: [], destination: '', requiresUpdate: true, loading: false }, () => {
                if(close) {
                    this.setState({ response_code: 301, requiresUpdate: false });

                    this.props.closeModal();
                    this.props.reloadUrlRedirects();
                }
            });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    closeModal() {
        if(this.state.requiresUpdate) {
            this.props.reloadUrlRedirects();
        }

        this.props.closeModal();
    }

    render() {
        const { Option } = Select;
        const { TextArea } = Input;

        return(
            <Modal
                centered
                title={'New URL Redirect'}
                open={this.props.showModal}
                destroyOnClose={true}
                footer={<AccessItem scopes={['can_add_domain_url_redirects']}>
                    <Space>
                        <Button loading={this.state.loading} disabled={this.state.loading} onClick={() => this.createUrlRedirect()}>Create</Button>
                        <Button type='primary' loading={this.state.loading} disabled={this.state.loading}  onClick={() => this.createUrlRedirect(true)}>Create & Close</Button>
                    </Space>
                </AccessItem>}
                confirmLoading={this.state.loading}
                onCancel={() => this.closeModal()}>

                <Form layout='vertical'>
                    <Form.Item label='Response code'>
                        <Select defaultValue={this.state.responseCode} onChange={(value) => this.setState({ responseCode: value })}>
                            <Option value={301}>301 Moved Permanently</Option>
                            <Option value={302}>302 Found (Previously "Moved Temporarily")</Option>
                            <Option value={303}>303 See Other</Option>
                            <Option value={307}>307 Temporary Redirect</Option>
                            <Option value={308}>308 Permanent Redirect</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='URLs' extra={<small>One relative URL per row. Use * as a wildcard at the end of the URL. Eg: /products/*</small>}>
                        <TextArea placeholder='/url-to-redirect' value={this.state.urls.join('\n')} rows={7} onChange={(e) => this.setState({ urls: e.target.value.split('\n') })}  />
                    </Form.Item>
                    <Form.Item label='Redirect destination' extra={<small>The destination URL must an absolute and start with https:// or http://</small>}>
                        <Input placeholder='https://destination.com/page' value={this.state.destination} onChange={(e) => this.setState({ destination: e.target.value })} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { createUrlRedirect })(DomainRedirectModal);