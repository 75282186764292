import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Modal, Button, InputNumber, Alert, message} from "antd";
import Table from "antd/lib/table";
import {createIPAddressModification} from "../../actions/billing";
import {displayErrors} from "../../libs/utils";
import AccessItem from "../permissions/AccessItem";

class ModifyIPAddressesModal extends React.Component {
    state = {
        data: [],
        items: [],
        loading: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && this.state.data.length === 0) {
            let data = this.props.ipAddresses.filter(item => this.props.selectedIPAddresses.indexOf(item.guid) !== -1);

            data = data.map((item) => {
                return {...item, new_domains_allowed: item.domains_allowed };
            });

            this.setState({ data });
        }
    }

    updateData(guid, value) {
        let data = this.state.data.map((item) => {
            if(item.guid === guid) {
                return {...item, new_domains_allowed: value };
            }

            return item;
        });

        this.setState({ data });
    }

    closeModal() {
        this.props.close();
        this.setState({ data: [] });
    }

    submitRequest() {
        let data = [];

        for(let i = 0; i < this.state.data.length; i++) {
            if(this.state.data[i].domains_allowed !== this.state.data[i].new_domains_allowed) {
                data.push({
                    ip_address: this.state.data[i].guid,
                    value: this.state.data[i].new_domains_allowed
                })
            }
        }

        if(data.length === 0) {
            return message.error('Please change at least one IP address!');
        }

        this.setState({ loading: true });

        this.props.createIPAddressModification(data, (res) => {
            this.setState({ loading: false });

            if(typeof res.data.invoice_id !== 'undefined') {
                message.success('Success! Redirecting to invoice...', 5);
                this.props.history.push('/billing/invoices/' + res.data.invoice_id);
            } else {
                message.success('Success! IP address changes scheduled!');
            }
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'IP Address', dataIndex: 'ip_address' },
            { title: 'Available Slots', dataIndex: 'domains_allowed', align: 'center' },
            { title: 'Required Slots', width: '25%', render: (item, record) => {
                let maxDomainSlots = 1;

                if(record.ip_address_type === 'DEDICATED') {
                    if(record.domains_allowed > 10) {
                        maxDomainSlots = record.domains_allowed;
                    } else {
                        maxDomainSlots = 10;
                    }
                }

                let minDomainSlots = record.domain_count;

                if(record.domain_count === 1) {
                    minDomainSlots = 1;
                }

                return <InputNumber size='small' value={record.new_domains_allowed} min={minDomainSlots} max={maxDomainSlots} onChange={(value) => this.updateData(record.guid, value)} />
            }}
        ];

        return(
            <Modal
                open={this.props.showModal}
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={
                    <Fragment>
                        <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                        <AccessItem scopes={['can_edit_ip_addresses']}>
                            <Button disabled={this.state.loading} loading={this.state.loading} onClick={() => this.submitRequest()} type='primary'>Submit</Button>
                        </AccessItem>
                    </Fragment>
                }
                title='Modify IP Addresses'>
                <Alert showIcon type='info' message='Downgrades will be processed at the end of the billing cycle. Upgrades will be processed after the payment is received.' /><br />
                <Table
                    size='small'
                    dataSource={this.state.data}
                    columns={columns}
                    rowKey={(item) => item.guid}
                    pagination={false} />
            </Modal>
        );
    }
}

export default connect(null, { createIPAddressModification })(ModifyIPAddressesModal);