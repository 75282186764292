import React from 'react';
import {Button, Checkbox, Descriptions, Form, Input, message, Modal, Space} from "antd";
import {connect} from "react-redux";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {editContact, loadContact} from "../../actions/account";
import {Loading} from "../../libs/loading";

class EditCompanyContactModal extends React.Component {
    state = {
        first_name: '',
        last_name: '',
        email_address: '',
        events: [
            {
                name: 'Billing',
                fields: ['new_invoice', 'invoice_payment_reminder', 'new_payment', 'new_payment_method',
                    'payment_method_disabled', 'new_order', 'order_suspended', 'order_termination_warning',
                    'order_terminated', 'new_hosting_account', 'card_charge_failed']
            },
            {
                name: 'Domains',
                fields: ['new_domain', 'domain_deleted', 'software_install', 'software_uninstall',
                    'ssl_install', 'ssl_uninstall', 'ssl_renewal']
            }
        ],
        selectedEvents: [],
        savingContact: false,
        loadingContact: true,
        contactLoaded: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && this.state.loadingContact) {
            this.props.loadContact(this.props.companyGuid, this.props.activeContact, (res) => {
                this.setState({ loadingContact: false, contactLoaded: true, ...res.data, selectedEvents: res.data.selected_events });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({loadingContact: false});
                    displayErrors(err.response.data);
                }
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal() {
        this.setState({first_name: '', last_name: '', selectedEvents: [], savingContact: false, loadingContact: true, contactLoaded: false});
        this.props.close();
    }

    editContact() {
        let first_name = this.state.first_name.trim();
        let last_name = this.state.last_name.trim();
        let events = this.state.selectedEvents;

        if(first_name.length === 0) {
            return message.error('Please enter first name!');
        }

        if(last_name.length === 0) {
            return message.error('Please enter last name!');
        }

        let data = { first_name, last_name, events };

        this.setState({savingContact: true});

        this.props.editContact(this.props.companyGuid, this.props.activeContact, data, () => {
            message.success('Contact successfully updated!');

            this.setState({savingContact: false});
            this.closeModal();
            this.props.reloadContacts();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({savingContact: false});
                displayErrors(err.response.data);
            }
        });
    }

    updateSelectedEvents(field, value) {
        let selectedEvents = this.state.selectedEvents;

        if(value) {
            selectedEvents.push(field);
        }
        else
        {
            selectedEvents = selectedEvents.filter(e => e !== field);
        }

        this.setState({ selectedEvents });
    }

    render() {
        let content;

        if(!this.state.contactLoaded) {
            content = <div className='text-center'><Loading /></div>;
        } else {
            content = <Form layout='vertical'>
                <Form.Item label='First name'>
                    <Input value={this.state.first_name} onChange={(e) => this.setState({first_name: e.target.value})}/>
                </Form.Item>
                <Form.Item label='Last name'>
                    <Input value={this.state.last_name} onChange={(e) => this.setState({last_name: e.target.value})}/>
                </Form.Item>
                <Form.Item label='Email address'>
                    <Input type='email' value={this.state.email_address} disabled />
                </Form.Item>
                <Form.Item label='Events' extra={<small>Select events when the contact receives an email. If left empty, contact will receive all emails including transactional emails.</small>}>
                    <Descriptions bordered column={1}>
                        {this.state.events.map((item, i) => {
                            return <Descriptions.Item key={i} label={item.name}>
                                {item.fields.map((field, j) => {
                                    return <div key={j}><Checkbox key={j} checked={this.state.selectedEvents.indexOf(field) !== -1} onChange={(e) => this.updateSelectedEvents(field, e.target.checked)}>{normalizeEnum(field, false)}</Checkbox></div>;
                                })}
                            </Descriptions.Item>;
                        })}
                    </Descriptions>

                </Form.Item>
            </Form>;
        }

        return (
            <Modal
                title='Edit Contact'
                open={this.state.showModal}
                destroyOnClose={true}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button type='primary' disabled={this.state.savingContact} loading={this.state.savingContact} onClick={() => this.editContact()}>Edit</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                {content}
            </Modal>
        )
    }
}

export default connect(null, { editContact, loadContact })(EditCompanyContactModal);