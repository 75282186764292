import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Alert, Button, Checkbox, Form, Input, message, Modal, Space, Typography} from "antd";
import {Loading} from "../../libs/loading";
import {setUp2FA, verify2FACode} from "../../actions/account";
import {displayErrors} from "../../libs/utils";

class User2FAModal extends React.Component {
    state = {
        step: 1,
        generatingSecret: false,
        qrCodeCreated: false,
        qrCode: '',
        u2faGuid: '',
        verificationCode: '',
        verifyingCode: false,
        confirmedSavedCodes: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal() {
        this.setState({
            step: 1,
            generatingSecret: false,
            qrCodeCreated: false,
            qrCode: '',
            u2faGuid: '',
            verificationCode: '',
            verifyingCode: false,
        }, () => this.props.closeModal());
    }

    generateSecret() {
        this.setState({ step: 2, generatingSecret: true });

        this.props.setUp2FA((res) => {
            this.setState({ generatingSecret: false, qrCodeCreated: true, qrCode: res.data.qrcode, u2faGuid: res.data.guid });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ generatingSecret: false });
            }
        });
    }

    verifyCode() {
        this.setState({ verifyingCode: true });

        let code = this.state.verificationCode.trim();

        if(code === '') {
            return message.error('Please enter the code from Google Authenticator app!');
        }

        let data = { code: code.replaceAll(' ', '')};

        this.props.verify2FACode(this.state.u2faGuid, data, (res) => {
            this.setState({ step: 4, backupCodes: res.data.codes })
            message.success('2FA successfully enabled! Please log in again!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ verifyingCode: false });
            }
        });
    }

    logOut() {
        window.location = '/logout';
    }

    render() {
        const { Paragraph } = Typography;

        return <Modal open={this.props.showModal}
                      title='Enable 2FA'
                      maskClosable={false} closable={false}
                      footer={false}
                      onCancel={() => this.closeModal()}>

            {this.state.step === 1 ? <Fragment>
                <div className='text-center'>
                    <p>Please click on "Continue" to proceed with the setup!</p>
                    <Space>
                        <Button onClick={() => this.closeModal()}>Close</Button>
                        <Button type='primary' onClick={() => this.generateSecret()}>Continue</Button>
                    </Space>
                </div>
            </Fragment> : ''}

            {this.state.step === 2 ? <Fragment>
                {this.state.generatingSecret ? <div className='text-center'><Loading /></div> : <Fragment>
                    <p><img style={{width: '100%'}} src={this.state.qrCode} alt='QR code' /></p>
                    <div className='text-center'>
                        <p>Please scan this QR code with your Google Authenticator app.</p>
                        <Button type='primary' onClick={() => this.setState({ step: 3 })}>Continue</Button>
                    </div>
                </Fragment>}
            </Fragment> : ''}

            {this.state.step === 3 ? <Fragment>
                <Form layout='vertical'>
                    <Form.Item label='Please enter the 6 digit code you can see in the Google Authenticator app'>
                        <Input onChange={(e) => this.setState({ verificationCode: e.target.value })} />
                    </Form.Item>
                </Form>
                <div className='text-center'>
                    <Button type='primary' disabled={this.state.verifyingCode} loading={this.state.verifyingCode} onClick={() => this.verifyCode()}>Verify</Button>
                </div>
            </Fragment> : ''}

            {this.state.step === 4 ? <Fragment>
                <Alert type='warning' showIcon message='Here are your backup codes in case you lose your device. Please keep them safe, ideally printed out!' />
                <Paragraph>
                    <pre style={{marginTop: '10px'}}>{this.state.backupCodes.map((value, index) => {
                        if((index-1)%2 === 0) {
                            return value + '\n';
                        }

                        return value + '\t\t';
                    })}</pre>
                </Paragraph>

                <Form>
                    <Form.Item>
                        <Checkbox onChange={(e) => this.setState({ confirmedSavedCodes: e.target.checked})}>I confirm that I have saved backup codes somewhere safe where only I have access to them.</Checkbox>
                    </Form.Item>
                    <div className='text-center'>
                        <Button type='primary' disabled={!this.state.confirmedSavedCodes} onClick={() => this.logOut()}>Continue</Button>
                    </div>
                </Form>
            </Fragment> : ''}

        </Modal>
    }
}

export default connect(null, { setUp2FA, verify2FACode })(User2FAModal);