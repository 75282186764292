import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    Card,
    Col,
    ConfigProvider,
    Form, Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Space,
    Table,
    Tooltip,
    Typography
} from "antd";
import AccessItem from "../permissions/AccessItem";
import Button from "antd/es/button";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import {ExclamationCircleOutlined, LinkOutlined} from "@ant-design/icons";
import {Loading} from "../../libs/loading";
import {DEFAULT_PER_PAGE} from "../../config";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {StatusIndicator} from "../shared/Status";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {createAffiliateLink, disableAffiliateLink, loadAffiliateLinks} from "../../actions/affiliate";

class AffiliateLinks extends React.Component {
    state = {
        loading: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        links: [],
        creatingLink: false,
        showCustomLinkModal: false,
        customLinkHash: '',
        customLinkUsageDescription: '',
        creatingCustomLink: false
    };

    componentDidMount() {
        this.props.updatePage('Links');
        this.loadLinks();
    }

    loadLinks(page = null, per_page = null) {
        this.setState({ loading: true });

        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: false });

        this.props.loadAffiliateLinks(page, per_page, (res) => {
            this.setState({ groups: res.data.data, links: res.data.data,
                pagination: { current: res.data.page, pageSize: res.data.per_page, showSizeChanger: true, total: res.data.total }, groupsLoaded: true });
        }, (err) => {
            this.setState({ loading: true });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    generateLink() {
        const { confirm } = Modal;

        confirm({
            title: 'Create a new link?',
            centered: true,
            okText: 'Yes',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you wish to create a new link?',
            onOk: () => {
                this.setState({ creatingLink: true });

                this.props.createAffiliateLink({ link_type: 'DEFAULT' }, () => {
                    message.success('New link successfully created!');

                    this.setState({ creatingLink: false });
                    this.loadLinks();
                }, (err) => {
                    this.setState({ creatingLink: false });

                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    requestCustomLink() {
        let custom_hash = this.state.customLinkHash.toLowerCase().trim();
        let usage_description = this.state.customLinkUsageDescription.trim();
        let link_type = 'CUSTOM';

        if(custom_hash === '') {
            return message.error('Please enter your brand name!');
        }

        if(usage_description === '') {
            return message.error('Please describe how and where you use the custom link!');
        }

        this.setState({ creatingCustomLink: true });

        let data = { custom_hash, usage_description, link_type };

        this.props.createAffiliateLink(data, () => {
            message.success('A custom link successfully requested!');
            this.setState({ creatingCustomLink: false });

            this.closeCustomLinkModal();
            this.loadLinks();
        }, (err) => {
            this.setState({ creatingCustomLink: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });

    }

    closeCustomLinkModal() {
        this.setState({
            customLinkHash: '',
            showCustomLinkModal: false,
            customLinkUsageDescription: ''
        });
    }

    disableLink(guid) {
        this.props.disableAffiliateLink(guid, () => {
            message.success('Link successfully disabled!');
            this.loadLinks();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { TextArea } = Input;

        if(this.state.loading) {
            return <div className='text-center'><Loading /></div>;
        }

        const { Paragraph, Text } = Typography;

        const columns = [
            { title: 'Hash', dataIndex: 'link_hash', render: (item) => <Paragraph style={{marginBottom: 0}} copyable={{ text: item }}>
                    <Text code>{item}</Text>
            </Paragraph>},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Status', dataIndex: 'status', align: 'center', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Type', dataIndex: 'link_type', align: 'center', render: (item) => normalizeEnum(item)},
            { title: 'Example', render: (item, record) => {

                let affiliate_link;

                if(record.link_type === 'CUSTOM') {
                    affiliate_link = 'https://priorityprospect.com/' + record.link_hash;
                } else {
                    affiliate_link = 'https://priorityprospect.com/?aff=' + record.link_hash;
                }

                return <Paragraph style={{marginBottom: 0}} copyable={{ text: affiliate_link }}>
                    <Text code>{affiliate_link}</Text>
                </Paragraph>;
            }},
            { title: '', align: 'right', render: (item, record) => <Popconfirm
                    title={<span>Are you sure you want to disable this link?</span>}
                    onConfirm={() => this.disableLink(record.guid)}
                    okText='Disable'
                    placement='left'
                    cancelText='Cancel'>
                    <Button danger size='small' icon={<DeleteOutlined />} />
                </Popconfirm>}
        ];

        return <Fragment>
            <Card size='small'>
                <Row justify='space-between' style={{paddingBottom: '10px'}}>
                    <Col>
                        <AccessItem scopes={['can_add_domain_groups']}>
                            <Space>
                                <Button type='primary' icon={<PlusOutlined />} loading={this.state.creatingLink} disabled={this.state.creatingLink} onClick={() => this.generateLink()}>Create a Link</Button>
                                <Button icon={<PlusOutlined />} onClick={() => this.setState({ showCustomLinkModal: true })}>Request a Custom Link</Button>
                            </Space>
                        </AccessItem>
                    </Col>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadLinks()}/></Tooltip></Col>
                </Row>

                <ConfigProvider renderEmpty={() => <div style={{ textAlign: 'center' }}>
                    <LinkOutlined style={{ fontSize: 40 }} />
                    <p>No links here!</p>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => this.setState({ showAddModal: true })}>Create a Link</Button>
                </div>}>
                    <Table
                        dataSource={this.state.links}
                        columns={columns}
                        rowKey={(item) => item.guid}
                        pagination={false}
                        loading={this.state.loading} />
                </ConfigProvider>
            </Card>

            <Modal
                open={this.state.showCustomLinkModal}
                onCancel={() => this.closeCustomLinkModal()}
                footer={<Space>
                    <Button onClick={() => this.closeCustomLinkModal()} disabled={this.state.creatingCustomLink}>Close</Button>
                    <Button type='primary' onClick={() => this.requestCustomLink()} disabled={this.state.creatingCustomLink}
                            loading={this.state.creatingCustomLink}>Request</Button>
                </Space>}
                title='Custom Link'>
                <p>A custom link is a link with your branding. You can request a custom affiliate link if you wish to display and share it with your audience. </p>
                <p>All custom links are reviewed manually and must be approved before they can be used.</p>
                <Form layout='vertical'>
                    <Form.Item label='Your brand name' extra={<small>Valid characters: a-z, 0-9, -</small>}>
                        <Input value={this.state.customLinkHash} onChange={(e) => this.setState({ customLinkHash: e.target.value.toLowerCase().replace(/[^a-z0-9-]/g, '') })} />
                    </Form.Item>
                    <Form.Item label='Link preview'>
                        <Text code>https://priorityprospect.com/{this.state.customLinkHash}</Text>
                    </Form.Item>
                    <Form.Item label='Where and how this link will be used?'>
                        <TextArea rows={3} value={this.state.customLinkUsageDescription} onChange={(e) => this.setState({ customLinkUsageDescription: e.target.value })} />
                    </Form.Item>
                </Form>
            </Modal>
        </Fragment>;
    }
}

export default connect(null, { updatePage, loadAffiliateLinks,
    createAffiliateLink, disableAffiliateLink })(AffiliateLinks);