import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import AccessGate from "../../components/permissions/AccessGate";
import {Card, Col, ConfigProvider, Input, Row, Select, Space, Table, Typography} from "antd";
import Button from "antd/es/button";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DEFAULT_PER_PAGE} from "../../config";
import {loadDomainDetail} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";
import {loadAnalyticsData} from "../../actions/analytics";

class AnalyticsDetails extends React.Component {
    state = {
        data: [],
        originalData: [],
        domain: null,
        loadingDomain: true,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        loadingData: false,
        groupBy: ['domain'],
        groupByColumns: [
            { label: 'Domain', value: 'domain' },
            { label: 'Uri', value: 'uri' },
            { label: 'IP address', value: 'ip_address' },
            { label: 'User-agent', value: 'user_agent' },
            { label: 'Method', value: 'method' },
            { label: 'Referrer', value: 'referrer' },
            { label: 'Status code', value: 'status_code' },
            { label: 'Country', value: 'country' },
            { label: 'SSL cipher', value: 'ssl_cipher' },
            { label: 'Content type', value: 'content_type' },
            { label: 'Header: Accept', value: 'header_accept' },
            { label: 'Header: Accept-language', value: 'header_accept_language' },
            { label: 'Header: Accept-Encoding', value: 'header_accept_encoding' },
        ],
        dateRanges: [
            { label: 'Last 15 minutes', value: 'last_15_minutes' },
            { label: 'Last 30 minutes', value: 'last_30_minutes' },
            { label: 'Last 1 hour', value: 'last_1_hour' },
            { label: 'Last 3 hours', value: 'last_3_hours' },
            { label: 'Last 6 hours', value: 'last_6_hours' },
            { label: 'Last 12 hours', value: 'last_12_hours' },
            { label: 'Last 24 hours', value: 'last_24_hours' },
            { label: 'Last 2 days', value: 'last_2_days' },
            { label: 'Last 3 days', value: 'last_3_days' },
            { label: 'Last 7 days', value: 'last_7_days' },
        ],
        selectedDateRange: 'last_15_minutes',
    }

    componentDidMount() {
        this.props.updatePage('');
        this.loadDomain();
    }

    loadDomain() {
        this.setState({ loadingDomain: true });

        this.props.loadDomainDetail(this.props.match.params.guid, (res) => {
            this.props.updatePage('Domain analytics: ' + res.data.domain);
            this.setState({ domain: res.data.data, loadingDomain: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDomain: false });
                displayErrors(err.response.data);
            }
        });
    }

    loadData(page = null, per_page = null) {
        this.setState({ loadingData: true, searchQuery: '', data: this.state.originalData });

        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        } else {
            if(per_page !== this.state.pagination.pageSize) {
                page = 1;
            }
        }

        this.props.loadAnalyticsData(this.props.match.params.guid, this.state.groupBy, this.state.selectedDateRange, page, per_page, (res) => {
            this.setState({ originalData: res.data.data, data: res.data.data, loadingData: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingData: false });
                displayErrors(err.response.data);
            }
        });
    }

    updateSearchQuery(value) {
        this.setState({ groupBy: value });
    }

    getColumns() {
        let columns = this.state.groupBy.map((column) => {
            return { title: this.state.groupByColumns.filter(c => c.value === column)[0].label, dataIndex: column, align: 'left'}
        });

        columns.push({ title: 'Requests', dataIndex: 'requests'})

        return columns;
    }

    searchData(keyword) {
        keyword = keyword.trim();

        if(keyword.length === 0) {
            return this.setState({ data: this.state.originalData });
        } else {
            let newData = this.state.data.filter(item => {
                let keys = Object.keys(item);

                for(let i = 0; i < keys.length; i++) {
                    if(item[keys[i]].toString().toLowerCase().includes(keyword.toLowerCase())) {
                        return item;
                    }
                }

                return false;
            });

            this.setState({ data: newData });
        }
    }

    render() {
        if(!this.state.loadingDomain && this.state.domain === null) {
            return <></>
        }

        const { Text } = Typography;

        return(
            <AccessGate scopes={['can_view_analytics']}>
                <Card size='small'>
                    <Row justify='space-between' style={{paddingBottom: '10px'}}>
                        <Col>
                            <Space>
                                <Select
                                    placeholder='Select columns to group by'
                                    value={this.state.groupBy}
                                    onInputKeyDown={(e) => { if(e.key === 'Enter') { this.runSearch() }}}
                                    mode="multiple" options={this.state.groupByColumns}
                                    style={{width: '500px'}}
                                    defaultValue={['domain']}
                                    onChange={(value) => this.updateSearchQuery(value)} />
                                <Button loading={this.state.loadingData} disabled={this.state.loadingData}
                                        icon={<UndoOutlined />} type='primary'
                                        onClick={() => this.loadData()}>Generate</Button>

                                <Input style={{width: '300px'}} placeholder='Search' onChange={(e) => this.searchData(e.target.value)} />
                            </Space>
                        </Col>
                        <Col>
                            <Select
                                value={this.state.selectedDateRange}
                                options={this.state.dateRanges}
                                style={{width: '150px'}}
                                onChange={(value) => this.setState({ selectedDateRange: value })} />
                        </Col>
                    </Row>

                    <ConfigProvider renderEmpty={() => <div style={{ textAlign: 'center' }}>
                        <p>Please select columns to group by and click on the "Generate" button.</p>
                    </div>}>
                    <Table
                        dataSource={this.state.data}
                        columns={this.getColumns()}
                        rowKey={(item, i) => i}
                        loading={this.state.loadingData} />
                    </ConfigProvider>
                    <Text type="secondary"><small>The data displayed here is not real-time. It can take up to 30 minutes to process traffic information.</small></Text>
                </Card>
            </AccessGate>
        );
    }
}
const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadDomainDetail, loadAnalyticsData })(AnalyticsDetails);