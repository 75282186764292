import React from "react";
import {connect} from "react-redux";
import {Button, Image, Input, message, Modal, Table} from "antd";
import {addItemsToSoftwarePresetGroup, searchWordpressPlugins, searchWordpressThemes} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";
import {EyeOutlined} from "@ant-design/icons";

class AddItemToSoftwarePresetGroupModal extends React.Component {
    state = {
        loading: false,
        searching: false,
        selectedItems: [],
        searchQuery: '',
        dataList: [],
        pagination: { current: 1, pageSize: 10, showSizeChanger: true, total: 0 },
    };

    closeModal(reload = false) {
        this.setState({
            loading: false,
            searching: false,
            selectedItems: [],
            searchQuery: '',
            dataList: [],
        });

        this.props.close();

        if(reload) {
            this.props.reloadPresets();
        }
    }

    addItemsToGroup() {
        let selectedItems = this.state.selectedItems.map(item => {
           let pickedItem = this.state.dataList.filter(_item => _item.slug === item)[0];

           return {
               name: pickedItem['name'],
               slug: pickedItem['slug']
           }
        });

        let data = {
            items: selectedItems
        };

        this.setState({ loading: true });

        this.props.addItemsToSoftwarePresetGroup(this.props.activePreset, this.props.activeGroup, data, () => {
            this.setState({ loading: false });
            message.success('Items successfully added to the preset group!');
            this.closeModal(true);

        }, (err) => {
            this.setState({ loading: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    searchThemes(query, page = null, per_page = null) {
        this.setState({ searching: true, searchQuery: query });

        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.props.searchWordpressThemes(query, page, per_page, (res) => {
            this.setState({ dataList: res.data.themes, searching: false,
                pagination: { showSizeChanger: true, total: res.data.info.results } });
        }, (err) => {
            this.setState({ searching: true});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    searchPlugins(query, page = null, per_page = null) {
        this.setState({ searching: true, searchQuery: query });

        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.props.searchWordpressPlugins(query, page, per_page, (res) => {
            this.setState({ dataList: res.data.plugins, searching: false,
                pagination: { showSizeChanger: true, total: res.data.info.results } });
        }, (err) => {
            this.setState({ searching: true});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    displayScreenshots(items) {
        let keys = Object.keys(items);
        return keys.map(key => {
            return <Image src={items[key].src} width={30} />
        });
    }

    render() {
        const { Search } = Input;

        let content = '';

        const pluginsColumns = [
            { title: 'Name', dataIndex: 'name', render: (item, row) => <a href={'https://wordpress.org/plugins/' + row.slug + '/'} target='_blank' rel='noopener noreferrer'>{new DOMParser().parseFromString(item, "text/html").documentElement.textContent}</a>},
            { title: 'Version', dataIndex: 'version', align: 'center'},
            { title: 'Downloads', dataIndex: 'downloaded', align: 'center'},
            { title: 'Updated', dataIndex: 'last_updated'},
            { title: 'Screenshots', dataIndex: 'screenshots', render: (item) => this.displayScreenshots(item)},
            { title: 'Author', dataIndex: 'author', render: (item) => new DOMParser().parseFromString(item, 'text/html').body.textContent}
        ];

        const themesColumns = [
            { title: 'Name', dataIndex: 'name', render: (item, row) => <a href={'https://wordpress.org/themes/' + row.slug + '/'} target='_blank' rel='noopener noreferrer'>{item}</a>},
            { title: 'Version', dataIndex: 'version', align: 'center'},
            { title: 'Preview', dataIndex: 'screenshot_url', render: (item) => <Image src={item} width={30} />},
            { title: 'Author', dataIndex: 'author'},
            { title: '', align: 'right', dataIndex: 'preview_url', render: (item) => <a href={item} target='_blank' rel='noopener noreferrer'><Button icon={<EyeOutlined />} size='small'>Preview</Button></a>}
        ];

        if(this.props.activeGroupType === 'PLUGINS') {
            content = <div>
                <Search placeholder="Search plugins" enterButton="Search" loading={this.state.searching}
                        disabled={this.state.searching} allowClear onSearch={(value) => this.searchPlugins(value)} />
                <br /><br />
                <Table
                    dataSource={this.state.dataList}
                    columns={pluginsColumns}
                    rowKey={(item) => item.slug}
                    pagination={this.state.pagination}
                    size='small'
                    rowSelection={{
                        type: 'checkbox',
                        onChange: (items) => this.setState({ selectedItems: items })
                    }}
                    onChange={(pagination) => this.searchPlugins(this.state.searchQuery, pagination.current, pagination.pageSize)}
                    loading={this.state.searching} />
            </div>
        } else if(this.props.activeGroupType === 'THEMES') {
            content = <div>
                <Search placeholder="Search themes" enterButton="Search" loading={this.state.searching}
                        disabled={this.state.searching} allowClear onSearch={(value) => this.searchThemes(value)} />
                <br /><br />
                <Table
                    dataSource={this.state.dataList}
                    columns={themesColumns}
                    rowKey={(item) => item.slug}
                    pagination={this.state.pagination}
                    size='small'
                    rowSelection={{
                        type: 'checkbox',
                        onChange: (items) => this.setState({ selectedItems: items })
                    }}
                    onChange={(pagination) => this.searchThemes(this.state.searchQuery, pagination.current, pagination.pageSize)}
                    loading={this.state.searching} />
            </div>
        }

        return(
            <Modal
                centered
                title={this.props.activeGroupType === 'PLUGINS' ? 'Add Plugin' : 'Add Theme'}
                open={this.props.showModal}
                onCancel={() => this.closeModal()}
                width={'100%'}
                footer={<div>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Cancel</Button>
                    <Button disabled={this.state.loading || this.state.selectedItems.length === 0}
                            loading={this.state.loading} type='primary' onClick={() => this.addItemsToGroup()}>Add</Button>
                </div>}
                destroyOnClose={true}>
                {content}
            </Modal>
        );
    }
}

export default connect(null, { searchWordpressPlugins, searchWordpressThemes,
    addItemsToSoftwarePresetGroup })(AddItemToSoftwarePresetGroupModal);