import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Checkbox, Descriptions, Form, Input, message, Modal, Select, Space, Typography} from "antd";
import Button from "antd/es/button";
import {submitPayment} from "../../actions/billing";
import {displayErrors} from "../../libs/utils";

class InvoicePaymentModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invoice: props.invoice,
            paymentMethod: 'CARD',
            payFull: true,
            defaultPaymentAmount: props.amount,
            totalAmount: props.amount,
            currency: props.currency,
            loading: false,
            showBankTransferModal: false
        };
    }

    submitPayment() {
        if(this.state.paymentMethod === 'BANK_TRANSFER') {
            this.setState({ showBankTransferModal: true });
        } else {
            this.setState({ loading: true });

            let paymentAmount = this.state.paymentAmount;

            if(paymentAmount === '' || paymentAmount === null || typeof paymentAmount === 'undefined') {
                paymentAmount = this.props.amount;
            }

            let data = {
                payment_method: this.state.paymentMethod,
                payment_amount: paymentAmount,
                invoice_id: this.state.invoice
            };

            this.props.submitPayment(data, (res) => {
                if(this.state.paymentMethod === 'PAYPAL') {
                    message.success('Redirecting to Paypal...');
                    window.location.href = res.data.redirect_url;
                    this.setState({ loading: false });
                } else {
                    this.setState({ loading: false });
                    window.location.href = '/billing/invoices/' + this.state.invoice + '?payment=success';

                    //this.props.reloadInvoice();
                    //this.closeModal();
                }
            }, (err) => {
                this.setState({ loading: false });

                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                }
            });
        }
    }

    setPaymentAmount(value) {
        let paymentAmount = value.trim();

        if(paymentAmount === '') {
            paymentAmount = this.state.totalAmount;
        } else if(value <= 0) {
            paymentAmount = this.state.totalAmount;
        } else if(value > this.state.totalAmount) {
            paymentAmount = this.state.totalAmount;
        }

        this.setState({ paymentAmount });
    }

    closeModal() {
        this.setState({
            invoice: this.props.invoice,
            paymentMethod: 'CARD',
            payFull: true,
            paymentAmount: this.props.amount,
            totalAmount: this.props.amount,
            currency: this.props.currency,
            loading: false,
            showBankTransferModal: false
        });

        this.props.close();
    }

    render() {
        const { Option } = Select;
        const { Paragraph } = Typography;

        return(
            <Fragment>
                <Modal
                    centered
                    title={'Pay Invoice'}
                    open={this.props.showModal}
                    destroyOnClose={true}
                    closable={false}
                    onCancel={() => this.closeModal()}
                    footer={
                        <Space>
                            <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                            <Button type='primary' onClick={() => this.submitPayment()} disabled={this.state.loading}>{this.state.paymentMethod === 'BANK_TRANSFER' ? 'View Details' : 'Pay Now'}</Button>
                        </Space>
                    }
                    confirmLoading={this.state.loading}>
                    <Form layout='vertical'>
                        <Form.Item>
                            <Checkbox checked={this.state.payFull} onChange={(e) => this.setState({ payFull: e.target.checked, paymentAmount: e.target.checked ? this.state.totalAmount : this.state.paymentAmount })}>Pay invoice in full</Checkbox>
                        </Form.Item>
                        <Form.Item label='Payment method'>
                            <Select defaultValue={this.state.paymentMethod} onChange={(value) => this.setState({ paymentMethod: value})}>
                                <Option value='CARD'>Credit card</Option>
                                <Option value='PAYPAL'>PayPal</Option>
                                <Option value='BANK_TRANSFER'>Bank transfer</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='Total payment'>
                            <Input value={this.state.paymentAmount} defaultValue={this.state.defaultPaymentAmount} disabled={this.state.payFull}
                                   onChange={(e) => this.setPaymentAmount(e.target.value)} />
                        </Form.Item>
                        <Form.Item>
                            <Descriptions bordered size='small' column={1} label='Payment method limits'>
                                <Descriptions.Item label='PayPal'>Up to 5000.00 USD/EUR</Descriptions.Item>
                                <Descriptions.Item label='Credit card'>From 0.50 to 3000.00 USD/EUR</Descriptions.Item>
                                <Descriptions.Item label='Bank transfer'>No limit</Descriptions.Item>
                            </Descriptions>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal destroyOnClose={true} closable={false} centered
                       width='600px' footer={<Button onClick={() => this.setState({ showBankTransferModal: false })}>Close</Button>}
                       open={this.state.showBankTransferModal}>
                    <p>For bank transfer, please use following details:</p>
                    <Descriptions bordered size='small' layout='horizontal' column={1}>
                        <Descriptions.Item label='Beneficiary name'>Priority Prospect OÜ</Descriptions.Item>
                        <Descriptions.Item label='IBAN'>EE412200221064329810</Descriptions.Item>
                        <Descriptions.Item label='SWIFT/BIC'>HABAEE2X</Descriptions.Item>
                        <Descriptions.Item label='Description'>Invoice {this.state.invoice}</Descriptions.Item>
                        <Descriptions.Item label='Amount'>{this.state.paymentAmount || this.state.totalAmount}</Descriptions.Item>
                        <Descriptions.Item label='Currency'>{this.state.currency}</Descriptions.Item>
                        <Descriptions.Item label={<Paragraph copyable={{ text: 'Liivalaia 8, Tallinn, Estonia, 15040' }}>Bank info</Paragraph>}>
                            <Descriptions bordered size='small' layout='horizontal' column={1}>
                                <Descriptions.Item label='Name'>Swedbank AS</Descriptions.Item>
                                <Descriptions.Item label='Address'>Liivalaia 8</Descriptions.Item>
                                <Descriptions.Item label='City'>Tallinn</Descriptions.Item>
                                <Descriptions.Item label='Country'>Estonia</Descriptions.Item>
                                <Descriptions.Item label='Postal code'>15040</Descriptions.Item>
                            </Descriptions>
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>
            </Fragment>
        );
    }
}

export default connect(null, { submitPayment })(InvoicePaymentModal);