import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_IN_PROGRESS,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    REGISTER_IN_PROGRESS,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    LOGOUT_FAILURE,
    LOGOUT_SUCCESS,
    LOGOUT_IN_PROGRESS
} from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    isLoading: true,
    user: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case LOGIN_SUCCESS:

            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('company', action.payload.user.company.guid);

            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
                isLoading: false
            };
        case USER_LOADING:

            let isAuthenticated = false;

            if(state.token) {
                isAuthenticated = true;
            }

            return {
                ...state,
                isAuthenticated: isAuthenticated,
                isLoading: true
            };
        case USER_LOADED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                user: action.payload,
            };
        case LOGIN_IN_PROGRESS:
            return {
                ...state,
                isLoading: true
            };
        case LOGOUT_IN_PROGRESS:
            return {
                ...state,
                isLoading: true
            };
        case LOGIN_FAILURE:
        case REGISTER_FAILURE:
        case AUTH_ERROR:
        case LOGOUT_FAILURE:
        case LOGOUT_SUCCESS:
            localStorage.removeItem('token');
            localStorage.removeItem('company');

            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                user: null,
                token: null
            };
        case REGISTER_IN_PROGRESS:
            return {
                ...state,
                isLoading: true
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        default:
            return state;
    }
}