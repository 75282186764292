import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Space, message} from "antd";
import {editFirewallPolicy, loadFirewallPolicy, loadFirewallRules, loadFirewallRulesets} from "../../actions/firewall";
import {displayErrors, isNumeric} from "../../libs/utils";
import {loadAllDomainGroups, loadAllDomains} from "../../actions/domains";
import {Loading} from "../../libs/loading";
import AccessItem from "../permissions/AccessItem";

class EditFirewallPolicyModal extends React.Component {
    state = {
        name: '',
        priority: 0,
        rulesets: [],
        selectedRulesets: [],
        loadingRulesets: true,
        rules: [],
        selectedRules: [],
        loadingRules: true,
        domainGroups: [],
        selectedDomainGroups: [],
        loadingDomainGroups: true,
        domains: [],
        selectedDomains: [],
        loadingDomains: true,
        loadingData: false,
        dataLoaded: false,
        editing: false,
        policyLoaded: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.loadingData && this.props.showModal && !this.state.dataLoaded) {
            this.setState({ loadingData: true });

            this.props.loadFirewallPolicy(this.props.policy, (res) => {
                let rulesets = res.data.rulesets.map((ruleset) => {
                    return ruleset.guid;
                });

                let domainGroups = res.data.domain_groups.map((group) => {
                    return group.guid;
                });

                let rules = res.data.rules.map((rule) => {
                    return rule.guid;
                });

                let domains = res.data.domains.map((domain) => {
                    return domain.guid;
                });

                this.setState({ policyLoaded: true, name: res.data.name, priority: res.data.priority, selectedRulesets: rulesets,
                    selectedRules: rules, selectedDomainGroups: domainGroups, selectedDomains: domains });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ policyLoaded: true });
                    displayErrors(err.response.data);
                }
            });

            this.props.loadAllDomainGroups(1, 9999, (res) => {
                let domainGroups = res.data.data.map((group) => {
                    return { label: group.name, value: group.guid };
                });

                this.setState({ domainGroups, loadingDomainGroups: false });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingDomainGroups: false });
                    displayErrors(err.response.data);
                }
            });

            this.props.loadFirewallRules(1, 9999, (res) => {
                let rules = res.data.data.map((rule) => {
                    return { label: rule.name, value: rule.guid };
                });

                this.setState({ rules, loadingRules: false });

            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingRules: false });
                    displayErrors(err.response.data);
                }
            });

            this.props.loadFirewallRulesets(1, 9999, (res) => {
                let rulesets = res.data.data.map((ruleset) => {
                    return { label: ruleset.name, value: ruleset.guid };
                });

                this.setState({ rulesets, loadingRulesets: false });

            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingRulesets: false });
                    displayErrors(err.response.data);
                }
            });

            this.props.loadAllDomains(1, 9999, (res) => {
                let domains = res.data.data.map((domain) => {
                    return { label: domain.domain, value: domain.guid };
                });

                this.setState({ domains, loadingDomains: false });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingDomains: false });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal(reload = false) {
        this.props.close();

        this.setState({
            name: '',
            priority: 0,
            rulesets: [],
            selectedRulesets: [],
            loadingRulesets: true,
            rules: [],
            selectedRules: [],
            loadingRules: true,
            domainGroups: [],
            selectedDomainGroups: [],
            loadingDomainGroups: true,
            domains: [],
            selectedDomains: [],
            loadingDomains: true,
            loadingData: false,
            dataLoaded: false,
            editing: false,
            policyLoaded: false
        });

        if(reload) {
            this.props.reloadPolicies();
        }
    }

    editPolicy() {
        let name = this.state.name.trim();
        let priority = this.state.priority;
        let rulesets = this.state.selectedRulesets;
        let rules = this.state.selectedRules;
        let domainGroups = this.state.selectedDomainGroups;
        let domains = this.state.selectedDomains;

        if(name.length === 0) {
            return message.error('Enter your policy\'s name!');
        }

        if(!isNumeric(priority)) {
            return message.error('Priority must be a number between 0 and 9999!');
        }

        if(rulesets.length === 0 && rules.length === 0) {
            return message.error('Select at least one ruleset or rule!');
        }

        if(domainGroups.length === 0 && domains.length === 0) {
            return message.error('Select at least one domain group or domain!');
        }

        let data = {
            name, priority, rulesets, rules, domain_groups: domainGroups, domains
        };

        this.setState({ editing: true });

        this.props.editFirewallPolicy(this.props.policy, data, () => {
            message.success('Firewall policy successfully updated!');
            this.setState({ editing: true });
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ editing: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        let content;

        if(!this.state.policyLoaded) {
            content = <div className='text-center'><Loading /></div>;
        } else {
            content = <Fragment>
                <Row gutter={[16, 16]}>
                    <Col flex="auto">
                        <p>Name</p>
                        <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </Col>
                    <Col>
                        <p>Priority</p>
                        <InputNumber value={this.state.priority} onChange={(value) => this.setState({ priority: value })} min={0} max={9999} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form layout='vertical'>
                            <Divider plain>Rules</Divider>
                            <Form.Item label='Rule sets'>
                                <Select
                                    options={this.state.rulesets}
                                    loading={this.state.loadingRulesets}
                                    value={this.state.selectedRulesets}
                                    mode='multiple' showSearch allowClear
                                    onChange={(values) => this.setState({ selectedRulesets: values })}
                                    filterOption={(input, option) => {
                                        if(typeof option !== 'undefined') {
                                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }
                                        return false;
                                    }} />
                            </Form.Item>
                            <Form.Item label='Rules'>
                                <Select
                                    options={this.state.rules}
                                    loading={this.state.loadingRules}
                                    value={this.state.selectedRules}
                                    mode='multiple' showSearch allowClear
                                    onChange={(values) => this.setState({ selectedRules: values })}
                                    filterOption={(input, option) => {
                                        if(typeof option !== 'undefined') {
                                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }
                                        return false;
                                    }} />
                            </Form.Item>
                            <Divider plain>Domains</Divider>
                            <Form.Item label='Domain groups'>
                                <Select
                                    options={this.state.domainGroups}
                                    loading={this.state.loadingDomainGroups}
                                    value={this.state.selectedDomainGroups}
                                    mode='multiple' showSearch allowClear
                                    onChange={(values) => this.setState({ selectedDomainGroups: values })}
                                    filterOption={(input, option) => {
                                        if(typeof option !== 'undefined') {
                                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }
                                        return false;
                                    }} />
                            </Form.Item>
                            <Form.Item label='Domains'>
                                <Select
                                    options={this.state.domains}
                                    loading={this.state.loadingDomains}
                                    value={this.state.selectedDomains}
                                    mode='multiple' showSearch allowClear
                                    onChange={(values) => this.setState({ selectedDomains: values })}
                                    filterOption={(input, option) => {
                                        if(typeof option !== 'undefined') {
                                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }
                                        return false;
                                    }} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Fragment>;
        }

        return(
            <Modal
                title='Edit Firewall Policy'
                open={this.props.showModal}
                destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={() => this.closeModal()} disabled={this.state.editing}>Close</Button>
                        <AccessItem scopes={['can_edit_firewall_policies']}>
                            <Button type='primary' onClick={() => this.editPolicy()} loading={this.state.editing} disabled={this.state.editing}>Update</Button>
                        </AccessItem>
                    </Space>
                }
                onCancel={() => this.closeModal()}>
                {content}
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { loadFirewallRules, loadFirewallRulesets,
    loadAllDomainGroups, loadAllDomains, editFirewallPolicy, loadFirewallPolicy })(EditFirewallPolicyModal);