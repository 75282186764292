import React, {Fragment} from "react";
import {connect} from "react-redux";
import {deleteDNSRecordDomain, loadDNSZone, resetDNSZone} from "../../actions/domains";
import {Alert, Button, Card, message, Popconfirm, Space, Table, Tooltip} from "antd";
import {Loading} from "../../libs/loading";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import DNSRecordModal from "./DNSRecordModal";
import {displayErrors} from "../../libs/utils";
import {RetweetOutlined} from "@ant-design/icons";
import AccessItem from "../permissions/AccessItem";
import {hasPermission} from "../permissions/PermissionUtils";

class DNSZone extends React.Component {
    state = {
        loadingDNSZone: false,
        updatingDNSRecord: false,
        domain: '',
        records: null,
        showDNSRecordModal: false,
        recordData: null,
        recordType: '',
        soaData: null,
        isAvailable: false,
        dnsType: null,
    };

    componentDidMount() {
        this.loadDNSZone();
    }

    loadDNSZone() {
        if(this.state.records === null) {
            this.setState({ loadingDNSZone: true });
        }

        this.props.loadDNSZone(this.props.guid, (res) => {
            this.setState({ loadingDNSZone: false, domain: res.data.domain, dnsType: res.data.dns_type, records: res.data.records, isAvailable: true });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDNSZone: false });
            }
        });
    }

    deleteRecord(type, data) {
        this.setState({ updatingDNSRecord: true });

        let name = data.name;

        if(type === 'NS') {
            name = data.records[0];
        }

        this.props.deleteDNSRecordDomain(this.props.guid, type, name, () => {
            message.success('DNS record successfully deleted!');
            this.loadDNSZone();
            this.setState({ updatingDNSRecord: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ updatingDNSRecord: false });
            }
        });
    }

    resetDNSZone() {
        this.setState({ loadingDNSZone: true });

        this.props.resetDNSZone(this.props.guid, () => {
            message.success('DNS zone successfully reset!');
            this.setState({ loadingDNSZone: false });
            this.loadDNSZone();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingDNSZone: false });
            }
        });
    }

    render() {
        if(!this.state.loadingDNSZone && !this.state.isAvailable) {
            return <Alert message='DNS zone is not available, yet!' showIcon />;
        }

        if(this.state.loadingDNSZone || this.state.records === null) {
            return <div className='text-center'><Loading/></div>;
        }

        const { records } = this.state;

        let ns_records_columns = [
            { title: 'Nameserver', dataIndex: 'content', key: 'content', render: (item, row) => row.records.map((line, i) => { return <span key={i}>{line}</span>})},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl', render: (item, row) => row.ttl},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                return  <AccessItem scopes={['can_delete_domain_dns_zone_records']}>
                    <Popconfirm
                        onConfirm={() => this.deleteRecord('NS', row)}
                        placement='topRight'
                        title="Are you sure?"
                        okButtonProps={{danger: true}}
                        okText="Delete"
                        cancelText="Cancel">
                        <Tooltip title='Delete NS record'>
                            <Button size='small' danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </AccessItem>;
            }}
        ];

        let a_records_columns = [
            { title: 'Name', dataIndex: 'content', key: 'name', render: (item, row) => row.name},
            { title: 'Records', key: 'records', render: (item, row) => row.records.map((line, i) => { return <span key={i}>{line}</span>})},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl', render: (item, row) => row.ttl},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Space>
                        <AccessItem scopes={['can_edit_domain_dns_zone_records']}>
                            <Tooltip title='Edit A record'>
                                <Button size='small' icon={<EditOutlined />} onClick={() => this.setState({ showDNSRecordModal: true, recordType: 'A', recordData: { name: row.name, data: row } })} />
                            </Tooltip>
                        </AccessItem>
                        <AccessItem scopes={['can_delete_domain_dns_zone_records']}>
                            <Popconfirm
                                onConfirm={() => this.deleteRecord('A', row)}
                                placement='topRight'
                                title="Are you sure?"
                                okButtonProps={{danger: true}}
                                okText="Delete"
                                cancelText="Cancel">
                                <Tooltip title='Delete A record'>
                                    <Button size='small' danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        </AccessItem>
                    </Space>
                }}
        ];

        let cname_records_columns = [
            { title: 'Name', dataIndex: 'name', key: 'name', render: (item, row) => row.name},
            { title: 'Records', key: 'records', render: (item, row) => row.records.map((line, i) => { return <span key={i}>{line}</span>})},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl', render: (item, row) => row.ttl},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Space>
                        <AccessItem scopes={['can_edit_domain_dns_zone_records']}>
                            <Tooltip title='Edit CNAME record'>
                                <Button size='small' icon={<EditOutlined />} onClick={() => this.setState({ showDNSRecordModal: true, recordType: 'CNAME', recordData: { name: row.name, data: row } })} />
                            </Tooltip>
                        </AccessItem>
                        <AccessItem scopes={['can_delete_domain_dns_zone_records']}>
                            <Popconfirm
                                onConfirm={() => this.deleteRecord('CNAME', row)}
                                placement='topRight'
                                title="Are you sure?"
                                okButtonProps={{danger: true}}
                                okText="Delete"
                                cancelText="Cancel">
                                <Tooltip title='Delete CNAME record'>
                                    <Button size='small' danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        </AccessItem>
                    </Space>
                }}
        ];

        let mx_records_columns = [
            { title: 'Name', dataIndex: 'name', key: 'name',  render: (item, row) => row.name},
            { title: 'Records', key: 'hostname', render: (item, row) => row.records.map((line, i) => { return <div key={i}>{line}</div>})},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl', render: (item, row) => row.ttl},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Space>
                        <AccessItem scopes={['can_edit_domain_dns_zone_records']}>
                            <Tooltip title='Edit MX record'>
                                <Button size='small' icon={<EditOutlined />} onClick={() => this.setState({ showDNSRecordModal: true, recordType: 'MX', recordData: { name: row.name, data: row } })} />
                            </Tooltip>
                        </AccessItem>
                        <AccessItem scopes={['can_delete_domain_dns_zone_records']}>
                            <Popconfirm
                                onConfirm={() => this.deleteRecord('MX', row)}
                                placement='topRight'
                                title="Are you sure?"
                                okButtonProps={{danger: true}}
                                okText="Delete"
                                cancelText="Cancel">
                                <Tooltip title='Delete MX record'>
                                    <Button size='small' danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        </AccessItem>
                    </Space>
                }}
        ];

        let txt_records_columns = [
            { title: 'Name', dataIndex: 'name', key: 'name', render: (item, row) => row.name},
            { title: 'Records', key: 'records', render: (item, row) => row.records.map((line, i) => { return <p key={i}>{line}</p>})},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl', render: (item, row) => row.ttl},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Space>
                        <AccessItem scopes={['can_edit_domain_dns_zone_records']}>
                            <Tooltip title='Edit TXT record'>
                                <Button size='small' icon={<EditOutlined />} onClick={() => this.setState({ showDNSRecordModal: true, recordType: 'TXT', recordData: { name: row.name, data: row } })} />
                            </Tooltip>
                        </AccessItem>
                        <AccessItem scopes={['can_delete_domain_dns_zone_records']}>
                            <Popconfirm
                                onConfirm={() => this.deleteRecord('TXT', row)}
                                placement='topRight'
                                title="Are you sure?"
                                okButtonProps={{danger: true}}
                                okText="Delete"
                                cancelText="Cancel">
                                <Tooltip title='Delete TXT record'>
                                    <Button size='small' danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        </AccessItem>
                    </Space>
                }}
        ];

        let soa_records_columns = [
            { title: 'Nameserver', dataIndex: 'nameserver', key: 'nameserver'},
            { title: 'Email', dataIndex: 'email', key: 'email'},
            { title: 'Serial', dataIndex: 'serial', key: 'serial'},
            { title: 'Refresh', dataIndex: 'refresh', key: 'refresh'},
            { title: 'Retry', dataIndex: 'retry', key: 'retry'},
            { title: 'Expiry', dataIndex: 'expiry', key: 'expiry'},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl'}
        ];

        if(hasPermission(this.props.company.permissions, ['can_edit_domain_dns_zone_records'])) {
            soa_records_columns.push({ title: '', key: 'actions', render: (item, row) => {
                return <Tooltip title='Edit SOA record'>
                    <Button size='small' icon={<EditOutlined />} onClick={() => this.setState({ showDNSRecordModal: true, recordData:  { name: row.name, data: row } , recordType: 'SOA'})} />
                </Tooltip>
            }});
        }

        // TODO: Update editor form when SOA and TXT records are being edited

        let resetDNSZone = <AccessItem scopes={['can_reset_domain_dns_zone']}>
            <Popconfirm
                onConfirm={() => this.resetDNSZone()}
                title="Are you sure?"
                okButtonProps={{danger: true}}
                okText="Reset"
                cancelText="Cancel">
                <Tooltip title='Reset DNS zone'>
                    <Button size='small' icon={<RetweetOutlined />}>Reset</Button>
                </Tooltip>
            </Popconfirm>
        </AccessItem>;

        let nameserverWarning = null;

        if(this.state.dnsType === 'EXTERNAL') {
            nameserverWarning = (<Alert type='warning' showIcon={true} message={<span>
                This domain is using an external DNS. Please modify your DNS zone at your DNS provider. <a href="https://support.priorityprospect.com/knowledgebase/article/what-means-this-domain-is-using-external-dns-warning" target="_blank" rel="noopener noreferrer">Read more</a>
            </span>} />)
        }

        return(
            <Fragment>
                {nameserverWarning !== null ? nameserverWarning : ''}
                <Card bordered={false} size='small' title='SOA' extra={resetDNSZone}>
                    <Table loading={this.state.updatingDNSRecord} columns={soa_records_columns} dataSource={records['SOA']} pagination={false} size='small' bordered={false} rowKey={(item) => item.nameserver} />
                </Card>
                <Card bordered={false} size='small' title='NS' extra={<AccessItem scopes={['can_add_domain_dns_zone_records']}>
                    <Button size='small' onClick={() => this.setState({ showDNSRecordModal: true, recordData: null, recordType: 'NS' })}><PlusOutlined /> Add NS Record</Button>
                </AccessItem>}>
                    <Table loading={this.state.updatingDNSRecord} columns={ns_records_columns} dataSource={records['NS']} pagination={false} size='small' bordered={false} rowKey={(item) => item.records[0]} />
                </Card>
                <Card bordered={false} size='small' title='A' extra={<AccessItem scopes={['can_add_domain_dns_zone_records']}>
                    <Button size='small' onClick={() => this.setState({ showDNSRecordModal: true, recordData: null, recordType: 'A' })}><PlusOutlined /> Add A Record</Button>
                </AccessItem>}>
                    <Table loading={this.state.updatingDNSRecord} columns={a_records_columns} dataSource={records['A']} pagination={false} size='small' bordered={false} rowKey={(item) => item.name} />
                </Card>
                <Card bordered={false} size='small' title='CNAME' extra={<AccessItem scopes={['can_add_domain_dns_zone_records']}>
                    <Button size='small' onClick={() => this.setState({ showDNSRecordModal: true, recordData: null, recordType: 'CNAME' })}><PlusOutlined /> Add CNAME Record</Button>
                </AccessItem>}>
                    <Table loading={this.state.updatingDNSRecord} columns={cname_records_columns} dataSource={records['CNAME']} pagination={false} size='small' bordered={false} rowKey={(item) => item.name} />
                </Card>
                <Card bordered={false} size='small' title='MX' extra={<AccessItem scopes={['can_add_domain_dns_zone_records']}>
                    <Button size='small' onClick={() => this.setState({ showDNSRecordModal: true, recordData: null, recordType: 'MX' })}><PlusOutlined /> Add MX Record</Button></AccessItem>
                }>
                    <Table loading={this.state.updatingDNSRecord} columns={mx_records_columns} dataSource={records['MX']} pagination={false} size='small' bordered={false} rowKey={(item) => item.name} />
                </Card>
                <Card bordered={false} size='small' title='TXT' extra={<AccessItem scopes={['can_add_domain_dns_zone_records']}>
                    <Button size='small' onClick={() => this.setState({ showDNSRecordModal: true, recordData: null, recordType: 'TXT' })}><PlusOutlined /> Add TXT Record</Button>
                </AccessItem>}>
                    <Table loading={this.state.updatingDNSRecord} columns={txt_records_columns} dataSource={records['TXT']} pagination={false} size='small' bordered={false} rowKey={(item) => item.name} />
                </Card>

                <DNSRecordModal
                    showModal={this.state.showDNSRecordModal}
                    data={this.state.recordData}
                    type={this.state.recordType}
                    domainName={this.state.domain}
                    domainGuid={this.props.guid}
                    reloadZone={() => this.loadDNSZone()}
                    closeModal={() => this.setState({ showDNSRecordModal: false, recordData: null, recordType: null })} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { loadDNSZone, deleteDNSRecordDomain, resetDNSZone })(DNSZone);