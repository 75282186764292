import React from "react";
import {connect} from "react-redux";
import {Alert} from "antd";
import {loadAnnouncements} from "../../actions/system";
import {displayErrors} from "../../libs/utils";

class Announcements extends React.Component {
    state = {
        announcements: []
    };

    updateAnnouncements = null;

    componentDidMount() {
        this.loadAnnouncements();

        this.updateAnnouncements = setInterval(() => {
            this.loadAnnouncements();
        }, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.updateAnnouncements);
    }

    loadAnnouncements() {
        this.props.loadAnnouncements((res) => {
            this.setState({ announcements: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined' && err.response.status !== 401) {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
       return(this.state.announcements.map((item, i) => {
           let params = {
               type: item.type.toLowerCase(),
               key: i,
               style: {marginBottom: '10px'}
           }

           if(item.show_icon) {
               params['showIcon'] = true;
           }

           if(item.title !== null && item.title !== '') {
               params['message'] = item.title;
               params['description'] = <div dangerouslySetInnerHTML={{__html: item.message}}/>;
           } else {
               params['message'] = <div dangerouslySetInnerHTML={{__html: item.message}}/>;
           }

           return <Alert {...params} />;
       }));
    }
}

export default connect(null, { loadAnnouncements })(Announcements);