import React from "react";
import {connect} from "react-redux";
import {Button, message, Form, Input, Modal, Select, Space} from "antd";
import {normalizeEnum} from "../libs/utils";

class EmailsTableFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFilter: 'SUBJECT',
            filterValue: '',
            filterKey: 'subject',
            filterVisible: null,
        };
    }

    onChange(){
        if(this.state.filterValue === null || this.state.filterValue.trim() === '') {
            return message.error('Please enter filter value!');
        }

        this.props.onChange({
            name: normalizeEnum(this.state.selectedFilter),
            key: this.state.filterKey,
            value: typeof this.state.filterValue !== 'boolean' ? this.state.filterValue.trim() : this.state.filterValue,
            visible: null
        });
    }

    closeModal(apply = false, close = true) {
        this.setState({
            selectedFilter: 'SUBJECT',
            filterValue: '',
            filterKey: 'subject',
            filterVisible: null,
        });

        if(apply) {
            this.onChange();
        }

        if(close) {
            this.props.closeModal();
        }
    }

    setFilter(name) {
        const filters = {
            SUBJECT: { key: 'subject', value: '' },
            CONTENT: { key: 'content', value: '' },
        };

        this.setState({ selectedFilter: name, filterKey: filters[name].key, filterValue: filters[name].value });
    }

    render() {
        const { Option } = Select;

        let content = '';

        if(this.state.selectedFilter === 'SUBJECT' || this.state.selectedFilter === 'CONTENT') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({filterValue: e.target.value})}/>;

        }

        return(
            <Modal
                title='Add Filter'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button onClick={() => this.closeModal(true, false)}>Apply</Button>
                    <Button type='primary' onClick={() => this.closeModal(true, true)}>Apply & Close</Button>
                </Space>}
                open={this.props.showModal}>
                <Form layout='vertical'>
                    <Form.Item label='Select filter:'>
                        <Select value={this.state.selectedFilter} onChange={(value) => this.setFilter(value)}>
                            <Option value='SUBJECT'>Subject</Option>
                            <Option value='CONTENT'>Content</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {content}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { })(EmailsTableFilter);