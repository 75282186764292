import {Redirect, Route} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom'

const PrivateRoute = ({component: Component, auth, ...rest}) => {
    if(auth.isAuthenticated) {
        return <Route {...rest} render={props => <Component {...props} />}/>
    } else if(!auth.isAuthenticated && auth.isLoading) {
        return <Route {...rest} render={props => <Component {...props} />}/>
    } else {
        return <Redirect to={'/auth/login' + window.location.search} />;
    }
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default withRouter(connect(mapStateToProps, null)(PrivateRoute));