import React from "react";
import {connect} from "react-redux";
import {Card} from "antd";
import {GoMail} from "react-icons/go";
import {FaPhoneAlt} from "react-icons/fa";

class CustomSupportProfile extends React.Component {
    render() {
        let support = this.props.support;

        return(
            <Card title={<span className='support-manager-title'>Support</span>} bordered={false} size='small'>
                <div className='support-manager-data'>
                    <p><strong>{support.name}</strong></p>
                    {support.email ? <p><GoMail className='icon' /> {support.email}</p> : ''}
                    {support.phone ? <p><FaPhoneAlt className='icon' /> {support.phone}</p> : ''}
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    support: state.auth.user.user.company.support
});

export default connect(mapStateToProps, {})(CustomSupportProfile);