import Moment from "react-moment";
import React from "react";

export const DateFormat = function(props) {
    let format = 'YYYY/MM/DD';

    if(props.value === null) {
        return <span>Unavailable</span>;
    }

    if(typeof props.format !== 'undefined') {
        format = props.format;
    }

    return <Moment date={props.value} format={format} />;
};
